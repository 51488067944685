import dayjs from 'dayjs';
import React, { useContext } from 'react';

import { clsx } from 'src/shared/utils/clsx';

import { MONTHS } from '../../constants';
import { DatepickerContext } from '../../contexts/DatepickerContext';
import { loadLanguageModule } from '../../helpers';

interface Props {
  currentMonth: number;
  clickMonth: (month: number) => void;
}

const Months: React.FC<Props> = ({ currentMonth, clickMonth }) => {
  const { i18n } = useContext(DatepickerContext);
  loadLanguageModule(i18n);
  return (
    <div className="w-full h-full grid grid-cols-4 grid-rows-3 px-[11.1px] pb-[10px]">
      {MONTHS.map((item) => (
        <button
          type="button"
          key={item}
          className={clsx(
            'text-textColor-primary text-[15px] heading-[24px] h-[44px] w-[76px] hover:bg-[#BFD1EA] hover:rounded',
            currentMonth === item &&
              'bg-brandingColor-primary-gradient text-textColor-white rounded hover:bg-brandingColor-primary-gradient-hover',
          )}
          onClick={() => clickMonth(item)}
        >
          {dayjs(`2022-${item}-01`).locale(i18n).format('MMM')}
        </button>
      ))}
    </div>
  );
};

export { Months };
