import { useState, useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useGetModelsOptionsQuery } from 'src/store/api';
import { Sidebar } from 'src/shared/ui/sidebar';
import { Button } from 'src/shared/ui/button';
import { Icons } from 'src/assets/icons';
import { Typography } from 'src/shared/ui/typography';
import { Skeleton } from 'src/shared/ui/skeleton';

const Admin = () => {
  const { data: modelsOptions = {}, isFetching: isFetchingModelsOptions } =
    useGetModelsOptionsQuery();

  const navigate = useNavigate();
  const { modelName } = useParams();

  const [isExpanded, setExpanded] = useState<boolean>(true);

  const isPageSelected = !!modelName;

  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState);
  };

  const handleNavigate = (href: string) => {
    navigate(href);
  };

  const visibleModels = useMemo(
    () => Object.values(modelsOptions).filter((model) => !model?.hidden),
    [modelsOptions],
  );

  return (
    <div className="w-full h-full bg-[#EFF0F4] flex">
      <Sidebar
        title="Tables"
        isExpanded={isExpanded}
        toggleExpanded={toggleExpanded}
        setExpanded={setExpanded}
      >
        {isExpanded && (
          <div className="flex flex-col gap-y-4 h-full overflow-auto">
            <div className="flex gap-2 flex-col">
              {!isFetchingModelsOptions
                ? visibleModels.map(({ name, label }) => {
                    return (
                      <Button
                        color="primary"
                        variant={modelName === name ? 'filled' : 'outlined'}
                        key={name}
                        endIcon={<Icons.Filled.Chevrons.ArrowIOSRightIcon />}
                        onClick={() => handleNavigate(`/admin/${name}`)}
                        className="flex justify-between"
                      >
                        {label || name}
                      </Button>
                    );
                  })
                : Array.from({ length: 5 }).map((_, i) => (
                    <Skeleton
                      className="h-[40px] rounded-xl"
                      // eslint-disable-next-line
                      key={i}
                    />
                  ))}
            </div>
          </div>
        )}
      </Sidebar>

      <div className="flex-1 p-4">
        {Object.keys(modelsOptions).length > 0 && (
          <Outlet context={{ modelsOptions, isFetchingModelsOptions }} />
        )}
        {!isPageSelected && (
          <div>
            <Typography
              variant="h3"
              className="text-center"
            >
              Select a Table to view details
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export { Admin };
