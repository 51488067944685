import { z } from 'zod';

const addDocumentSchema = z.object({
  documentType: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .nullable()
    .superRefine((data, ctx) => {
      if (data === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Document type cannot be empty',
        });
      }
    }),
  files: z.array(z.instanceof(File)).min(1, 'At least one file must be uploaded'),
});

export { addDocumentSchema };
