import { FC } from 'react';

interface ActivityProgressProps {
  progress: number;
}

const ActivityProgress: FC<ActivityProgressProps> = ({ progress }) => {
  return (
    <div className="border border-[#CFD1FF] w-[130px] h-[22px] rounded-[25px] relative">
      <div
        className="text-xs leading-[18px] font-medium text-[#9F24FF] py-0.5 px-2 bg-transparent absolute top-0 bottom-0 left-0 z-20 min-w-12 text-center"
        style={{
          width: `${progress}%`,
        }}
      >
        {`${progress} %`}
      </div>

      <div
        className="absolute top-0 bottom-0 left-0 rounded-[25px] bg-[#CFD1FF] z-10"
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
};

export { ActivityProgress };
