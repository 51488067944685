import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { match } from 'ts-pattern';

import { clsx } from 'src/shared/utils/clsx';

import { FontWeight, Variant } from './typography.types';

type TypographyProps = {
  variant: Variant;
  fontWeight?: FontWeight;
};

const Typography: FC<PropsWithChildren<HTMLAttributes<HTMLHeadingElement> & TypographyProps>> = ({
  children,
  variant,
  className,
  fontWeight,
}) => {
  const commonClasses = 'text-textColor-primary';
  const captionCommonClasses = 'text-[12px] heading-[18px]';
  const headingCommonClasses = 'font-bold';
  const fontWeightClasses = match(fontWeight)
    .with('regular', () => 'font-normal')
    .with('medium', () => 'font-medium')
    .with('semibold', () => 'font-semibold')
    .with('bold', () => 'font-bold')
    .otherwise(() => 'font-normal');

  return match(variant)
    .with('h1', () => (
      <h1
        className={clsx(
          commonClasses,
          headingCommonClasses,
          'text-[32px] leading-[40px]',
          className,
        )}
      >
        {children}
      </h1>
    ))
    .with('h2', () => (
      <h2
        className={clsx(
          commonClasses,
          headingCommonClasses,
          'text-[30px] leading-[40px]',
          className,
        )}
      >
        {children}
      </h2>
    ))
    .with('h3', () => (
      <h3
        className={clsx(
          commonClasses,
          headingCommonClasses,
          'text-[20px] leading-[24px]',
          className,
        )}
      >
        {children}
      </h3>
    ))
    .with('p1', () => (
      <p className={clsx('text-[16px] heading-[24px]', fontWeightClasses, className)}>{children}</p>
    ))
    .with('p2', () => (
      <p className={clsx('text-[14px] heading-[22px]', fontWeightClasses, className)}>{children}</p>
    ))
    .with('c1', () => (
      <p className={clsx(commonClasses, captionCommonClasses, 'font-normal', className)}>
        {children}
      </p>
    ))
    .with('c2', () => (
      <p className={clsx(commonClasses, captionCommonClasses, 'font-medium', className)}>
        {children}
      </p>
    ))
    .with('c3', () => (
      <p className={clsx(commonClasses, captionCommonClasses, 'font-bold', className)}>
        {children}
      </p>
    ))
    .with('label', () => (
      <p
        className={clsx(
          commonClasses,
          'text-textColor-secondary text-[16px] heading-[16px] font-semibold',
          className,
        )}
      >
        {children}
      </p>
    ))

    .otherwise(() => (
      <h1 className={clsx(commonClasses, headingCommonClasses, className)}>{children}</h1>
    ));
};

export { Typography };
export type { TypographyProps };
