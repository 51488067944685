import { FC } from 'react';

import { clsx } from 'src/shared/utils';

interface CheckboxHelperTextProps {
  helperText: string;
  className?: string;
}

const CheckboxHelperText: FC<CheckboxHelperTextProps> = ({ helperText, className }) => {
  return <span className={clsx('block font-normal font-inter', className)}>{helperText}</span>;
};

export { CheckboxHelperText };
