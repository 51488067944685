import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { camelToTitleCase } from 'src/shared/utils';

type Option = {
  id: string;
  value: string;
};

type FilterMenuType = 'board' | 'sidebar';

type InitialState = {
  board: { selectedFilters: Option[] };
  sidebar: { selectedFilters: Option[] };
};

const initialState: InitialState = {
  board: { selectedFilters: [] },
  sidebar: { selectedFilters: [] },
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    toggleValue: (
      state,
      action: PayloadAction<{
        type: FilterMenuType;
        value: string;
        id: string;
      }>,
    ) => {
      const { type, value, id } = action.payload;
      const { selectedFilters } = state[type];

      const selectedValuesById = selectedFilters.filter((el) => el.id === id).map((el) => el.value);
      const isValueSelected = selectedValuesById.includes(value);
      let newSelectedValues: string[] = [...selectedValuesById, value];

      if (isValueSelected) {
        newSelectedValues = selectedValuesById.filter((val) => val !== value);
      }

      const newSelectedFilters = newSelectedValues.map((el) => ({ id, value: el }));
      const allOtherFilters = selectedFilters.filter((el) => el.id !== id);
      const newFilters = [...allOtherFilters, ...newSelectedFilters];

      state[type].selectedFilters = newFilters;
    },
    toggleOptionValues: (
      state,
      action: PayloadAction<{
        type: FilterMenuType;
        optionValues: string[];
        id: string;
      }>,
    ) => {
      const { type, optionValues, id } = action.payload;
      const { selectedFilters } = state[type];

      const selectedValuesById = selectedFilters.filter((el) => el.id === id).map((el) => el.value);
      let newSelectedValues = [...new Set([...selectedValuesById, ...optionValues])];

      const isAllOptionsSelected = optionValues.every((value) =>
        selectedValuesById.includes(value),
      );

      if (isAllOptionsSelected) {
        newSelectedValues = newSelectedValues.filter((value) => !optionValues.includes(value));
      }

      const newSelectedFilters = newSelectedValues.map((el) => ({ id, value: el }));
      const allOtherFilters = selectedFilters.filter((el) => el.id !== id);
      const newFilters = [...allOtherFilters, ...newSelectedFilters];

      state[type].selectedFilters = newFilters;
    },
    setOptionValues: (
      state,
      action: PayloadAction<{
        type: FilterMenuType;
        optionValues: { id: string; options: string[] }[];
      }>,
    ) => {
      const { type, optionValues } = action.payload;

      const options = optionValues.reduce<Option[]>((acc: Option[], el) => {
        const id = camelToTitleCase(el.id);

        return [
          ...acc,
          ...el.options.reduce((acc: Option[], el) => {
            return [
              ...acc,
              {
                id,
                value: el,
              },
            ];
          }, []),
        ];
      }, []);

      state[type].selectedFilters = options;
    },
    removeFiltersById: (
      state,
      action: PayloadAction<{
        type: 'board' | 'sidebar';
        id: string;
      }>,
    ) => {
      const { type, id } = action.payload;

      const { selectedFilters } = state[type];

      state[type].selectedFilters = selectedFilters.filter((el) => el.id !== id);
    },
    removeFiltersByType: (state, action: PayloadAction<FilterMenuType>) => {
      state[action.payload].selectedFilters = [];
    },
  },
});

const filtersReducer = filtersSlice.reducer;
const filtersActions = filtersSlice.actions;

export { filtersReducer, filtersActions };
