import { FC, useMemo, useState } from 'react';

import { ActivityEntity, EntityRecord } from 'src/shared/types';

import { TableReportByActivities, TableReportByActivityStates } from './ui';

const ActivityReports: FC = () => {
  const [totalValues, setTotalValues] = useState({
    total: 0,
    totalStates: 0,
  });

  const handleSetTotal = (value: number, statesValue: number) => {
    setTotalValues({
      total: value,
      totalStates: statesValue,
    });
  };

  const [selectedEntity, setSelectedEntity] = useState<ActivityEntity>(ActivityEntity.Activities);

  const isTableByStates = useMemo(() => selectedEntity === ActivityEntity.States, [selectedEntity]);

  const handleSelectEntity = (option: EntityRecord) => {
    setSelectedEntity(option.label);
  };

  return (
    <div className="w-full px-6">
      {isTableByStates ? (
        <TableReportByActivityStates
          isSkip={!isTableByStates}
          onTotalChange={handleSetTotal}
          totalValues={totalValues}
          handleSelectEntity={handleSelectEntity}
          selectedEntity={selectedEntity}
        />
      ) : (
        <TableReportByActivities
          isSkip={isTableByStates}
          onTotalChange={handleSetTotal}
          totalValues={totalValues}
          handleSelectEntity={handleSelectEntity}
          selectedEntity={selectedEntity}
        />
      )}
    </div>
  );
};

export { ActivityReports };
