import { ComponentPropsWithRef, forwardRef, ReactElement } from 'react';
import { match } from 'ts-pattern';

import { clsx } from 'src/shared/utils/clsx';

import { FilledButton } from './ui/filledButton';
import { Color, Size, Variant } from './button.types';
import { OutlinedButton } from './ui/outlinedButton';
import { GhostButton } from './ui/ghostButton';

type ButtonProps = ComponentPropsWithRef<'button'> & {
  size?: Size;
  variant?: Variant;
  color?: Color;
  darkBg?: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  iconClassName?: string;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      size = 'md',
      color = 'basic',
      variant = 'filled',
      darkBg,
      startIcon,
      endIcon,
      ...props
    },
    ref,
  ) => {
    const commonClasses = `inline-flex justify-center justify-items-center text-center 
                           text-textColor-primary 
                           font-bold 
                           transition-[filter] duration-200 ease-in-out`;
    const sizeClasses = match(size)
      .with('sm', () => 'h-[32px] text-xs py-[8px] px-[8px] min-w-[83px] rounded-lg')
      .with('md', () => 'h-[40px] text-sm py-[10px] px-[8px] min-w-[95px] rounded-xl gap-x-[8px]')
      .with(
        'lg',
        () => 'h-[48px] text-base py-[12px] px-[12px] min-w-[108px] rounded-lg gap-x-[4px]',
      )
      .exhaustive();

    const classes = clsx(
      'flex items-center',
      commonClasses,
      sizeClasses,
      startIcon && 'pl-[0px]',
      endIcon && 'pr-[0px]',
    );

    const content = match(variant)
      .with('filled', () => (
        <FilledButton
          ref={ref}
          color={color}
          className={className}
          buttonClassName={classes}
          size={size}
          endIcon={endIcon}
          startIcon={startIcon}
          darkBg={darkBg}
          {...props}
        >
          {children}
        </FilledButton>
      ))
      .with('outlined', () => (
        <OutlinedButton
          ref={ref}
          color={color}
          className={className}
          buttonClassName={classes}
          size={size}
          endIcon={endIcon}
          startIcon={startIcon}
          darkBg={darkBg}
          {...props}
        >
          {children}
        </OutlinedButton>
      ))
      .with('ghost', () => (
        <GhostButton
          ref={ref}
          color={color}
          className={className}
          buttonClassName={classes}
          size={size}
          endIcon={endIcon}
          startIcon={startIcon}
          darkBg={darkBg}
          {...props}
        >
          {children}
        </GhostButton>
      ))
      .otherwise(() => (
        <FilledButton
          ref={ref}
          color={color}
          className={className}
          buttonClassName={classes}
          size={size}
          endIcon={endIcon}
          startIcon={startIcon}
          darkBg={darkBg}
          {...props}
        >
          {children}
        </FilledButton>
      ));

    return content;
  },
);

Button.displayName = 'Button';

export { Button };
export type { ButtonProps };
