import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { FC, useEffect, useMemo, useState } from 'react';

import { Card } from 'src/shared/ui/card';
import { useGetJELLItemsQuery, useLazyGetJELLDetailsQuery } from 'src/store/api';
import { Icon } from 'src/shared/ui/icon';
import { ReactComponent as CarIcon } from 'src/assets/icons/outlined/shop/car.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/outlined/controls/filter.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { ReactComponent as CheckmarkCircleIcon } from 'src/assets/icons/outlined/edit/checkmark-circle.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { Typography } from 'src/shared/ui/typography';
import { IconButton } from 'src/shared/ui/iconButton';
import { Switch } from 'src/shared/ui/switch';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { LaborJELLDetail, JobEntity, Role, TicketEntity } from 'src/shared/types';
import { selectConfig, selectCurrentUser, selectModalConfig } from 'src/store/slices';
import { clsx, not } from 'src/shared/utils';
import { DetailsSkeleton } from 'src/shared/ui/skeleton/ui/detailsSkeleton';
import { useToggle } from 'src/shared/hooks/useToggle';

import { CreateAdditionalItemsModal } from './components/createAdditionalItems';
import { DeleteItemModal } from './components/deleteItem';
import { EditItemModal } from './components/editItem';

type RequiredLaborTableProps = {
  ticket: TicketEntity;
  job: JobEntity;
  withActionButtons?: boolean;
};

const RequiredLaborTable: FC<RequiredLaborTableProps> = ({ ticket, job, withActionButtons }) => {
  const [
    getJELLDetails,
    {
      data = {
        requiredEquipment: [],
        requiredLabor: [],
      },
      isLoading,
    },
  ] = useLazyGetJELLDetailsQuery();

  const {
    data: JELLItems = {
      requiredEquipment: [],
      requiredLabor: [],
    },
  } = useGetJELLItemsQuery(ticket?.JELL?.EquipmentKitID || '', {
    skip: not(ticket?.JELL?.EquipmentKitID),
  });

  const user = useSelector(selectCurrentUser);
  const modalConfig = useSelector(selectModalConfig);

  const [openModalId, setOpenModalId] = useState<'create' | 'edit' | 'delete' | null>(null);
  const [selectedItemId, setSelectedItemId] = useState('');

  const { requiredLabor } = data;
  const laborJELLItems = JELLItems.requiredLabor;

  const isCreateModalOpen = openModalId === 'create' || modalConfig.openModalId === 'CreateLabor';
  const isEditModalOpen = openModalId === 'edit';
  const isDeleteModalOpen = openModalId === 'delete';

  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;
  const isNotReadonly = not(isReadonly);

  const openEditModal = (selectedItemId: string) => {
    setOpenModalId('edit');
    setSelectedItemId(selectedItemId);
  };

  const openDeleteModal = (selectedItemId: string) => {
    setOpenModalId('delete');
    setSelectedItemId(selectedItemId);
  };

  const openCreateModal = () => {
    setOpenModalId('create');
  };

  const requiredLaborHeaders: TableHeaderType<LaborJELLDetail>[] = useMemo(
    () => [
      {
        title: 'Filled',
        field: 'Filled',
        render: (el) =>
          el.ProviderPersonnelID && (
            <Icon
              size="sm"
              icon={<CheckmarkCircleIcon />}
              className="fill-semanticColor-success mx-auto my-0"
            />
          ),
        className: 'w-[50px]',
      },
      {
        title: 'Employee ID',
        field: 'Employee ID',
        render: (el) => {
          if (not(el.ProviderPersonnel)) return '';
          const { FirstName, LastName } = el.ProviderPersonnel;

          return `${FirstName} ${LastName} (${el.ProviderPersonnelID})`;
        },
        className: 'w-[100px]',
      },
      {
        title: 'Role',
        field: 'Role',
        render: (el) => {
          const isCrewLeader = ticket?.crewLeader?.ProviderPersonnel?.id === el.ProviderPersonnelID;

          return isCrewLeader ? 'Crew Leader' : 'Technician';
        },
        className: 'w-[100px]',
      },
      {
        title: 'Item Code',
        field: 'Item Code',
        render: (el) => el?.ContractRateSheet?.Itemcode,
        className: 'w-[50px]',
      },
      {
        title: 'Description',
        field: 'Description',
        render: (el) => el?.ContractRateSheet?.CustomItemDescription,
        className: 'w-[125px]',
      },
      {
        title: 'UOM',
        field: 'UOM',
      },
      {
        title: 'QTY',
        field: 'QTY',
      },
      {
        title: 'Category',
        field: 'Category',
        render: (el) => el?.ContractRateSheet?.ItemCodeCategoryName,
      },
      {
        title: 'Bill Type',
        field: 'Bill Type',
        render: (el) => el?.ContractRateSheet?.BillType,
      },
      {
        title: 'JELL',
        field: 'JELL',
        render: (el) => {
          const isFromJEL = laborJELLItems.some(
            (item) => item?.ContractRateSheet?.Itemcode === el?.ContractRateSheet?.Itemcode,
          );

          return isFromJEL ? 'Yes' : 'No';
        },
      },
      {
        title: 'Actions',
        field: 'Actions',
        className: 'w-[70px]',
        render: (el) => {
          const isFromJEL = laborJELLItems.some(
            (item) => item?.ContractRateSheet?.Itemcode === el?.ContractRateSheet?.Itemcode,
          );
          const withEditButton = not(isFromJEL) && not(el.ProviderPersonnelID);
          const withTrashButton = not(isFromJEL) && not(el.ProviderPersonnelID);

          return (
            isNotReadonly &&
            withActionButtons && (
              <div className="flex gap-1">
                {/* eslint-disable-next-line etc/no-commented-out-code */}
                {/* {withEditButton && (
                  <IconButton
                    size="sm"
                    onClick={() => openEditModal(el.PeopleWorkRecordID)}
                  >
                    <EditIcon />
                  </IconButton>
                )} */}

                {withTrashButton && (
                  <IconButton
                    size="sm"
                    onClick={() => openDeleteModal(el.PeopleWorkRecordID)}
                  >
                    <TrashIcon />
                  </IconButton>
                )}
              </div>
            )
          );
        },
      },
    ],
    [ticket?.crewLeader?.ProviderPersonnel?.id, laborJELLItems, isNotReadonly, withActionButtons],
  );

  const laborBlock = requiredLabor.length ? (
    <>
      <div className="flex justify-between">
        <div className="flex justify-between items-center gap-2">
          <div className="w-6 h-6">
            <Icon
              icon={<CarIcon />}
              className="fill-brandingColor-primary-gradient w-6 h-6"
            />
          </div>

          <Typography
            variant="p1"
            fontWeight="bold"
          >
            Labor
          </Typography>
        </div>

        <div className="flex gap-5 items-center">
          {isNotReadonly && withActionButtons && (
            <IconButton
              color="basic"
              iconSize="md"
              iconClassName="fill-textColor-tertiary"
              onClick={openCreateModal}
            >
              <PlusIcon />
            </IconButton>
          )}
        </div>
      </div>

      <Table
        headers={requiredLaborHeaders}
        data={requiredLabor}
        scroll
        containerClassName="max-h-[225px]"
        idProvider={(el: LaborJELLDetail) =>
          `${
            (el?.ContractRateSheet?.Itemcode || '') +
            (el?.ContractRateSheet?.CustomItemDescription || '') +
            uuidv4()
          }`
        }
      />
    </>
  ) : (
    <div className="flex justify-between items-center gap-3">
      <Typography variant="p1">Required Labor was not found</Typography>

      {isNotReadonly && withActionButtons && (
        <IconButton
          color="basic"
          iconSize="md"
          iconClassName="fill-textColor-tertiary"
          onClick={openCreateModal}
        >
          <PlusIcon />
        </IconButton>
      )}
    </div>
  );

  useEffect(() => {
    getJELLDetails(ticket.id as string);
  }, [getJELLDetails, ticket]);

  if (isLoading) {
    return <DetailsSkeleton variant="requirements" />;
  }

  return (
    <>
      <Card title={laborBlock} />

      {isCreateModalOpen && (
        <CreateAdditionalItemsModal
          ticket={ticket}
          rateSheeetId={job.ownerContract.RateSheetID}
          isOpen={isCreateModalOpen}
          setIsOpen={setOpenModalId}
          type="Labor"
        />
      )}

      {isDeleteModalOpen && (
        <DeleteItemModal
          ticket={ticket}
          isOpen={isDeleteModalOpen}
          setIsOpen={setOpenModalId}
          selectedItemId={selectedItemId}
          type="Labor"
        />
      )}

      {isEditModalOpen && (
        <EditItemModal
          ticket={ticket}
          isOpen={isEditModalOpen}
          setIsOpen={setOpenModalId}
          selectedItemId={selectedItemId}
          type="Labor"
        />
      )}
    </>
  );
};

export { RequiredLaborTable };
