import { FC } from 'react';

import { clsx } from 'src/shared/utils';
import { DropDown } from 'src/shared/ui/dropDown';
import { PageSize } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as ChevronDownIcon } from 'src/assets/icons/filled/chevrons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'src/assets/icons/filled/chevrons/chevron-up.svg';

type PageSizeDropDownProps = {
  sizes: PageSize[];
  currentSize: PageSize;
  onSelect: (pageSize: PageSize) => void;
};

const PageSizeDropDown: FC<PageSizeDropDownProps> = ({ sizes, onSelect, currentSize }) => {
  const selectSize = (pageSize: PageSize) => {
    onSelect(pageSize);
  };

  return (
    <div className="flex items-center gap-x-2">
      <Typography
        variant="p2"
        className="text-textColor-secondary"
      >
        Items Per Page
      </Typography>

      <DropDown
        options={{ placement: 'bottom-start' }}
        config={{
          itemsElementClassName: clsx(
            'shadow-[0px_2px_66px_-10px_#0000000F]',
            'w-[72px]',
            'rounded-lg',
            'bg-bgColor-card',
          ),
        }}
        renderElement={(isOpen) => (
          <div
            role="button"
            key={currentSize}
            onClick={() => selectSize(currentSize)}
            className={clsx(
              'flex gap-x-2 items-center py-2 px-3 w-[72px] h-[32px] text-sm rounded-[4px] cursor-pointer font-semibold text-center border border-[#F5F5F5] text-textColor-primary duration-200 transition-all hover:text-brandingColor-primary-gradient hover:border-[#1F1F1F] active:text-brandingColor-primary-gradient active:bg-[rgba(18,60,142,0.1)]',
              isOpen && 'border-[#1F1F1F]',
            )}
          >
            <span className="text-[#636874]">{currentSize}</span>

            {isOpen ? <ChevronUpIcon fill="black" /> : <ChevronDownIcon fill="black" />}
          </div>
        )}
        items={sizes.map((size) => {
          const isCurrentSize = size === currentSize;

          return {
            value: size,
            label: (
              <div
                role="button"
                key={size}
                onClick={() => selectSize(size)}
                className={clsx(
                  'bg-white py-2 px-3 text-sm cursor-pointer font-semibold text-center text-textColor-secondary hover:bg-[rgba(18,60,142,0.05)] hover:text-textColor-primary duration-200 transition-all active:text-brandingColor-primary-gradient active:bg-[rgba(18,60,142,0.1)]',
                  isCurrentSize && 'text-brandingColor-primary-gradient bg-[rgba(18,60,142,0.1)]',
                )}
              >
                <span>{size}</span>
              </div>
            ),
          };
        })}
      />
    </div>
  );
};

export { PageSizeDropDown };
