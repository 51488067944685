import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as LogoutIcon } from 'src/assets/icons/outlined/account/log-out.svg';
import { Typography } from 'src/shared/ui/typography';
import { RootState } from 'src/store';
import { Button } from 'src/shared/ui/button';
import { DetailsSkeleton } from 'src/shared/ui/skeleton/ui/detailsSkeleton';
import { useGetPersonQuery, useLogoutMutation } from 'src/store/api';
import { selectModalConfig } from 'src/store/slices/modalConfig';

// ts can't resolve package.json when using an absolute import
import packageJson from '../../../../package.json';

const ProfileDetails = () => {
  const modalConfig = useSelector(selectModalConfig);
  const { user } = useSelector((state: RootState) => state.auth);
  const [logout] = useLogoutMutation();

  const { data: person, isLoading } = useGetPersonQuery(
    modalConfig.openProfileModalPersonEmail || '',
  );

  const isMyProfileOpen = user?.email === modalConfig.openProfileModalPersonEmail;

  const everyUndefined = /undefined/g;
  const onlySpace = /^ $/;

  const fullName = `${person?.firstName} ${person?.lastName}`
    .replace(everyUndefined, '')
    .replace(onlySpace, '-');

  const handleLogout = () => {
    logout({});
  };

  if (isLoading) {
    return <DetailsSkeleton variant="profile" />;
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-5 border border-solid border-textColor-light rounded-2xl p-[18px]">
          <div className="flex justify-between items-center gap-2">
            <div className="flex flex-col gap-1">
              <Typography
                variant="h3"
                className="break-all"
              >
                {fullName}
              </Typography>

              <Typography
                fontWeight="medium"
                variant="p1"
              >
                {person?.jobTitle}
              </Typography>
            </div>

            {isMyProfileOpen && (
              <Button
                variant="outlined"
                size="lg"
                onClick={handleLogout}
                endIcon={<LogoutIcon />}
                iconClassName="fill-textColor-danger"
                className="!text-textColor-danger !border-textColor-danger w-[125px] justify-evenly"
              >
                Logout
              </Button>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-5 border border-solid border-textColor-light rounded-2xl pt-4 px-4 pb-[28px]">
          <div className="flex flex-col gap-2">
            <Typography
              variant="p1"
              fontWeight="bold"
            >
              Personal Information
            </Typography>
          </div>

          <div className="flex justify-between gap-5">
            <div className="w-[50%] break-all">
              <Typography variant="label">Last Name</Typography>

              <Typography
                fontWeight="medium"
                variant="p1"
                className="break-all"
              >
                {person?.lastName || '-'}
              </Typography>
            </div>

            <div className="w-[50%] break-all">
              <Typography variant="label">First Name</Typography>

              <Typography
                fontWeight="medium"
                variant="p1"
                className="break-all"
              >
                {person?.firstName || '-'}
              </Typography>
            </div>
          </div>

          <div className="flex justify-between gap-5">
            <div className="w-[50%] break-all">
              <Typography variant="label">Email address</Typography>

              <Typography
                fontWeight="medium"
                variant="p1"
                className="break-all"
              >
                {person?.providerPersonnelEmail || '-'}
              </Typography>
            </div>

            <div className="w-[50%] break-all">
              <Typography variant="label">Phone</Typography>

              <Typography
                fontWeight="medium"
                variant="p1"
                className="max-w-[221px] break-all"
              >
                {person?.providerPersonnelPhone || '-'}
              </Typography>
            </div>
          </div>

          <div className="flex justify-between gap-5">
            <div className="w-[50%]">
              <Typography variant="label">Job</Typography>

              <Typography
                fontWeight="medium"
                variant="p1"
                className="break-all"
              >
                {person?.jobTitle || '-'}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <Typography
        variant="label"
        className="text-textColor-tertiary"
      >
        {`Version: ${packageJson.version}`}
      </Typography>
    </div>
  );
};

export { ProfileDetails };
