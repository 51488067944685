import dayjs from 'dayjs';
import { FC } from 'react';

import { SafetyListItem } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import { Typography } from 'src/shared/ui/typography';

type PopupBodyProps = {
  item: SafetyListItem;
};

const SafetyPopupBody: FC<PopupBodyProps> = ({ item }) => {
  const {
    createdAt,
    unit,
    surveyLink,
    surveyType,
    surveySubType,
    jsaRiskScore,
    surveyPublicLink,
    createdBy,
    jobNum,
  } = item;
  const details = [
    {
      label: 'Date',
      value: dayjs.utc(createdAt).format('M/D/YYYY HH:mm'),
    },
    {
      label: 'Completed By',
      value: createdBy,
    },
    {
      label: 'Type',
      value: surveyType.name,
    },
    {
      label: 'Sub Type',
      value: surveySubType.name,
    },
    {
      label: 'Risk score',
      value: jsaRiskScore,
    },
    {
      label: 'Job Number',
      value: jobNum,
    },
  ];

  return (
    <div className="flex flex-col gap-2 divide-y">
      <Typography
        className="font-semibold"
        variant="p1"
      >
        Unit - {unit.name}
      </Typography>

      <div className="flex flex-col gap-2 py-2">
        {details.map(({ label, value }) => (
          <Typography
            variant="p1"
            key={label}
          >
            <span className="font-semibold">{label}:</span>{' '}
            <span>{value || value === 0 ? value : 'N/A'}</span>
          </Typography>
        ))}
      </div>

      <div className="py-2 flex gap-2 items-center flex-wrap">
        {surveyLink && (
          <a href={surveyLink}>
            <Button variant="outlined">Go to Answer</Button>
          </a>
        )}
        {surveyPublicLink && (
          <a href={surveyPublicLink}>
            <Button variant="outlined">Go to Public Answer</Button>
          </a>
        )}
      </div>
    </div>
  );
};

export { SafetyPopupBody };
