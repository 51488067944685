export const pgTabIcons = {
  "appearance": "icon-pg-v1/pg-appearance-24x24",
  "background": "icon-pg-v1/pg-background-24x24",
  "columns": "icon-pg-v1/pg-columns-24x24",
  "logic": "icon-pg-v1/pg-conditions-24x24",
  "data": "icon-pg-v1/pg-data-24x24",
  "design": "icon-pg-v1/pg-design-24x24",
  "general": "icon-pg-v1/pg-general-24x24",
  "header": "icon-pg-v1/pg-header-24x24",
  "language": "icon-pg-v1/pg-language-24x24",
  "layout": "icon-pg-v1/pg-layout-24x24",
  "logo": "icon-pg-v1/pg-logo-24x24",
  "navigation": "icon-pg-v1/pg-navigation-24x24",
  "pages": "icon-pg-v1/pg-pages-24x24",
  "question": "icon-pg-v1/pg-questiondesign-24x24",
  "timer": "icon-pg-v1/pg-quiz-24x24",
  "rows": "icon-pg-v1/pg-rows-24x24",
  "specific": "icon-pg-v1/pg-specific-24x24",
  "showOnCompleted": "icon-pg-v1/pg-thankyyoupage-24x24",
  "themes": "icon-pg-v1/pg-themes-24x24",
  "totals": "icon-pg-v1/pg-totals-24x24",
  "validation": "icon-pg-v1/pg-validation-24x24",
  "undefined": "icon-pg-v1/pg-undefined-24x24",
};