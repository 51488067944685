import { Owner } from 'src/shared/types';
import { api } from 'src/store/api/api';

const ownerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOwners: builder.query<Owner[], ''>({
      query: () => ({
        url: '/owners',
      }),
      providesTags: ['Owners'],
      transformResponse: (response: { data: Owner[] }) => response.data,
    }),
  }),
});

export const { useGetOwnersQuery } = ownerApi;
