const METRIC_HEIGHT = 78;
const METRIC_SKELETON_COUNT = 6;
const CARD_BORDER_RADIUS = 4;
const CHART_HEIGHT = 468;
const DEFAULT_COLOR_BAR = '#8884D8';
const SECONDARY_COLOR_BAR = '#B3CDAD';

enum ChartComponentName {
  PieChartView = 'PieChartView',
  BarChartView = 'BarChartView',
  StackedBarChartView = 'StackedBarChartView',
}

export {
  METRIC_HEIGHT,
  METRIC_SKELETON_COUNT,
  CARD_BORDER_RADIUS,
  CHART_HEIGHT,
  DEFAULT_COLOR_BAR,
  SECONDARY_COLOR_BAR,
  ChartComponentName,
};
