import { api } from 'src/store/api/api';
import { ProjectsStatsBySurveyAnswers } from 'src/shared/types';

const inspectorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTopProjectsStatsBySurveyAnswers: builder.query<ProjectsStatsBySurveyAnswers, ''>({
      query: () => ({
        url: '/admin/projects/stats/survey-answers',
        method: 'GET',
      }),
      providesTags: ['Projects'],
      transformResponse: (response: { data: ProjectsStatsBySurveyAnswers }) => response.data,
    }),
  }),
});

export const { useGetTopProjectsStatsBySurveyAnswersQuery } = inspectorApi;
