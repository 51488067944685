import axios from 'axios';
import { useState } from 'react';

import { showToastErrorMessage } from '../utils';

const usePostUploadNewJobs = () => {
  const [isLoadingNewJobs, setIsLoadingNewJobs] = useState(false);
  const [newJobsResponse, setNewJobsResponse] = useState<null | string>(null);

  const postNewJobs = async ({
    formData,
    pathname,
    key,
  }: {
    formData: FormData;
    pathname: string;
    key: string;
  }) => {
    setIsLoadingNewJobs(true);
    try {
      const response = await axios.post(`${pathname}?code=${key}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response?.data?.message) {
        setNewJobsResponse(response.data.message);
      }
      setIsLoadingNewJobs(false);
    } catch (error) {
      showToastErrorMessage('Failed to upload file.');
      setIsLoadingNewJobs(false);
    }
  };

  return {
    isLoadingNewJobs,
    postNewJobs,
    newJobsResponse,
  };
};

export { usePostUploadNewJobs };
