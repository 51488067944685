import { FC, PropsWithChildren } from 'react';
import { match } from 'ts-pattern';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid, GridCellProps } from 'react-virtualized';
import { useSelector } from 'react-redux';

import { EquipmentEntity, Role, TicketEntity } from 'src/shared/types';
import { EquipmentCard } from 'src/shared/ui/equipmentCard';
import { not, sortAssignedEquipment } from 'src/shared/utils';
import { clsx } from 'src/shared/utils/clsx';
import { selectCurrentUser } from 'src/store/slices';
import { Typography } from 'src/shared/ui/typography';

import 'react-virtualized/styles.css';
import { EquipmentDropfield } from './ui/equipmentDropfield';
import { UniversalEquipmentDropfield } from './ui/universalEquipmentDropfield';

type EquipmentListProps = {
  assignedEquipment?: string[];
  equipment?: EquipmentEntity[];
  cardId?: string;
  ticket?: TicketEntity;
  isDropDisabled?: boolean;
  isTicketCard?: boolean;
  onEquipmentDrop?: (item: EquipmentEntity, key: string) => void;
};

const EquipmentList: FC<PropsWithChildren<EquipmentListProps>> = ({
  assignedEquipment,
  equipment = [],
  cardId,
  ticket,
  onEquipmentDrop,
  isDropDisabled = false,
  isTicketCard = false,
}) => {
  const user = useSelector(selectCurrentUser);

  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const requiredEquipmentList =
    ticket?.equipment.filter((el) => el.RequiresUnitNumber === 'Yes') || [];

  const filteredRequiredEquipmentList = requiredEquipmentList.filter((el) =>
    el.IsRental ? not(el.RentalUnitNumber) : not(el.UnitNumber),
  );

  const cache = new CellMeasurerCache({
    defaultHeight: 68,
    fixedWidth: true,
  });

  const sortedEquipment = sortAssignedEquipment(equipment);

  const cellRenderer = ({ key, rowIndex, style, parent }: GridCellProps) => {
    const item = sortedEquipment[rowIndex];

    if (not(item)) return null;

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          key={key}
          style={{
            ...style,
            paddingBottom: 4,
          }}
        >
          <EquipmentCard
            disabled={assignedEquipment?.includes(item.AssetID)}
            key={`${item.AssetID} ${item.UnitNo} ${item.IsRental}`}
            cardId={cardId}
            ticket={ticket}
            clearAfterDrag
            {...item}
          />
        </div>
      </CellMeasurer>
    );
  };

  const content = match(sortedEquipment.length)
    .when(
      (equipmentLength) => equipmentLength === 0 && not(isDropDisabled) && isTicketCard,
      () => (
        <div className="flex flex-col gap-3">
          {filteredRequiredEquipmentList.map((el) => (
            <EquipmentDropfield
              key={el.KITItemsRecordID}
              sourceEntityId={el.KITItemsRecordID}
              title={`Drop ${el.ContractRateSheet.CustomItemDescription} here to assign`}
              isDropDisabled={isDropDisabled}
              onEquipmentDrop={onEquipmentDrop}
            />
          ))}

          {not(isReadonly) && (
            <UniversalEquipmentDropfield
              ticket={ticket}
              isDropDisabled={isDropDisabled}
            />
          )}

          {not(requiredEquipmentList.length) && isReadonly && (
            <EquipmentDropfield
              title="There is no required equipment"
              isDropDisabled={isDropDisabled}
            />
          )}
        </div>
      ),
    )
    .when(
      (equipmentLength) =>
        equipmentLength === 0 &&
        isTicketCard &&
        isDropDisabled &&
        filteredRequiredEquipmentList.length,
      () => (
        <>
          {filteredRequiredEquipmentList.map((el) => (
            <EquipmentDropfield
              key={el.KITItemsRecordID}
              title={`${el.ContractRateSheet.CustomItemDescription} was not assigned`}
              sourceEntityId={el.KITItemsRecordID}
              isDropDisabled={isDropDisabled}
              onEquipmentDrop={onEquipmentDrop}
            />
          ))}
        </>
      ),
    )
    .when(
      (equipmentLength) =>
        equipmentLength === 0 && isTicketCard && not(filteredRequiredEquipmentList.length),
      () => (
        <EquipmentDropfield
          title="There was no required equipment"
          isDropDisabled={isDropDisabled}
        />
      ),
    )
    .when(
      (equipmentLength) => equipmentLength === 0 && not(isTicketCard),
      () => <Typography variant="p1">There is no equipment matching search criteria</Typography>,
    )
    .when(
      (equipmentLength) => equipmentLength > 0 && isTicketCard,
      () => (
        <div className="flex flex-col gap-2">
          <div className={clsx('grid grid-cols-1 gap-2 relative')}>
            {isDropDisabled &&
              filteredRequiredEquipmentList.map((el) => (
                <EquipmentDropfield
                  key={el.KITItemsRecordID}
                  title={`${el.ContractRateSheet.CustomItemDescription} was not assigned`}
                  sourceEntityId={el.KITItemsRecordID}
                  isDropDisabled={isDropDisabled}
                  onEquipmentDrop={onEquipmentDrop}
                />
              ))}

            {not(isDropDisabled) &&
              filteredRequiredEquipmentList.map((el) => (
                <div
                  key={el.KITItemsRecordID}
                  className="relative"
                >
                  <EquipmentDropfield
                    title={`Drop ${el.ContractRateSheet.CustomItemDescription} here to assign`}
                    sourceEntityId={el.KITItemsRecordID}
                    isDropDisabled={isDropDisabled}
                    onEquipmentDrop={onEquipmentDrop}
                  />
                </div>
              ))}

            {sortedEquipment.map((equipment) => (
              <EquipmentCard
                disabled={
                  (isTicketCard && isDropDisabled) || assignedEquipment?.includes(equipment.AssetID)
                }
                isTicketCard={isTicketCard}
                key={
                  isTicketCard
                    ? equipment.KITItemsRecordID
                    : `${equipment.AssetID} ${equipment.UnitNo} ${equipment.IsRental}`
                }
                cardId={cardId}
                ticket={ticket}
                clearAfterDrag
                {...equipment}
              />
            ))}

            {not(isReadonly) && not(isDropDisabled) && (
              <UniversalEquipmentDropfield
                ticket={ticket}
                isDropDisabled={isDropDisabled}
              />
            )}
          </div>
        </div>
      ),
    )
    .when(
      (equipmentLength) => equipmentLength > 0 && not(isTicketCard),
      () => (
        <div className="h-[550px]">
          <AutoSizer>
            {({ height, width }) => (
              <Grid
                cellRenderer={cellRenderer}
                columnCount={1}
                columnWidth={width - 15}
                height={height}
                rowCount={sortedEquipment.length}
                rowHeight={(index) => cache.rowHeight(index) + 4}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      ),
    )
    .otherwise(() => null);

  return content;
};

export { EquipmentList };
export type { EquipmentListProps };
