import { forwardRef, PropsWithChildren, useState } from 'react';
import { useDrop } from 'react-dnd';
import { mergeRefs } from 'react-merge-refs';
import { useSelector } from 'react-redux';

import { not } from 'src/shared/utils';
import { TicketStatus, type JobEntity, type TicketEntity } from 'src/shared/types';
import { clsx } from 'src/shared/utils/clsx';
import { Card } from 'src/shared/ui/card';
import { CreateOrUpdateTicketModal } from 'src/shared/ui/modals/createOrUpdateTicket/createOrUpdateTicket';
import { CopyTicketModal } from 'src/shared/ui/modals/copyTicket';
import { Details, TicketDetails, RequirementsDetails } from 'src/shared/ui/details';
import { useAppDispatch } from 'src/store';
import { modalConfigActions, selectModalConfig } from 'src/store/slices';

import { ticketStatusOptions } from './constants';
import {
  TicketCardSupervisorsList,
  TicketCardPeopleList,
  TicketCardEquipmentList,
  TicketCardTitle,
} from './components';

type TicketCardProps = {
  ticket: TicketEntity;
  job: JobEntity;
} & PropsWithChildren;

const TicketCard = forwardRef<HTMLDivElement, TicketCardProps>(({ ticket, job }, ref) => {
  const dispatch = useAppDispatch();

  const modalConfig = useSelector(selectModalConfig);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const ticketStatusOption = ticketStatusOptions.find((option) => option.text === ticket.status);

  const isReadonlyStatus = ticketStatusOptions.every((status) => status.text !== ticket.status);
  const isTicketDropDisabled =
    ticketStatusOption?.text === TicketStatus.Scheduled || isReadonlyStatus;

  const toggleIsRequirementsDetailsOpen = (isOpen: boolean) => {
    if (modalConfig.openAdditionalEquipmentModalTicketId === ticket.id) {
      dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));

      return;
    }

    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(isOpen ? ticket.id : ''));
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['person', 'supervisor', 'crewLeader', 'equipment'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <>
      <Card
        iconSize="md"
        ref={mergeRefs([ref, drop])}
        title={
          <TicketCardTitle
            ticket={ticket}
            setIsEditModalOpen={setIsEditModalOpen}
            setIsCopyModalOpen={setIsCopyModalOpen}
            setIsDetailsModalOpen={setIsDetailsModalOpen}
          />
        }
        className={clsx(
          'border gap-y-3 bg-bgColor-ticketCard shadow-[0px_2px_66px_-10px_#0000000F]',
          isOver && not(isTicketDropDisabled)
            ? 'border-brandingColor-primary-gradient'
            : 'border-[#BDC7D9]',
        )}
      >
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-3">
            <TicketCardSupervisorsList
              ticket={ticket}
              job={job}
              isTicketDropDisabled={isTicketDropDisabled}
            />

            <TicketCardPeopleList
              ticket={ticket}
              job={job}
              isTicketDropDisabled={isTicketDropDisabled}
            />

            <TicketCardEquipmentList
              ticket={ticket}
              job={job}
              isTicketDropDisabled={isTicketDropDisabled}
            />
          </div>
        </div>
      </Card>

      <CreateOrUpdateTicketModal
        type="update"
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        job={job}
        ticket={ticket}
        date={ticket?.startDate}
      />

      <CopyTicketModal
        isOpen={isCopyModalOpen}
        setIsOpen={setIsCopyModalOpen}
        ticket={ticket}
        job={job}
      />

      <Details
        setIsOpen={(isOpen) => setIsDetailsModalOpen(isOpen)}
        setIsEditMenuOpen={(isOpen) => setIsEditModalOpen(isOpen)}
        isOpen={isDetailsModalOpen}
        title={ticket.projectName}
        hasOutsideClick
      >
        <TicketDetails
          ticket={ticket}
          job={job}
        />
      </Details>

      <Details
        isOpen={modalConfig.openAdditionalEquipmentModalTicketId === ticket.id}
        setIsOpen={toggleIsRequirementsDetailsOpen}
        title="Requirements"
        hasHiddenBackground={false}
        className="w-[900px]"
      >
        <RequirementsDetails
          job={job}
          ticket={ticket}
          title={
            job?.isJELLEnforced
              ? job?.JELL?.AdditionalKitDescription1
              : ticket?.JELL?.AdditionalKitDescription1
          }
          withActionButtons={not(isTicketDropDisabled)}
        />
      </Details>
    </>
  );
});

TicketCard.displayName = 'TicketCard';
export { TicketCard };
