import { useMediaQuery } from '@mui/material';
import { breakpointValues } from './useBreakpoints';

interface DeviceBreakpoints {
  isSmallScreen: boolean;
  isMediumScreen: boolean;
  isLargeScreen: boolean;
}

export const useScreenBreakpoints = (): DeviceBreakpoints => {
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpointValues.sm}px)`);
  const isMediumScreen = useMediaQuery(
    `(min-width: ${breakpointValues.md + 1}px) and (max-width: ${breakpointValues.lg - 1}px)`,
  );
  const isLargeScreen = useMediaQuery(`(min-width: ${breakpointValues.lg}px)`);

  return {
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
  };
};
