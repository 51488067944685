import { LayersControl, MapContainer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';

import { MarkerMap } from './components/MarkerMap';
import { TileLayerMap } from './components/TileLayerMap';
import { SearchFieldMap } from './components/SearchFieldMap';
import { SearchResult, RawResult } from 'src/shared/types';
import './style.css';
import { ReactNode, useState } from 'react';
import { LatLngExpression } from 'leaflet';
import { SelectedAddressMarker } from './components/SelectedAddressMarker';
import { MAP_DEFAULT_VIEW, MAP_DEFAULT_ZOOM, MAP_MAX_ZOOM } from './constants';

type MarkerProps = {
  latitude: number;
  longitude: number;
  id: string;
};

interface LeafletMapProps<Marker extends MarkerProps> {
  markers: Marker[];
  isSearchVisible: boolean;
  center?: LatLngExpression;
  zoom?: number;
  renderPopupBody: (data: Marker) => ReactNode;
}

const LeafletMap = <Marker extends MarkerProps>({
  markers,
  isSearchVisible,
  center = MAP_DEFAULT_VIEW,
  zoom = MAP_DEFAULT_ZOOM,
  renderPopupBody,
}: LeafletMapProps<Marker>) => {
  const [address, setAddress] = useState('');
  const [selectedAddress, setSelectedAddress] = useState<SearchResult<RawResult> | null>(null);

  return (
    <MapContainer
      doubleClickZoom
      center={center}
      zoom={zoom}
      maxZoom={MAP_MAX_ZOOM}
      scrollWheelZoom
      zoomDelta={1}
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      <LayersControl position="bottomright">
        <LayersControl.Overlay
          name="Street view"
          checked
        >
          <TileLayerMap />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Satellite view">
          <TileLayerMap view="satellite" />
        </LayersControl.Overlay>
      </LayersControl>

      {isSearchVisible && (
        <SearchFieldMap
          address={address}
          setAddress={setAddress}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      )}

      <MarkerClusterGroup>
        {markers.map((marker) => (
          <MarkerMap
            key={`MarkerMap-${marker.id}`}
            markerData={marker}
            renderPopupBody={renderPopupBody}
          />
        ))}
        {selectedAddress && <SelectedAddressMarker selectedAddress={selectedAddress} />}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export { LeafletMap };
