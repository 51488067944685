import { DateRangeType } from '../../datepicker/types';

type TicketFormInitialValues = {
  job: string;
  dateRange: DateRangeType;
  ticketCategory: string;
  ownerContract: string;
  po: string;
  wo: string;
  scopeOfWork: string;
  shiftType: string;
  isLunchAllowed: string;
  plannedLunchDuration: string;
  owner: string;
  ownerLocation: string;
  ownerContact: string;
  ownerRepresentative: string;
  startTime: string;
  duration: string;
  thisShiftTimeOnYard: string;
  thisShiftBaseTime: string;
  JELL: string;
};

enum Step {
  details = 'Ticket Details',
  owner = 'Owner Details',
  schedule = 'Schedule',
}

export type { TicketFormInitialValues };

export { Step };
