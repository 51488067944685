/* eslint-disable guard-for-in */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const FILE_EXTENSION = '.xlsx';
const ADDITIONAL_MINIMAL_WIDTH = 2;

const fitToColumn = (data: any[]) => {
  const widths = [];

  for (const field in data[0]) {
    widths.push({
      wch:
        Math.max(field.length, ...data.map((item) => item[field]?.toString()?.length ?? 0)) +
        ADDITIONAL_MINIMAL_WIDTH,
    });
  }

  return widths;
};

export const exportToExcel = (excelData: any[], fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(excelData);

  for (const i in ws) {
    if (typeof ws[i] !== 'object') continue;

    const cell = XLSX.utils.decode_cell(i);
    const isFirstRow = cell.r === 0;

    ws[i].s = {
      font: {
        name: 'arial',
      },
      alignment: {
        vertical: 'center',
        horizontal: 'left',
      },
    };

    if (isFirstRow) {
      ws[i].s.font.bold = true;
    }
  }

  ws['!cols'] = fitToColumn(excelData);

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: FILE_TYPE });

  FileSaver.saveAs(data, fileName + FILE_EXTENSION);
};
