import {
  EquipmentEntity,
  MostUsedEquipment,
  ContractRateSheet,
  EquipmentJELL,
  JELLItems,
  SortOrder,
  EquipmentTabType,
} from 'src/shared/types';
import { SidebarFilters, api } from 'src/store/api/api';

export type AdditionalEquipment = {
  rateSheetItemID: string;
  QTY: number;
};

const equipmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEquipment: builder.query<
      EquipmentEntity[],
      {
        filters?: SidebarFilters;
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        sorting?: {
          key?: keyof EquipmentEntity | null;
          order?: SortOrder;
        };
      }
    >({
      query: ({ filters, pagination, sorting }) => ({
        url: '/equipment',
        params: {
          filters: {
            providerArea: filters?.providerAreaSidebar,
            providerRegion: filters?.providerRegionSidebar,
            providerBranch: filters?.providerBranchSidebar,
            division: filters?.divisionSidebar,
            type: filters?.equipmentTypeSidebar,
          },
          pagination,
          sorting,
        },
      }),
      providesTags: () => ['Equipment'],
      transformResponse: (response: { data: EquipmentEntity[] }) => response.data,
    }),
    getRentalEquipment: builder.query<
      EquipmentEntity[],
      {
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        sorting?: {
          key?: keyof EquipmentEntity | null;
          order?: SortOrder;
        };
      }
    >({
      query: ({ pagination, sorting }) => ({
        url: '/rental-equipment',
        params: {
          pagination,
          sorting,
        },
      }),
      providesTags: () => ['Equipment'],
      transformResponse: (response: { data: EquipmentEntity[] }) => response.data,
    }),
    getMostUsedEquipment: builder.query<MostUsedEquipment[], ''>({
      query: () => ({
        url: '/equipment/most-used',
      }),
      providesTags: () => ['Equipment'],
      transformResponse: (response: { data: MostUsedEquipment[] }) => response.data,
    }),
    getMostUsedRentalEquipment: builder.query<MostUsedEquipment[], ''>({
      query: () => ({
        url: '/rental-equipment/most-used',
      }),
      providesTags: () => ['Equipment'],
      transformResponse: (response: { data: MostUsedEquipment[] }) => response.data,
    }),
    getAdditionalJELLItems: builder.query<
      {
        additionalEquipment: ContractRateSheet[];
        additionalLabor: ContractRateSheet[];
      },
      string
    >({
      query: (rateSheetId) => ({
        url: `/additional-jell/${rateSheetId}`,
      }),
      providesTags: () => ['Equipment', 'People'],
      transformResponse: (response: {
        data: {
          additionalEquipment: ContractRateSheet[];
          additionalLabor: ContractRateSheet[];
        };
      }) => response.data,
    }),
    getJELLItems: builder.query<JELLItems, string>({
      query: (equipmentKitID) => ({
        url: `/additional-jell/equipment-kit/${equipmentKitID}`,
      }),
      providesTags: () => ['Equipment', 'People'],
      transformResponse: (response: { data: JELLItems }) => response.data,
    }),
    addAdditionalItems: builder.mutation<
      ContractRateSheet[],
      {
        items: AdditionalEquipment[];
        ticketId: string;
        type: 'Labor' | 'Equipment';
      }
    >({
      query: ({ items, ticketId, type }) => ({
        url: `/additional-jell/${ticketId}/${type.toLowerCase()}`,
        method: 'POST',
        body: items,
      }),
      invalidatesTags: () => ['Equipment', 'People', 'Jobs'],
    }),
    addAdditionalItem: builder.mutation<
      ContractRateSheet[],
      {
        rateSheetItemID: string;
        ticketId: string;
        entityId: string;
        equipmentType: EquipmentTabType;
        type: 'Labor' | 'Equipment';
      }
    >({
      query: ({ rateSheetItemID, ticketId, entityId, equipmentType, type }) => ({
        url: `/additional-jell/${ticketId}/universal/${type.toLowerCase()}`,
        method: 'POST',
        body: {
          rateSheetItemID,
          entityId,
          equipmentType,
        },
      }),
      invalidatesTags: () => ['Equipment', 'People', 'Jobs'],
    }),
    editJELLItem: builder.mutation<
      EquipmentJELL,
      {
        id: string;
        QTY: string;
        requiresUnitNumber: 'Yes' | 'No';
        type: 'Labor' | 'Equipment';
      }
    >({
      query: ({ id, QTY, requiresUnitNumber, type }) => ({
        url: `/required-${type.toLowerCase()}/${id}`,
        method: 'PATCH',
        body: {
          QTY,
          requiresUnitNumber,
        },
      }),
      invalidatesTags: () => ['Equipment', 'Jobs'],
    }),
    deleteJELLItem: builder.mutation<
      EquipmentJELL,
      {
        selectedItemId: string;
        type: 'Labor' | 'Equipment';
      }
    >({
      query: ({ selectedItemId, type }) => ({
        url: `/required-${type.toLowerCase()}/${selectedItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Equipment', 'Jobs'],
    }),
  }),
});

export const {
  useGetEquipmentQuery,
  useGetRentalEquipmentQuery,
  useLazyGetEquipmentQuery,
  useGetMostUsedEquipmentQuery,
  useGetMostUsedRentalEquipmentQuery,
  useGetJELLItemsQuery,
  useGetAdditionalJELLItemsQuery,
  useAddAdditionalItemsMutation,
  useAddAdditionalItemMutation,
  useEditJELLItemMutation,
  useDeleteJELLItemMutation,
} = equipmentApi;
export { equipmentApi };
