import { FC } from 'react';

import { Icons } from 'src/assets/icons';
import { clsx } from 'src/shared/utils';

import { IconButton, IconButtonProps } from '../iconButton';
import { Icon } from '../icon';

type BackButtonProps = IconButtonProps & {
  href: string;
  handleNavigationClick: (href: string) => void;
};

const BackButton: FC<BackButtonProps> = ({ className, href, handleNavigationClick, ...props }) => {
  return (
    <IconButton
      onClick={() => handleNavigationClick(href)}
      className={clsx('w-fit', className)}
      {...props}
    >
      <Icon icon={<Icons.Filled.Chevrons.ChevronLeft />} />
    </IconButton>
  );
};

export { BackButton };
