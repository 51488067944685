import { useSelector } from 'react-redux';

import { Typography } from 'src/shared/ui/typography';
import { selectConfig } from 'src/store/slices';

const EquipmentDetails = () => {
  const { selectedEquipmentItem } = useSelector(selectConfig);

  const getItemCode = () => {
    if (selectedEquipmentItem?.IsRental) {
      return `#${selectedEquipmentItem.UnitNo} (RENTAL)`;
    }

    if (selectedEquipmentItem?.UnitNo) {
      return `#${selectedEquipmentItem.UnitNo}`;
    }

    return '-';
  };

  return (
    <div className="flex flex-col gap-5 border border-solid border-textColor-light rounded-2xl pt-4 px-4 pb-[28px]">
      <div className="flex flex-col gap-2">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Details
        </Typography>
      </div>

      <div className="w-full break-all">
        <Typography variant="label">Description</Typography>

        <Typography
          fontWeight="medium"
          variant="p1"
          className="break-all"
        >
          {selectedEquipmentItem?.UnitDescription || '-'}
        </Typography>
      </div>

      <div className="flex justify-between gap-5">
        <div className="w-[50%] break-all">
          <Typography variant="label">Item Code</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {getItemCode()}
          </Typography>
        </div>

        <div className="w-[50%] break-all">
          <Typography variant="label">Type</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="max-w-[221px] break-all"
          >
            {selectedEquipmentItem?.Type || '-'}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { EquipmentDetails };
