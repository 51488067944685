import { ReactNode, FC } from 'react';

import { clsx } from 'src/shared/utils';
import { Tag } from 'src/shared/ui/tag';

interface TabProps {
  index: number;
  title: string;
  amount?: ReactNode;
  currentTabIndex: number;
  onClick: () => void;
}

const Tab: FC<TabProps> = ({ index, title, amount, currentTabIndex, onClick }) => {
  const isActive = currentTabIndex === index;

  return (
    <div
      role="button"
      className={clsx(
        'group relative h-fit cursor-pointer pb-3 hover:after:bg-brandingColor-primary after:duration-200',
        "after:content-[''] after:bottom-[0px] after:block after:rounded-[10px] after:w-full after:absolute after:h-[2px]",
        isActive &&
          'after:h-[4px] after:bg-brandingColor-primary after:rounded-[10px] hover:after:bg-brandingColor-primary',
      )}
      onClick={onClick}
    >
      <div className="flex items-center px-4 gap-x-1">
        <p
          className={clsx(
            'w-full text-sm font-bold text-textColor-tertiary duration-200',
            isActive && 'text-brandingColor-primary group-hover:text-main',
          )}
        >
          {title}
        </p>

        {(!!amount || amount === 0) && (
          <Tag
            type={isActive ? 'info' : 'light'}
            className="!px-[4px] !py-[2px] duration-200"
          >
            {amount}
          </Tag>
        )}
      </div>
    </div>
  );
};

export { Tab };
