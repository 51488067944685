import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GridRenderEditCellParams, GridRowModel, useGridApiContext } from '@mui/x-data-grid';
import { FC } from 'react';

import { RelationOption } from 'src/shared/types';

type EditComponentProps = GridRenderEditCellParams<GridRowModel, string[]>;

type MultiSelectEditComponentProps = EditComponentProps & {
  options: RelationOption[];
};

const MultiSelectEditComponent: FC<MultiSelectEditComponentProps> = ({
  id,
  value,
  field,
  options,
}) => {
  const apiRef = useGridApiContext();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const rawValue = event.target.value;

    const formattedValue = Array.isArray(rawValue) ? rawValue : [rawValue];

    apiRef.current.setEditCellValue({
      id,
      field,
      value: formattedValue,
    });
  };

  return (
    <Select<string[]>
      multiple
      value={value}
      onChange={handleChange}
      fullWidth
    >
      {options.map(({ label, value }) => (
        <MenuItem
          key={value}
          value={value}
        >
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export const getMultiSelectRenderer = (options: RelationOption[]) => {
  const renderEditComponent = (props: EditComponentProps) => (
    <MultiSelectEditComponent
      {...props}
      options={options}
    />
  );

  return renderEditComponent;
};
