import { ComponentProps, FC } from 'react';

import { clsx } from 'src/shared/utils';
import { IconButton } from 'src/shared/ui/iconButton';
import { ReactComponent as ArrowIOSRightIcon } from 'src/assets/icons/filled/chevrons/arrow-ios-right.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';

type CollapsedSchedulerSidebarProps = ComponentProps<'div'> & {
  setIsCreateJobModalOpen: (isOpen: boolean) => void;
  toggleSidebar?: () => void;
};

const CollapsedSchedulerSidebar: FC<CollapsedSchedulerSidebarProps> = ({
  setIsCreateJobModalOpen,
  toggleSidebar,
  className,
  ...props
}) => {
  return (
    <div
      className={clsx('flex flex-col gap-y-5', className)}
      {...props}
    >
      <IconButton
        color="secondary"
        size="md"
        onClick={toggleSidebar}
        className="h-[48px] w-[48px]"
      >
        <ArrowIOSRightIcon />
      </IconButton>

      <IconButton
        color="primary"
        size="md"
        onClick={() => setIsCreateJobModalOpen(true)}
        className="h-[48px] w-[48px]"
      >
        <PlusIcon />
      </IconButton>
    </div>
  );
};

export type { CollapsedSchedulerSidebarProps };
export { CollapsedSchedulerSidebar };
