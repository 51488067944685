import { FC, useState } from 'react';

import { Icons } from 'src/assets/icons';
import { Button } from 'src/shared/ui/button';

import { AddActivityJobModal } from '../addActivityJobModal';

interface AddActivityJobButtonProps {
  timeZone: string;
  ownerLocationID: string;
}

const AddActivityJobButton: FC<AddActivityJobButtonProps> = ({ timeZone, ownerLocationID }) => {
  const [isAddActivityJobModalOpen, setAddActivityJobModalOpen] = useState(false);

  const handleClick = () => setAddActivityJobModalOpen((prev) => !prev);

  return (
    <>
      <div className="fixed right-6 bottom-6 z-[999]">
        <Button
          type="button"
          color="primary"
          size="lg"
          startIcon={<Icons.Outlined.Edit.PlusIcon className="fill-white" />}
          autoFocus
          className="px-10 py-4"
          onClick={handleClick}
        >
          Add Discovery
        </Button>
      </div>

      {isAddActivityJobModalOpen && (
        <AddActivityJobModal
          isOpen={isAddActivityJobModalOpen}
          setIsOpen={setAddActivityJobModalOpen}
          timeZone={timeZone}
          ownerLocationID={ownerLocationID}
        />
      )}
    </>
  );
};

export { AddActivityJobButton };
