import { api } from 'src/store/api/api';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    refreshToken: builder.mutation({
      query: () => ({
        url: '/auth/refresh-token',
        method: 'POST',
      }),
      invalidatesTags: () => ['Auth'],
      transformResponse: (response: {
        data: {
          accessToken: string;
        };
      }) => response.data,
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const { useRefreshTokenMutation, useLogoutMutation } = authApi;
export { authApi };
