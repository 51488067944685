import { Tooltip } from 'react-tooltip';
import { FC } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { NavigationLinkProps } from 'src/shared/ui/layout/navigationLink';
import { SvgRenderer } from 'src/shared/ui/svgRenderer';
import { truncateText } from 'src/shared/utils';

type SecondaryCardProps = NavigationLinkProps;

const SecondaryCard: FC<SecondaryCardProps> = ({ label, description, icon, link }) => {
  return (
    <a
      href={link}
      target="blank"
      className="group flex items-center gap-2"
      data-tooltip-id={`description-${label}`}
    >
      <SvgRenderer
        svgUrl={icon}
        className="[&>svg]:fill-black group-hover:[&>svg]:fill-[#4D6DAA]"
      />

      <Typography
        variant="p1"
        fontWeight="medium"
        className="text-brandingColor-primary-gradient duration-200 ease-in-out group-hover:text-[#4D6DAA] group-hover:underline"
      >
        {truncateText(label, 25)}
      </Typography>

      <Tooltip
        id={`description-${label}`}
        place="bottom"
        content={description}
        className="max-w-[250px] z-[1] bg-[rgba(0,0,0,0.7)]"
      />
    </a>
  );
};

export { SecondaryCard };
