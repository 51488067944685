import * as _clsx from 'clsx';
import { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

const clsx = (...inputs: ClassValue[]) => {
  const classes = _clsx.default(...inputs);
  return twMerge(classes);
};

export { clsx };
