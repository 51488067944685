import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'src/shared/ui/button';
import { dayjs, formatDate, getDaysHandler } from 'src/shared/utils';
import { RootState, useAppDispatch } from 'src/store';
import { calendarActions, configActions } from 'src/store/slices';

const TodayButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedRange = useSelector((state: RootState) => state.calendar.currentLayout);

  const handleButtonClick = () => {
    const currentDay = dayjs().hour(12).format().toString();
    const newDates = getDaysHandler(selectedRange)(currentDay);
    dispatch(calendarActions.changeDates({ dates: newDates }));
    dispatch(calendarActions.changeSelectedDate({ date: currentDay }));

    if (selectedRange !== 'day' && newDates.length > 0) {
      const startDate = newDates[0];
      const endDate = newDates[newDates.length - 1];
      dispatch(
        configActions.setSelectedWeek({
          selectedWeek: `${formatDate(startDate, 'MMMM DD')} - ${formatDate(endDate, 'MMMM DD')}`,
        }),
      );
    }
    dispatch(calendarActions.changeShouldScrollToToday(true));
  };

  return (
    <Button
      variant="outlined"
      onClick={handleButtonClick}
      className="!border-brandingColor-primary-gradient !text-brandingColor-primary-gradient"
    >
      Today
    </Button>
  );
};
export { TodayButton };
