import { api } from 'src/store/api/api';
import { Inspector, InspectorsStatsBySurveyAnswers } from 'src/shared/types';

const inspectorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInspectors: builder.query<Inspector[], ''>({
      query: () => ({
        url: '/admin/inspectors/list',
        method: 'GET',
      }),
      providesTags: ['Inspectors'],
      transformResponse: (response: { data: Inspector[] }) => response.data,
    }),
    getInspectorStatsBySurveyAnswers: builder.query<InspectorsStatsBySurveyAnswers, ''>({
      query: () => ({
        url: '/admin/inspectors/stats/survey-answers',
        method: 'GET',
      }),
      providesTags: ['Inspectors'],
      transformResponse: (response: { data: InspectorsStatsBySurveyAnswers }) => response.data,
    }),
  }),
});

export const { useGetInspectorsQuery, useGetInspectorStatsBySurveyAnswersQuery } = inspectorApi;
