import { FC, useState } from 'react';

import { Button } from 'src/shared/ui/button';
import { ReactComponent as UploadIcon } from 'src/assets/icons/filled/files/upload.svg';
import { UploadJobsModal } from 'src/pages/activities/ui';

const UploadJobsButton: FC = () => {
  const [isUploadJobsModalOpen, setUploadJobsModalOpen] = useState(false);

  const handleClick = () => setUploadJobsModalOpen((prev) => !prev);

  return (
    <div>
      <Button
        variant="filled"
        onClick={handleClick}
        className="bg-brandingColor-primary-gradient text-white pr-2"
        endIcon={<UploadIcon />}
        iconClassName="fill-white"
      >
        Upload Activities
      </Button>

      {isUploadJobsModalOpen && (
        <UploadJobsModal
          isOpen={isUploadJobsModalOpen}
          setIsOpen={setUploadJobsModalOpen}
        />
      )}
    </div>
  );
};

export { UploadJobsButton };
