import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';

const BUTTON_STYLES = {
  'display': 'flex',
  'alignItems': 'center',
  'minWidth': '114px',
  '& span': {
    lineHeight: '0.5',
  },
};

const MapView = () => {
  const navigate = useNavigate();

  const handleNavigationClick = () => {
    navigate('/c2o/Inspectors/map');
  };
  return (
    <Button
      color="primary"
      startIcon={<MapIcon />}
      onClick={handleNavigationClick}
      sx={BUTTON_STYLES}
    >
      Map View
    </Button>
  );
};

export { MapView };
