import { SortOrder } from 'src/shared/types';
import {
  Action,
  ActionById,
  ActionList,
  ActionState,
  AllActions,
  ParamsById,
  PersonnelsResponse,
  PostAction,
  PostActionState,
} from 'src/shared/types/actions';
import { api } from 'src/store/api/api';

const actionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllActions: builder.query<
      AllActions,
      {
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        sorting: {
          key: keyof Action | 'status' | 'assignedTo' | null;
          order: SortOrder;
        };
        filters: {
          status: string[] | undefined;
          refinery: string[] | undefined;
          assignedTo: string[] | undefined;
          dates: string[] | undefined;
          actionIds: string[] | undefined;
          types: string[] | undefined;
        };
      }
    >({
      query: ({ pagination, sorting, filters }) => ({
        url: `actions`,
        params: {
          pagination,
          sorting,
          filters,
        },
      }),
      transformResponse: (response: { data: AllActions }) => response.data,
    }),
    postActionState: builder.mutation<ActionState, PostActionState>({
      query: (newState) => ({
        url: `actions/create-state`,
        method: 'POST',
        body: newState,
      }),
      transformResponse: (response: { data: ActionState }) => response.data,
    }),

    postAction: builder.mutation<Action, PostAction>({
      query: (newAction) => ({
        url: `actions/create-action`,
        method: 'POST',
        body: newAction,
      }),
      transformResponse: (response: { data: Action }) => response.data,
    }),
    getActionById: builder.query<ActionById, ParamsById>({
      query: ({ id }) => ({
        url: `actions/${id}`,
      }),
      transformResponse: (response: { data: ActionById }) => response.data,
    }),
    getPersonnelListByOwnerLocationId: builder.query<PersonnelsResponse, ParamsById>({
      query: ({ id }) => ({
        url: `actions/personnel-list/${id}`,
      }),
      transformResponse: (response: { data: PersonnelsResponse }) => response.data,
    }),
    getActionByIdUpdates: builder.query<
      ActionList,
      {
        id: string;
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        sorting?: {
          key?: keyof ActionList['actionList'][number] | null;
          order?: SortOrder;
        };
      }
    >({
      query: ({ id, pagination, sorting }) => ({
        url: `actions/${id}/list`,
        params: {
          pagination,
          sorting,
        },
      }),
      transformResponse: (response: { data: ActionList }) => response.data,
    }),
  }),
});

export const {
  useGetAllActionsQuery,
  usePostActionStateMutation,
  useGetActionByIdQuery,
  useGetActionByIdUpdatesQuery,
  useGetPersonnelListByOwnerLocationIdQuery,
  usePostActionMutation,
} = actionsApi;
