import { FC } from 'react';
import { useSelector } from 'react-redux';

import { JobEntity, TicketEntity } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { selectModalConfig } from 'src/store/slices';

import { CreateUniversalItemModal } from '../../modals/createUniversalDropfield';

import { RequiredEquipmentTable } from './features/requiredEquipmentTable';
import { RequiredLaborTable } from './features/requiredLaborTable';

type RequirementsDetailsProps = {
  ticket: TicketEntity;
  job: JobEntity;
  title?: string;
  withActionButtons?: boolean;
};

const RequirementsDetails: FC<RequirementsDetailsProps> = ({
  ticket,
  job,
  title,
  withActionButtons = false,
}) => {
  const { openCreateUniversalModalType } = useSelector(selectModalConfig);

  return (
    <>
      <RequiredLaborTable
        ticket={ticket}
        job={job}
        withActionButtons={withActionButtons}
      />

      <RequiredEquipmentTable
        ticket={ticket}
        job={job}
        withActionButtons={withActionButtons}
      />

      <hr />

      <div className="flex flex-col gap-1">
        <div className="flex justify-between items-center">
          <Typography variant="label">JELL</Typography>

          <Typography
            variant="p1"
            className="text-textColor-tertiary"
          >
            {`Enforced from ${job?.isJELLEnforced ? 'Job' : 'Ticket'}`}
          </Typography>
        </div>

        <Typography
          fontWeight="medium"
          variant="p1"
          className="break-all"
        >
          {title || '-'}
        </Typography>
      </div>

      <CreateUniversalItemModal
        ticket={ticket}
        rateSheeetId={job.ownerContract.RateSheetID}
        isOpen={!!openCreateUniversalModalType}
        type={openCreateUniversalModalType}
      />
    </>
  );
};

export { RequirementsDetails };
