import { FC, useState } from 'react';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  LegendProps,
} from 'recharts';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  List,
  Typography,
  useMediaQuery,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { v4 as uuidv4 } from 'uuid';

import { getColorForItem } from '../../helpers/functions';

import { WrapperChart } from '../WrapperChart';
import { ItemChartData } from 'src/shared/types';

type PieChartViewProps = {
  title: string;
  items: ItemChartData[];
  mainColor?: string;
};

const WrapperTitle = styled(Box)(({ theme }) => ({
  padding: '1rem',
}));
const WrapperLegend = styled(Box)(({ theme }) => ({
  padding: '.5rem',
  border: '1px solid #C4C4C4',
  borderRadius: '4px',
  overflowY: 'scroll',
  maxHeight: 300,
}));
const CustomListItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  gap: '0.2rem',
  alignItems: 'center',
  padding: 0,
  width: 'auto',
}));
const CustomList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));
const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const PieChartView: FC<PieChartViewProps> = ({ title = 'Chart', items = [] }) => {
  const theme = useTheme();
  const isXlDown = useMediaQuery(theme.breakpoints.down('xl'));
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const renderLegend = (props: LegendProps) => {
    const { payload } = props;
    return (
      <WrapperLegend>
        <CustomList sx={{ flexDirection: !isXlDown ? 'column' : 'row' }}>
          {payload ? (
            payload.map((entry, index) => (
              <CustomListItem
                key={uuidv4()}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <InfoIcon sx={{ color: entry.color }} />
                </ListItemIcon>
                <CustomListItemText sx={{ color: entry.color }}>{entry.value}</CustomListItemText>
              </CustomListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText>No data</ListItemText>
            </ListItem>
          )}
        </CustomList>
      </WrapperLegend>
    );
  };

  const hasData = items.length > 0;

  return (
    <WrapperChart>
      <WrapperTitle>
        <Typography
          textAlign="center"
          variant="h6"
          color="initial"
        >
          {title}
        </Typography>
      </WrapperTitle>
      <Divider />

      <Box
        sx={{
          width: '100%',
          height: '400px',
          padding: '1rem',
        }}
      >
        {hasData ? (
          <ResponsiveContainer>
            <PieChart>
              <Tooltip />
              <Pie
                cx={isXlDown ? '50%' : '30%'}
                label
                data={items}
                innerRadius={80}
                fill="#8884d8"
                dataKey="value"
                cornerRadius={5}
                paddingAngle={2}
              >
                {items.map((entry, index) => (
                  <Cell
                    key={uuidv4()}
                    fill={
                      hoveredIndex === index ? getColorForItem(index, true) : getColorForItem(index)
                    }
                    stroke={hoveredIndex === index ? '#000' : 'none'}
                    strokeWidth={1}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  />
                ))}
              </Pie>

              <Legend
                content={renderLegend as any}
                wrapperStyle={{ padding: 20 }}
                width={isXlDown ? undefined : 240}
                height={isXlDown ? undefined : 160}
                layout={isXlDown ? 'horizontal' : 'centric'}
                align={isXlDown ? 'center' : 'right'}
                verticalAlign={isXlDown ? 'bottom' : 'top'}
              />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <Typography
            variant="body1"
            color="textSecondary"
            textAlign="center"
          >
            No data available
          </Typography>
        )}
      </Box>
    </WrapperChart>
  );
};

export { PieChartView };
