import { FC, PropsWithChildren, HTMLAttributes } from 'react';
import { DialogActions, SxProps, Theme, Divider, Box } from '@mui/material';

type FooterDialogProps = PropsWithChildren<HTMLAttributes<HTMLElement>> & {
  sx?: SxProps<Theme>;
};

const FooterDialog: FC<FooterDialogProps> = ({ children, sx, ...rest }) => {
  return (
    <Box>
      <Divider />
      <DialogActions
        sx={{ padding: '1rem', ...sx }}
        {...rest}
      >
        {children}
      </DialogActions>
    </Box>
  );
};

export { FooterDialog };
