import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  REPORT_TABLE_BOTTOM_SPACE,
  REPORT_TABLE_PAGINATION_HEIGHT,
} from 'src/pages/activities/helpers';
import {
  ActivityEntity,
  ActivityHealth,
  AllActivityStates,
  EntityRecord,
  EnumActivityTrack,
  PageSize,
  SortOrder,
} from 'src/shared/types';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { DATE_FORMAT } from 'src/shared/utils';
import { exportToExcel } from 'src/shared/utils/excel';
import { RootState } from 'src/store';
import { useGetAllActivityStatesProcessesFiltersQuery } from 'src/store/api/activities';

import { TableReportHeader } from '../tableReportHeader';

interface TableReportByActivityStatesProps {
  isSkip: boolean;
  onTotalChange: (total: number, statesCount: number) => void;
  totalValues: {
    total: number;
    totalStates: number;
  };
  handleSelectEntity: (option: EntityRecord) => void;
  selectedEntity: ActivityEntity;
}

const TableReportByActivityStates: FC<TableReportByActivityStatesProps> = ({
  isSkip,
  onTotalChange,
  totalValues,
  handleSelectEntity,
  selectedEntity,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>(15);
  const [isDownloadAllClicked, setIsDownloadAllClicked] = useState(false);

  const handlePageSizeChange = (size: PageSize) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    setPageSize(size);
  };

  const [sortSettings, setSortSettings] = useState<{
    key: keyof AllActivityStates['allActivities'][number] | null;
    order: SortOrder;
  }>({
    key: null,
    order: SortOrder.ASC,
  });

  const handleChangeSortSettings = (newSort: {
    key: keyof AllActivityStates['allActivities'][number] | null;
    order: SortOrder;
  }) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setSortSettings(newSort);
  };

  const boardFilters = useSelector((state: RootState) => state.config.boardFilters);

  const appliedFiltersAmount = Object.values(boardFilters).filter(
    (filter) => filter.length > 0,
  ).length;

  const { data, isLoading, isFetching, isError } = useGetAllActivityStatesProcessesFiltersQuery(
    {
      pagination: {
        page: `${currentPage}`,
        pageSize: `${pageSize}`,
      },
      filters: {
        health: boardFilters.health?.length ? (boardFilters.health as ActivityHealth[]) : undefined,
        track: boardFilters.status?.length
          ? (boardFilters.status as EnumActivityTrack[])
          : undefined,
        jobId: boardFilters.jobID?.length ? boardFilters.jobID : undefined,
        provider: boardFilters.provider?.length ? boardFilters.provider : undefined,
        providerTeam: boardFilters.crew?.length ? boardFilters.crew : undefined,
        delayCategory: boardFilters.delayCategory?.length ? boardFilters.delayCategory : undefined,
      },
      sorting: sortSettings,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isSkip,
    },
  );

  const {
    data: dataAll,
    isLoading: isLoadingAll,
    isFetching: isFetchingAll,
    isError: isErrorAll,
  } = useGetAllActivityStatesProcessesFiltersQuery(
    {
      filters: {
        health: boardFilters.health?.length ? (boardFilters.health as ActivityHealth[]) : undefined,
        track: boardFilters.status?.length
          ? (boardFilters.status as EnumActivityTrack[])
          : undefined,
        jobId: boardFilters.jobID?.length ? boardFilters.jobID : undefined,
        provider: boardFilters.provider?.length ? boardFilters.provider : undefined,
        providerTeam: boardFilters.crew?.length ? boardFilters.crew : undefined,
        delayCategory: boardFilters.delayCategory?.length ? boardFilters.delayCategory : undefined,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isSkip || !isDownloadAllClicked,
    },
  );

  useEffect(() => {
    if (data) {
      onTotalChange(data.totalActivities, data.pagination.total);
    }
  }, [data]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [boardFilters]);

  const headers: TableHeaderType<AllActivityStates['allActivities'][number]>[] = useMemo(
    () => [
      {
        title: 'Activity Name',
        field: 'ActivityName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.name || '',
      },
      {
        title: 'Reported By',
        field: 'ReportedBy',
        className: 'w-max',
        canSort: true,
        render: (job) => job.reportedBy || '',
      },
      {
        title: 'Job Number',
        field: 'JobNumber',
        className: 'w-max',
        canSort: true,
        render: (job) => job.jobNumber || '',
      },
      {
        title: 'Job Name',
        field: 'JobName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.jobName || '',
      },
      {
        title: 'Progress',
        field: 'Progress',
        className: 'w-max',
        canSort: true,
        render: (job) => job.progress || 0,
      },
      {
        title: 'Provider Name',
        field: 'ProviderName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.provider?.ProviderName || '',
      },
      {
        title: 'Provider Team Name',
        field: 'ProviderTeamName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.providerTeam?.name || '',
      },
      {
        title: 'Equipment',
        field: 'Equipment',
        className: 'w-max',
        canSort: true,
        render: (job) => job.equipment || '',
      },
      {
        title: 'Equipment Type',
        field: 'EquipmentType',
        className: 'w-max',
        canSort: true,
        render: (job) => job.equipmentTypeId || '',
      },
      {
        title: 'Health',
        field: 'Health',
        className: 'w-max',
        canSort: true,
        render: (job) => job.health || '',
      },
      {
        title: 'Status',
        field: 'Status',
        className: 'w-max',
        canSort: true,
        render: (job) => job.track || '',
      },
      {
        title: 'Notes',
        field: 'Notes',
        className: 'w-max',
        canSort: true,
        render: (job) => job.notes || '',
      },
      {
        title: 'Delay Start',
        field: 'DelayStart',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.delayStart
            ? dayjs
                .utc(job.delayStart)
                .tz(job.activity.OwnerLocation.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'Delay End',
        field: 'DelayEnd',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.delayEnd
            ? dayjs
                .utc(job.delayEnd)
                .tz(job.activity.OwnerLocation.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'Delay Duration',
        field: 'DelayDuration',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.delayDuration
            ? dayjs.duration(job.delayDuration).format(DATE_FORMAT.HOURS_MINUTES)
            : '',
      },
      {
        title: 'Delay Category',
        field: 'DelayCategory',
        className: 'w-max',
        canSort: true,
        render: (job) => job.delayCategory?.DelayCategory || '',
      },
      {
        title: 'Start',
        field: 'CurrentStartDate',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.startDate
            ? dayjs
                .utc(job.startDate)
                .tz(job.activity.OwnerLocation.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'End',
        field: 'endDate',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.endDate
            ? dayjs
                .utc(job.endDate)
                .tz(job.activity.OwnerLocation.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'Duration',
        field: 'Duration',
        className: 'w-max',
        render: (job) =>
          job.startDate && job.endDate
            ? dayjs
                .duration(dayjs(job.endDate).diff(dayjs(job.startDate)))
                .format(DATE_FORMAT.HOURS_MINUTES)
            : '',
      },
    ],
    [],
  );

  const total = useMemo(() => data?.pagination.total || 0, [data]);

  const handleExportToExcel = async () => {
    if (!data?.allActivities) {
      return;
    }

    let dataToExport = data;

    if (isDownloadAllClicked && !dataAll?.allActivities) {
      return;
    }

    if (isDownloadAllClicked && dataAll?.allActivities) {
      dataToExport = dataAll;
    }

    const excelData = dataToExport.allActivities.map((activityState) => {
      const {
        name = '',
        reportedBy = '',
        jobName = '',
        jobNumber = '',
        activityId = '',
        progress = 0,
        provider = { ProviderName: '' },
        delayCategory,
        providerId = '',
        providerTeamId = '',
        equipment = '',
        equipmentTypeId = '',
        health = '',
        track = '',
        notes = '',
        startDate = '',
        endDate = '',
        delayStart = '',
        delayEnd = '',
        delayDuration = '',
        id = '',
      } = activityState || {};

      const providerTeamName = activityState.providerTeam?.name || '';

      const ownerLocationTimeZone =
        activityState.activity.OwnerLocation.LocationTimezone || dayjs.tz.guess();

      const delayCategoryData = delayCategory || { DelayCategory: '', DelayCategoryID: '' };

      return {
        StateID: id,
        ActivityName: name,
        ReportedBy: reportedBy,
        JobNumber: jobName,
        JobName: jobNumber,
        ActivityID: activityId,
        Progress: progress,
        ProviderName: provider.ProviderName,
        ProviderID: providerId,
        ProviderTeamName: providerTeamName,
        ProviderTeamID: providerTeamId,
        Equipment: equipment,
        EquipmentType: equipmentTypeId,
        Health: health,
        Status: track,
        Notes: notes,
        DelayStart: delayStart
          ? dayjs.utc(delayStart).tz(ownerLocationTimeZone).format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
          : '',
        DelayEnd: delayEnd
          ? dayjs.utc(delayEnd).tz(ownerLocationTimeZone).format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
          : '',
        DelayDuration: delayDuration
          ? dayjs.duration(delayDuration).format(DATE_FORMAT.HOURS_MINUTES)
          : '',
        DelayCategory: delayCategoryData.DelayCategory,
        DelayCategoryID: delayCategoryData.DelayCategoryID,
        ScheduledStartDate: startDate
          ? dayjs.utc(startDate).tz(ownerLocationTimeZone).format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
          : '',
        ScheduledEndDate: endDate
          ? dayjs.utc(endDate).tz(ownerLocationTimeZone).format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
          : '',
        ScheduledDuration:
          startDate && endDate
            ? dayjs
                .duration(dayjs(endDate).diff(dayjs(startDate)))
                .format(DATE_FORMAT.HOURS_MINUTES)
            : '',
      };
    });

    exportToExcel(excelData, 'Activity States');
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [minViewSpace, setMinViewSpace] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const offsetTop = containerRef.current.getBoundingClientRect().top;
      setMinViewSpace(offsetTop + REPORT_TABLE_BOTTOM_SPACE + REPORT_TABLE_PAGINATION_HEIGHT);
    }
  }, [containerRef]);

  return (
    <div className="flex flex-col gap-4">
      <TableReportHeader
        appliedFiltersAmount={appliedFiltersAmount}
        filters={data?.filters || []}
        handleExportToExcel={handleExportToExcel}
        handleSelectEntity={handleSelectEntity}
        isDownloadAllClicked={isDownloadAllClicked}
        selectedEntity={selectedEntity}
        setIsDownloadAllClicked={setIsDownloadAllClicked}
        totalValues={totalValues}
        isExcelDisabled={isFetching || isLoadingAll || isFetchingAll}
      />

      <div ref={containerRef}>
        <Table
          headers={headers}
          data={data?.allActivities || []}
          isFetching={isFetching}
          isLoading={isLoading}
          sortSettings={sortSettings}
          onSort={handleChangeSortSettings}
          withIndicator
          variant="secondary"
          containerClassName="h-full"
          heightTable={minViewSpace}
          sticky
          scroll
          isError={isError || isErrorAll}
          pagination={{
            currentPage,
            onPageChange: (page: number) => setCurrentPage(page),
            onPageSizeChange: handlePageSizeChange,
            pageSize,
            total,
          }}
        />
      </div>
    </div>
  );
};

export { TableReportByActivityStates };
