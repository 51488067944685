import { ComponentProps, FC } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as CheckmarkIcon } from 'src/assets/icons/filled/edit/checkmark.svg';
import { Icon } from 'src/shared/ui/icon';

type SelectInputItemProps = ComponentProps<'div'> & { selected?: boolean };

const SelectInputItem: FC<SelectInputItemProps> = ({ selected = false, children, ...props }) => {
  return (
    <div
      className="flex flex-1 h-[48px] items-center"
      {...props}
    >
      <div className="flex flex-1 px-[16px] gap-x-2">
        <Typography
          variant="p1"
          fontWeight={selected ? 'bold' : 'regular'}
        >
          {children}
        </Typography>

        {selected && (
          <Icon
            icon={<CheckmarkIcon />}
            className="fill-brandingColor-primary-gradient"
          />
        )}
      </div>
    </div>
  );
};

export { SelectInputItem };
export type { SelectInputItemProps };
