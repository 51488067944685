import { FC, ComponentProps } from 'react';

import { clsx } from 'src/shared/utils';
import { CheckboxHelperText } from 'src/shared/ui/checkbox/ui/checkboxHelperText';
import { ReactComponent as CheckboxInnerIcon } from 'src/shared/ui/checkbox/ui/checkbox-inner.svg';
import { ReactComponent as CheckboxOuterIcon } from 'src/shared/ui/checkbox/ui/checkbox-outer.svg';

import './style.css';

interface CheckboxProps extends ComponentProps<'input'> {
  startLabel?: string;
  endLabel?: string;
  labelClassName?: string;
  fullWidth?: boolean;
  helperTextClassName?: string;
  checked?: boolean;
  helperText?: string;
}

const Checkbox: FC<CheckboxProps> = ({
  startLabel,
  endLabel,
  labelClassName,
  fullWidth,
  helperText,
  helperTextClassName,
  disabled,
  ...props
}) => {
  return (
    <label
      className={clsx(
        'checkbox-container cursor-pointer w-fit',
        {
          'w-full': fullWidth,
          'cursor-default': disabled,
        },
        labelClassName,
      )}
    >
      {startLabel && (
        <p>
          {startLabel}

          {helperText && (
            <CheckboxHelperText
              helperText={helperText}
              className={helperTextClassName}
            />
          )}
        </p>
      )}

      <input
        type="checkbox"
        disabled={disabled}
        {...props}
      />

      <span
        className={clsx('relative', {
          'ml-2': startLabel,
          'mr-2': endLabel,
          'self-start': helperText,
        })}
      >
        <CheckboxOuterIcon className="checkbox-outer" />

        <CheckboxInnerIcon className="checkbox-inner absolute top-0 checkmark-checked" />
      </span>

      {endLabel && (
        <p>
          {endLabel}

          {helperText && (
            <CheckboxHelperText
              helperText={helperText}
              className={helperTextClassName}
            />
          )}
        </p>
      )}
    </label>
  );
};

export { Checkbox };
