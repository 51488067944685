import { ReactElement, useState } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { CheckboxItem, Checkboxes } from 'src/shared/ui/checkboxes/checkboxes';
import {
  useGetJobsQuery,
  useGetTicketsQuery,
  useGetSurveyAnswersReportAmountQuery,
} from 'src/store/api';

import { JobsTable, TicketsTable, SurveyAnswersTable } from './ui';
import { useAppDispatch } from 'src/store';
import { configActions, FilterMode, FilterModeType } from 'src/store/slices';
import { tenantsData, getTenant } from 'src/shared/utils/tenant';

type Entity = 'Jobs' | 'Tickets' | 'Answers';

type EntityRecord = {
  label: Entity;
  amount: number;
  mode: FilterModeType;
};

const Reports = () => {
  const { tenant } = getTenant();

  const [selectedEntity, setSelectedEntity] = useState<Entity>('Jobs');

  const dispatch = useAppDispatch();

  const {
    data: jobsData = {
      jobs: [],
    },
  } = useGetJobsQuery({});

  const {
    data: ticketsData = {
      tickets: [],
    },
  } = useGetTicketsQuery({});
  const {
    data: surveyAnswersAmount = {
      total: 0,
    },
  } = useGetSurveyAnswersReportAmountQuery('');

  const { jobs } = jobsData;
  const { tickets } = ticketsData;
  const { total: totalSurveyAnswers } = surveyAnswersAmount;

  const isSurveyAnswersVisible = tenant === tenantsData?.evergreen?.name;

  const entities: EntityRecord[] = [
    {
      label: 'Jobs' as Entity,
      amount: jobs.length,
      mode: FilterMode.Default,
    },
    {
      label: 'Tickets' as Entity,
      amount: tickets.length,
      mode: FilterMode.Default,
    },

    ...(isSurveyAnswersVisible
      ? [
          {
            label: 'Answers' as Entity,
            amount: totalSurveyAnswers,
            mode: FilterMode.SurveyAnswer,
          },
        ]
      : []),
  ];

  const table: { [key in Entity]?: ReactElement } = {
    Jobs: <JobsTable />,
    Tickets: <TicketsTable />,
    ...(isSurveyAnswersVisible && { Answers: <SurveyAnswersTable /> }),
  };

  const handleSelectEntity = (option: EntityRecord) => {
    setSelectedEntity(option.label);
    dispatch(configActions.setTypeBoardFilters(option.mode));
  };

  return (
    <div className="w-full flex-col px-6">
      <div className="flex justify-between items-center pt-8 pb-4">
        <Typography variant="h2">Reports</Typography>

        <Checkboxes
          options={entities.reduce<CheckboxItem[]>((acc, option) => {
            return [
              ...acc,
              {
                className: 'w-[151px]',
                label: option.label,
                amount: option.amount,
                onClick: () => handleSelectEntity(option),
              },
            ];
          }, [])}
          selectedValue={selectedEntity}
          className="w-fit"
        />
      </div>

      {table[selectedEntity]}
    </div>
  );
};

export { Reports };
