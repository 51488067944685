/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-array-index-key */
import { FC, PropsWithChildren, useEffect, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import { dayjs, formatDate, getCurrentDay, not } from 'src/shared/utils';
import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as TargetIcon } from 'src/assets/icons/outlined/edit/target.svg';
import { useScrollToToday } from 'src/shared/hooks/useScrollToToday';
import { clsx } from 'src/shared/utils/clsx';
import { IconButton } from 'src/shared/ui/iconButton';
import { RootState, useAppDispatch } from 'src/store';
import { schedulerBoardSettingsActions } from 'src/store/slices/schedulerBoardSettings';
import { JobEntity, Role } from 'src/shared/types';
import { CARD_WIDTH, MINIMAL_CARD_HEIGHT } from 'src/shared/constants';
import { selectConfig, selectCurrentUser } from 'src/store/slices';

import { SchedulerCard } from './schedulerCard';

type SchedulerColumnProps = {
  date: string;
  jobs?: JobEntity[];
  columnIndex: number;
  allJobs: JobEntity[];
} & PropsWithChildren;

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const getMissingRows = (job: JobEntity, jobs: JobEntity[], jobIndex: number) => {
  if (jobIndex === 0) {
    return Number(job.row) - 1;
  }

  return Number(job.row) - Number(jobs[jobIndex - 1].row) - 1;
};

const generateMissedRows = (job: JobEntity, jobs: JobEntity[], jobIndex: number) => {
  const startRow = jobIndex === 0 ? 1 : Number(jobs[jobIndex - 1].row) + 1;
  const endRow = Number(job.row);

  const rows = [];
  // eslint-disable-next-line no-plusplus
  for (let i = startRow; i < endRow; ++i) {
    rows.push(i);
  }

  return rows;
};

const SchedulerColumn: FC<SchedulerColumnProps> = ({ date, jobs = [], allJobs, columnIndex }) => {
  const currentDay = formatDate(getCurrentDay());
  const isSameDay = dayjs(currentDay).isSame(formatDate(date));
  const { ref: scrollToTodayRef } = useScrollToToday();
  const dispatch = useAppDispatch();

  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const filterAssignedForDate = useSelector(
    (state: RootState) => state.schedulerBoardSettings.filterAssignedForDate,
  );
  const config = useSelector(selectConfig);
  const { weeks, selectedWeek } = config;
  const rowSettings = weeks[selectedWeek].rows;

  const isTodayTarget = formatDate(filterAssignedForDate) === formatDate(date);

  const getCardsForRender = () => {
    return [...jobs]
      .sort((a, b) => (a.row && b.row ? a.row - b.row : 0))
      .map((job, jobIndex) => {
        const missingRowsBeforeCurrent = getMissingRows(job, jobs, jobIndex);

        const missedRows = generateMissedRows(job, jobs, jobIndex);

        const currentRow = rowSettings[Number(job.row)];

        return (
          <Fragment key={`${Number(job.row)}-${job.id}`}>
            {[...Array(missingRowsBeforeCurrent).keys()].map((missedRowIndex) => {
              const currentRow = rowSettings[missedRows[missedRowIndex]];

              return (
                <div
                  key={missedRows[missedRowIndex]}
                  style={{
                    gridRowStart: missedRows[missedRowIndex],
                    minHeight: `${
                      currentRow && currentRow.isOpen
                        ? currentRow.height || MINIMAL_CARD_HEIGHT
                        : MINIMAL_CARD_HEIGHT
                    }px`,
                    width: `${CARD_WIDTH}px`,
                  }}
                  className={`row-${missedRows[missedRowIndex]}`}
                />
              );
            })}

            <div
              key={job.row}
              style={{
                gridRowStart: job.row,
                minHeight: `${
                  currentRow && currentRow.isOpen
                    ? currentRow.height || MINIMAL_CARD_HEIGHT
                    : MINIMAL_CARD_HEIGHT
                }px`,
                width: `${CARD_WIDTH}px`,
              }}
              className={`row-${job.row}`}
            >
              {!!job && (
                <SchedulerCard
                  date={date}
                  currentColumn={columnIndex + 1}
                  row={job.row}
                  jobs={allJobs}
                  {...job!}
                />
              )}
            </div>
          </Fragment>
        );
      });
  };

  const handleTargetClick = () => {
    if (isTodayTarget) {
      dispatch(schedulerBoardSettingsActions.changeFilterAssignedForDate(''));

      return;
    }

    dispatch(schedulerBoardSettingsActions.changeFilterAssignedForDate(date));
  };

  useEffect(() => {
    jobs.forEach((job) => {
      if (dayjs(job.requestedStartDate).isSame(date))
        dispatch(
          schedulerBoardSettingsActions.changeCardColumn({
            id: job.id,
            column: columnIndex + 1,
          }),
        );
    });
  }, [jobs, jobs.length, columnIndex, date, dispatch]);

  const [columnHeight, setColumnHeight] = useState(0);

  useEffect(() => {
    const COLUMN_GAP = 40;
    const DATE_ROW_HEIGHT = 56;
    const additionalHeight = COLUMN_GAP + DATE_ROW_HEIGHT;
    const maxJobsContainerHeight = Math.max(
      ...Array.from(document.querySelectorAll('.jobsContainer')).map((el) => el.scrollHeight),
    );

    const newColumnHeight = maxJobsContainerHeight + additionalHeight;

    setColumnHeight(newColumnHeight);
  }, [rowSettings]);

  return (
    <div
      ref={isSameDay ? scrollToTodayRef : undefined}
      className="w-full h-fit bg-transparent border-l border-outlineColor-input-border grid grid-flow-row auto-rows-max gap-8"
      style={{
        minWidth: `${CARD_WIDTH}px`,
        height: columnHeight || 'fit-content',
      }}
    >
      <div className="sticky top-0 z-[20] w-full flex justify-center items-center bg-bgColor-card border-r-textColor-light border-b-textColor-light border-r border-solid border-b h-[56px] min-h-[56px] gap-x-3">
        <Typography
          variant="h3"
          className={clsx(isSameDay && 'text-[#174EBA]')}
        >
          {days[dayjs(date).weekday()]}

          {', '}

          {dayjs(date).date()}
        </Typography>

        {not(isReadonly) && (
          <IconButton
            size="none"
            iconClassName={clsx(
              'fill-textColor-tertiary',
              isTodayTarget && 'fill-semanticColor-info',
            )}
            onClick={handleTargetClick}
          >
            <TargetIcon />
          </IconButton>
        )}
      </div>

      <div className="grid grid-flow-row auto-rows-max gap-4 jobsContainer">
        {getCardsForRender()}
      </div>
    </div>
  );
};

export { SchedulerColumn };
export type { SchedulerColumnProps };
