import { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import {
  getFormattedTimePickerDateTime,
  showToastErrorMessage,
  not,
  dayjs,
} from 'src/shared/utils';
import { type TicketEntity, Role, TicketStatus } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { clsx } from 'src/shared/utils/clsx';
import { ReactComponent as MoreHorizontalIcon } from 'src/assets/icons/filled/menus/more-horizontal.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { ReactComponent as CopyIcon } from 'src/assets/icons/outlined/edit/copy.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/outlined/notifications/info.svg';
import { ReactComponent as EquipmentIcon } from 'src/assets/icons/filled/misc/bin.svg';
import { ReactComponent as ChevronDownIcon } from 'src/assets/icons/filled/chevrons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'src/assets/icons/filled/chevrons/chevron-up.svg';
import { ReactComponent as CheckmarkIcon } from 'src/assets/icons/filled/edit/checkmark.svg';
import { ReactComponent as LockClosedIcon } from 'src/assets/icons/outlined/security/lock.svg';
import { IconButton } from 'src/shared/ui/iconButton';
import { Icon } from 'src/shared/ui/icon';
import { DropDown, DropDownItem } from 'src/shared/ui/dropDown';
import { useDeleteTicketMutation, useUpdateTicketStatusMutation } from 'src/store/api';
import { Tag } from 'src/shared/ui/tag';
import { Button } from 'src/shared/ui/button';
import { useAppDispatch } from 'src/store';
import { modalConfigActions, selectCurrentUser, selectModalConfig } from 'src/store/slices';
import { EndsTomorrowIndicator } from 'src/shared/ui/endsTomorrowIndicator';
import { CardMenuItem } from 'src/shared/ui/schedulerBoard/ui/cardMenuItem';

import { ticketStatusOptions } from '../constants';

const typeStyles = {
  info: 'text-brandingColor-primary-gradient',
  danger: 'text-textColor-danger',
  success: 'text-semanticColor-success',
  warning: 'text-semanticColor-F69E5E',
  disabled: 'text-brandingColor-primary',
  light: 'text-textColor-tertiary',
  white: 'text-textColor-tertiary',
};

type TicketCardTitleProps = {
  ticket: TicketEntity;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsCopyModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsDetailsModalOpen: Dispatch<SetStateAction<boolean>>;
};

const TicketCardTitle: FC<TicketCardTitleProps> = ({
  ticket,
  setIsEditModalOpen,
  setIsCopyModalOpen,
  setIsDetailsModalOpen,
}) => {
  const dispatch = useAppDispatch();

  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const { openAdditionalEquipmentModalTicketId } = useSelector(selectModalConfig);

  const [deleteTicket] = useDeleteTicketMutation();
  const [updateTicketStatus] = useUpdateTicketStatusMutation();

  const ticketStartTime = getFormattedTimePickerDateTime(ticket?.startTime);
  const ticketEndTime = getFormattedTimePickerDateTime(ticket?.endTime);
  const ticketStatusOption = ticketStatusOptions.find((option) => option.text === ticket.status);

  const isReadyToDispatch = ticketStatusOption?.text === TicketStatus.ReadyToDispatch;

  const endsTomorrow = dayjs(ticket.startDate)
    .subtract(dayjs(ticket.startDate).utcOffset(), 'minute')
    .isBefore(dayjs(ticket.endDate).subtract(dayjs(ticket.endDate).utcOffset(), 'minute'), 'day');

  const isReadonlyStatus = ticketStatusOptions.every((status) => status.text !== ticket.status);

  const toggleIsRequirementsDetailsOpen = (isOpen: boolean) => {
    if (openAdditionalEquipmentModalTicketId === ticket.id) {
      dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));

      return;
    }

    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(isOpen ? ticket.id : ''));
  };

  const openDetails = () => {
    setIsDetailsModalOpen(true);
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const openCopyModal = () => {
    setIsCopyModalOpen(true);
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  const handleDelete = async () => {
    try {
      await deleteTicket(ticket.id).unwrap();
    } catch {
      showToastErrorMessage(
        `Sorry, an error occurred, when you tried to delete a ticket, please check your internet connection`,
      );
    }
  };

  const ticketActionButtons: DropDownItem[] = [
    not(isReadonlyStatus || !isReadyToDispatch)
      ? {
          value: 'edit',
          label: (
            <CardMenuItem
              onClick={openEditModal}
              startIcon={
                <Icon
                  className="fill-textColor-tertiary"
                  icon={<EditIcon />}
                />
              }
              titleClassName="text-textColor-tertiary"
            >
              Edit
            </CardMenuItem>
          ),
        }
      : null,
    {
      value: 'copy',
      label: (
        <CardMenuItem
          onClick={openCopyModal}
          startIcon={
            <Icon
              className="fill-textColor-tertiary"
              icon={<CopyIcon />}
            />
          }
          titleClassName="text-textColor-tertiary"
        >
          Copy
        </CardMenuItem>
      ),
    },
    not(isReadonlyStatus)
      ? {
          value: 'delete',
          label: (
            <CardMenuItem
              onClick={handleDelete}
              startIcon={
                <Icon
                  className="fill-semanticColor-danger"
                  icon={<TrashIcon />}
                />
              }
              titleClassName="text-semanticColor-danger"
            >
              Delete
            </CardMenuItem>
          ),
        }
      : null,
  ].filter(Boolean) as DropDownItem[];

  return (
    <div className="flex flex-col gap-2 justify-between">
      <Typography
        variant="c1"
        className="text-textColor-secondary"
      >
        {`ID: ${ticket.ticketNumber || 'Pending'}`}
      </Typography>

      <div className="flex justify-between items-center gap-2">
        <Typography
          variant="p1"
          fontWeight="bold"
          className="break-all"
        >
          {ticket.projectName}
        </Typography>

        <div className="flex gap-[10px]">
          <IconButton
            color="basic"
            size="none"
            iconSize="md"
            className="bg-bgColor-ticketCard"
            iconClassName="fill-textColor-tertiary"
            onClick={openDetails}
          >
            <InfoIcon />
          </IconButton>

          <IconButton
            color="basic"
            size="none"
            iconSize="md"
            className="bg-bgColor-ticketCard"
            iconClassName="fill-textColor-tertiary !w-[20px] !h-[20px]"
            onClick={() => toggleIsRequirementsDetailsOpen(true)}
          >
            <EquipmentIcon />
          </IconButton>

          {not(isReadonly) && (
            <div className="flex gap-x-4">
              <DropDown
                options={{ placement: 'bottom-start' }}
                config={{
                  itemsElementClassName: clsx(
                    'shadow-[0px_2px_66px_-10px_#0000000F]',
                    'rounded-lg',
                    'bg-bgColor-card',
                  ),
                }}
                renderElement={() => (
                  <IconButton
                    color="basic"
                    size="none"
                    iconSize="md"
                    iconClassName="fill-textColor-tertiary"
                    className="bg-bgColor-ticketCard"
                  >
                    <MoreHorizontalIcon />
                  </IconButton>
                )}
                items={ticketActionButtons}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center">
        <Typography
          variant="c2"
          fontWeight="medium"
          className="relative flex flex-col text-textColor-secondary"
        >
          <span>{`${ticket?.shift} shift `}</span>

          <span>{`${ticketStartTime || 'Pending'} - ${ticketEndTime || 'Pending'}`}</span>

          {endsTomorrow && (
            <EndsTomorrowIndicator
              className="absolute right-[-20px] top-[5px]"
              isTooltipAvailable={endsTomorrow}
            />
          )}
        </Typography>

        <DropDown
          options={{ placement: 'bottom-start' }}
          config={{
            itemsElementClassName: clsx(
              'shadow-[0px_2px_66px_-10px_#0000000F]',
              'rounded-lg',
              'bg-bgColor-card',
              'w-[225px]',
            ),
          }}
          renderElement={(isOpen) =>
            not(isReadonly) && not(isReadonlyStatus) && ticket.crewLeader ? (
              <Button
                size="sm"
                className="bg-inherit p-0"
                disabled={isReadonly || isReadonlyStatus || not(ticket.crewLeader)}
              >
                <Tag
                  type={ticketStatusOption ? ticketStatusOption.type : 'disabled'}
                  startIcon={
                    ticketStatusOption?.text === TicketStatus.Scheduled ? (
                      <LockClosedIcon />
                    ) : undefined
                  }
                  endIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  className="font-medium"
                >
                  {ticket.status}
                </Tag>
              </Button>
            ) : (
              <button
                type="button"
                disabled
                data-tooltip-id="status-change-restricted"
              >
                <Tag
                  type={ticketStatusOption ? ticketStatusOption.type : 'disabled'}
                  className="font-medium"
                >
                  {ticket.status}
                </Tag>
              </button>
            )
          }
          items={ticketStatusOptions.map((status) => ({
            value: status.text,
            label: (
              <CardMenuItem
                onClick={async () => {
                  try {
                    await updateTicketStatus({
                      id: ticket.id,
                      job: ticket.job,
                      status: status.text,
                      thisShiftTimeOnYard: ticket.thisShiftTimeOnYard || '',
                    }).unwrap();
                  } catch (error) {
                    showToastErrorMessage('There was an error trying to change status');
                  }
                }}
                titleClassName={clsx('flex items-center gap-3', typeStyles[status.type])}
              >
                {ticket.status === status.text && (
                  <Icon
                    icon={<CheckmarkIcon />}
                    className="fill-brandingColor-primary-gradient"
                  />
                )}

                {status.text}

                {status.text === TicketStatus.Scheduled && (
                  <Icon
                    icon={<LockClosedIcon />}
                    className="!fill-semanticColor-success"
                  />
                )}
              </CardMenuItem>
            ),
          }))}
        />
      </div>

      {not(isReadonly) && not(isReadonlyStatus) && not(ticket.crewLeader) && (
        <Tooltip
          id="status-change-restricted"
          place="bottom"
          content="Assign Crew Leader to change status"
        />
      )}
    </div>
  );
};

TicketCardTitle.displayName = 'TicketCardTitle';
export { TicketCardTitle };
