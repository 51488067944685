import { Dispatch, FC, SetStateAction } from 'react';
import { useAppDispatch } from 'src/store';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Button } from '@mui/material';

import {
  TypeNotation,
  RelationOptionsMap,
  TableManagerFieldValue,
  MetadataMap,
  TagType,
} from 'src/shared/types';
import { adminTableActions } from 'src/store/slices';
import { ResponsiveDialog } from 'src/shared/ui/responsiveDialog';
import { BodyDialog, FooterDialog, HeaderDialog } from 'src/shared/ui/responsiveDialog/ui';

import { DetailsFields } from '../DetailsFields';

type DetailsTableRowModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  schema: TypeNotation[];
  relationOptions: RelationOptionsMap;
  selectedRow: Record<string, TableManagerFieldValue> | null;
  setSelectedRow: Dispatch<SetStateAction<Record<string, TableManagerFieldValue> | null>>;
  fieldsMetadata: MetadataMap;
  modelName: TagType;
  idField: string;
};

const DetailsTableRowModal: FC<DetailsTableRowModalProps> = ({
  schema,
  isOpen,
  selectedRow,
  fieldsMetadata,
  setSelectedRow,
  relationOptions,
  modelName,
  setIsOpen,
  idField,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const closeDialog = () => {
    setIsOpen(false);
    setSelectedRow(null);
    dispatch(adminTableActions.selectRow({ id: null, typeModal: null }));
  };

  return (
    <ResponsiveDialog
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: fullScreen ? '100%' : '80%',
          padding: '1rem',
        },
      }}
    >
      <HeaderDialog
        title={`${modelName} Details`}
        onClose={closeDialog}
      />
      <BodyDialog>
        <DetailsFields
          schema={schema}
          selectedRow={selectedRow}
          relationOptions={relationOptions}
          fieldsMetadata={fieldsMetadata}
          idField={idField}
        />
      </BodyDialog>
      <FooterDialog>
        <Button
          variant="contained"
          color="info"
          onClick={closeDialog}
          sx={{ minWidth: '6rem' }}
        >
          Cancel
        </Button>
      </FooterDialog>
    </ResponsiveDialog>
  );
};

export { DetailsTableRowModal };
