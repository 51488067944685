import clsx from 'clsx';
import { FC } from 'react';

interface ConnectionLineProps {
  index: number;
  leftDistance: number;
  isOverlapped?: boolean;
}

const ConnectionLine: FC<ConnectionLineProps> = ({ index, leftDistance, isOverlapped }) => {
  return (
    <div
      className={clsx('h-2', index === 0 || isOverlapped ? 'bg-transparent' : 'bg-bgColor-green')}
      style={{
        width: `${leftDistance}px`,
      }}
    />
  );
};

export { ConnectionLine };
