import { FC } from 'react';
import clsx from 'clsx';

import { Typography } from 'src/shared/ui/typography';
import { Icons } from 'src/assets/icons';

interface TaskManagementInfoProps {
  activityName?: string;
  provider?: string;
  crew?: string;
  canShrink?: boolean;
}

const TaskManagementInfo: FC<TaskManagementInfoProps> = ({
  activityName,
  provider,
  crew,
  canShrink,
}) => {
  return (
    <div className={clsx('flex items-center gap-2', canShrink ? 'flex-wrap' : 'shrink-0')}>
      {activityName && (
        <Typography
          variant="c3"
          className="text-xs leading-[18px] shrink-0"
        >
          {activityName}
        </Typography>
      )}

      {provider && (
        <div className="flex gap-1 items-center justify-center shrink-0">
          <Icons.Outlined.Account.PersonIcon className="fill-[#231F20] shrink-0 w-5 h-5" />

          <Typography
            variant="c3"
            className="text-xs leading-[18px] shrink-0"
          >
            {provider}
          </Typography>
        </div>
      )}

      {crew && (
        <div className="flex gap-1 items-center justify-center shrink-0">
          <Icons.Outlined.Account.PeopleIcon className="fill-[#231F20] shrink-0 w-5 h-5" />

          <Typography
            variant="c3"
            className="text-xs leading-[18px] shrink-0"
          >
            {crew}
          </Typography>
        </div>
      )}
    </div>
  );
};

export { TaskManagementInfo };
