import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';
import { MetricCard } from '../MetricCard';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

import { DashboardAdminMetric } from 'src/shared/types';
import { useGetProviderQuery } from 'src/store/api/provider';
import { METRIC_SKELETON_COUNT, METRIC_HEIGHT } from '../../helpers/constants';

const MetricList = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(15rem, 1fr))',
  gap: '1rem',
}));

type MetricsProps = {
  metrics: DashboardAdminMetric[];
  isLoadingData: boolean;
};

const Metrics: FC<MetricsProps> = ({ metrics, isLoadingData }) => {
  const { data: provider } = useGetProviderQuery('');
  return (
    <MetricList>
      {!isLoadingData
        ? metrics.map((metric) => (
            <MetricCard
              key={metric.name}
              metric={metric}
              fileRootPath={provider?.FileRootPath ?? ''}
            />
          ))
        : Array.from({ length: METRIC_SKELETON_COUNT }).map((_) => (
            <Skeleton
              key={uuidv4()}
              variant="rounded"
              height={METRIC_HEIGHT}
            />
          ))}
    </MetricList>
  );
};
export { Metrics };
