import { DateRangeType } from '../../datepicker/types';

type JobFormInitialValues = {
  dateRange: DateRangeType;
  owner: string;
  ownerContract: string;
  ownerContact: string;
  ownerRepresentative: string;
  ownerLocation: string;
  po: string;
  wo: string;
  department: string;
  serviceLine: string;
  serviceType: string;
  description: string;
  jobCategory: string;
  jobIdentifier: string;
  isLunchAllowed: 'Yes' | 'No';
  shiftType: string;
  JELL: string;
  isJELEnforced: boolean;
  defaultScheduledStartTime?: string;
  defaultScheduledDuration?: string;
  defaultScheduledTimeOnYard?: string;
  defaultScheduledBaseTime?: string;
  sro: string;
};

enum Step {
  owner = 'Owner Details',
  details = 'Job Details',
  schedule = 'Schedule',
}

export type { JobFormInitialValues };
export { Step };
