import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  REPORT_TABLE_BOTTOM_SPACE,
  REPORT_TABLE_PAGINATION_HEIGHT,
} from 'src/pages/activities/helpers';
import {
  ActivityEntity,
  ActivityHealth,
  AllActivities,
  EntityRecord,
  EnumActivityTrack,
  PageSize,
  SortOrder,
} from 'src/shared/types';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { DATE_FORMAT } from 'src/shared/utils';
import { exportToExcel } from 'src/shared/utils/excel';
import { RootState } from 'src/store';
import { useGetAllActivityProcessesFiltersQuery } from 'src/store/api/activities';

import { TableReportHeader } from '../tableReportHeader';

interface TableReportByActivitiesProps {
  isSkip: boolean;
  onTotalChange?: (total: number, statesCount: number) => void;
  totalValues: {
    total: number;
    totalStates: number;
  };
  handleSelectEntity: (option: EntityRecord) => void;
  selectedEntity: ActivityEntity;
}

const TableReportByActivities: FC<TableReportByActivitiesProps> = ({
  isSkip,
  onTotalChange,
  totalValues,
  handleSelectEntity,
  selectedEntity,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>(15);
  const [isDownloadAllClicked, setIsDownloadAllClicked] = useState(false);

  const handlePageSizeChange = (size: PageSize) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    setPageSize(size);
  };

  const [sortSettings, setSortSettings] = useState<{
    key: keyof AllActivities['allActivities'][number] | null;
    order: SortOrder;
  }>({
    key: null,
    order: SortOrder.ASC,
  });

  const handleChangeSortSettings = (newSort: {
    key: keyof AllActivities['allActivities'][number] | null;
    order: SortOrder;
  }) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setSortSettings(newSort);
  };

  const boardFilters = useSelector((state: RootState) => state.config.boardFilters);

  const appliedFiltersAmount = Object.values(boardFilters).filter(
    (filter) => filter.length > 0,
  ).length;

  const { data, isLoading, isFetching, isError } = useGetAllActivityProcessesFiltersQuery(
    {
      pagination: {
        page: `${currentPage}`,
        pageSize: `${pageSize}`,
      },
      filters: {
        health: boardFilters.health?.length ? (boardFilters.health as ActivityHealth[]) : undefined,
        track: boardFilters.status?.length
          ? (boardFilters.status as EnumActivityTrack[])
          : undefined,
        jobId: boardFilters.jobID?.length ? boardFilters.jobID : undefined,
        provider: boardFilters.provider?.length ? boardFilters.provider : undefined,
        providerTeam: boardFilters.crew?.length ? boardFilters.crew : undefined,
        delayCategory: boardFilters.delayCategory?.length ? boardFilters.delayCategory : undefined,
      },
      sorting: sortSettings,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isSkip,
    },
  );

  const {
    data: dataAll,
    isLoading: isLoadingAll,
    isFetching: isFetchingAll,
    isError: isErrorAll,
  } = useGetAllActivityProcessesFiltersQuery(
    {
      filters: {
        health: boardFilters.health?.length ? (boardFilters.health as ActivityHealth[]) : undefined,
        track: boardFilters.status?.length
          ? (boardFilters.status as EnumActivityTrack[])
          : undefined,
        jobId: boardFilters.jobID?.length ? boardFilters.jobID : undefined,
        provider: boardFilters.provider?.length ? boardFilters.provider : undefined,
        providerTeam: boardFilters.crew?.length ? boardFilters.crew : undefined,
        delayCategory: boardFilters.delayCategory?.length ? boardFilters.delayCategory : undefined,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isSkip || !isDownloadAllClicked,
    },
  );

  useEffect(() => {
    if (data?.totalStates && data.pagination.total) {
      onTotalChange?.(data.pagination.total, data.totalStates);
    }
  }, [data]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [boardFilters]);

  const headers: TableHeaderType<AllActivities['allActivities'][number]>[] = useMemo(
    () => [
      {
        title: 'Activity Name',
        field: 'ActivityName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.name || '',
      },
      {
        title: 'Reported By',
        field: 'ReportedBy',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.reportedBy || '',
      },
      {
        title: 'Job Number',
        field: 'JobNumber',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.jobNumber || '',
      },
      {
        title: 'Job Name',
        field: 'JobName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.jobName || '',
      },
      {
        title: 'Progress',
        field: 'Progress',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.progress || 0,
      },
      {
        title: 'Provider Name',
        field: 'ProviderName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.provider.ProviderName || '',
      },
      {
        title: 'Provider Team Name',
        field: 'ProviderTeamName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.providerTeam.name || '',
      },
      {
        title: 'Equipment',
        field: 'Equipment',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.equipment || '',
      },
      {
        title: 'Equipment Type',
        field: 'EquipmentType',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.equipmentTypeId || '',
      },
      {
        title: 'Health',
        field: 'Health',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.health || '',
      },
      {
        title: 'Status',
        field: 'Status',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.track || '',
      },
      {
        title: 'Delay Category',
        field: 'DelayCategory',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.delayCategory?.DelayCategory || '',
      },
      {
        title: 'Notes',
        field: 'Notes',
        className: 'w-max',
        canSort: true,
        render: (job) => job.actualState?.notes || '',
      },
      {
        title: 'Scheduled Start',
        field: 'ScheduledStartDate',
        className: 'w-max',
        render: (job) =>
          job.activityStates[0]?.startDate
            ? dayjs
                .utc(job.activityStates[0].startDate)
                .tz(job.OwnerLocation?.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'Scheduled End',
        field: 'ScheduledEndDate',
        className: 'w-max',
        render: (job) =>
          job.activityStates[0]?.endDate
            ? dayjs
                .utc(job.activityStates[0].endDate)
                .tz(job.OwnerLocation?.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'Scheduled Duration',
        field: 'ScheduledDuration',
        className: 'w-max',
        render: (job) =>
          job.activityStates[0]?.startDate && job.activityStates[0]?.endDate
            ? dayjs
                .duration(
                  dayjs(job.activityStates[0]?.endDate).diff(
                    dayjs(job.activityStates[0]?.startDate),
                  ),
                )
                .format(DATE_FORMAT.HOURS_MINUTES)
            : 0,
      },
      {
        title: 'Current Start',
        field: 'CurrentStartDate',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.actualState?.startDate
            ? dayjs
                .utc(job.actualState.startDate)
                .tz(job.OwnerLocation?.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'Current End',
        field: 'CurrentEndDate',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.actualState?.endDate
            ? dayjs
                .utc(job.actualState?.endDate)
                .tz(job.OwnerLocation?.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'Current Estimated Duration',
        field: 'CurrentEstimatedDuration',
        className: 'w-max',
        render: (job) =>
          job.actualState?.startDate && job.actualState?.endDate
            ? dayjs
                .duration(dayjs(job.actualState?.endDate).diff(dayjs(job.actualState?.startDate)))
                .format(DATE_FORMAT.HOURS_MINUTES)
            : 0,
      },
      {
        title: 'Actual Start',
        field: 'ActualStartDate',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.actualState?.startDate && job.actualState?.progress === 100
            ? dayjs
                .utc(job.actualState.startDate)
                .tz(job.OwnerLocation?.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'Actual End',
        field: 'ActualEndDate',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.actualState?.endDate && job.actualState?.progress === 100
            ? dayjs
                .utc(job.actualState?.endDate)
                .tz(job.OwnerLocation?.LocationTimezone)
                .toISOString()
            : '',
      },
      {
        title: 'Actual Duration',
        field: 'ActualDuration',
        className: 'w-max',
        render: (job) =>
          job.actualState?.startDate &&
          job.actualState?.endDate &&
          job.actualState?.progress === 100
            ? dayjs
                .duration(dayjs(job.actualState?.endDate).diff(dayjs(job.actualState?.startDate)))
                .format(DATE_FORMAT.HOURS_MINUTES)
            : 0,
      },
    ],
    [],
  );

  const total = useMemo(() => data?.pagination.total || 0, [data]);

  const handleExportToExcel = async () => {
    if (!data?.allActivities) {
      return;
    }

    let dataToExport = data;

    if (isDownloadAllClicked && !dataAll?.allActivities) {
      return;
    }

    if (isDownloadAllClicked && dataAll?.allActivities) {
      dataToExport = dataAll;
    }

    const excelData = dataToExport.allActivities.map((activity) => {
      const {
        name = '',
        reportedBy = '',
        jobName = '',
        jobNumber = '',
        activityId = '',
        progress = 0,
        provider = { ProviderName: '' },
        providerId = '',
        providerTeamId = '',
        equipment = '',
        equipmentTypeId = '',
        health = '',
        track = '',
        startDate = '',
        endDate = '',
        delayCategory,
        notes = '',
      } = activity.actualState || {};

      const providerTeamName = activity.team.name || '';

      const ownerLocationTimeZone = activity.OwnerLocation?.LocationTimezone || dayjs.tz.guess();

      const scheduledStartDate = activity.activityStates[0]?.startDate
        ? dayjs
            .utc(activity.activityStates[0].startDate)
            .tz(ownerLocationTimeZone)
            .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
        : '';

      const scheduledEndDate = activity.activityStates[0]?.endDate
        ? dayjs
            .utc(activity.activityStates[0].endDate)
            .tz(ownerLocationTimeZone)
            .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
        : '';

      const currentStart = startDate
        ? dayjs.utc(startDate).tz(ownerLocationTimeZone).format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
        : '';

      const currentEnd = endDate
        ? dayjs.utc(endDate).tz(ownerLocationTimeZone).format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
        : '';

      const delayCategoryData = delayCategory || { DelayCategory: '', DelayCategoryID: '' };

      return {
        ActivityName: name,
        ReportedBy: reportedBy,
        JobNumber: jobName,
        JobName: jobNumber,
        ActivityID: activityId,
        Progress: progress,
        ProviderName: provider.ProviderName,
        ProviderID: providerId,
        ProviderTeamName: providerTeamName,
        ProviderTeamID: providerTeamId,
        Equipment: equipment,
        EquipmentType: equipmentTypeId,
        Health: health,
        Status: track,
        Notes: notes,
        DelayCategory: delayCategoryData.DelayCategory,
        DelayCategoryID: delayCategoryData.DelayCategoryID,
        ScheduledStartDate: scheduledStartDate,
        ScheduledEndDate: scheduledEndDate,
        ScheduledDuration:
          scheduledStartDate && scheduledEndDate
            ? dayjs
                .duration(dayjs(scheduledEndDate).diff(dayjs(scheduledStartDate)))
                .format(DATE_FORMAT.HOURS_MINUTES)
            : '',
        CurrentStartDate: currentStart,
        CurrentEndDate: currentEnd,
        CurrentEstimatedDuration:
          currentStart && currentEnd
            ? dayjs
                .duration(dayjs(currentEnd).diff(dayjs(currentStart)))
                .format(DATE_FORMAT.HOURS_MINUTES)
            : '',
        ActualStartDate: progress === 100 ? currentStart : '',
        ActualEndDate: progress === 100 ? currentEnd : '',
        ActualDuration:
          progress === 100
            ? dayjs
                .duration(dayjs(currentEnd).diff(dayjs(currentStart)))
                .format(DATE_FORMAT.HOURS_MINUTES)
            : '',
      };
    });

    exportToExcel(excelData, 'Activities');
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [minViewSpace, setMinViewSpace] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const offsetTop = containerRef.current.getBoundingClientRect().top;
      setMinViewSpace(offsetTop + REPORT_TABLE_BOTTOM_SPACE + REPORT_TABLE_PAGINATION_HEIGHT);
    }
  }, [containerRef]);

  return (
    <div className="flex flex-col gap-4">
      <TableReportHeader
        appliedFiltersAmount={appliedFiltersAmount}
        filters={data?.filters || []}
        handleExportToExcel={handleExportToExcel}
        handleSelectEntity={handleSelectEntity}
        isDownloadAllClicked={isDownloadAllClicked}
        selectedEntity={selectedEntity}
        setIsDownloadAllClicked={setIsDownloadAllClicked}
        totalValues={totalValues}
        isExcelDisabled={isFetching || isLoadingAll || isFetchingAll}
      />

      <div ref={containerRef}>
        <Table
          headers={headers}
          data={data?.allActivities || []}
          onSort={handleChangeSortSettings}
          sortSettings={sortSettings}
          isFetching={isFetching}
          isLoading={isLoading}
          withIndicator
          variant="secondary"
          containerClassName="h-full"
          heightTable={minViewSpace}
          sticky
          scroll
          isError={isError || isErrorAll}
          pagination={{
            currentPage,
            onPageChange: (page: number) => setCurrentPage(page),
            onPageSizeChange: handlePageSizeChange,
            pageSize,
            total,
          }}
        />
      </div>
    </div>
  );
};

export { TableReportByActivities };
