import dayjs from 'dayjs';
import { z } from 'zod';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

export const updateActionSchema = z.object({
  actionStatus: z.string(),
  assignedTo: z.object({
    id: z.string(),
    name: z.string(),
  }),
  notes: z.string().optional(),
  files: z.array(z.instanceof(File)).optional(),
});

export const addActionSchema = z.object({
  actionStatus: z.string(),
  notes: z.string().optional(),
  shortDescription: z.string(),
  ownerLocation: z.object({
    id: z.string(),
    name: z.string(),
  }),
  assignedTo: z.object({
    id: z.string(),
    name: z.string(),
  }),
});
