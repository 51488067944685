import React, { FC } from 'react';
import { match } from 'ts-pattern';

import { Skeleton } from '../skeleton';
import { Card } from '../../card';

type DetailsSkeletonProps = {
  variant: 'requirements' | 'profile';
};

const DetailsSkeleton: FC<DetailsSkeletonProps> = ({ variant }) => {
  const skeletonLoader = match(variant)
    .with('requirements', () => (
      <div>
        <Card
          title={
            <>
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <Skeleton className="w-[20px] h-[18px]" />

                  <Skeleton className="w-[150px] h-[18px]" />
                </div>

                <div className="flex gap-2 items-center">
                  <Skeleton className="w-[28px] h-[28px] rounded-lg" />

                  <Skeleton className="w-[28px] h-[28px] rounded-full" />

                  <Skeleton className="w-[52px] h-[32px] rounded-full" />

                  <Skeleton className="w-[62px] h-[18px]" />
                </div>
              </div>

              <Skeleton className="w-full h-[200px] mb-5" />
            </>
          }
          className="mb-5"
        />

        <Skeleton className="w-[25px] h-[16px] mb-2" />

        <Skeleton className="w-full h-[48px]" />
      </div>
    ))
    .with('profile', () => (
      <div className="flex flex-col gap-5">
        <Card
          title={
            <div className="flex justify-between items-center">
              <div className="flex flex-col justify-between gap-1">
                <Skeleton className="w-[140px] h-[18px]" />

                <Skeleton className="w-[100px] h-[18px]" />
              </div>

              <Skeleton className="w-[125px] h-[48px]" />
            </div>
          }
        />

        <Card
          title={
            <>
              <div className="flex flex-col gap-2">
                <Skeleton className="w-[162px] h-[18px]" />
              </div>

              <div className="flex justify-between gap-5">
                <div className="flex flex-col gap-2 w-[50%]">
                  <Skeleton className="w-[83px] h-[18px]" />

                  <Skeleton className="w-[75px] h-[18px]" />
                </div>

                <div className="flex flex-col gap-2 w-[50%]">
                  <Skeleton className="w-[81px] h-[18px]" />

                  <Skeleton className="w-[75px] h-[18px]" />
                </div>
              </div>

              <div className="flex justify-between gap-5">
                <div className="flex flex-col gap-2 w-[50%]">
                  <Skeleton className="w-[109px] h-[18px]" />

                  <Skeleton className="w-[210px] h-[18px]" />
                </div>

                <div className="flex flex-col gap-2 w-[50%]">
                  <Skeleton className="w-[50px] h-[18px]" />

                  <Skeleton className="w-[137px] h-[18px]" />
                </div>
              </div>

              <div className="flex justify-between gap-5">
                <div className="flex flex-col gap-2 w-[50%]">
                  <Skeleton className="w-[28px] h-[18px]" />

                  <Skeleton className="w-[98px] h-[18px]" />
                </div>
              </div>
            </>
          }
        />
      </div>
    ))
    .exhaustive();

  return skeletonLoader;
};

export { DetailsSkeleton };
