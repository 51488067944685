import { forwardRef } from 'react';
import clsx from 'clsx';
import { match } from 'ts-pattern';

import { Icon } from 'src/shared/ui/icon';

import { type ButtonProps } from '../button';

type GhostButtonProps = ButtonProps & {
  buttonClassName?: string;
};

const GhostButton = forwardRef<HTMLButtonElement, GhostButtonProps>(
  (
    {
      children,
      className,
      buttonClassName,
      color = 'basic',
      darkBg,
      startIcon,
      endIcon,
      size,
      iconClassName,
      ...props
    },
    ref,
  ) => {
    const colorClasses = match(color)
      // | Ghost button styles.
      .when(
        () => darkBg,
        () => 'bg-transparent text-textColor-white',
      )
      .with('basic', () => 'bg-transparent text-textColor-tertiary')
      .with('primary', () => `bg-transparent text-brandingColor-primary`)
      .with('success', () => 'bg-transparent text-semanticColor-success')
      .with('info', () => 'bg-transparent text-semanticColor-info')
      .with('warning', () => 'bg transparent border text-semanticColor-warning')
      .with('danger', () => 'bg-transparent text-semanticColor-danger')
      .otherwise(() => '');

    return (
      <button
        ref={ref}
        type="button"
        className={clsx(buttonClassName, colorClasses, className)}
        {...props}
      >
        {startIcon && (
          <Icon
            icon={startIcon}
            size={size}
            color={darkBg ? 'light' : 'dark'}
            className={iconClassName}
          />
        )}

        <span>{children}</span>

        {endIcon && (
          <Icon
            icon={endIcon}
            size={size}
            color={darkBg ? 'light' : 'dark'}
            className={iconClassName}
          />
        )}
      </button>
    );
  },
);

GhostButton.displayName = 'GhostButton';

export { GhostButton };
export type { GhostButtonProps };
