import { ComponentProps, FC } from 'react';

import { clsx } from 'src/shared/utils';

type SkeletonProps = ComponentProps<'div'>;
const Skeleton: FC<SkeletonProps> = ({ className, ...props }) => {
  return (
    <div
      className={clsx('animate-pulse rounded-md bg-[#E5E6E9]', className)}
      {...props}
    />
  );
};

export type { SkeletonProps };
export { Skeleton };
