import { FC, PropsWithChildren, useState } from 'react';

import { clsx } from 'src/shared/utils/clsx';
import { CreateOrUpdateJobModal } from 'src/shared/ui/modals/createOrUpdateJob/createOrUpdateJob';

import { ExpandedSchedulerSidebar } from './ui/expandedSchedulerSidebar';
import { CollapsedSchedulerSidebar } from './ui/collapsedSchedulerSidebar';

type SchedulerSidebarProps = { isOpen: boolean; toggleSidebar?: () => void };

const SchedulerSidebar: FC<PropsWithChildren<SchedulerSidebarProps>> = ({
  toggleSidebar,
  isOpen,
}) => {
  const [isCreateJobModalOpen, setIsCreateJobModalOpen] = useState(false);

  return (
    <>
      <div
        className={clsx(
          'h-full overflow-y-scroll flex grow flex-col gap-y-6 bg-bgColor-card py-7 px-4 border-[1px] border-outlineColor-input-border border-r-[0]',
          'transition-[all] duration-300 ease-in-out shadow-[0px_2px_66px_-10px_#0000000F]',
          isOpen ? 'min-w-[420px] max-w-[420px]' : 'min-w-[99px] max-w-[99px]',
        )}
      >
        {isOpen ? (
          <ExpandedSchedulerSidebar
            setIsCreateJobModalOpen={setIsCreateJobModalOpen}
            toggleSidebar={toggleSidebar}
          />
        ) : (
          <CollapsedSchedulerSidebar
            setIsCreateJobModalOpen={setIsCreateJobModalOpen}
            toggleSidebar={toggleSidebar}
          />
        )}
      </div>

      <CreateOrUpdateJobModal
        type="create"
        isOpen={isCreateJobModalOpen}
        setIsOpen={setIsCreateJobModalOpen}
      />
    </>
  );
};

export { SchedulerSidebar };
export { type SchedulerSidebarProps };
