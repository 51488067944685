import { FC, PropsWithChildren } from 'react';

import { SchedulerBoard } from 'src/shared/ui/schedulerBoard';

type MainProps = Record<string, unknown>;

const Main: FC<PropsWithChildren<MainProps>> = () => {
  return <SchedulerBoard />;
};

export { Main };
export type { MainProps };
