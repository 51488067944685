import { useParams } from 'react-router-dom';
import { Typography } from 'src/shared/ui/typography';
import { TagType } from 'src/shared/types';
import { TableView } from './TableView';

const ToolbarMap = () => {
  const { modelName } = useParams<{ modelName: TagType }>();
  return (
    <div className="w-full flex justify-between items-center gap-4 bg-white p-2 flex-wrap rounded-lg">
      <Typography
        variant="h3"
        className="flex items-center leading-3"
      >
        {modelName}
      </Typography>
      <TableView />
    </div>
  );
};

export { ToolbarMap };
