import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const FILE_EXTENSION = '.xlsx';
const BASE64_PREFIX = 'data:image/png;base64,';
const EXTENSION = 'png';

export const exportToExcelWithImg = async (
  excelData: any[],
  fileName: string,
  imageColumnNames?: string[],
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(fileName);

  if (excelData.length > 0) {
    const keys = Object.keys(excelData[0]);

    worksheet.columns = keys.map((key) => ({
      header: key.trim(),
      key,
      width: 20,
    }));

    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    excelData.forEach((data) => {
      const rowData = { ...data };

      (imageColumnNames ?? []).forEach((columnName) => {
        delete rowData[columnName];
      });

      const row = worksheet.addRow(rowData);

      (imageColumnNames ?? []).forEach((columnName) => {
        const imageBase64 = data[columnName];
        if (imageBase64 && imageBase64.startsWith(BASE64_PREFIX)) {
          const imageId = workbook.addImage({
            base64: imageBase64,
            extension: EXTENSION,
          });

          const colIndex = keys.indexOf(columnName);

          worksheet.addImage(imageId, {
            tl: { col: colIndex, row: row.number - 1 },
            ext: { width: 160, height: 100 },
            editAs: 'twoCell',
          });
        }
      });

      if (
        (imageColumnNames ?? []).some((columnName) => data[columnName]?.startsWith(BASE64_PREFIX))
      ) {
        worksheet.getRow(row.number).height = 80;
      }
    });
  }

  const excelBuffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([excelBuffer], { type: FILE_TYPE });

  FileSaver.saveAs(blob, fileName + FILE_EXTENSION);
};
