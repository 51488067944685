import { SafetyListItem } from 'src/shared/types';
import { api } from './api';

const surveyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSafetyList: builder.query<
      SafetyListItem[],
      {
        filters?: {
          startDate?: string;
          endDate?: string;
          type?: string[];
          riskScore?: string[];
          completedBy?: string[];
        };
      }
    >({
      query: (params) => ({
        url: '/safety/list',
        method: 'GET',
        params,
      }),
      providesTags: ['Safety'],
      transformResponse: (response: { data: SafetyListItem[] }) => response.data,
    }),
  }),
});

export const { useGetSafetyListQuery } = surveyApi;
