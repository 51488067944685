import { FC, PropsWithChildren, MouseEvent } from 'react';
import clsx from 'clsx';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { match } from 'ts-pattern';

import { ReactComponent as HardHatIcon } from 'src/assets/icons/outlined/account/hard-hat.svg';
import { ReactComponent as MoreVerticalIcon } from 'src/assets/icons/filled/menus/more-vertical.svg';
import { ReactComponent as CheckmarkIcon } from 'src/assets/icons/outlined/edit/checkmark-circle.svg';
import { ReactComponent as ClockIcon } from 'src/assets/icons/outlined/misc/clock.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/outlined/edit/close-circle.svg';
import { LaborEntity, PersonEntity, Role, TicketEntity } from 'src/shared/types';
import { dayjs, not, showToastErrorMessage } from 'src/shared/utils';
import { Typography } from 'src/shared/ui/typography';
import { TicketConnections, useUnassignFromTicketMutation } from 'src/store/api';
import { modalConfigActions, selectCurrentUser } from 'src/store/slices';
import { useAppDispatch } from 'src/store';

import { Tag } from '../tag';
import { DropDown } from '../dropDown';
import { IconButton } from '../iconButton';
import { Icon } from '../icon';
import { CardMenuItem } from '../schedulerBoard/ui/cardMenuItem';

enum PersonCardMode {
  CrewLeader = 'crewLeader',
  Employee = 'employee',
  Supervisor = 'supervisor',
}

type PersonCardProps = {
  type: 'person' | 'supervisor' | 'crewLeader';
  disabled?: boolean;
  mode?: PersonCardMode;
  clearAfterDrag?: boolean;
  cardId?: string;
  ticket?: TicketEntity;
  className?: string;
  isTicketCard?: boolean;
  sourceEntityId?: string;
  withMoreButton?: boolean;
  onCrewLeaderAssign?: (item: LaborEntity, key: string) => void;
} & PersonEntity;

const PersonCard: FC<PropsWithChildren<PersonCardProps>> = ({
  type,
  disabled,
  mode = PersonCardMode.Employee,
  clearAfterDrag,
  className,
  cardId,
  ticket,
  isTicketCard,
  sourceEntityId,
  withMoreButton = false,
  onCrewLeaderAssign,
  ...person
}) => {
  const dispatch = useAppDispatch();

  const [unassignFromTicket] = useUnassignFromTicketMutation();

  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const entityTypes = {
    crewLeader: 'crew-leader',
    employee: 'person',
    supervisor: 'supervisor',
  };

  const unassign = async (ticketId: string, entityId: string) => {
    const entityType = entityTypes[mode] as TicketConnections;

    try {
      await unassignFromTicket({
        ticketId,
        entityType,
        entityId,
        sourceEntityId,
      }).unwrap();
    } catch {
      showToastErrorMessage(
        `Sorry, an error occurred, when you tried to unassign ${person.firstName} ${person.lastName} from ticket, please check your internet connection`,
      );
    }
  };

  const [, drag] = useDrag(() => ({
    type,
    item: () => ({ ...person }),
    canDrag: () => not(isReadonly),
    end: async (_item, monitor) => {
      if (ticket && monitor.getDropResult() === null && clearAfterDrag) {
        unassign(ticket.id, person.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const renderTag = () => {
    if (mode === PersonCardMode.CrewLeader)
      return (
        <Tag
          type="info"
          className="bg-[rgba(18,60,142,.15)] text-brandingColor h-fit"
        >
          Crew Leader
        </Tag>
      );

    return null;
  };

  const { firstName, lastName, id, providerPersonnelEmail } = person;

  const openProfileDetails = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(modalConfigActions.setOpenProfileModalPersonEmail(providerPersonnelEmail || ''));
  };

  const toggleCrewLeader = () => {
    const crewLeader = ticket?.labor.find(
      (el) => el.ProviderPersonnel?.providerPersonnelID === person.id,
    );

    if (mode === PersonCardMode.Employee && onCrewLeaderAssign && crewLeader) {
      onCrewLeaderAssign(crewLeader, crewLeader.PeopleWorkRecordID);
    }

    if (mode === PersonCardMode.CrewLeader && ticket) {
      unassign(ticket.id, person.id);
    }
  };

  const laborEntity =
    mode === PersonCardMode.CrewLeader
      ? ticket?.crewLeader
      : ticket?.labor.find((labor) => labor.ProviderPersonnel?.id === person.id);

  const isFarFromDue = laborEntity?.Provider?.LaborAcknowledgementDue
    ? dayjs().isBefore(
        dayjs(ticket?.startTime).subtract(laborEntity?.Provider?.LaborAcknowledgementDue, 'hours'),
      )
    : false;

  const isTicketDatePassed = dayjs(ticket?.startTime).isBefore(dayjs());

  const acknowledgementIconClassName = clsx(
    'rounded-full',
    mode === PersonCardMode.CrewLeader && 'bg-[#EFF1F5]',
  );

  const acknowledgementIcon = laborEntity
    ? match(laborEntity.AcceptWork)
        .when(
          (acceptWork) => acceptWork === 'Yes',
          () => (
            <CheckmarkIcon
              className={clsx(acknowledgementIconClassName, 'fill-semanticColor-success')}
              data-tooltip-id={`${person.firstName} ${person.lastName} ${person.id} ${ticket?.id} labor`}
            />
          ),
        )
        .when(
          (acceptWork) => acceptWork === 'Declined',
          () => (
            <CloseIcon
              className={clsx(acknowledgementIconClassName, 'fill-semanticColor-danger')}
              data-tooltip-id={`${person.firstName} ${person.lastName} ${person.id} ${ticket?.id} labor`}
            />
          ),
        )
        .when(
          () => isTicketDatePassed,
          () => (
            <ClockIcon
              className={clsx(acknowledgementIconClassName, 'fill-semanticColor-danger')}
              data-tooltip-id={`${person.firstName} ${person.lastName} ${person.id} ${ticket?.id} labor`}
            />
          ),
        )
        .when(
          (acceptWork) => (acceptWork === 'No' || not(acceptWork)) && isFarFromDue,
          () => (
            <ClockIcon
              className={clsx(acknowledgementIconClassName, 'fill-textColor-disabled')}
              data-tooltip-id={`${person.firstName} ${person.lastName} ${person.id} ${ticket?.id} labor`}
            />
          ),
        )
        .when(
          (acceptWork) => (acceptWork === 'No' || not(acceptWork)) && not(isFarFromDue),
          () => (
            <ClockIcon
              className={clsx(acknowledgementIconClassName, 'fill-semanticColor-warning')}
              data-tooltip-id={`${person.firstName} ${person.lastName} ${person.id} ${ticket?.id} labor`}
            />
          ),
        )
        .otherwise(() => null)
    : null;

  const acknowledgementTooltipText = laborEntity
    ? match(laborEntity.AcceptWork)
        .when(
          (acceptWork) => acceptWork === 'Yes',
          () => 'Accepted',
        )
        .when(
          (acceptWork) => acceptWork === 'Declined',
          () => 'Declined',
        )
        .when(
          (acceptWork) => not(acceptWork) && isTicketDatePassed,
          () => 'Was not Acknowledged',
        )
        .when(
          (acceptWork) => (acceptWork === 'No' || not(acceptWork)) && isFarFromDue,
          () => 'Far from Due',
        )
        .when(
          (acceptWork) => (acceptWork === 'No' || not(acceptWork)) && not(isFarFromDue),
          () => 'Close to Due',
        )
        .otherwise(() => '')
    : '';

  return (
    <div
      ref={disabled || mode === PersonCardMode.CrewLeader ? undefined : drag}
      className={clsx(
        'z-[10] relative border-[1px] rounded-lg flex translate-x-0 translate-y-0',
        not(disabled) && not(isReadonly) && 'active:cursor-move',
        mode === PersonCardMode.CrewLeader && 'border-[#E4E9F2] bg-[#EFF1F5]',
        mode === PersonCardMode.Supervisor &&
          'border-[rgba(220,88,88,0.2)] bg-[rgba(220,88,88,0.06)]',
        mode === PersonCardMode.Employee && 'border-outlineColor-input-border bg-bgColor-card',
        disabled && not(isTicketCard) && 'opacity-[0.4]',
        className,
      )}
    >
      <div
        className={clsx(
          'flex border-l-[7px] rounded-tl-[7px] rounded-bl-[7px] w-full min-w-[310px]',
          mode === PersonCardMode.Employee && 'border-l-bgColor-blue',
          mode === PersonCardMode.CrewLeader && 'border-l-brandingColor-primary-gradient',
          mode === PersonCardMode.Supervisor && 'border-l-bgColor-red',
        )}
      >
        <div className="flex p-[8px] justify-between w-full items-center">
          <div className="flex gap-x-2 w-full">
            <div className="flex flex-col gap-y-1 w-full">
              <div className="flex justify-between items-center">
                <Typography
                  variant="c2"
                  className="text-textColor-secondary"
                >
                  {id}
                </Typography>

                <div className="flex justify-between items-center gap-x-1">
                  {isTicketCard && acknowledgementIcon}

                  {renderTag()}

                  {isTicketCard && withMoreButton && not(isReadonly) && (
                    <DropDown
                      config={{
                        withPortal: true,
                        itemsElementClassName: clsx(
                          'shadow-[0px_2px_66px_-10px_#0000000F]',
                          'w-[200px]',
                          'rounded-lg',
                          'bg-bgColor-card',
                        ),
                      }}
                      options={{ placement: 'bottom-start' }}
                      renderElement={() => (
                        <IconButton
                          color={mode === PersonCardMode.CrewLeader ? 'secondary' : 'basic'}
                          size="none"
                          iconSize="md"
                          iconClassName="fill-textColor-tertiary"
                        >
                          <MoreVerticalIcon />
                        </IconButton>
                      )}
                      items={[
                        {
                          value: 'Mark as Crew Leader',
                          label: (
                            <CardMenuItem
                              onClick={toggleCrewLeader}
                              startIcon={
                                <Icon
                                  className="fill-textColor-tertiary"
                                  icon={<HardHatIcon />}
                                />
                              }
                              titleClassName="text-textColor-tertiary"
                            >
                              {mode === PersonCardMode.CrewLeader
                                ? 'Set as Technician'
                                : 'Set as Crew Leader'}
                            </CardMenuItem>
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col">
                <button
                  type="button"
                  className="text-xs text-left font-bold leading-4"
                  onClick={openProfileDetails}
                >
                  {`${firstName} ${lastName}`}
                </button>

                <Typography variant="c1">{person?.jobTitle || 'No job title'}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isTicketCard && (
        <Tooltip
          id={`${person.firstName} ${person.lastName} ${person.id} ${ticket?.id} labor`}
          place="bottom"
          content={acknowledgementTooltipText}
          className="w-[200px] bg-[rgba(0,0,0,0.7)]"
        />
      )}
    </div>
  );
};

export { PersonCard, PersonCardMode };
export type { PersonCardProps };
