import { CircularProgress } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { LeafletMap } from 'src/features/admin/LeafletMap';
import { AddAssignmentMap } from 'src/features/admin/LeafletMap/components/AddAssignmentMap';
import { useGetInspectorsQuery } from 'src/store/api';
import { InspectorPopupBody } from './InspectorPopupBody';
import { Inspector } from 'src/shared/types';
import { ToolbarMap } from './ToolbarMap';

const InspectorsMap: React.FC = () => {
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
  const { data: inspectors = [], isFetching, isLoading } = useGetInspectorsQuery('');

  const toggleSearchVisibility = useCallback(() => {
    setIsSearchVisible((prev) => !prev);
  }, []);

  const isLoadingOverall = isFetching || isLoading;

  const renderPopupBody = useCallback(
    (data: Inspector) => <InspectorPopupBody inspector={data} />,
    [],
  );

  return (
    <div className="w-full h-full flex flex-col gap-4">
      <ToolbarMap />
      {!isLoadingOverall ? (
        <div className="relative w-full h-full flex flex-col gap-4">
          <LeafletMap
            markers={inspectors}
            isSearchVisible={isSearchVisible}
            renderPopupBody={renderPopupBody}
          />
          <AddAssignmentMap
            isSearchVisible={isSearchVisible}
            toggleSearchVisibility={toggleSearchVisibility}
          />
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
export { InspectorsMap };
