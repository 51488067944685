import { MULTI_SIGNATURE_TYPE } from '../customFormQuestions';

export const surveyCreatorConfig = {
  showLogicTab: true,
  showJSONEditorTab: true,
  showPreviewTab: true,
  showThemeTab: true,
  questionTypes: [
    'boolean',
    'checkbox',
    'comment',
    'dropdown',
    'tagbox',
    'expression',
    'file',
    'html',
    'image',
    'imagepicker',
    'matrix',
    'matrixdropdown',
    'matrixdynamic',
    'multipletext',
    'panel',
    'paneldynamic',
    'radiogroup',
    'rating',
    'ranking',
    'signaturepad',
    'text',
    'geolocation',
    'qrcode',
    'surveySelect',
    'surveyMultiSelect',
    MULTI_SIGNATURE_TYPE,
  ],
};
