import { ProviderBranch } from 'src/shared/types';
import { api } from 'src/store/api/api';

const providerBranchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProviderBranches: builder.query<ProviderBranch[], ''>({
      query: () => ({
        url: '/provider-branches',
      }),
      providesTags: ['ProviderBranches'],
      transformResponse: (response: { data: ProviderBranch[] }) => response.data,
    }),
  }),
});

export const { useGetProviderBranchesQuery } = providerBranchApi;
