import React, { FC } from 'react';

import { JobEntity, TicketEntity, TicketStatus } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { useGetPeopleQuery } from 'src/store/api';
import { getFormattedTimePickerDateTime } from 'src/shared/utils';
import { ReactComponent as LockClosedIcon } from 'src/assets/icons/outlined/security/lock.svg';

import { Icon } from '../icon';

import { getFormattedDate } from './helpers';
import { DetailsMeta } from './detailsMeta';

const TICKET_STATUS_DATA = {
  [TicketStatus.Scheduled]: {
    icon: (
      <Icon
        icon={<LockClosedIcon />}
        size="sm"
        className="fill-[#231F20]"
      />
    ),
    description:
      'The ticket has already been scheduled and you can no longer assign people or equipment to it.',
  },
  [TicketStatus.ReadyToDispatch]: {
    icon: undefined,
    description: 'During this status you free to assign people or equipment',
  },
};

type TicketDetailsProps = {
  ticket?: TicketEntity;
  job?: JobEntity;
};

const TicketDetails: FC<TicketDetailsProps> = ({ ticket, job }) => {
  const { data: people } = useGetPeopleQuery({});

  if (!ticket) {
    return <Typography variant="h3">There is no information about this Ticket</Typography>;
  }

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    crewLeader: _crewLeader,
    ticketNumber,
    owner,
    ownerContract,
    ownerContact,
    ownerLocation,
    ownerRepresentative,
    wo,
    po,
    workNotes,
    lunchAllowed,
    scheduledLunch,
    shift,
    startTime,
    endTime,
    thisShiftTimeOnYard,
    thisShiftBaseTime,
    status,
  } = ticket;

  const crewLeader =
    people &&
    people.find((person) => person.id === _crewLeader?.ProviderPersonnel?.id && person.id !== null);

  const ticketStartTime = getFormattedTimePickerDateTime(startTime);
  const ticketEndTime = getFormattedTimePickerDateTime(endTime);
  const ticketTimeOnYard = thisShiftTimeOnYard
    ? getFormattedTimePickerDateTime(thisShiftTimeOnYard)
    : '-';
  const ticketBaseTime = thisShiftBaseTime
    ? getFormattedTimePickerDateTime(thisShiftBaseTime)
    : '-';

  const supervisorDetails = (
    <div className="flex flex-col gap-2 border border-solid border-textColor-light rounded-2xl p-4">
      <div className="flex flex-col gap-1">
        <Typography
          variant="h3"
          className="break-all"
        >
          {crewLeader
            ? `${crewLeader?.firstName} ${crewLeader?.lastName}`
            : 'Crew Leader was not assigned'}
        </Typography>

        <Typography
          fontWeight="regular"
          variant="label"
        >
          Crew Leader
        </Typography>
      </div>

      <Typography
        fontWeight="regular"
        variant="label"
        className="break-all"
      >
        -
      </Typography>
    </div>
  );

  const statusDetails = (
    <div className="flex flex-col gap-5 border border-solid border-textColor-light rounded-2xl pt-4 px-4 pb-[28px]">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between gap-2">
          <div className="flex gap-1 items-center">
            {TICKET_STATUS_DATA[status].icon}

            <Typography
              variant="p1"
              fontWeight="bold"
            >
              {status}
            </Typography>
          </div>

          <Typography
            variant="label"
            className="font-normal text-textColor-tertiary"
          >
            Status
          </Typography>
        </div>

        <Typography
          fontWeight="medium"
          variant="p1"
        >
          {TICKET_STATUS_DATA[status].description}
        </Typography>
      </div>

      <DetailsMeta meta={ticket} />
    </div>
  );

  const ownerDetails = (
    <div className="flex flex-col gap-5 border border-solid border-textColor-light rounded-2xl pt-4 px-4 pb-[28px]">
      <div className="flex flex-col gap-2">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Owner Details
        </Typography>

        <Typography
          fontWeight="medium"
          variant="p1"
          className="break-all"
        >
          {owner?.OwnerName || '-'}
        </Typography>
      </div>

      <div className="flex justify-between gap-5">
        <div className="w-[50%] break-all">
          <Typography variant="label">Representative</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {ownerRepresentative?.OwnerPersonnelEmail ||
              job?.ownerRepresentative?.OwnerPersonnelEmail ||
              '-'}
          </Typography>
        </div>

        <div className="w-[50%] break-all">
          <Typography variant="label">Rate Sheet</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {ownerContract?.RateSheetDescription || job?.ownerContract?.RateSheetDescription || '-'}
          </Typography>
        </div>
      </div>

      <div className="flex justify-between gap-5">
        <div className="w-[50%] break-all">
          <Typography variant="label">Contact</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="max-w-[221px] break-all"
          >
            {ownerContact?.OwnerPersonnelEmail || job?.ownerContact?.OwnerPersonnelEmail || '-'}
          </Typography>
        </div>

        <div className="w-[50%]">
          <Typography variant="label">Location</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {ownerLocation?.OwnerLocation || '-'}
          </Typography>
        </div>
      </div>

      <div className="flex justify-between gap-5">
        <div className="w-[50%]">
          <Typography variant="label">W.O</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {wo || '-'}
          </Typography>
        </div>

        <div className="w-[50%] break-all">
          <Typography variant="label">P.O</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {po || '-'}
          </Typography>
        </div>
      </div>
    </div>
  );

  const ticketDetails = (
    <div className="flex flex-col gap-5 border border-solid border-textColor-light rounded-2xl p-[18px]">
      <div className="flex justify-between gap-2">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Ticket Details
        </Typography>

        <Typography
          variant="label"
          className="font-normal text-textColor-tertiary break-all"
        >
          {ticket.jobCategory.Category || '-'}
        </Typography>
      </div>

      <div className="flex justify-between gap-5">
        <div className="w-[50%] break-all">
          <Typography variant="label">ID</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {ticketNumber || 'Pending'}
          </Typography>
        </div>

        <div className="w-[50%]">
          <Typography variant="label">Job SRO</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {job?.sro || 'Pending'}
          </Typography>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <Typography variant="label">Job</Typography>

        <Typography
          fontWeight="medium"
          variant="p1"
          className="break-all"
        >
          {job?.projectName || '-'}
        </Typography>
      </div>

      <div className="flex flex-col gap-1">
        <Typography variant="label">Scope of work</Typography>

        <Typography
          fontWeight="medium"
          variant="p1"
          className="break-all"
        >
          {workNotes || '-'}
        </Typography>
      </div>
    </div>
  );

  const scheduleDetails = (
    <div className="flex flex-col gap-5 border border-solid border-textColor-light rounded-2xl p-[18px]">
      <div className="flex justify-between gap-2">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Schedule
        </Typography>

        <Typography
          variant="label"
          className="font-normal text-textColor-tertiary break-all"
        >
          {shift}
          &nbsp;shift
        </Typography>
      </div>

      <div className="flex flex-col gap-1">
        <Typography variant="label">Due Date</Typography>

        <Typography
          fontWeight="medium"
          variant="p1"
          className="break-all"
        >
          {getFormattedDate(startTime)}
        </Typography>
      </div>

      <div className="flex flex-col gap-1">
        <Typography variant="label">Lunch</Typography>

        <Typography
          fontWeight="medium"
          variant="p1"
          className="break-all"
        >
          {lunchAllowed === 'Yes' ? `Allowed ${scheduledLunch}` : 'Not allowed'}
        </Typography>
      </div>

      <div className="flex justify-between gap-5">
        <div className="w-[33%] break-all">
          <Typography variant="label">Shift Time</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {`${ticketStartTime} - ${ticketEndTime}`}
          </Typography>
        </div>

        <div className="w-[33%] break-all">
          <Typography variant="label">Yard Time</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {ticketTimeOnYard}
          </Typography>
        </div>

        <div className="w-[33%] break-all">
          <Typography variant="label">Base Time</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {ticketBaseTime}
          </Typography>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {supervisorDetails}

      {statusDetails}

      {ownerDetails}

      {ticketDetails}

      {scheduleDetails}
    </>
  );
};

export { TicketDetails };
