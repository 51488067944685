import {
  Activity,
  ActivityByIdParams,
  ActivityHealth,
  ActivityState,
  ActivityStateBody,
  ActivityWithState,
  AllActivities,
  AllActivityStates,
  DashboardFilters,
  DelayCategory,
  EnumActivityTrack,
  EquipmentType,
  FreeByTimeProviderTeamsPayload,
  GetActivityProvidersParams,
  GraphData,
  GraphPieData,
  NewJobsPathPayload,
  NewJobsPathResponse,
  ParamsById,
  Process,
  ProcessByIdParams,
  ProcessesParams,
  ProviderActivity,
  ProviderTeam,
  SortOrder,
} from 'src/shared/types';
import { api } from 'src/store/api/api';

const activitiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActivitiesByProviderTeamId: builder.query<Process, ProcessByIdParams>({
      query: ({ id, startDate, endDate, ...restFilters }) => ({
        url: `activities/team-providers/${id}`,
        params: {
          startDate,
          endDate,
          ...restFilters,
        },
      }),
      providesTags: ['ProviderTeamActivities'],
      transformResponse: (response: { data: Process }) => response.data,
    }),
    getFreeByDatesProviderTeams: builder.query<ProviderTeam[], FreeByTimeProviderTeamsPayload>({
      query: ({ id, startDate, endDate }) => ({
        url: `activities/free-team-providers/${id}`,
        params: {
          startDate,
          endDate,
        },
      }),
      transformResponse: (response: { data: ProviderTeam[] }) => response.data,
    }),
    getActivityById: builder.query<Activity, ActivityByIdParams>({
      query: ({ id }) => ({
        url: `activities/${id}`,
      }),
      transformResponse: (response: { data: Activity }) => response.data,
    }),
    postActivityState: builder.mutation<ActivityState, ActivityStateBody>({
      query: (newState) => ({
        url: 'activities/create-state',
        method: 'POST',
        body: newState,
      }),
      transformResponse: (response: { data: ActivityState }) => response.data,
      invalidatesTags: ['ProviderTeamActivities'],
    }),
    getActivityProviders: builder.query<ProviderActivity[], GetActivityProvidersParams | undefined>(
      {
        query: (params) => ({
          url: 'activities/providers',
          params,
        }),
        transformResponse: (response: { data: ProviderActivity[] }) => response.data,
      },
    ),
    getActivityProviderTeams: builder.query<ProviderTeam[], void>({
      query: () => ({
        url: 'activities/team-providers-list',
      }),
      transformResponse: (response: { data: ProviderTeam[] }) => response.data,
    }),
    getProviderTeamsById: builder.query<ProviderTeam[], ParamsById>({
      query: ({ id }) => ({
        url: `activities/provider-teams/${id}`,
      }),
      transformResponse: (response: { data: ProviderTeam[] }) => response.data,
    }),
    getActivitiesByProviderTeams: builder.query<Process[], ProcessesParams>({
      query: (params) => ({
        url: 'activities/team-providers',
        params: { ...params },
      }),
      transformResponse: (response: { data: Process[] }) => response.data,
      providesTags: ['Activities'],
    }),
    getDelayCategories: builder.query<DelayCategory[], void>({
      query: () => ({
        url: 'activities/delay-categories',
      }),
      transformResponse: (response: { data: DelayCategory[] }) => response.data,
    }),

    getEquipmentTypes: builder.query<EquipmentType[], void>({
      query: () => ({
        url: 'activities/equipment-types',
      }),
      transformResponse: (response: { data: EquipmentType[] }) => response.data,
    }),
    postNewActivityByProviderTeam: builder.mutation<ActivityWithState | string, ActivityWithState>({
      query: (newState) => ({
        url: 'activities/create-activity',
        method: 'POST',
        body: newState,
      }),
      transformResponse: (response: { data: ActivityWithState | string }) => response.data,
      invalidatesTags: (result, error) => {
        if (typeof result === 'string' || error) {
          return [];
        }
        return ['Activities'];
      },
    }),
    getActivityDashboard: builder.query<
      {
        activitiesByDates: GraphData[];
        activitiesByProviders: GraphPieData[];
        activitiesStatistics: {
          delayedActivities: number;
          totalDelayedDuration: number;
          totalStateUpdates: number;
          completedActivities: number;
        };
        activitiesByDelayCategories: GraphPieData[];
        total: number;
        pageSize: number;
        totalPages: number;
      },
      {
        filters?: DashboardFilters;
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
      }
    >({
      query: ({ filters, pagination }) => ({
        url: 'activities/activity-dashboard',
        params: {
          filters: {
            providerId: filters?.providerId,
            track: filters?.track,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
          },
          pagination,
        },
      }),
      transformResponse: (response: {
        data: {
          activitiesByDates: GraphData[];
          activitiesByProviders: GraphPieData[];
          activitiesStatistics: {
            delayedActivities: number;
            totalDelayedDuration: number;
            totalStateUpdates: number;
            completedActivities: number;
          };
          activitiesByDelayCategories: GraphPieData[];
          total: number;
          pageSize: number;
          totalPages: number;
        };
      }) => response.data,
    }),
    getUploadJobsPath: builder.query<NewJobsPathResponse, NewJobsPathPayload>({
      query: ({ tenant }) => ({
        url: 'activities/tenant-path',
        params: {
          tenant,
        },
      }),
      transformResponse: (response: { data: NewJobsPathResponse }) => response.data,
    }),
    getAllActivityProcessesFilters: builder.query<
      AllActivities,
      {
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        filters: {
          jobId: string[] | undefined;
          provider: string[] | undefined;
          providerTeam: string[] | undefined;
          track: EnumActivityTrack[] | undefined;
          health: Activity['actualState']['health'][] | undefined;
          delayCategory: string[] | undefined;
        };
        sorting?: {
          key?: keyof AllActivities['allActivities'][number] | null;
          order?: SortOrder;
        };
      }
    >({
      query: ({ pagination, filters, sorting }) => ({
        url: 'activities/all-activities-report',
        params: {
          pagination,
          filters,
          sorting,
        },
      }),
      transformResponse: (response: { data: AllActivities }) => response.data,
    }),
    getAllActivityStatesProcessesFilters: builder.query<
      AllActivityStates,
      {
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        filters: {
          jobId: string[] | undefined;
          provider: string[] | undefined;
          providerTeam: string[] | undefined;
          track: EnumActivityTrack[] | undefined;
          health: ActivityHealth[] | undefined;
          delayCategory: string[] | undefined;
        };
        sorting?: {
          key?: keyof AllActivityStates['allActivities'][number] | null;
          order?: SortOrder;
        };
      }
    >({
      query: ({ pagination, filters, sorting }) => ({
        url: 'activities/all-activity-states-report',
        params: {
          pagination,
          filters,
          sorting,
        },
      }),
      transformResponse: (response: { data: AllActivityStates }) => response.data,
    }),
  }),
});

export const {
  useGetActivitiesByProviderTeamIdQuery,
  usePostActivityStateMutation,
  useGetActivityProvidersQuery,
  useGetActivityProviderTeamsQuery,
  useGetActivitiesByProviderTeamsQuery,
  useGetDelayCategoriesQuery,
  useGetActivityByIdQuery,
  useGetEquipmentTypesQuery,
  usePostNewActivityByProviderTeamMutation,
  useGetProviderTeamsByIdQuery,
  useGetActivityDashboardQuery,
  useGetUploadJobsPathQuery,
  useGetFreeByDatesProviderTeamsQuery,
  useGetAllActivityProcessesFiltersQuery,
  useGetAllActivityStatesProcessesFiltersQuery,
} = activitiesApi;
