import { FC, PropsWithChildren, useRef, useEffect } from 'react';
import Modal, { Props } from 'react-modal';

import { clsx } from 'src/shared/utils/clsx';
import { useOutsideClick } from 'src/shared/hooks/useOutsideClick';

import './style.css';

Modal.setAppElement('#root');

type ModalProps = PropsWithChildren<{
  toggleModal?: (isOpen: boolean) => void;
  customClassName?: string;
  childrenClassName?: string;
  removeScroll?: boolean;
}> &
  Props;

const customStyles = {
  overlay: {
    background: '#00000099',
    zIndex: 999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    overflow: 'scroll',
  },
};

const ModalWindow: FC<ModalProps> = ({
  children,
  isOpen = true,
  closeTimeoutMS = 200,
  ariaHideApp = false,
  customClassName = '',
  childrenClassName = '',
  removeScroll,
  toggleModal,
  ...props
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isOpen) {
      const scrollWidth = Math.abs(document.body.clientWidth - window.innerWidth);

      document.body.style.paddingRight = `${scrollWidth}px`;
      document.body.style.overflow = 'hidden';
    } else {
      timeoutRef.current = setTimeout(() => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
      }, closeTimeoutMS);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [isOpen]);

  const modalRef = useRef(null);

  useOutsideClick(
    modalRef,
    (event: MouseEvent | TouchEvent) => {
      const target = event.target as HTMLElement;
      if (target.className.includes('MuiButtonBase-root')) {
        return;
      }
      if (toggleModal) toggleModal(isOpen);
    },
    isOpen,
  );

  const onESCButtonPress = () => {
    toggleModal?.(isOpen);
  };

  const stylesToAdd = removeScroll
    ? {
        ...customStyles,
        content: {
          ...customStyles.content,
          overflow: undefined,
        },
      }
    : customStyles;

  return (
    <Modal
      isOpen={isOpen}
      style={stylesToAdd}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={ariaHideApp}
      onRequestClose={onESCButtonPress}
      className={clsx(
        'bg-bgColor-card absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] min-w-[350px]',
        'shadow-[0px_16px_46px_-11px_#00000029] rounded-[12px] modal-content overflow-hidden',
        customClassName,
      )}
      {...props}
    >
      <div
        ref={modalRef}
        className={clsx('p-4', childrenClassName)}
      >
        {children}
      </div>
    </Modal>
  );
};

export { ModalWindow as Modal };
export type { ModalProps };
