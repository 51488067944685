import { forwardRef } from 'react';

import { ButtonProps, Button } from '@mui/material';

type NavigateButtonProps = ButtonProps & {
  to: string;
};

const NavigateButton = forwardRef<HTMLButtonElement, NavigateButtonProps>(
  ({ children, sx, ...props }, ref) => {
    return (
      <Button
        sx={{
          borderRadius: '50%',
          minWidth: 0,
          minHeight: 0,
          padding: 0,
          margin: 0,
          width: '1.8rem',
          height: '1.8rem',
          ...sx,
        }}
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    );
  },
);

NavigateButton.displayName = 'NavigateButton';

export { NavigateButton };
