import { FC, ReactNode } from 'react';

import { clsx, not } from 'src/shared/utils';

import { Typography } from '../typography';
import { Spinner } from '../spinner';

interface ChartContainerProps {
  title: ReactNode;
  children: ReactNode;
  isLoading?: boolean;
  actions?: ReactNode;
  containerClassName?: string;
  headerClassName?: string;
  chartContainerClassName?: string;
}

const ChartContainer: FC<ChartContainerProps> = ({
  title,
  children,
  actions,
  containerClassName,
  headerClassName,
  chartContainerClassName,
  isLoading = false,
}) => {
  const hasHelperText = not(children) && not(isLoading);

  return (
    <div className={clsx('w-full h-full p-6 border rounded-xl bg-[#FFFFFF]', containerClassName)}>
      <div className={clsx('flex min-h-[40px] justify-between items-center mb-7', headerClassName)}>
        <Typography variant="h1">{title}</Typography>

        {actions}
      </div>

      <div className={clsx('flex justify-center items-center', chartContainerClassName)}>
        {hasHelperText && (
          <div className="flex flex-col gap-2 text-center w-[400px]">
            <Typography variant="h3">There is no updates for this week</Typography>

            <Typography variant="p1">
              As soon as the new data is added to the scheduler, we will display it here
            </Typography>
          </div>
        )}

        {not(isLoading) && children}

        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

export { ChartContainer };
