import { FC } from 'react';
import { Button } from 'src/shared/ui/button';
import { Datepicker } from 'src/shared/ui/datepicker';
import { DateRangeType, DatesStateRange, DateValueType } from 'src/shared/ui/datepicker/types';

type DateRangeProps = {
  datesState: DatesStateRange;
  handleChange: (key: keyof DatesStateRange, value: DateValueType) => void;
  handleClear: () => void;
};

const DateRange: FC<DateRangeProps> = ({ datesState, handleChange, handleClear }) => {
  return (
    <div className="flex items-center gap-4">
      <Button
        onClick={handleClear}
        variant="outlined"
      >
        Clear
      </Button>
      <div className="flex items-center gap-2">
        <span className=" text-base font-bold">Start</span>
        <Datepicker
          value={datesState.start}
          useRange={false}
          onChange={(date) => {
            handleChange('start', date);
          }}
          asSingle
        />
      </div>
      <div className="flex items-center gap-2">
        <span className=" text-base font-bold">End</span>
        <Datepicker
          value={datesState.end}
          useRange={false}
          onChange={(date) => {
            handleChange('end', date);
          }}
          asSingle
        />
      </div>
    </div>
  );
};

export { DateRange };
