import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

import { Icon } from 'src/shared/ui/icon';
import { Typography } from 'src/shared/ui/typography';
import { clsx } from 'src/shared/utils';
import { ReactComponent as ChevronRightIcon } from 'src/assets/icons/filled/chevrons/chevron-right.svg';
import { NavigationLinkProps } from 'src/shared/ui/layout/navigationLink';
import { SvgRenderer } from 'src/shared/ui/svgRenderer';

type TertiaryCardProps = NavigationLinkProps & {
  isFirstCard: boolean;
  isLastCard: boolean;
};

const TertiaryCard: React.FC<TertiaryCardProps> = ({
  label,
  description,
  icon,
  isFirstCard,
  isLastCard,
  link,
  isAppLink,
}) => {
  const navigate = useNavigate();

  const content = (
    <>
      <div className="flex justify-between items-center gap-3">
        <SvgRenderer svgUrl={icon} />

        <Typography
          variant="p1"
          fontWeight="medium"
          className="text-textColor-primary"
        >
          {label}
        </Typography>
      </div>

      <Icon icon={<ChevronRightIcon />} />

      <Tooltip
        id={`description-${label}`}
        place="bottom"
        content={description}
        className="max-w-[250px] z-[1] bg-[rgba(0,0,0,0.7)]"
      />
    </>
  );

  const className = clsx(
    'flex justify-between items-center w-full p-4 gap-3 border border-solid max-h-[56px] duration-200 ease-in-out border-b-0 hover:bg-[#F3F5F9]',
    isFirstCard && 'rounded-tl-[10px] rounded-tr-[10px]',
    isLastCard && 'border-b-[1px] rounded-bl-[10px] rounded-br-[10px]',
  );

  return isAppLink ? (
    <button
      type="button"
      className={className}
      onClick={() => navigate(link)}
      data-tooltip-id={`description-${label}`}
    >
      {content}
    </button>
  ) : (
    <a
      href={link}
      target="_blank"
      type="button"
      className={className}
      rel="noreferrer"
      data-tooltip-id={`description-${label}`}
    >
      {content}
    </a>
  );
};

export { TertiaryCard };
