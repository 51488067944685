import { FC } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { CustomLegend } from './ui/customLegend';
import { CustomTooltip } from './ui/customTooltip';

type PieChartItem = {
  name: string;
  value: number;
  color: string;
};

type CustomPieChartProps = {
  data: PieChartItem[];
  innerPrimaryText?: string | number;
  innerSecondaryText?: string | number;
};

const CustomPieChart: FC<CustomPieChartProps> = ({
  data,
  innerPrimaryText,
  innerSecondaryText,
}) => {
  const withInnerPrimaryText = !!innerPrimaryText;
  const withInnerSecondaryText = !!innerSecondaryText;

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <PieChart
        width={400}
        height={400}
      >
        <Tooltip content={<CustomTooltip />} />

        <Legend content={<CustomLegend />} />

        {withInnerPrimaryText && (
          <text
            x="50%"
            y="40%"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontSize: 30,
              fontWeight: 'bold',
            }}
          >
            {innerPrimaryText}
          </text>
        )}

        {withInnerSecondaryText && (
          <text
            x="50%"
            y="45%"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: 14 }}
          >
            {innerSecondaryText}
          </text>
        )}

        <Pie
          data={data}
          cx="50%"
          cy="50%"
          paddingAngle={1}
          innerRadius={120}
          outerRadius={180}
          cornerRadius={3}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry) => (
            <Cell
              key={entry.name}
              fill={entry.color}
              style={{ outline: 'none' }}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export { CustomPieChart };
