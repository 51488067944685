import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import { useGetSurveyQuery, useCreateSurveyAnswerMutation } from 'src/store/api';
import './style.css';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { Spinner } from 'src/shared/ui/spinner';
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage';
import { Element, GeoLocation, PageJson } from 'src/shared/types/surveys';

import { GEOLOCATION_QUESTION_TYPE } from '../SurveyFormCreator/customFormQuestions/Geolocation';

import { navigationCSS, initSurveySettings, getUserCoordinates } from './utils';
import { SurveyNavigation } from './Components/SurveyNavigation';

initSurveySettings();

const SurveyForm = () => {
  const [searchParams] = useSearchParams();
  const { formId } = useParams();

  const navigate = useNavigate();

  const { data: form, isFetching: isLoadingForm } = useGetSurveyQuery(formId || '', {
    refetchOnMountOrArgChange: true,
  });

  const [geoLocation, setGeoLocation] = useState<GeoLocation | null>(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);

  const questionName = useMemo(() => {
    let geolocationQuestion = null;

    if (!form || !form.content || !formId) {
      return geolocationQuestion;
    }

    const geolocationPage = form.content.pages?.find((page: PageJson) =>
      page?.elements?.some((element) => element?.type === GEOLOCATION_QUESTION_TYPE),
    );

    if (!geolocationPage || !geolocationPage.elements) {
      return geolocationQuestion;
    }

    const question: Element = geolocationPage.elements?.find(
      (el: Element) => el?.type === GEOLOCATION_QUESTION_TYPE,
    );

    const isNotVisibleQuestion = question && 'visible' in question && question.visible === false;

    if (isNotVisibleQuestion) {
      geolocationQuestion = question.name;
    }

    return geolocationQuestion;
  }, [form, formId]);

  const [createSurveyAnswer, { isLoading: isSaving }] = useCreateSurveyAnswerMutation();
  const [page, setPage] = useState(0);
  const [survey, setSurvey] = useState<Model>(new Model({}));
  const [localStorageFormData, setLocalStorageFormData] = useLocalStorage<string | null>(
    formId || '',
    '',
  );
  const localStorageSurveyData = localStorageFormData ? JSON.parse(localStorageFormData) : {};

  useEffect(() => {
    if (form) {
      setSurvey(new Model(form.content));
    }
  }, [form]);

  useEffect(() => {
    searchParams.forEach((value, key) => {
      survey.setValue(key, value);
    });
  }, [survey, searchParams]);

  useEffect(() => {
    if (questionName && formId) {
      const fetchCoordinates = async () => {
        setIsLoadingLocation(true);
        try {
          const coords = await getUserCoordinates();
          setGeoLocation(coords as GeoLocation);
        } catch (err) {
          setIsLoadingLocation(false);
          showToastErrorMessage('Geolocation is not supported by this browser.');
        } finally {
          setIsLoadingLocation(false);
        }
      };

      fetchCoordinates();
    }
  }, [questionName, formId]);

  useEffect(() => {
    survey.onComplete.add(async (sender) => {
      if (not(survey)) return;

      if (questionName && geoLocation) {
        sender.setValue(questionName, geoLocation);
      }

      try {
        await createSurveyAnswer({
          surveyId: form!.id,
          answer: sender.data,
        }).unwrap();

        setLocalStorageFormData(null);
      } catch (error) {
        showToastErrorMessage('There was an error trying to save the results of the filled form');
      } finally {
        if (not(form?.content?.completedHtml)) {
          navigate('/forms');
        }
      }
    });

    return () => {
      survey.onComplete.clear();
    };
  }, [
    form,
    survey,
    createSurveyAnswer,
    navigate,
    formId,
    setLocalStorageFormData,
    questionName,
    geoLocation,
  ]);

  useEffect(() => {
    setPage(localStorageSurveyData.pageNo || 0);
  }, [localStorageFormData]);

  if (isLoadingForm) {
    return (
      <Spinner
        withBackdrop
        fallbackText="Loading form..."
      />
    );
  }

  if (not(form)) {
    navigate('/forms');
    showToastErrorMessage('There was an error trying to load the form');

    return null;
  }

  survey.onCurrentPageChanged.add((_, options) => {
    setPage(options.newCurrentPage.visibleIndex);
  });

  const handleSurveyLocalStorageSave = (survey: Model) => {
    const { data } = survey;
    if (Object.keys(data).length === 0) {
      return;
    }
    data.pageNo = survey.currentPageNo;

    setLocalStorageFormData(JSON.stringify(data));
  };

  survey.onValueChanged.add(handleSurveyLocalStorageSave);
  survey.onCurrentPageChanged.add(handleSurveyLocalStorageSave);
  survey.css = navigationCSS;
  if (Object.keys(localStorageSurveyData).length) {
    survey.data = localStorageSurveyData;
  } else {
    survey.data = undefined;
  }

  return (
    <>
      {isSaving && (
        <Spinner
          withBackdrop
          fallbackText="Saving results of the filled form..."
        />
      )}

      {isLoadingLocation && (
        <Spinner
          withBackdrop
          fallbackText="Loading current location..."
        />
      )}

      <div className="flex flex-col gap-y-4 mt-4 w-full">
        <SurveyNavigation
          form={form}
          survey={survey}
          page={page}
          setLocalStorageFormData={setLocalStorageFormData}
          withCompleteButton
        />

        <Survey
          currentPageNo={page}
          model={survey}
        />
      </div>
    </>
  );
};

export { SurveyForm };
