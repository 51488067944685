import { FC } from 'react';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { TableManagerFieldValue, TagType } from 'src/shared/types';
import { useGetDocumentsQuery } from 'src/store/api/documents';

type DocumentTableProps = {
  modelName: TagType;
  state: Record<string, TableManagerFieldValue>;
  idField: string;
};

const WrapperDocumentTable = styled(Stack)(({ theme }) => ({
  padding: '.5rem',
  border: '1px solid #C4C4C4',
  borderRadius: '4px',
}));

const filesTableHeaders = [{ name: 'File Type' }, { name: 'Filename' }];

const DocumentTable: FC<DocumentTableProps> = ({ modelName, state, idField }) => {
  const {
    data: documents = [],
    isLoading: isLoadingDocuments,
    isFetching: isFetchingDocuments,
  } = useGetDocumentsQuery(
    {
      modelName,
      filters: {
        entityId: state?.[idField] as string,
      },
    },
    {
      skip: !state?.[idField] || !modelName,
    },
  );

  return (
    <WrapperDocumentTable>
      <Typography variant="h6">Files</Typography>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ tableLayout: 'fixed' }}
        >
          <TableHead>
            <TableRow>
              {filesTableHeaders.map(({ name }) => (
                <TableCell
                  sx={{ fontSize: '1.05rem' }}
                  key={name}
                  colSpan={1}
                >
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isFetchingDocuments &&
              (documents.length > 0 ? (
                documents.map(({ id, fileName, fileUrl, documentableType }) => (
                  <TableRow key={id}>
                    <TableCell
                      component="th"
                      scope="row"
                      colSpan={1}
                    >
                      {documentableType}
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                    >
                      <Link
                        href={fileUrl}
                        download={fileName}
                        variant="body2"
                        color="primary"
                        underline="hover"
                      >
                        {fileName}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      No documents available yet.
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}

            {isFetchingDocuments && (
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                >
                  <CircularProgress size={14} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </WrapperDocumentTable>
  );
};

export { DocumentTable };
