import { ComponentProps, FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { Datepicker } from 'src/shared/ui/datepicker';
import { IconButton } from 'src/shared/ui/iconButton';
import { Typography } from 'src/shared/ui/typography';
import { clsx } from 'src/shared/utils';
import { RootState, useAppDispatch } from 'src/store';
import { DateValueType } from 'src/shared/ui/datepicker/types';
import { schedulerBoardSettingsActions } from 'src/store/slices/schedulerBoardSettings';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/outlined/misc/calendar.svg';
import { ReactComponent as ArrowIOSLeftIcon } from 'src/assets/icons/filled/chevrons/arrow-ios-left.svg';
import { modalConfigActions } from 'src/store/slices';
import { Button } from 'src/shared/ui/button';

import { SidebarPeopleCard } from './sidebarPeopleCard';
import { SidebarEquipmentCard } from './sidebarEquipmentCard';

type ExpandedSchedulerSidebarProps = ComponentProps<'div'> & {
  setIsCreateJobModalOpen: (isOpen: boolean) => void;
  toggleSidebar?: () => void;
};

const ExpandedSchedulerSidebar: FC<ExpandedSchedulerSidebarProps> = ({
  setIsCreateJobModalOpen,
  toggleSidebar,
  className,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const filterAssignedForDate = useSelector(
    (state: RootState) => state.schedulerBoardSettings.filterAssignedForDate,
  );

  const [datepickerValue, setValue] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });

  const datepickerIcon = useCallback(
    (open: boolean) => (
      <IconButton
        iconClassName="fill-textColor-tertiary"
        size="none"
      >
        {open ? <CalendarIcon /> : <CloseCircleIcon />}
      </IconButton>
    ),
    [],
  );

  const handleDatepickerChange = (newValue: DateValueType) => {
    dispatch(
      schedulerBoardSettingsActions.changeFilterAssignedForDate(
        (newValue?.startDate as string) || '',
      ),
    );
    setValue(newValue);
  };

  return (
    <>
      <div
        className={clsx('flex justify-between', className)}
        {...props}
      >
        <Typography variant="h2">Scheduler</Typography>

        <div className="flex justify-center items-center gap-x-4">
          <Button
            size="lg"
            color="primary"
            onClick={() => {
              setIsCreateJobModalOpen(true);
              dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
            }}
          >
            Create Job
          </Button>

          <IconButton
            color="secondary"
            size="lg"
            onClick={toggleSidebar}
          >
            <ArrowIOSLeftIcon />
          </IconButton>
        </div>
      </div>

      <div className="flex flex-wrap gap-4">
        <Datepicker
          useRange={false}
          placeholder="Available for Date"
          asSingle
          value={
            {
              startDate: filterAssignedForDate || null,
              endDate: filterAssignedForDate || null,
            } || datepickerValue
          }
          onChange={handleDatepickerChange}
          displayFormat="dddd, DD MMMM"
          toggleIcon={datepickerIcon}
        />
      </div>

      <div className="flex flex-col items-center gap-4">
        <SidebarPeopleCard />

        <SidebarEquipmentCard />
      </div>
    </>
  );
};

export type { ExpandedSchedulerSidebarProps };
export { ExpandedSchedulerSidebar };
