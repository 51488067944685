import { FC, ReactElement } from 'react';
import { LegendProps, LegendType } from 'recharts';

import { Typography } from 'src/shared/ui/typography';
import { not } from 'src/shared/utils';

type CustomLegendPayload = {
  value: any;
  id?: string;
  type?: LegendType;
  payload: {
    percent?: number;
    strokeDasharray: string | number;
    label?: () => ReactElement | string;
  };
  color?: string;
  dataKey?: string;
  inactive?: boolean;
};

type CustomLegendProps = LegendProps & {
  payload?: CustomLegendPayload[];
};

const CustomLegend: FC<CustomLegendProps> = ({ payload }) => {
  if (not(payload)) return null;

  const filteredData = payload
    .filter((item) => item.type !== 'none')
    .sort((a, b) => (b?.payload?.value || 0) - (a?.payload?.value || 0));

  const ticketStatusesAmount = filteredData.reduce((acc, el) => acc + (el?.payload?.value || 0), 0);

  return (
    <div className="flex w-full flex-wrap gap-x-6 mt-6">
      {filteredData?.map((group) => {
        const statuses = group?.payload?.value || 0;
        const percentage = ((statuses / ticketStatusesAmount) * 100).toFixed(0);

        return (
          <span
            key={group.value}
            className="flex items-center justify-start gap-x-1"
          >
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: group.color }}
            />

            <Typography variant="p2">{`${group.value}: `}</Typography>

            <Typography
              variant="p2"
              fontWeight="bold"
            >
              {`${statuses} (${percentage}%)`}
            </Typography>
          </span>
        );
      })}
    </div>
  );
};

export { CustomLegend };
