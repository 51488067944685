import { FC, useEffect, useMemo, useState } from 'react';

import { ActivityJob } from 'src/shared/types';

import { ActivityJobCard } from '../activityJobCard';

interface ActivityRowProps {
  cards: ActivityJob[];
  startDate: Date;
  timeZone: string;
  setContainerWidth: (width?: number) => void;
  processIndex: number;
  isOverlapped?: boolean;
  indexOverLapped?: number;
}

const ActivityRow: FC<ActivityRowProps> = ({
  cards,
  startDate,
  timeZone,
  setContainerWidth,
  processIndex,
  isOverlapped,
  indexOverLapped,
}) => {
  const jobCardsWithSpread = useMemo(() => {
    return Array.from({ length: cards.length }, () => false);
  }, [cards]);

  const [cardsListWithSpread, setCardsListWithSpread] = useState(jobCardsWithSpread);

  useEffect(() => {
    setCardsListWithSpread(jobCardsWithSpread);
  }, [jobCardsWithSpread]);

  return (
    <div className="flex items-center relative">
      {cards.map((card, i) => {
        const previousDate = i > 0 ? cards[i - 1].actualEnd || cards[i - 1].endWork : startDate;

        const updateCardSpreadList = () => {
          setCardsListWithSpread((prev) => {
            return prev.map((item, index) => {
              if (index === i) {
                return !item;
              }

              return item;
            });
          });
        };

        return (
          <ActivityJobCard
            key={card.id}
            card={card}
            index={i}
            previousDate={previousDate}
            updateCardSpreadList={updateCardSpreadList}
            hasPrevCardSpread={i > 0 && cardsListWithSpread[i - 1]}
            hasCardSpread={cardsListWithSpread[i]}
            processIndex={processIndex}
            timeZone={timeZone}
            datesStart={startDate}
            setContainerWidth={setContainerWidth}
            isOverlapped={isOverlapped}
            indexOverLapped={indexOverLapped}
          />
        );
      })}
    </div>
  );
};

export { ActivityRow };
