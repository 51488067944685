import dayjs from 'dayjs';
import { FC } from 'react';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { ActivityTrack, DelayCategory, ProviderActivity } from 'src/shared/types';

type CustomBarTooltipProps = TooltipProps<ValueType, NameType> & {
  providers?: ProviderActivity[];
  tracks?: ActivityTrack[];
  delayCategory?: DelayCategory[];
  graphBy?: 'tracks' | 'providers' | 'delayCategories';
  withoutDate?: boolean;
};

export const CustomBarTooltip: FC<CustomBarTooltipProps> = ({
  active,
  payload,
  label,
  providers = [],
  tracks = [],
  delayCategory = [],
  graphBy = 'providers',
  withoutDate = false,
}) => {
  const getPreparedName = (name: NameType) => {
    switch (graphBy) {
      case 'providers':
        return providers.find(({ ProviderID }) => ProviderID === name)?.ProviderName;
      case 'tracks':
        return tracks.find((track) => `${name}`.includes(track));
      case 'delayCategories':
        return delayCategory.find(({ DelayCategoryID }) => DelayCategoryID === name)?.DelayCategory;
      default:
        return name;
    }
  };
  if (active) {
    return (
      <div className="rounded-lg border border-inked-silk bg-bgColor-main p-2">
        {!withoutDate && <div className="font-bold">{dayjs(label).format('MMM, D')}</div>}

        <ul className="flex flex-col gap-2">
          {payload?.map(({ name, value }) => {
            const preparedName = name ? getPreparedName(name) : '';

            return value ? (
              <li
                key={name}
                className="flex flex-col gap-2"
              >
                <div className="flex items-center gap-1">
                  <div>{`${preparedName}: `}</div>

                  <div className="font-semibold">{value}</div>
                </div>
              </li>
            ) : (
              <li key={name} />
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};
