import { ProviderDivision } from 'src/shared/types';
import { api } from 'src/store/api/api';

const providerDivisionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProviderDivisions: builder.query<ProviderDivision[], ''>({
      query: () => ({
        url: '/provider-divisions',
      }),
      providesTags: ['ProviderDivisions'],
      transformResponse: (response: { data: ProviderDivision[] }) => response.data,
    }),
  }),
});

export const { useGetProviderDivisionsQuery } = providerDivisionApi;
