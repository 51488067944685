import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IconButton } from 'src/shared/ui/iconButton';
import { JobEntity, PageSize, SortOrder } from 'src/shared/types';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { useGetJobsQuery } from 'src/store/api';
import { Button } from 'src/shared/ui/button';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/filled/files/download.svg';
import { exportToExcel } from 'src/shared/utils/excel';
import { TextField } from 'src/shared/ui/textField';
import { Icon } from 'src/shared/ui/icon';
import { ReactComponent as SearchIcon } from 'src/assets/icons/filled/internet&code/search.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { dayjs, not } from 'src/shared/utils';
import { selectConfig } from 'src/store/slices';
import { getFormattedDate } from 'src/shared/ui/details/helpers';

const JobsTable = () => {
  const [sortSettings, setSortSettings] = useState<{
    key: keyof JobEntity | null;
    order: SortOrder;
  }>({
    key: null,
    order: SortOrder.ASC,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>(15);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);

  const config = useSelector(selectConfig);

  const {
    data = {
      jobs: [],
      pageSize: 0,
      total: 0,
      totalPages: 0,
      search: '',
    },
    isError,
    isFetching,
    isLoading,
  } = useGetJobsQuery({
    filters: {
      ...config.boardFilters,
    },
    pagination: {
      page: String(currentPage),
      pageSize: String(pageSize),
      search: debouncedQuery,
    },
    sorting: sortSettings,
  });

  const { data: allJobsData = { jobs: [] }, isFetching: isAllJobsFetching } = useGetJobsQuery({});

  const { jobs = [], total } = data;

  // *  Data for the Excel Export
  const { jobs: allJobs } = allJobsData;

  const headers: TableHeaderType<JobEntity>[] = useMemo(
    () => [
      {
        title: 'Work request Id',
        field: 'WorkRequestID',
        className: 'min-w-[260px]',
        canSort: true,
        render: (job) => job.id,
      },
      {
        title: 'Owner',
        field: 'OwnerID',
        className: 'min-w-[170px]',
        canSort: true,
        render: (job) => job.owner.OwnerName,
      },
      {
        title: 'Owner Location',
        field: 'OwnerLocationID',
        className: 'min-w-[170px]',
        canSort: true,
        render: (job) => job.ownerLocation.OwnerLocation,
      },
      {
        title: 'Category',
        field: 'Category',
        className: 'min-w-[80px]',
        canSort: true,
        render: (job) => job.jobCategory.Category,
      },
      {
        title: 'PO',
        field: 'PO',
        className: 'min-w-[100px]',
        canSort: true,
        render: (job) => job.po,
      },
      {
        title: 'WO',
        field: 'WO',
        className: 'min-w-[100px]',
        canSort: true,
        render: (job) => job.wo,
      },
      {
        title: 'Service line',
        field: 'ServiceLineID',
        className: 'min-w-[150px]',
        canSort: true,
        render: (job) => job.serviceLine.ServiceLineDescription,
      },
      {
        title: 'Service type',
        field: 'ServiceTypeID',
        className: 'min-w-[150px]',
        canSort: true,
        render: (job) => job.serviceType?.ServiceTypeDescription || '',
      },
      {
        title: 'Owner Representative',
        field: 'OwnerPlanner',
        className: 'min-w-[140px]',
        canSort: true,
        render: (job) =>
          job.ownerRepresentative
            ? `${job.ownerRepresentative.FirstName} ${job.ownerRepresentative.LastName}`
            : '',
      },
      {
        title: 'Owner Contact',
        field: 'OwnerContact',
        className: 'min-w-[126px]',
        canSort: true,
        render: (job) =>
          job.ownerContact ? `${job.ownerContact.FirstName} ${job.ownerContact.LastName}` : '',
      },
      {
        title: 'Description',
        field: 'WorkRequestNotes',
        className: 'min-w-[100px]',
        canSort: true,
        render: (job) => job.workRequestNotes,
      },
      {
        title: 'Start Date',
        field: 'RequestedStartDate',
        className: 'min-w-[140px]',
        canSort: true,
        render: (job) => getFormattedDate(String(dayjs(job.requestedStartDate).add(8, 'hour'))),
      },
      {
        title: 'End Date',
        field: 'RequestedCompleteDate',
        className: 'min-w-[140px]',
        canSort: true,
        render: (job) => getFormattedDate(String(dayjs(job.requestedCompleteDate).add(8, 'hour'))),
      },
      {
        title: 'Division',
        field: 'ProviderDivisionId',
        className: 'min-w-[180px]',
        canSort: true,
        render: (job) => job.department.ProviderDivisionName,
      },
      {
        title: 'Job Number',
        field: 'ProviderJobId',
        className: 'min-w-[126px]',
        canSort: true,
        render: (job) => job.sro,
      },
    ],
    [],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedQuery, pageSize]);

  const handleExportToExcel = async () => {
    const excelData = allJobs.map((job) => ({
      'Work request Id': job.id,
      'Owner': job.owner.OwnerName,
      'Owner Location': job.ownerLocation.OwnerLocation,
      'Category': job.jobCategory.Category,
      'PO': job.po,
      'WO': job.wo,
      'Service line': job.serviceLine.ServiceLineDescription,
      'Service type': job.serviceType?.ServiceTypeDescription,
      'Owner Representative': job.ownerRepresentative
        ? `${job.ownerRepresentative.FirstName} ${job.ownerRepresentative.LastName}`
        : '',
      'Owner Contact': job.ownerContact
        ? `${job.ownerContact.FirstName} ${job.ownerContact.LastName}`
        : '',
      'Description': job.workRequestNotes,
      'Start Date': getFormattedDate(String(dayjs(job.requestedStartDate).add(8, 'hour'))),
      'End Date': getFormattedDate(String(dayjs(job.requestedCompleteDate).add(8, 'hour'))),
      'Division': job.department.ProviderDivisionName,
      'Job Number': job.sro,
    }));

    exportToExcel(excelData, 'Jobs');
  };

  return (
    <div className="flex flex-col gap-y-6 pb-6">
      {not(isError) && (
        <div className="flex justify-end items-center gap-x-6 w-full">
          <Button
            size="lg"
            variant="filled"
            endIcon={<DownloadIcon fill="#231F20" />}
            className="border-[#636874] flex !gap-3 !p-0"
            onClick={handleExportToExcel}
            disabled={isAllJobsFetching}
          >
            Excel
          </Button>

          <TextField
            startIcon={
              <Icon
                icon={<SearchIcon />}
                className="absolute left-[16px] top-[12px] fill-textColor-tertiary"
              />
            }
            endIcon={
              query ? (
                <IconButton
                  iconClassName="fill-textColor-tertiary"
                  size="none"
                  className="absolute right-[12px] top-[12px] bg-transparent"
                  onClick={() => setQuery('')}
                >
                  <CloseCircleIcon />
                </IconButton>
              ) : undefined
            }
            placeholder="Search by Job properties"
            className="flex-1 max-w-[450px]"
            inputClassName="bg-bgColor-menu"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      )}

      <Table
        headers={headers}
        data={jobs}
        onSort={setSortSettings}
        sortSettings={sortSettings}
        isFetching={isFetching}
        isLoading={isLoading}
        withIndicator
        variant="secondary"
        highlight={debouncedQuery}
        containerClassName="h-[600px]"
        sticky
        scroll
        isError={isError}
        pagination={{
          currentPage,
          onPageChange: (page: number) => setCurrentPage(page),
          onPageSizeChange: (pageSize: PageSize) => setPageSize(pageSize),
          pageSize,
          total,
        }}
      />
    </div>
  );
};

export { JobsTable };
