import { FC } from 'react';
import { useDrop } from 'react-dnd';

import { DropField } from 'src/shared/ui/dropField';
import { clsx } from 'src/shared/utils';
import { PersonEntity, TicketEntity } from 'src/shared/types';
import { ReactComponent as PersonAddIcon } from 'src/assets/icons/outlined/account/person-add.svg';
import { modalConfigActions } from 'src/store/slices';
import { useAppDispatch } from 'src/store';

type UniversalPersonDropfieldProps = {
  ticket: TicketEntity | undefined;
  isDropDisabled?: boolean;
};

const UniversalPersonDropfield: FC<UniversalPersonDropfieldProps> = ({
  ticket,
  isDropDisabled = false,
}) => {
  const dispatch = useAppDispatch();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['person', 'crewLeader', 'supervisor'],
    drop: (item: PersonEntity) => {
      if (ticket && item) {
        dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(ticket.id));
        dispatch(modalConfigActions.setLastDroppedUniversalItemId(item.id));
        dispatch(modalConfigActions.setOpenCreateUniversalModalType('Labor'));
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const className = clsx(
    isOver &&
      'rounded-lg bg-bgColor-dropZoneHovered border-brandingColor-primary-gradient transition-[opacity] duration-300 ease-in-out',
  );

  return (
    <div ref={isDropDisabled ? undefined : drop}>
      <DropField
        className={className}
        icon={<PersonAddIcon />}
        title="Drop Labor here for quick assign"
        isDropDisabled={isDropDisabled}
      />
    </div>
  );
};

export { UniversalPersonDropfield };
