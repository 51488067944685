import { FC, useEffect } from 'react';
import { useMap, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';

import { RawResult, SearchResult, PointTuple } from 'src/shared/types';
import redMarker from 'src/assets/images/markers/red-marker.png';

import { PopupBody } from './PopupBody';

type SelectedAddressMarkerProps = {
  selectedAddress: SearchResult<RawResult> | null;
};

const SelectedAddressMarker: FC<SelectedAddressMarkerProps> = ({ selectedAddress }) => {
  const markerIcon = new Icon({
    iconUrl: redMarker,
    iconSize: [25, 41],
  });

  const map = useMap();

  useEffect(() => {
    if (selectedAddress) {
      const position: PointTuple = [selectedAddress.y, selectedAddress.x];

      map.flyTo(position, 7, {
        duration: 1.5,
        animate: true,
      });
    }
  }, [selectedAddress, map]);

  if (!selectedAddress) {
    return null;
  }

  return (
    <Marker
      position={[selectedAddress?.y, selectedAddress?.x]}
      icon={markerIcon}
    >
      <Popup>
        <PopupBody selectedAddress={selectedAddress} />
      </Popup>
    </Marker>
  );
};

export { SelectedAddressMarker };
