import { ServiceLine } from 'src/shared/types';
import { api } from 'src/store/api/api';

const serviceLineApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServiceLines: builder.query<ServiceLine[], ''>({
      query: () => ({
        url: '/service-lines',
      }),
      providesTags: ['ServiceLines'],
      transformResponse: (response: { data: ServiceLine[] }) => response.data,
    }),
  }),
});

export const { useGetServiceLinesQuery } = serviceLineApi;
