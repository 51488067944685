import { io } from 'socket.io-client';

import { JobEntity, SortOrder } from 'src/shared/types';
import { dayjs, getPaginationDays } from 'src/shared/utils';
import { store } from 'src/store';
import { API_URL } from 'src/config';
import { BoardFilters, api } from 'src/store/api/api';

import { configActions } from '../slices';

type CopyJobPayload = {
  description: string;
  newJobId: string;
  jobSourceId: string;
  startDate: string;
  endDate: string;
};

const jobsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query<
      {
        jobs: JobEntity[];
        total: number;
        pageSize: number;
        totalPages: number;
      },
      {
        filters?: BoardFilters;
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        sorting?: {
          key?: keyof JobEntity | null;
          order?: SortOrder;
        };
      }
    >({
      query: ({ filters, pagination, sorting }) => ({
        url: '/jobs',
        params: {
          filters: {
            owner: filters?.owner,
            ownerSite: filters?.ownerSite,
            jobCategory: filters?.jobCategory,
            serviceLine: filters?.serviceLine,
            providerArea: filters?.providerArea,
            providerRegion: filters?.providerRegion,
            providerBranch: filters?.providerBranch,
            division: filters?.division,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
          },
          pagination,
          sorting,
        },
      }),
      providesTags: ['Jobs'],
      transformResponse: (response: {
        data: {
          jobs: JobEntity[];
          total: number;
          pageSize: number;
          totalPages: number;
        };
      }) => {
        return {
          ...response.data,
          jobs: response.data.jobs.map((job, i) => ({
            ...job,
            row: i + 1,
          })),
        };
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        const { filters } = store.getState();
        const socket = io(`${API_URL}/jobs`, {
          query: {
            owner: filters.board.selectedFilters,
          },
          reconnectionDelay: 1000,
          reconnection: true,
          transports: ['websocket'],
          agent: false,
          upgrade: false,
          rejectUnauthorized: false,
        });

        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: selectedFilters } = store.getState().config;

        try {
          await cacheDataLoaded;
          socket.on('jobsUpdate', (data) => {
            updateCachedData(() => {
              dispatch(
                jobsApi.util.updateQueryData(
                  'getJobs',
                  {
                    filters: {
                      ...selectedFilters,
                      startDate,
                      endDate,
                    },
                  },
                  (draft) => {
                    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
                    draft = data.data;
                  },
                ),
              );

              dispatch(jobsApi.util.invalidateTags(['Jobs', 'Tickets']));
            });
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }

        await cacheEntryRemoved;
        socket.close();
      },
    }),
    getJobById: builder.query<JobEntity, string>({
      query: (id) => ({
        url: `/jobs/${id}`,
      }),
      providesTags: ['Jobs'],
    }),
    addJob: builder.mutation<JobEntity, Partial<JobEntity> & { timezone: string | undefined }>({
      query: (job) => ({
        url: '/jobs',
        method: 'POST',
        body: {
          ...job,
          owner: job?.owner?.OwnerID,
          ownerContract: job?.ownerContract?.RateSheetID,
          ownerContact: job?.ownerContact?.OwnerPersonnelEmail,
          ownerRepresentative: job?.ownerRepresentative?.OwnerPersonnelEmail,
          ownerLocation: job?.ownerLocation?.OwnerLocationID,
          department: job?.department?.ProviderDivisionID,
          serviceLine: job?.serviceLine?.ServiceLineID,
          serviceType: job?.serviceType?.ServiceTypeID,
          jobCategory: job?.jobCategory?.Id,
          equipmentKitID: job?.JELL?.EquipmentKitID,
        },
      }),
      async onQueryStarted({ timezone, ...job }, { queryFulfilled, dispatch }) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, search } = store.getState().config;

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              jobs.forEach((el) => {
                // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-non-null-assertion
                el.row! += 1;
              });

              const startTime = dayjs.utc(job.defaultScheduledStartTime);
              const defaultScheduledStartTime = dayjs
                .tz(startTime, timezone)
                .utc(true)
                .toDate()
                .toString();

              jobs.unshift({
                ...job,
                defaultScheduledStartTime,
                row: 1,
              } as JobEntity);
            },
          ),
        );

        dispatch(configActions.clearRows());

        try {
          await queryFulfilled;
          dispatch(jobsApi.util.invalidateTags(['Jobs']));
        } catch {
          dispatch(jobsApi.util.invalidateTags(['Jobs']));
        }
      },
    }),
    copyJob: builder.mutation<JobEntity, CopyJobPayload>({
      query: ({ newJobId, description, jobSourceId, startDate, endDate }) => ({
        url: '/jobs/copy',
        method: 'POST',
        body: {
          newJobId,
          description,
          jobSourceId,
          startDate,
          endDate,
        },
      }),
      invalidatesTags: ['Jobs'],
    }),
    updateJob: builder.mutation<JobEntity, Partial<JobEntity> & { timezone: string | undefined }>({
      query: ({ id, ...job }) => ({
        url: `/jobs/${id}`,
        method: 'PATCH',
        body: {
          ...job,
          id,
          owner: job?.owner?.OwnerID,
          ownerContract: job?.ownerContract?.RateSheetID,
          ownerContact: job?.ownerContact?.OwnerPersonnelEmail,
          ownerRepresentative: job?.ownerRepresentative?.OwnerPersonnelEmail,
          ownerLocation: job?.ownerLocation?.OwnerLocationID,
          department: job?.department?.ProviderDivisionID,
          serviceLine: job?.serviceLine?.ServiceLineID,
          serviceType: job?.serviceType?.ServiceTypeID,
          jobCategory: job?.jobCategory?.Id,
          equipmentKitID: job?.JELL?.EquipmentKitID,
        },
      }),
      async onQueryStarted({ timezone, ...job }, { queryFulfilled, dispatch }) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, search } = store.getState().config;

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              const startTime = dayjs.utc(job.defaultScheduledStartTime);
              const defaultScheduledStartTime = dayjs
                .tz(startTime, timezone)
                .utc(true)
                .toDate()
                .toString();

              jobs.splice(
                jobs.findIndex((el) => el.id === job.id),
                1,
                {
                  ...job,
                  defaultScheduledStartTime,
                } as JobEntity,
              );
            },
          ),
        );

        try {
          await queryFulfilled;
          dispatch(jobsApi.util.invalidateTags(['Jobs']));
        } catch {
          dispatch(jobsApi.util.invalidateTags(['Jobs']));
        }
      },
    }),
    deleteJob: builder.mutation<JobEntity, string>({
      query: (id) => ({
        url: `/jobs/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { queryFulfilled, dispatch }) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, search } = store.getState().config;

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              const jobIndex = jobs.findIndex((el) => el.id === id);
              jobs.splice(jobIndex, 1);
              jobs.forEach((el, index) => {
                if (index < jobIndex) return;
                // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-non-null-assertion
                el.row! -= 1;
              });
            },
          ),
        );

        dispatch(configActions.clearRows());

        try {
          await queryFulfilled;
        } catch {
          dispatch(api.util.invalidateTags(['Jobs']));
        }
      },
    }),
  }),
});

export const {
  useGetJobsQuery,
  useLazyGetJobsQuery,
  useGetJobByIdQuery,
  useAddJobMutation,
  useCopyJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,
} = jobsApi;
export { jobsApi };
