import { FC, PropsWithChildren } from 'react';
import { match } from 'ts-pattern';
import { useDrop } from 'react-dnd';

import { PersonEntity, TicketEntity } from 'src/shared/types';
import { PersonCard, PersonCardMode } from 'src/shared/ui/personCard';
import { DropField } from 'src/shared/ui/dropField';
import { not } from 'src/shared/utils';
import { ReactComponent as HardHatIcon } from 'src/assets/icons/outlined/account/hard-hat.svg';
import { ReactComponent as PersonAddIcon } from 'src/assets/icons/outlined/account/person-add.svg';
import { clsx } from 'src/shared/utils/clsx';

type SupervisorsListProps = {
  supervisors?: PersonEntity[];
  cardId?: string;
  ticket?: TicketEntity;
  onSupervisorDrop?: (item: PersonEntity, sourceEntityId: string) => void;
  isDropDisabled?: boolean;
  isTicketCard?: boolean;
};

const SupervisorsList: FC<PropsWithChildren<SupervisorsListProps>> = ({
  supervisors = [],
  cardId,
  ticket,
  onSupervisorDrop,
  isDropDisabled = false,
  isTicketCard = false,
}) => {
  const [{ isOver: supervisorDropIsOver }, supervisorDrop] = useDrop(() => ({
    accept: ['person', 'crewLeader', 'supervisor'],
    drop: (item) => {
      if (onSupervisorDrop) {
        onSupervisorDrop(item as PersonEntity, '');
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const supervisorsContent = match(supervisors.length)
    .when(
      (x) => x === 0 && not(isDropDisabled),
      () => (
        <div ref={isDropDisabled ? undefined : supervisorDrop}>
          <DropField
            icon={<HardHatIcon />}
            title="Drop labor here to assign as ticket owner"
            className={clsx(
              supervisorDropIsOver &&
                'rounded-lg bg-bgColor-dropZoneHovered border-brandingColor-primary-gradient transition-[opacity] duration-300 ease-in-out',
            )}
            isDropDisabled={isDropDisabled}
          />
        </div>
      ),
    )
    .when(
      (x) => x === 0 && isTicketCard && isDropDisabled,
      () => (
        <DropField
          icon={<HardHatIcon />}
          title="Ticket Owners were not assigned"
          isDropDisabled={isDropDisabled}
        />
      ),
    )
    .otherwise(() => (
      <div
        ref={isDropDisabled ? undefined : supervisorDrop}
        className={clsx('grid grid-cols-1 gap-2 relative')}
      >
        {supervisors.map((person) => (
          <PersonCard
            clearAfterDrag
            isTicketCard={isTicketCard}
            disabled={isTicketCard && isDropDisabled}
            mode={PersonCardMode.Supervisor}
            cardId={cardId}
            ticket={ticket}
            key={`${person.firstName} ${person.lastName} ${person.id} ${ticket?.id} supervisor`}
            {...person}
            type="supervisor"
          />
        ))}

        {not(isDropDisabled) && (
          <DropField
            icon={<PersonAddIcon />}
            title="Drop labor here to assign as ticket owner"
            className={clsx(
              'absolute top-0 w-full h-full rounded-lg bg-bgColor-dropZoneHovered border-brandingColor-primary-gradient',
              'transition-[opacity] duration-300 ease-in-out',
              supervisorDropIsOver && not(isDropDisabled)
                ? 'max-w-full opacity-1 p-[8px]'
                : 'max-w-[0px] opacity-0 p-0 transition-none',
            )}
            iconClasses="fill-brandingColor-primary-gradient"
            titleClasses="text-brandingColor-primary-gradient"
            isDropDisabled={isDropDisabled}
          />
        )}
      </div>
    ));

  return <div className={clsx('flex flex-col gap-y-2')}>{supervisorsContent}</div>;
};

export { SupervisorsList };

export type { SupervisorsListProps };
