import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { match } from 'ts-pattern';
import { useLocation, useSearchParams } from 'react-router-dom';

import { SchedulerSidebar } from 'src/shared/ui/schedulerSidebar';
import { RootState, useAppDispatch } from 'src/store';
import { SidebarSkeleton } from 'src/shared/ui/skeleton/ui/sidebarSkeleton';
import { useToggle } from 'src/shared/hooks/useToggle';
import { configActions, selectConfig, selectCurrentUser } from 'src/store/slices';
import { Role } from 'src/shared/types';

import { BoardSkeleton } from '../skeleton/ui/boardSkeleton';
import { not } from '../../utils';

import { Header } from './header';
import { Navigation } from './navigation';

type FilterOption = {
  [key: string]: string[];
};

type LayoutProps = PropsWithChildren & {
  withSidebar?: boolean;
  withHeader?: boolean;
  withHeaderFilters?: boolean;
  withHeaderCollapseAll?: boolean;
  withHeaderWeekNavigation?: boolean;
  withHeaderToday?: boolean;
  withHeaderSearch?: boolean;
  withPreloader?: boolean;
  withUserInfo?: boolean;
  withNavigation?: boolean;
  navigationAlwaysOpen?: boolean;
  navigationContainerSelector?: string;
  headerSearchPlaceholder?: string;
};

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  withSidebar = false,
  withHeader = false,
  withHeaderFilters = false,
  withHeaderCollapseAll = false,
  withHeaderWeekNavigation = false,
  withHeaderToday = false,
  withHeaderSearch = false,
  withPreloader = false,
  withUserInfo = false,
  withNavigation = false,
  navigationAlwaysOpen = false,
  navigationContainerSelector,
  headerSearchPlaceholder,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;
  const { pathname } = location;

  const [searchParams, setSearchParams] = useSearchParams();
  const config = useSelector(selectConfig);
  const filters = useSelector((state: RootState) => state.filters);

  const [isSidebarOpen, toggleIsSidebarOpen] = useToggle(true);
  const [isNavigationOpen, toggleIsNavigationOpen] = useToggle(navigationAlwaysOpen);
  // | We need this only once basically.
  // | Because in most cases we will use TCP/IP connection
  // | and we need to show skeleton only once.
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoadStarted, setIsLoadStarted] = useState(false);
  const isLoading = useSelector((state: RootState) =>
    Object.values(state.api.queries).some((query) => query?.status === 'pending'),
  );

  const handleNavigationToggle = () => {
    if (navigationAlwaysOpen) {
      return;
    }

    toggleIsNavigationOpen();
  };

  useEffect(() => {
    if (pathname === '/') return;

    if (isFirstLoad && isLoading) {
      setIsLoadStarted(true);
    }

    if (isLoadStarted && !isLoading) {
      setIsLoadStarted(false);
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, isLoadStarted, isLoading, pathname]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const paramsObject = Array.from(searchParams.entries()).reduce<FilterOption>(
      (acc, [key, value]) => {
        acc[key] = acc[key] ? [...acc[key], value] : [value];

        return acc;
      },
      {},
    );
    // *
    // * Set Board Filters from searchParams on initial load
    // *
    const boardKeys = Object.keys(config.boardFilters);

    const boardKeysAndValues = boardKeys.reduce<FilterOption>((acc, key) => {
      if (key in paramsObject) {
        acc[key] = paramsObject[key];
      }
      return acc;
    }, {});

    const formattedBoardFilters = {
      ...config.boardFilters,
      ...Object.keys(boardKeysAndValues).reduce<FilterOption>((acc, key) => {
        acc[key] = boardKeysAndValues[key];
        return acc;
      }, {}),
    };

    dispatch(
      configActions.setSelectedFilters({
        type: 'board',
        filters: formattedBoardFilters,
      }),
    );
    // *
    // * Set Sidebar Filters from searchParams on initial load
    // *
    const sidebarKeys = Object.keys(config.sidebarFilters);

    const sidebarKeysAndValues = sidebarKeys.reduce<FilterOption>((acc, key) => {
      if (key in paramsObject) {
        acc[key] = paramsObject[key];
      }
      return acc;
    }, {});

    const formattedSidebarFilters = {
      ...config.sidebarFilters,
      ...Object.keys(sidebarKeysAndValues).reduce<FilterOption>((acc, key) => {
        acc[key] = sidebarKeysAndValues[key];
        return acc;
      }, {}),
    };

    dispatch(
      configActions.setSelectedFilters({
        type: 'sidebar',
        filters: formattedSidebarFilters,
      }),
    );
  }, []);

  useEffect(() => {
    if (isFirstLoad) return;

    const combinedFilters = {
      ...config.sidebarFilters,
      ...config.boardFilters,
    };

    const additionalSearchParams: Record<string, string> = {};

    searchParams.forEach((value, key) => {
      if (!(key in combinedFilters)) {
        additionalSearchParams[key] = value;
      }
    });

    setSearchParams({ ...combinedFilters, ...additionalSearchParams });
  }, [filters, config.boardFilters, config.sidebarFilters, isFirstLoad, setSearchParams]);

  const content = match(isLoadStarted && withPreloader)
    .with(false, () => (
      <div className="flex h-full flex-[1_1_auto] relative overflow-y-auto overflow-x-hidden bg-bgColor-main justify-center">
        {withSidebar && (
          <SchedulerSidebar
            isOpen={isSidebarOpen}
            toggleSidebar={toggleIsSidebarOpen}
          />
        )}

        {withNavigation && (
          <Navigation
            isOpen={isNavigationOpen}
            toggleNavigation={handleNavigationToggle}
            alwaysOpen={navigationAlwaysOpen}
            containerSelector={navigationContainerSelector}
          />
        )}

        {children}
      </div>
    ))
    .with(true, () => (
      <div className="flex h-full flex-[1_1_auto] relative overflow-auto bg-bgColor-main justify-center">
        {not(isReadonly) && <SidebarSkeleton />}

        <BoardSkeleton />
      </div>
    ))
    .exhaustive();

  return (
    <div className="flex flex-col h-full">
      {withHeader && (
        <Header
          className="flex-[0_0_auto]"
          withFilters={withHeaderFilters}
          withWeekNavigation={withHeaderWeekNavigation}
          withCollapseAll={withHeaderCollapseAll}
          withSearch={withHeaderSearch}
          isNavigationOpen={isNavigationOpen}
          toggleNavigation={handleNavigationToggle}
          withToday={withHeaderToday}
          withUserInfo={withUserInfo}
          withNavigation={withNavigation && !navigationAlwaysOpen}
          searchPlaceholder={headerSearchPlaceholder}
        />
      )}

      {content}
    </div>
  );
};

export { Layout };
export type { LayoutProps };
