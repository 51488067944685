import { Provider } from 'src/shared/types';
import { api } from 'src/store/api/api';

const providerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProvider: builder.query<Provider, ''>({
      query: () => ({
        url: '/provider',
      }),
      providesTags: ['Provider'],
      transformResponse: (response: { data: Provider }) => response.data,
    }),
  }),
});

export const { useGetProviderQuery } = providerApi;
