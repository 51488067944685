import { useState, FC, ChangeEvent, FormEvent, useEffect, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { ContractRateSheet, Error, TicketEntity } from 'src/shared/types';
import { clsx, not, showToastErrorMessage } from 'src/shared/utils';
import {
  AdditionalEquipment,
  useAddAdditionalItemMutation,
  useGetAdditionalJELLItemsQuery,
} from 'src/store/api';
import { ReactComponent as SearchIcon } from 'src/assets/icons/filled/internet&code/search.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/outlined/edit/close-circle.svg';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { Modal } from 'src/shared/ui/modal/modal';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { TextField } from 'src/shared/ui/textField';
import { Icon } from 'src/shared/ui/icon';
import { IconButton } from 'src/shared/ui/iconButton';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { Spinner } from 'src/shared/ui/spinner';
import { useAppDispatch } from 'src/store';
import {
  OpenCreateUniversalModalType,
  modalConfigActions,
  selectConfig,
  selectModalConfig,
} from 'src/store/slices';

type CreateAdditionalItemsModalProps = {
  ticket: TicketEntity;
  rateSheeetId: string;
  type: OpenCreateUniversalModalType;
  isOpen: boolean;
};

const CreateUniversalItemModal: FC<CreateAdditionalItemsModalProps> = ({
  ticket,
  rateSheeetId,
  type,
  isOpen,
}) => {
  const dispatch = useAppDispatch();
  const modalConfig = useSelector(selectModalConfig);
  const config = useSelector(selectConfig);

  const {
    data = {
      additionalEquipment: [],
      additionalLabor: [],
    },
    isFetching,
  } = useGetAdditionalJELLItemsQuery(rateSheeetId);

  const { additionalEquipment, additionalLabor } = data;

  const additionalItems = type === 'Labor' ? additionalLabor : additionalEquipment;

  const [addAdditionalItem, { isLoading: isAdding }] = useAddAdditionalItemMutation();

  const [selectedItem, setSelectedItem] = useState<AdditionalEquipment | null>(null);
  const [filteredItems, setFilteredItems] = useState(additionalItems);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    const formattedSearch = debouncedSearch.toLowerCase();

    const filteredItems = additionalItems.filter((el) => {
      const { Itemcode, ItemCodeCategoryName, CustomItemDescription, RequiresUnitNumber } = el;

      const isMatchesFilter =
        Itemcode?.toLowerCase().includes(formattedSearch) ||
        ItemCodeCategoryName?.toLowerCase().includes(formattedSearch) ||
        CustomItemDescription?.toLowerCase().includes(formattedSearch);

      return type === 'Labor' ? isMatchesFilter : isMatchesFilter && RequiresUnitNumber === 'Yes';
    });

    setFilteredItems(filteredItems);
  }, [additionalItems, debouncedSearch, type]);

  const toggleEquipmentSelection = (value: string) => {
    if (selectedItem?.rateSheetItemID === value) {
      setSelectedItem(null);

      return;
    }

    setSelectedItem({
      rateSheetItemID: value,
      QTY: 1,
    });
  };

  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>, value: string) => {
    e.preventDefault();
    toggleEquipmentSelection(value);
  };

  const closeModal = (isOpen: boolean) => {
    setSearch('');
    setSelectedItem(null);
    dispatch(modalConfigActions.setOpenCreateUniversalModalType(isOpen ? null : type));
  };

  const changeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearch(value === ' ' ? '' : value);
  };

  const clearSearch = () => {
    setSearch('');
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (not(selectedItem) || not(type)) {
      return;
    }

    try {
      if (!modalConfig.lastDroppedUniversalItemId) {
        showToastErrorMessage(`There was an error trying to add additional ${type}`);

        return;
      }

      await addAdditionalItem({
        rateSheetItemID: selectedItem.rateSheetItemID,
        ticketId: ticket.id,
        type,
        entityId: modalConfig.lastDroppedUniversalItemId,
        equipmentType: config.openEquipmentTab,
      }).unwrap();
    } catch (error) {
      const errorText =
        (error as Error).status === 400
          ? `Cannot add and assign ${type} due to overlapping timeframe`
          : `There was an error trying to add and assign additional ${type}`;

      showToastErrorMessage(errorText);
    } finally {
      closeModal(isOpen);
    }
  };

  const getIsItemSelected = (el: ContractRateSheet) => {
    return Boolean(selectedItem?.rateSheetItemID === el.RateSheetItemID);
  };

  const submitButtonContent = isAdding ? <Spinner size="sm" /> : 'Add and Assign';

  const isSubmitButtonDisabled = not(selectedItem) || isAdding || selectedItem.QTY <= 0;

  const buttons = (
    <div className="flex justify-end gap-2">
      <Button
        type="button"
        variant="outlined"
        color="basic"
        size="lg"
        className="w-[108px]"
        onClick={() => closeModal(isOpen)}
      >
        Cancel
      </Button>

      <Button
        type="submit"
        color="primary"
        size="lg"
        disabled={isSubmitButtonDisabled}
        className="w-[146px]"
      >
        {submitButtonContent}
      </Button>
    </div>
  );

  const additionalItemsHeaders: TableHeaderType<ContractRateSheet>[] = [
    {
      title: 'Item Code',
      field: 'Itemcode',
      className: 'w-[120px]',
    },
    {
      title: 'Description',
      field: 'CustomItemDescription',
      className: 'w-[300px]',
    },
    {
      title: 'Category',
      field: 'ItemCodeCategoryName',
      className: 'w-[150px]',
    },
    {
      title: 'UOM',
      field: 'UOM',
      className: 'w-[75px]',
    },
    ...(type === 'Equipment'
      ? ([
          {
            title: 'Req. Unit #',
            field: 'RequiresUnitNumber',
            className: 'w-[75px]',
          },
          {
            title: 'Rental',
            field: 'Rental',
            className: 'w-[75px]',
            render: (el) => el.RentalItemCode === '1' && 'Yes',
          },
        ] as TableHeaderType<ContractRateSheet>[])
      : []),
  ];

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={closeModal}
      customClassName="h-full max-h-[735px] overflow-y-auto"
    >
      <form
        className="flex flex-col justify-between gap-y-6  h-[700px] min-w-[1200px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <div className="flex justify-between items-center">
            <Typography
              variant="h2"
              fontWeight="bold"
            >
              {`Add and assign ${type}`}
            </Typography>

            <TextField
              startIcon={
                <Icon
                  icon={<SearchIcon />}
                  className="absolute left-[15px] top-[12px]"
                />
              }
              endIcon={
                search ? (
                  <IconButton
                    size="md"
                    className="absolute right-[5px] top-[4px]"
                    onClick={clearSearch}
                  >
                    <CloseCircleIcon className="fill-[#2E3A59] cursor-pointer" />
                  </IconButton>
                ) : undefined
              }
              placeholder={`Search for ${type?.toLowerCase()}`}
              className="w-[470px]"
              value={search}
              onChange={changeSearch}
            />
          </div>

          <Table
            containerClassName="h-[550px]"
            scroll
            sticky
            headers={additionalItemsHeaders}
            data={filteredItems}
            isLoading={isFetching}
            onRowClick={(e, el: ContractRateSheet) => handleRowClick(e, el.RateSheetItemID)}
            idProvider={(el: ContractRateSheet) => el.RateSheetItemID}
            getRowClassName={(el: ContractRateSheet) => {
              const includesInSelected = getIsItemSelected(el);

              return clsx(includesInSelected && 'bg-[#D0D8E8]', isAdding && 'pointer-events-none');
            }}
          />
        </div>

        {buttons}
      </form>
    </Modal>
  );
};

export { CreateUniversalItemModal };
