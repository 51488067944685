import { SurveyAnswerEntity } from 'src/shared/types';
import { getFormattedDate } from 'src/shared/ui/details/helpers';
import { getFormattedTimePickerTime } from 'src/shared/utils';

import { ExtraSurveyAnswerField } from './constants';

export const getAdaptedTabulatorAnswer = (answer: SurveyAnswerEntity) => ({
  ...answer.answer,
  [ExtraSurveyAnswerField.ResponseId]: answer.responseId,
  [ExtraSurveyAnswerField.CreatedAt]: `${getFormattedDate(
    answer.createdAt,
  )} ${getFormattedTimePickerTime(answer.createdAt)}`,
  [ExtraSurveyAnswerField.UpdatedAt]: `${getFormattedDate(
    answer.updatedAt,
  )} ${getFormattedTimePickerTime(answer.updatedAt)}`,
});

export const getAdaptedTabulatorAnswers = (answers: SurveyAnswerEntity[]) =>
  answers.map(getAdaptedTabulatorAnswer);
