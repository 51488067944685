import { api } from 'src/store/api/api';
import { AssignmentsStatsBySurveyAnswers } from 'src/shared/types';

const inspectorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTopAssignmentsStatsBySurveyAnswers: builder.query<AssignmentsStatsBySurveyAnswers, ''>({
      query: () => ({
        url: '/admin/assignments/stats/survey-answers',
        method: 'GET',
      }),
      providesTags: ['Assignments'],
      transformResponse: (response: { data: AssignmentsStatsBySurveyAnswers }) => response.data,
    }),
  }),
});

export const { useGetTopAssignmentsStatsBySurveyAnswersQuery } = inspectorApi;
