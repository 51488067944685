import { FC, PropsWithChildren, HTMLAttributes } from 'react';
import { DialogContent, SxProps, Theme, Divider } from '@mui/material';

type BodyDialogProps = PropsWithChildren<HTMLAttributes<HTMLElement>> & {
  sx?: SxProps<Theme>;
};

const BodyDialog: FC<BodyDialogProps> = ({ children, sx, ...rest }) => {
  return (
    <DialogContent
      {...rest}
      sx={{ padding: '1rem', ...sx }}
    >
      {children}
    </DialogContent>
  );
};

export { BodyDialog };
