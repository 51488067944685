import dayjs from 'dayjs';
import { z } from 'zod';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

export const addUpdateSchema = z
  .object({
    notes: z.string().optional(),
    delayCategory: z.object({
      name: z.string().optional(),
      id: z.string().optional(),
    }),
    percentComplete: z
      .string()
      .regex(
        /^((100((\.|,)[0-9]{1,2})?)|([0-9]{1,2}((\.|,)[0-9]{0,2})?))$/,
        'Percent-complete rate must be a number',
      )
      .optional(),
    providerTeam: z.object({
      name: z.string(),
      id: z.string(),
    }),
    activityStatus: z.string().min(1, 'Activity status is required'),
    duration: z.string().optional(),
    startTime: z.string().datetime().optional(),
    endTime: z.string().datetime().optional(),
    delayStart: z.string().datetime().optional(),
    delayEnd: z.string().datetime().optional(),
    completedDate: z.string().datetime().optional(),
    isCompleted: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (data.delayCategory.id && !data.duration) {
      ctx.addIssue({
        path: ['duration'],
        message: 'Duration is required',
        code: z.ZodIssueCode.custom,
      });
    }
    if (data.delayCategory.id && !data.startTime) {
      ctx.addIssue({
        path: ['startTime'],
        message: 'Delay start is required',
        code: z.ZodIssueCode.custom,
      });
    }
    if (data.delayCategory.id && !data.endTime) {
      ctx.addIssue({
        path: ['endTime'],
        message: 'Delay end is required',
        code: z.ZodIssueCode.custom,
      });
    }
    if (
      data.delayCategory.id &&
      data.duration &&
      (data.duration?.includes('-') || data.startTime === data.endTime)
    ) {
      ctx.addIssue({
        path: ['endTime'],
        message: 'Duration must be positive',
        code: z.ZodIssueCode.custom,
      });
    }
    const startDate = data.startTime
      ? dayjs(data.startTime).set('second', 0).set('millisecond', 0)
      : null;
    const completedDate = data.completedDate
      ? dayjs(data.completedDate).set('second', 0).set('millisecond', 0)
      : null;

    if (data.isCompleted && completedDate && startDate && completedDate.isSameOrBefore(startDate)) {
      ctx.addIssue({
        path: ['completedDate'],
        message: 'Completed date must be after start date',
        code: z.ZodIssueCode.custom,
      });
    }
    return true;
  });

export const editStatusSchema = z.object({
  dateRange: z.object({
    startDate: z.string().or(z.date()).or(z.null()),
    endDate: z.string().or(z.date()).or(z.null()),
  }),
  actualStart: z.string().or(z.date()).or(z.null()),
  actualEnd: z.string().or(z.date()).or(z.null()),
  providerTeam: z.object({
    name: z.string(),
    id: z.string(),
  }),
  activityStatus: z.string().min(1, 'Activity status is required'),
});

export const disconnectSchema = z.object({
  parent: z.string().min(1, 'Choose a parent activity'),
  child: z.string().min(1, 'Choose a child activity'),
});

export const addJobSchema = z
  .object({
    activityName: z.string().min(1, 'This field is required'),
    jobNumber: z.string().min(1, 'Enter job number'),
    jobName: z.string().min(1, 'Enter job number'),
    equipment: z.string().min(1, 'Enter equipment').optional(),
    type: z.object({
      name: z.string(),
      id: z.string(),
    }),
    startTime: z.string().datetime(),
    duration: z.string(),
    endTime: z.string().datetime(),
    providerTeam: z.object({
      name: z.string(),
      id: z.string(),
    }),
    provider: z.object({
      name: z.string(),
      id: z.string(),
    }),
    notes: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.duration.includes('-') || (data.duration && data.endTime === data.startTime)) {
      ctx.addIssue({
        path: ['endTime'],
        message: 'Duration must be positive',
        code: z.ZodIssueCode.custom,
      });
    }
  });

export const uploadJobsSchema = z.object({
  provider: z.object({
    name: z.string(),
    id: z.string(),
  }),
  fileName: z.string(),
});
