import { Placement, PositioningStrategy, State } from '@popperjs/core';
import {
  FC,
  ReactElement,
  ReactNode,
  cloneElement,
  useRef,
  useState,
  DOMAttributes,
  SetStateAction,
  Dispatch,
  useEffect,
  MouseEventHandler,
} from 'react';
import { Modifier, usePopper } from 'react-popper';
import { mergeRefs } from 'react-merge-refs';
import { createPortal } from 'react-dom';

import { clsx } from 'src/shared/utils/clsx';
import { useOutsideClick } from 'src/shared/hooks/useOutsideClick';
import { useAppDispatch } from 'src/store';
import { modalConfigActions } from 'src/store/slices';

export type DropDownItem = {
  label: ReactNode;
  identifier?: string;
  value: string | number | null;
  onClick?: () => void;
  customKey?: string;
  labelName?: string;
};

type DropDownProps = {
  renderElement: (
    isOpen: boolean,
    toggleDropDown: MouseEventHandler<HTMLButtonElement>,
  ) => ReactNode;
  items: DropDownItem[];
  config?: {
    sameWidth?: boolean;
    withPortal?: boolean;
    withBackdrop?: boolean;
    itemsElementClassName?: string | ((isOpen: boolean) => string);
    setSearch?: Dispatch<SetStateAction<string>>;
    focusLastElement?: boolean;
    setSearchOnSelect?: boolean;
    closeOnSelect?: boolean;
    onItemClick?: (item: DropDownItem) => void;
  };
  options?: Partial<{
    placement: Placement;
    modifiers: Array<Partial<Modifier<unknown, Record<string, unknown>>>>;
    strategy: PositioningStrategy;
    onFirstUpdate?: (arg0: Partial<State>) => void;
  }>;
};

const DropDown: FC<DropDownProps> = ({ renderElement, items, options, config }) => {
  const dispatch = useAppDispatch();

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const popperRef = useRef<HTMLDivElement>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    ...options,
  });

  const {
    sameWidth = false,
    withPortal = false,
    withBackdrop = true,
    itemsElementClassName,
    focusLastElement = true,
    setSearchOnSelect = true,
    closeOnSelect = true,
    setSearch = () => {},
    onItemClick,
  } = config || {};

  const toggleDropDown: DOMAttributes<HTMLButtonElement>['onClick'] = (e) => {
    e.stopPropagation();

    setIsPopperOpen((prev) => !prev);
    setSelectedIndex(0);
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));

    if (update) update();
  };

  const closeDropDown = () => {
    setIsPopperOpen(false);
  };

  const itemClick = (item: DropDownItem) => {
    if (item?.onClick) {
      item.onClick();
    }

    if (typeof item.value === 'string' && setSearch && setSearchOnSelect) {
      setSearch(item.value);
    }

    onItemClick?.(item);

    if (closeOnSelect) {
      closeDropDown();
    }
  };

  const renderElementWithRef = cloneElement(
    renderElement(isPopperOpen, toggleDropDown) as ReactElement,
    {
      ref: setReferenceElement,
      onClick: toggleDropDown,
    },
  );

  const handleKeyDown: DOMAttributes<HTMLDivElement>['onKeyDown'] = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();

      const currentIndex = selectedIndex !== null ? selectedIndex : -1;
      let nextIndex = currentIndex + (e.key === 'ArrowDown' ? 1 : -1);

      if (nextIndex < 0) {
        nextIndex = items.length - 1;
      } else if (nextIndex >= items.length) {
        nextIndex = 0;
      }

      setSelectedIndex(nextIndex);
    } else if (e.key === 'Enter' && selectedIndex !== null) {
      itemClick(items[selectedIndex]);
    } else if (e.key === 'Enter' && items.length === 1) {
      itemClick(items[0]);
    }
  };

  useEffect(() => {
    if (popperRef.current && selectedIndex !== null) {
      const selectedItem = popperRef.current.children[selectedIndex] as HTMLElement;

      if (selectedItem) {
        const scrollContainer = popperRef.current;
        const scrollPosition = selectedItem.offsetTop - scrollContainer.offsetTop;

        scrollContainer.scrollTop = scrollPosition;
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (items.length === 1 && popperRef.current && focusLastElement) {
      popperRef.current.focus();
    }
  }, [items, isPopperOpen, focusLastElement]);

  useOutsideClick(popperRef, closeDropDown, isPopperOpen);

  const selectItem = (item: DropDownItem) => {
    itemClick(item);
  };

  const content = (
    <div
      role="button"
      ref={mergeRefs([setPopperElement, popperRef])}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      data-id={isPopperOpen ? 'isActivePopper' : ''}
      style={{
        ...styles.popper,
        maxWidth: isPopperOpen
          ? `${sameWidth ? `${referenceElement?.offsetWidth}px` : '100%'}`
          : '0px',
      }}
      className={clsx(
        isPopperOpen ? `z-[100] max-h-[305px]` : 'max-h-0 p-0',
        'w-[175px] px-1 py-1',
        'overflow-y-auto outline-none',
        typeof itemsElementClassName === 'function'
          ? itemsElementClassName(isPopperOpen)
          : itemsElementClassName,
      )}
      {...attributes.popper}
    >
      {items.map((item, index) => (
        <div
          role="button"
          key={item.customKey || item.value}
          onClick={() => selectItem(item)}
          onKeyDown={() => selectItem(item)}
          className={clsx(
            'duration-200',
            selectedIndex === index && 'bg-gray-200 rounded-lg',
            'hover:bg-[#123C8E1A] hover:rounded-lg',
          )}
        >
          {item.label}
        </div>
      ))}

      {!items.length && <span className="pl-4">No items were found</span>}
    </div>
  );

  return (
    <>
      {renderElementWithRef}

      {isPopperOpen && (
        <div
          className={clsx(withBackdrop && 'absolute top-0 right-0 left-0 bottom-0 cursor-default')}
        >
          {withPortal ? createPortal(content, document.body) : content}
        </div>
      )}
    </>
  );
};

export { DropDown };
export type { DropDownProps };
