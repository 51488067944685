import { FC } from 'react';
import { useDrop } from 'react-dnd';

import { DropField } from 'src/shared/ui/dropField';
import { ReactComponent as CarIcon } from 'src/assets/icons/outlined/shop/car.svg';
import { clsx } from 'src/shared/utils';
import { EquipmentEntity, TicketEntity } from 'src/shared/types';
import { useAppDispatch } from 'src/store';
import { modalConfigActions } from 'src/store/slices';

type UniversalEquipmentDropfieldProps = {
  ticket: TicketEntity | undefined;
  isDropDisabled?: boolean;
};

const UniversalEquipmentDropfield: FC<UniversalEquipmentDropfieldProps> = ({
  ticket,
  isDropDisabled = false,
}) => {
  const dispatch = useAppDispatch();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'equipment',
    drop: (item: EquipmentEntity) => {
      if (ticket && item) {
        dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(ticket.id));
        dispatch(modalConfigActions.setLastDroppedUniversalItemId(item.AssetID));
        dispatch(modalConfigActions.setOpenCreateUniversalModalType('Equipment'));
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const className = clsx(
    isOver &&
      'rounded-lg bg-bgColor-dropZoneHovered border-brandingColor-primary-gradient transition-[opacity] duration-300 ease-in-out',
  );

  return (
    <div ref={isDropDisabled ? undefined : drop}>
      <DropField
        className={className}
        icon={<CarIcon />}
        title="Drop Equipment here for quick assign"
        isDropDisabled={isDropDisabled}
      />
    </div>
  );
};

export { UniversalEquipmentDropfield };
