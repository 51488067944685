import { FC, useEffect, useMemo, useRef, useState } from 'react';
import omit from 'just-omit';

import { useGetPeopleQuery } from 'src/store/api';
import { Typography } from 'src/shared/ui/typography';
import { PersonEntity } from 'src/shared/types';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { MultiSelectInput } from 'src/shared/ui/MultiSelectInput';
import { Card } from 'src/shared/ui/card';
import { not } from 'src/shared/utils';
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage';

import { MultiSignatureState } from '../customFormQuestions/multiSignature/types';

import { SignatureList } from './SignatureList';
import { SurveyEmployee } from './signaturePopup';
import { Metadata, MetadataType, SurveyData } from './signaturePopup/signaturePopup';

type MultiSignaturesFormProps = {
  questionName: string;
  onCrewLeaderSet: (crewLeader: SurveyEmployee | null) => void;
  onCrewMemberToggle: (crewMember: SurveyEmployee) => MultiSignatureState | undefined;
  onPersonUnset: (personId: string) => void;
  onMetadataSet: (personId: string, metadata: SurveyData, type: MetadataType) => void;
};

const FORM_ID = window.location.pathname.split('/').pop() || '';

const MultiSignaturesForm: FC<MultiSignaturesFormProps> = ({
  questionName,
  onCrewLeaderSet: passedOnCrewLeaderSet,
  onCrewMemberToggle: passedOnCrewMemberToggle,
  onPersonUnset: passedOnPersonUnset,
  onMetadataSet,
}) => {
  const { data: people = [] } = useGetPeopleQuery({});

  const [crewLeaderId, setCrewLeaderId] = useState('');
  const [crewMemberIds, setCrewMemberIds] = useState<string[]>([]);

  const [stringifiedLocalStorageData, setStringifiedLocalStorageData] = useLocalStorage<string>(
    FORM_ID,
    'null',
  );

  const storedAnswer = useMemo(() => {
    const parsedData = JSON.parse(stringifiedLocalStorageData) as Record<
      string,
      MultiSignatureState
    > | null;

    return parsedData?.[questionName];
  }, [stringifiedLocalStorageData, questionName]);

  useEffect(() => {
    if (!storedAnswer) {
      return;
    }

    setCrewLeaderId(storedAnswer.crewLeader?.id || '');
    setCrewMemberIds(storedAnswer.crewMembers?.map(({ id }) => id) || []);
  }, [storedAnswer, questionName]);

  const crewLeader = people.find((person) => person.id === crewLeaderId);
  const crewMembers = people.filter((person) => crewMemberIds.includes(person.id));

  const selectedEmployees = [crewLeader, ...crewMembers].filter(Boolean) as PersonEntity[];

  const storedEmployees = [storedAnswer?.crewLeader, ...(storedAnswer?.crewMembers || [])].filter(
    Boolean,
  ) as SurveyEmployee[];

  const selectedPeopleRef = useRef<SurveyEmployee[]>([]);
  selectedPeopleRef.current = selectedEmployees.map(({ id, firstName, lastName }) => {
    const storedEmployee = storedEmployees.find(({ id: employeeId }) => employeeId === id);

    const preShiftMetadata = storedEmployee?.[Metadata.preShiftMetadata] || null;
    const postShiftMetadata = storedEmployee?.[Metadata.postShiftMetadata] || null;

    return {
      id,
      firstName,
      lastName,
      preShiftMetadata,
      postShiftMetadata,
    };
  });

  const updateStoredAnswer = (
    updateAnswer: (prevState: MultiSignatureState) => MultiSignatureState,
  ) => {
    const parsedData = (JSON.parse(stringifiedLocalStorageData) || {}) as Record<string, unknown>;

    const questions = omit(parsedData, questionName);
    const updatedStorage = {
      ...questions,
      [questionName]: updateAnswer(storedAnswer || {}),
    };

    setStringifiedLocalStorageData(JSON.stringify(updatedStorage));
  };

  const onCrewLeaderSet = (crewLeader: SurveyEmployee | null) => {
    passedOnCrewLeaderSet(crewLeader);
    updateStoredAnswer((prevState) => ({
      ...prevState,
      crewLeader,
    }));
  };

  const onCrewMemberToggle = (crewMember: SurveyEmployee) => {
    const updatedAnswer = passedOnCrewMemberToggle(crewMember);

    if (!updatedAnswer) {
      return;
    }

    updateStoredAnswer((prevState) => ({
      ...prevState,
      crewMembers: updatedAnswer.crewMembers,
    }));
  };

  const toggleCrewMember = (id: string) => {
    const crewMember = people.find((person) => person.id === id);

    if (crewMemberIds.includes(id)) {
      setCrewMemberIds(crewMemberIds.filter((crewMemberId) => crewMemberId !== id));
    } else {
      setCrewMemberIds([...crewMemberIds, id]);
    }

    if (crewMember) {
      onCrewMemberToggle({
        id: crewMember.id,
        firstName: crewMember.firstName,
        lastName: crewMember.lastName,
        preShiftMetadata: null,
        postShiftMetadata: null,
      });
    }
  };

  const setCrewLeader = (id: string) => {
    const crewLeader = people.find((person) => person.id === id);

    setCrewLeaderId(id);

    if (crewLeader) {
      onCrewLeaderSet({
        id: crewLeader.id,
        firstName: crewLeader.firstName,
        lastName: crewLeader.lastName,
        preShiftMetadata: null,
        postShiftMetadata: null,
      });
    }
  };

  const unsetPerson = (personId: string) => {
    passedOnPersonUnset(personId);

    if (crewLeaderId === personId) {
      setCrewLeaderId('');

      return;
    }

    setCrewMemberIds(crewMemberIds.filter((crewMemberId) => crewMemberId !== personId));
  };

  const clearCrewLeader = () => {
    setCrewLeaderId('');
    onCrewLeaderSet(null);
  };

  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-col gap-y-4 max-w-[860px]">
        <div className="flex flex-col items-center gap-y-6 md:flex-row md:gap-x-6 md:justify-between">
          <Card
            className="w-full"
            title={
              <SelectInput
                className="lg:w-[370px]"
                name="crewLeader"
                label="Crew Leader"
                value={
                  crewLeader
                    ? `${crewLeader.firstName} ${crewLeader.lastName} (${crewLeader.id})`
                    : ''
                }
                placeholder="Select crew leader"
                onClear={clearCrewLeader}
                items={
                  people
                    .filter((person) => not(crewMemberIds.includes(person.id)))
                    .map((el) => {
                      const { id, firstName, lastName } = people.find(
                        (person) => person.id === el.id,
                      ) as PersonEntity;

                      const formattedPerson = `${firstName} ${lastName} (${id})`;

                      return {
                        label: (
                          <SelectInputItem selected={crewLeaderId === id}>
                            {formattedPerson}
                          </SelectInputItem>
                        ),
                        value: formattedPerson,
                        onClick: () => setCrewLeader(id),
                      };
                    }) ?? []
                }
              />
            }
          />

          <Card
            className="w-full"
            title={
              <MultiSelectInput
                className="lg:w-[370px]"
                placeholder="Select crew members"
                label="Crew Members"
                items={
                  people
                    .filter((person) => person.id !== crewLeaderId)
                    .map((el) => {
                      const { id, firstName, lastName } = people.find(
                        (person) => person.id === el.id,
                      ) as PersonEntity;

                      const formattedPerson = `${firstName} ${lastName} (${id})`;

                      return {
                        label: (
                          <SelectInputItem selected={crewMemberIds.includes(id)}>
                            {formattedPerson}
                          </SelectInputItem>
                        ),
                        value: formattedPerson,
                        onClick: () => toggleCrewMember(id),
                      };
                    }) ?? []
                }
              />
            }
          />
        </div>

        <Card
          className="w-full"
          title={
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1 w-full">
                <Typography
                  variant="label"
                  fontWeight="semibold"
                  className="w-full whitespace-normal break-words"
                >
                  Pre Job Safety Analysis - Acknowledgement Signature
                </Typography>

                <Typography
                  variant="c1"
                  className="w-full whitespace-normal break-words text-textColor-tertiary"
                  fontWeight="semibold"
                >
                  I have received personal instruction on the scope of the work I am about to
                  perform. I understand that it is my responsibility to comply with this and all
                  training that I have received from EVERGREEN NORTH AMERICA.
                </Typography>
              </div>

              <div className="flex flex-col gap-y-2">
                {selectedEmployees.length ? (
                  <SignatureList
                    employeesRef={selectedPeopleRef}
                    metadataKey={Metadata.preShiftMetadata}
                    onSubmit={(personId, metadata) =>
                      onMetadataSet(personId, metadata, Metadata.preShiftMetadata)
                    }
                    unsetPerson={unsetPerson}
                  />
                ) : (
                  <Typography
                    variant="p2"
                    fontWeight="semibold"
                    className="text-textColor-tertiary"
                  >
                    No selected people yet
                  </Typography>
                )}
              </div>
            </div>
          }
        />

        <Card
          title={
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1 w-full">
                <Typography
                  variant="label"
                  fontWeight="semibold"
                  className="w-full whitespace-normal break-words"
                >
                  End-of-Shift Signatures
                </Typography>

                <Typography
                  variant="c1"
                  className="w-full whitespace-normal break-words text-textColor-tertiary"
                  fontWeight="semibold"
                >
                  I certify that all equipment is cleaned, all debris Is disposed of, and all
                  personnel are injury & illness free at the end of the shift or at job completion.
                </Typography>
              </div>

              <div className="flex flex-col gap-y-2">
                {selectedEmployees.length ? (
                  <SignatureList
                    employeesRef={selectedPeopleRef}
                    metadataKey={Metadata.postShiftMetadata}
                    onSubmit={(personId, metadata) =>
                      onMetadataSet(personId, metadata, Metadata.postShiftMetadata)
                    }
                    unsetPerson={unsetPerson}
                  />
                ) : (
                  <Typography
                    variant="p2"
                    fontWeight="semibold"
                    className="text-textColor-tertiary"
                  >
                    No selected people yet
                  </Typography>
                )}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export { MultiSignaturesForm, type MultiSignaturesFormProps };
