import React, { FC } from 'react';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';

import { Modal, ModalProps } from 'src/shared/ui/modal';
import { addCancelButton, navigationCSS } from 'src/pages/SurveyForm/utils';
import { GEOLOCATION_QUESTION_TYPE } from 'src/pages/SurveyFormCreator/customFormQuestions/Geolocation';
import { PersonEntity } from 'src/shared/types';

const SurveyField = {
  Signature: 'signature',
  Name: 'name',
  EmployeeId: 'employeeId',
  LocationTimestamp: 'locationTimestamp',
} as const;
export type SurveyData = {
  [SurveyField.Signature]: string;
  [SurveyField.Name]: string;
  [SurveyField.EmployeeId]: string;
  [SurveyField.LocationTimestamp]: {
    latitude: number;
    longitude: number;
  };
};

export const Metadata = {
  preShiftMetadata: 'preShiftMetadata',
  postShiftMetadata: 'postShiftMetadata',
} as const;

export type MetadataType = keyof typeof Metadata;

type SurveyEmployee = Pick<PersonEntity, 'id' | 'firstName' | 'lastName'> & {
  [Metadata.preShiftMetadata]: SurveyData | null;
  [Metadata.postShiftMetadata]: SurveyData | null;
};

const getConfig = ({ id, firstName, lastName }: SurveyEmployee) => {
  return {
    elements: [
      {
        name: SurveyField.Signature,
        title: 'Signature',
        type: 'signaturepad',
        isRequired: true,
      },
      {
        name: SurveyField.Name,
        title: 'Name',
        type: 'text',
        readOnly: true,
        defaultValue: `${firstName} ${lastName}`,
      },
      {
        name: SurveyField.EmployeeId,
        title: 'Employee ID',
        type: 'text',
        readOnly: true,
        defaultValue: id,
      },
      {
        name: SurveyField.LocationTimestamp,
        title: 'Location | Timestamp',
        type: GEOLOCATION_QUESTION_TYPE,
        autoCapture: true,
        isRequired: true,
      },
    ],
  };
};

type SignaturePopupProps = Pick<ModalProps, 'toggleModal'> & {
  open: boolean;
  employee: SurveyEmployee;
  metadataKey: MetadataType;
  onSubmit: (personId: string, data: SurveyData) => void;
  onCancel: () => void;
};

const SignaturePopup: FC<SignaturePopupProps> = ({
  open: isOpen,
  employee,
  metadataKey,
  onSubmit: handleSubmit,
  onCancel: handleCancel,
  ...modalProps
}) => {
  const config = getConfig(employee);
  const model = new Model(JSON.stringify(config));

  addCancelButton({
    model,
    handleCancel,
  });

  model.css = navigationCSS;

  model.onComplete.add(async ({ data }) => {
    handleSubmit(employee.id, data);
  });

  model.data = employee[metadataKey] || {};

  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      customClassName="max-h-[calc(100vh-64px*2)] min-w-[600px] overflow-y-scroll"
    >
      <Survey model={model} />
    </Modal>
  );
};

export { SignaturePopup, type SignaturePopupProps, type SurveyEmployee };
