import clsx from 'clsx';
import { FC } from 'react';

import { Icons } from 'src/assets/icons';
import {
  ActivityHealth,
  ActivityHealthEnum,
  ActivityTrack,
  ActivityUpdated,
} from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';

import { ACTIVITY_HEALTH_BG_COLORS, getWorkTrackValue } from '../../helpers';

interface ActivityBadgeProps {
  status: ActivityHealth | ActivityTrack | ActivityUpdated;
  onValueSelect: (status: ActivityHealth | ActivityTrack | ActivityUpdated) => void;
  updatesCount?: number;
  isBadge?: boolean;
}

const ActivityBadge: FC<ActivityBadgeProps> = ({
  status,
  onValueSelect,
  updatesCount,
  isBadge,
}) => {
  const title = getWorkTrackValue(status);
  const activityHealthBgColor = ACTIVITY_HEALTH_BG_COLORS[status as ActivityHealth];

  return (
    <button
      type="button"
      onClick={() => onValueSelect(status)}
      disabled={isBadge}
      className={clsx(
        'flex items-center gap-1 px-2 py-0.5 rounded-[25px]',
        {
          'bg-[#CCFBE1]': status === 'Completed',
          'bg-[#F6FCB1]': status === 'NotStarted',
          'bg-bgColor-blue': status === 'InProgress',
          'bg-[#FAE4C2]': status === 'Updated',
        },
        activityHealthBgColor,
      )}
    >
      <Typography
        variant="c2"
        className={clsx('text-xs leading-[18px]', {
          'text-[#14B78B]': status === 'Completed',
          'text-[#93B700]': status === 'NotStarted',
          'text-semanticColor-info': status === 'InProgress',
          'text-semanticColor-warning': status === 'Updated',
          'text-gray-200':
            status === ActivityHealthEnum.OffTrack ||
            status === ActivityHealthEnum.Scheduled ||
            status === ActivityHealthEnum.Delayed,
          'text-green-900': status === ActivityHealthEnum.OnTrack,
        })}
      >
        {title}
      </Typography>

      {status === 'OnTrack' && (
        <Icons.Outlined.Edit.TargetIcon className="fill-green-900 w-4 h-4" />
      )}

      {status === 'Delayed' && (
        <Icons.Outlined.Notifications.AlertTriangleIcon className="fill-gray-200 w-4 h-4" />
      )}

      {status === 'NotStarted' && (
        <Icons.Outlined.Edit.EditIcon className="fill-[#93B700] w-4 h-4" />
      )}

      {status === 'InProgress' && (
        <Icons.Outlined.Edit.EditIcon className="fill-semanticColor-info w-4 h-4" />
      )}

      {status === 'Updated' && (
        <div className="w-4 h-4 rounded-full bg-white flex items-center justify-center">
          <Typography
            variant="c2"
            className="text-[10px] leading-3 text-center"
          >
            {updatesCount}
          </Typography>
        </div>
      )}
    </button>
  );
};

export { ActivityBadge };
