import { memo } from 'react';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { clsx } from 'src/shared/utils/clsx';
import { Typography } from '../typography';
import dayjs, { Dayjs } from 'dayjs';
import { DateType } from '../datepicker/types';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

type MuiDateTimePickerProps = Omit<DateTimePickerProps<Dayjs>, 'value' | 'onChange'> & {
  value: DateType;
  onChange: (
    value: string | null,
    context?: PickerChangeHandlerContext<DateTimeValidationError>,
  ) => void;
  error?: string;
  isRequired?: boolean;
};

const MuiDateTimePicker: React.FC<MuiDateTimePickerProps> = ({
  label,
  isRequired = false,
  error,
  className,
  value,
  onChange,
  ...props
}) => {
  const pickerValue = value ? dayjs(value) : null;

  const handleValueChange = (
    value: dayjs.Dayjs | null,
    context: PickerChangeHandlerContext<DateTimeValidationError>,
  ) => {
    const formattedValue = value?.isValid() ? value.toISOString() : null;
    onChange(formattedValue, context);
  };

  return (
    <div className={clsx('flex flex-col gap-y-2 w-full', className)}>
      {label && (
        <Typography variant="label">
          {label}
          {isRequired && <span className="text-textColor-danger">*</span>}
        </Typography>
      )}

      <div className={clsx('flex relative h-[48px] items-center')}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            {...props}
            value={pickerValue}
            onChange={handleValueChange}
            sx={{
              'width': '100%',
              '.MuiOutlinedInput-root': {
                borderRadius: '0.75rem',
              },
              '.MuiOutlinedInput-input': {
                padding: '12px 16px',
                backgroundColor: '#B1E5FC0F',
              },
            }}
          />
        </LocalizationProvider>
      </div>
      {error && <p className="text-semanticColor-danger text-[14px] leading-[150%]">{error}</p>}
    </div>
  );
};

const memoizedMuiDateTimePicker = memo(MuiDateTimePicker);

export { memoizedMuiDateTimePicker as MuiDateTimePicker };
export type { MuiDateTimePickerProps };
