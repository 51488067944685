import { AccessEntity } from 'src/shared/types';
import { api } from 'src/store/api/api';

const accessApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccess: builder.query<AccessEntity[], ''>({
      query: () => ({ url: '/access' }),
      providesTags: () => ['Access'],
      transformResponse: (response: { data: any[] }) => response.data,
    }),
    updateAccess: builder.mutation<
      '',
      {
        id: string;
        SystemAdmin: 'Yes' | 'No';
      }
    >({
      query: ({ id, SystemAdmin }) => ({
        url: `/access/${id}`,
        method: 'PATCH',
        body: { SystemAdmin },
      }),
      invalidatesTags: () => ['Access'],
    }),
  }),
});

export const { useGetAccessQuery, useUpdateAccessMutation } = accessApi;
