import { ReactNode } from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import { Icon } from 'leaflet';
import blueMarker from 'src/assets/images/markers/blue-marker.png';

type MarkerProps = {
  latitude: number;
  longitude: number;
  markerLabel?: string;
  markerLabelColor?: string;
};

type MarkerMapProps<Marker extends MarkerProps> = {
  markerData: Marker;
  renderPopupBody: (data: Marker) => ReactNode;
};

const MarkerMap = <Marker extends MarkerProps>({
  markerData,
  renderPopupBody,
}: MarkerMapProps<Marker>) => {
  const { latitude, longitude, markerLabel, markerLabelColor } = markerData;
  const markerIcon = new Icon({
    iconUrl: blueMarker,
    iconSize: [25, 41],
  });

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <Marker
      position={[latitude, longitude]}
      icon={markerIcon}
    >
      <Popup>{renderPopupBody(markerData)}</Popup>
      {markerLabel && (
        <Tooltip
          permanent
          direction="top"
          offset={[0, 10]}
          opacity={1}
        >
          <div
            className="border-4 font-bold text-lg p-2"
            style={{
              borderColor: markerLabelColor,
            }}
          >
            {markerLabel}
          </div>
        </Tooltip>
      )}
    </Marker>
  );
};

export { MarkerMap };
