import { Dispatch, FC, SetStateAction, useId, useMemo, useState } from 'react';
import { Checkbox, FormLabel } from '@mui/material';
import clsx from 'clsx';

import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { dayjs, not } from 'src/shared/utils';
import { useGetActivityByIdQuery } from 'src/store/api/activities';
import { ActivityHealth, ActivityTrack, ActivityUpdated } from 'src/shared/types';
import { Spinner } from 'src/shared/ui/spinner';

import { TaskManagementInfo } from '../taskManagementInfo';
import { TaskTimeInfo } from '../taskTimeInfo';
import { ActivityBadge } from '../activityBadge';
import { POLLING_INTERVAL_ACTIVITIES } from '../../helpers';

interface UpdatesListModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedJobId: string;
  timeZone: string;
}

const UpdatesListModal: FC<UpdatesListModalProps> = ({
  isOpen,
  setIsOpen,
  selectedJobId,
  timeZone,
}) => {
  const [isSystemUpdates, setIsSystemUpdates] = useState(false);

  const { data, isLoading } = useGetActivityByIdQuery(
    {
      id: selectedJobId,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: POLLING_INTERVAL_ACTIVITIES,
    },
  );
  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const updates = useMemo(() => {
    return data
      ? data.activityStates.filter(({ isSystemUpdate }) =>
          isSystemUpdates ? isSystemUpdate : !isSystemUpdate,
        )
      : [];
  }, [data, isSystemUpdates]);

  const emptyWorkTypeSelect = (status: ActivityHealth | ActivityTrack | ActivityUpdated) => {};

  const systemUpdatesLabelId = useId();

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[646px]"
      removeScroll
    >
      <div className="flex gap-4 items-center justify-between mb-5">
        <Typography
          variant="h2"
          fontWeight="bold"
        >
          Activity Updates
        </Typography>
        <div className="flex items-center justify-center">
          <Checkbox
            checked={isSystemUpdates}
            onChange={(_, checked) => setIsSystemUpdates(checked)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
            id={systemUpdatesLabelId}
          />

          <FormLabel
            htmlFor={systemUpdatesLabelId}
            className="!text-textColor-primary !font-normal"
          >
            Show System Updates
          </FormLabel>
        </div>
      </div>

      {isLoading && <Spinner fallbackText="Loading updates, please wait..." />}

      <div
        className={clsx('flex flex-col gap-3 max-h-[80vh]', !!updates.length && 'overflow-y-auto')}
      >
        {updates.map((update, index, array) => (
          <div
            key={update.id}
            className="border rounded-lg p-3 flex flex-col gap-2 w-full"
          >
            <div className="flex gap-3 items-center">
              <div className="size-4 rounded-full bg-brandingColor-primary-gradient flex items-center justify-center">
                <Typography
                  variant="c2"
                  className="text-[10px] leading-3 font-semibold text-center text-white"
                >
                  {array.length - (index + 1)}
                </Typography>
              </div>

              <Typography
                variant="p2"
                fontWeight="bold"
                className="shrink-0"
              >
                {`Updated ${dayjs.utc(update.createdAt).tz(timeZone).format('M/D/YYYY HH:mm')}`}
              </Typography>
            </div>

            <Typography
              variant="p2"
              className="shrink-0"
            >
              <strong>Job Number: </strong>

              {`${update.jobNumber}`}
            </Typography>

            <Typography
              variant="p2"
              className="shrink-0"
            >
              <strong>Reported By: </strong>

              {`${update.reportedBy}`}
            </Typography>

            <div className="flex gap-2">
              {update.track !== 'Completed' && (
                <ActivityBadge
                  status={update.health}
                  onValueSelect={emptyWorkTypeSelect}
                  isBadge
                />
              )}

              <ActivityBadge
                status={update.track}
                onValueSelect={emptyWorkTypeSelect}
                isBadge
              />
            </div>

            <TaskManagementInfo
              activityName={update.name}
              provider={update.provider.ProviderName}
              crew={update.providerTeam.name}
              canShrink
            />

            <TaskTimeInfo
              endWork={dayjs.utc(update.endDate).tz(timeZone).toDate()}
              startWork={dayjs.utc(update.startDate).tz(timeZone).toDate()}
              isTimeColored
              timeZone={timeZone}
              hasOriginal={false}
            />

            <div className="flex h-max">
              <div className="w-2 rounded-l-lg shrink-0 bg-semanticColor-warning" />

              <div className="py-3 pr-1.5 pl-3 flex flex-1 flex-col gap-2 rounded-r-lg bg-bgColor-ticketCard border-y border-y-textColor-light border-r border-r-textColor-light">
                <div className="flex flex-1 flex-col gap-1">
                  <Typography
                    variant="c3"
                    className="text-xs leading-[18px]"
                  >
                    Job Notes:
                  </Typography>

                  <Typography
                    variant="c2"
                    className="text-xs leading-[18px] text-textColor-secondary"
                  >
                    {update.notes}
                  </Typography>
                </div>

                <div className="flex flex-1 flex-col gap-1">
                  <Typography
                    variant="c3"
                    className="text-xs leading-[18px]"
                  >
                    Job Delays:
                  </Typography>

                  <Typography
                    variant="c2"
                    className="text-xs leading-[18px] text-textColor-secondary"
                  >
                    {update.delayCategory?.DelayCategory}
                  </Typography>
                </div>

                <div className="flex flex-1 flex-col gap-1">
                  <Typography
                    variant="c3"
                    className="text-xs leading-[18px]"
                  >
                    Percent-Complete Estimate:
                  </Typography>

                  <Typography
                    variant="c2"
                    className="text-xs leading-[18px] text-textColor-secondary"
                  >
                    {`${update.progress || 0} %`}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ))}

        {!updates.length && !isLoading && (
          <div className="flex items-center justify-center h-40">
            <Typography
              variant="h3"
              className="text-center"
            >
              No System Updates Were Found
            </Typography>
          </div>
        )}
      </div>
    </Modal>
  );
};

export { UpdatesListModal };
