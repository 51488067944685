import { useSelector } from 'react-redux';
import { FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useGetPortalProviderSettingsQuery } from 'src/store/api';
import { selectCurrentUser } from 'src/store/slices';
import { useOutsideClick } from 'src/shared/hooks/useOutsideClick';
import {
  clsx,
  getAzureUrl,
  getIsPortalSectionItemVisible,
  getIsPortalSectionVisible,
  getIsSectionItemClickable,
  not,
} from 'src/shared/utils';
import { useMount } from 'src/shared/hooks/useMount';
import { Role } from 'src/shared/types';
import { useGetProviderQuery } from 'src/store/api/provider';

import { Typography } from '../typography';

import { NavigationLink } from './navigationLink';

type NavigationProps = {
  isOpen: boolean;
  toggleNavigation: () => void;
  alwaysOpen?: boolean;
  containerSelector?: string;
};

const Navigation: FC<NavigationProps> = ({
  isOpen,
  toggleNavigation,
  alwaysOpen = false,
  containerSelector,
}) => {
  const mounted = useMount(isOpen);

  const [exited, setExited] = useState(false);

  const navigationRef = useRef<HTMLDivElement>(null);

  const { data: configuration } = useGetPortalProviderSettingsQuery({});
  const { data: provider } = useGetProviderQuery('');

  const user = useSelector(selectCurrentUser);
  const userRole = user?.ProviderRoleMatrix.userRole as Role | undefined;

  const sections = userRole
    ? configuration?.ProviderPortalSections.map((section) => {
        return {
          ...section,
          isVisible: getIsPortalSectionVisible(section, userRole),
        };
      })
    : [];

  const visibleSections = sections?.filter((section) => section.isVisible);

  const navigationSections = visibleSections?.map((section, i) => (
    <div
      key={section.Title}
      className={clsx(
        'flex flex-col gap-3',
        i !== visibleSections.length - 1 &&
          'border-b-[outlineColor-input-border] border-b border-solid',
      )}
    >
      <Typography
        variant="p1"
        fontWeight="bold"
        className="text-textColor-tertiary"
      >
        {section.Title}
      </Typography>

      <div className="flex flex-col justify-between gap-2 pb-6">
        {section?.ProviderPortalSectionItems?.filter(
          (item) =>
            userRole &&
            getIsPortalSectionItemVisible({
              section,
              item,
              userRole,
            }),
        ).map(
          (option) =>
            userRole && (
              <NavigationLink
                disabled={!getIsSectionItemClickable(option, userRole)}
                key={option.Id}
                label={option.Title}
                description={option.HoverText}
                icon={getAzureUrl(provider?.FileRootPath, option.Icon)}
                link={option.Link}
                isAppLink={not(option.Link.includes('http'))}
                toggleNavigation={toggleNavigation}
              />
            ),
        )}
      </div>
    </div>
  ));

  useOutsideClick(navigationRef, toggleNavigation, isOpen);

  useEffect(() => {
    const timeout = setTimeout(() => setExited(isOpen), 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  if (not(mounted)) {
    return null;
  }

  const renderNavigation = () => {
    return (
      <div
        ref={navigationRef}
        className={clsx(
          'flex w-[274px] h-full bg-white overflow-y-auto overflow-x-hidden duration-200 translate-x-[-100%]',
          exited && 'translate-x-0',
        )}
      >
        <div className="flex flex-col gap-6 w-fit h-fit bg-textColor-white rounded-[20px] p-6">
          {navigationSections}
        </div>
      </div>
    );
  };

  const container = containerSelector ? document.querySelector(containerSelector)! : document.body;

  return (
    <>
      {createPortal(
        alwaysOpen ? (
          renderNavigation()
        ) : (
          <div className="absolute left-0 top-0 w-full h-full !z-[9999]">
            <div className="absolute w-full h-[calc(100%_-_72px)] mt-[72px] bg-[rgba(0,0,0,0.4)] z-[100]">
              {renderNavigation()}
            </div>
          </div>
        ),
        container,
      )}
    </>
  );
};

export { Navigation };
