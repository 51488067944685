import { Dispatch, FC, FormEvent, SetStateAction } from 'react';

import { SurveyEntity } from 'src/shared/types';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { useDeleteSurveyMutation } from 'src/store/api';

type DeleteFormModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  form?: SurveyEntity;
};

const DeleteFormModal: FC<DeleteFormModalProps> = ({ form, isOpen, setIsOpen }) => {
  const [deleteSurvey] = useDeleteSurveyMutation();

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      toggleModal(isOpen);

      await deleteSurvey(form!.id).unwrap();
    } catch (error) {
      showToastErrorMessage(`There was an error trying to delete ${form!.name}`);
    }
  };

  const buttons = (
    <div className="flex justify-end gap-2">
      <Button
        type="button"
        variant="outlined"
        color="basic"
        size="lg"
        onClick={closeModal}
      >
        Cancel
      </Button>

      <Button
        type="submit"
        color="danger"
        size="lg"
        autoFocus
      >
        Delete
      </Button>
    </div>
  );

  if (not(form)) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
    >
      <form
        className="flex flex-col justify-between gap-y-6  min-h-[368x] min-w-[451px] max-w-[451px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            Delete Form
          </Typography>

          <Typography
            variant="label"
            fontWeight="bold"
            className="flex gap-1"
          >
            {`Are you sure you want to delete - ${form.name} ?`}
          </Typography>

          {buttons}
        </div>
      </form>
    </Modal>
  );
};

export { DeleteFormModal };
