import { FC, PropsWithChildren, ReactElement } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { Icon } from 'src/shared/ui/icon';
import { clsx } from 'src/shared/utils';

type DropFieldProps = {
  title: string;
  isDropDisabled?: boolean;
  titleClasses?: string;
  iconClasses?: string;
  className?: string;
  iconWrapperClassName?: string;
  icon?: ReactElement;
};

const DropField: FC<PropsWithChildren<DropFieldProps>> = ({
  title,
  isDropDisabled,
  titleClasses,
  iconClasses,
  className,
  iconWrapperClassName,
  icon,
}) => {
  return (
    <div
      className={clsx(
        'z-[15] pointer-events-none cursor-default flex gap-x-2 items-center p-[8px] h-[56px] bg-[rgba(99,155,221,0.06)] rounded-lg border border-[rgba(18,60,142,0.2)] border-dashed',
        className,
        isDropDisabled && 'bg-[rgba(99,155,221,0.03)] border-[rgba(18,60,142,0.05)]',
      )}
    >
      <div className={clsx(iconWrapperClassName)}>
        {icon && (
          <Icon
            icon={icon}
            className={clsx(iconClasses || 'fill-textColor-tertiary')}
          />
        )}
      </div>

      <Typography
        variant="c1"
        className={clsx(titleClasses || 'text-textColor-tertiary')}
      >
        {title}
      </Typography>
    </div>
  );
};

export { DropField };
export type { DropFieldProps };
