import { FC, ReactNode } from 'react';
import { Model } from 'survey-core';
import { useNavigate } from 'react-router-dom';

import { BackButton } from 'src/shared/ui/backButton';
import { Button } from 'src/shared/ui/button';
import { Color as ButtonColor } from 'src/shared/ui/button/button.types';
import { SurveyEntity } from 'src/shared/types';
import { UseSetLocalStorageCallback } from 'src/shared/hooks/useLocalStorage';
import { useBreakpoints } from 'src/shared/hooks/useBreakpoints';

import { createSurveyPDFModel, generatePDFFileName } from '../utils';
import { IconButton } from 'src/shared/ui/iconButton';
import { Icons } from 'src/assets/icons';

type SurveyNavigationProps = {
  form: SurveyEntity;
  survey: Model;
  page: number;
  backButtonHref?: string;
  withCompleteButton?: boolean;
  hiddenClearFormButton?: boolean;
  backButtonHidden?: boolean;
  isFormSubmitted?: boolean;
  setLocalStorageFormData?: UseSetLocalStorageCallback<string | null>;
};

const SurveyNavigation: FC<SurveyNavigationProps> = ({
  form,
  survey,
  page,
  backButtonHref = '/forms',
  withCompleteButton = false,
  hiddenClearFormButton = false,
  backButtonHidden = false,
  isFormSubmitted = false,
  setLocalStorageFormData,
}) => {
  const navigate = useNavigate();

  const isLargeScreen = useBreakpoints('sm', 'min');

  const prevPage = () => {
    survey.prevPage();
  };

  const nextPage = () => {
    survey.nextPage();
  };

  const endSurvey = () => {
    survey.completeLastPage();
  };

  const renderButton = ({
    text,
    onClick,
    disabled,
    color = 'primary',
    node,
  }: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    color?: ButtonColor;
    node?: ReactNode;
  }) => {
    return isLargeScreen ? (
      <Button
        color={color}
        onClick={onClick}
        disabled={disabled}
        size="lg"
      >
        {text}
      </Button>
    ) : (
      <IconButton
        color={color}
        size="md"
        onClick={onClick}
        disabled={disabled}
      >
        {node}
      </IconButton>
    );
  };

  const clearForm = () => {
    if (setLocalStorageFormData) {
      setLocalStorageFormData(null);
    }
  };

  const handleNavigationClick = (href: string) => {
    navigate(href);
  };

  return (
    <div className="flex gap-4 px-4 flex-wrap">
      {!backButtonHidden && (
        <BackButton
          href={backButtonHref}
          handleNavigationClick={handleNavigationClick}
        />
      )}

      {withCompleteButton &&
        renderButton({
          text: 'Complete',
          onClick: endSurvey,
          disabled: page !== survey.visiblePages.length - 1 || isFormSubmitted,
          color: 'success',
          node: <Icons.Filled.Edit.CheckmarkIcon />,
        })}

      {renderButton({
        text: 'Save as PDF',
        onClick: () =>
          createSurveyPDFModel(form.content, survey).save(generatePDFFileName(form.name)),
        disabled: false,
        color: 'info',
        node: <Icons.Filled.Edit.SaveIcon />,
      })}

      {!hiddenClearFormButton &&
        renderButton({
          text: 'Clear Form',
          onClick: clearForm,
          disabled: isFormSubmitted,
          node: <Icons.Filled.Edit.TrashIcon />,
        })}

      <div className="flex gap-4">
        {renderButton({
          text: 'Previous Page',
          onClick: prevPage,
          disabled: page === 0 || isFormSubmitted,
          node: <Icons.Filled.Chevrons.ArrowIOSLeftIcon />,
        })}

        {renderButton({
          text: 'Next Page',
          onClick: nextPage,
          disabled: page === survey.visiblePages.length - 1 || isFormSubmitted,
          node: <Icons.Filled.Chevrons.ArrowIOSRightIcon />,
        })}
      </div>
    </div>
  );
};

export { SurveyNavigation };
