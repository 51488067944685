/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { Html5Qrcode } from 'html5-qrcode';
import { FC, useEffect, useState } from 'react';

import { ReactComponent as QRCodeIcon } from 'src/assets/icons/outlined/misc/qr-code.svg';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { not } from 'src/shared/utils';

const QRCodeScannerConfig = {
  fps: 5,
  qrbox: {
    width: 250,
    height: 250,
  },
  aspectRatio: 1.777778,
};
const QRCodeId = 'html5qr-code-full-region';

type QRCodeScannerProps = {
  onSuccess: (result: string) => void;
};

const QRCodeScanner: FC<QRCodeScannerProps> = ({ onSuccess }) => {
  const [isScanStarted, setIsScanStarted] = useState(false);
  const [isResultSent, setIsResultSent] = useState(false);

  let QRCodeScanner: Html5Qrcode;

  useEffect(() => {
    if (not(isScanStarted)) {
      return () => {};
    }

    if (not(QRCodeScanner?.getState())) {
      QRCodeScanner = new Html5Qrcode(QRCodeId);

      const onScanSuccess = (result: string) => {
        if (not(isResultSent)) {
          setIsResultSent(true);
          onSuccess(result);
          QRCodeScanner.clear();
        }
      };

      // * This function is empty because it will spam console with a few logs per second until we sucesfully scan QR CODE
      const onScanError = () => {};

      QRCodeScanner.start(
        { facingMode: 'environment' },
        QRCodeScannerConfig,
        onScanSuccess,
        onScanError,
      );
    }

    return () => {
      if (not(QRCodeScanner?.getState())) {
        try {
          QRCodeScanner.clear();
        } catch (error) {
          console.error(error);
        }
      }
    };
  }, [isScanStarted]);

  const scanDropfieldContent = (
    <div className="flex flex-col">
      {not(isResultSent) && (
        <div className="flex flex-col gap-y-2">
          <Typography
            variant="p1"
            fontWeight="bold"
          >
            Continue scanning until you see a message
          </Typography>

          <div id={QRCodeId} />
        </div>
      )}

      {isResultSent && (
        <Typography
          variant="p1"
          fontWeight="bold"
          className="text-semanticColor-success"
        >
          Succesfully scanned
        </Typography>
      )}
    </div>
  );

  return isScanStarted ? (
    scanDropfieldContent
  ) : (
    <Button
      color="primary"
      endIcon={<QRCodeIcon />}
      className="px-3"
      size="md"
      onClick={() => setIsScanStarted(true)}
    >
      Scan QR Code
    </Button>
  );
};

export { QRCodeScanner };
