import { useSelector } from 'react-redux';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
} from 'recharts';

import { ChartContainer } from 'src/shared/ui/chartContainer';
import { Typography } from 'src/shared/ui/typography';
import { useGetJobsQuery, useGetOwnersQuery } from 'src/store/api';
import { RootState } from 'src/store';
import { getFormattedDate } from 'src/shared/ui/details/helpers';
import { CustomBarChart } from 'src/shared/ui/customBarChart';
import {
  formatDate,
  formatTicketDate,
  getDatesBetween,
  getPaginationDays,
  not,
} from 'src/shared/utils';
import { CustomPieChart } from 'src/shared/ui/customPieChart';
import { exportToExcel } from 'src/shared/utils/excel';
import { Button } from 'src/shared/ui/button';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/filled/files/download.svg';
import { TicketEntity, TicketStatus } from 'src/shared/types';

const lineData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const DEFAULT_PIE_COLOR = '#99A1B0';

const PIE_COLOR = {
  'Ready to Dispatch': '#123C8E',
  'Scheduled': '#14B78B',
};

const Dashboard = () => {
  const { dates } = useSelector((state: RootState) => state.calendar);
  const { boardFilters, search } = useSelector((state: RootState) => state.config);

  const { data: owners = [] } = useGetOwnersQuery('');

  const [startDate, endDate] = getPaginationDays(dates);

  const {
    data = {
      jobs: [],
    },
    isFetching,
  } = useGetJobsQuery({
    filters: {
      ...boardFilters,
      startDate,
      endDate,
    },
    pagination: {
      search,
    },
  });

  const { jobs } = data;

  const selectedDays = getDatesBetween({
    startDate,
    endDate,
  }).map((date) => formatDate(date));

  const tickets = jobs
    .flatMap((job) => job.tickets)
    .filter((ticket) => selectedDays.includes(formatTicketDate(ticket.startTime)));

  const ticketsByOwner = owners.reduce<{
    [key in string]: TicketEntity[];
  }>((acc, el) => {
    return {
      ...acc,
      [el.OwnerName]: tickets.filter((ticket) => ticket.owner.OwnerName === el.OwnerName),
    };
  }, {});

  const barDataByDate = dates.map((date) => ({
    name: getFormattedDate(date),
    keys: Object.entries(ticketsByOwner).map(([owner, tickets]) => {
      return {
        key: owner,
        value: tickets.length
          ? tickets.filter((ticket) => formatTicketDate(ticket.startTime) === formatDate(date))
              .length
          : 0,
      };
    }),
  }));

  const barData = barDataByDate.map((el) => ({
    name: el.name,
    ...el.keys.reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value,
      }),
      {},
    ),
  }));

  const excelBarData = barData.map((el) => {
    const { name, ...rest } = el;

    return {
      Day: el.name,
      ...rest,
    };
  });

  const ticketStatuses = tickets.reduce((acc: { [key in string]: number }, el) => {
    const { status } = el;

    if (acc[status]) {
      acc[status] += 1;

      return acc;
    }

    acc[status] = 1;

    return acc;
  }, {});

  const pieData = Object.entries(ticketStatuses)
    .map((el) => {
      const [key, value] = el;

      return {
        name: key,
        value,
        color: PIE_COLOR[key as TicketStatus] || DEFAULT_PIE_COLOR,
      };
    })
    .sort((a, b) => b.value - a.value);

  const excelPieData = pieData.map((el) => ({
    'Status': el.name,
    'Number of Tickets': el.value,
  }));

  const ticketStatusesAmount = pieData.reduce((acc, el) => acc + el.value, 0);

  const isBarChartVisible = !!tickets.length && not(isFetching);
  const isPieChartVisible = !!tickets.length && not(isFetching);

  const getExcelButton = (data: any, title: string) => (
    <Button
      size="lg"
      variant="outlined"
      endIcon={<DownloadIcon fill="#231F20" />}
      className="text-[#636874] border-[#636874] flex !gap-3 !p-0"
      onClick={() => exportToExcel(data, title)}
    >
      Excel
    </Button>
  );

  return (
    <div className="w-full h-full bg-[#EFF0F4]">
      <div className="w-full h-full px-6 pt-[44px]">
        <Typography variant="h1">Dashboard</Typography>

        <ChartContainer
          title="Number of Tickets"
          containerClassName="w-full h-[610px] mt-[44px]"
          chartContainerClassName="h-[450px]"
          isLoading={isFetching}
          actions={getExcelButton(excelBarData, 'Number of Tickets')}
        >
          {isBarChartVisible && (
            <CustomBarChart
              data={barData}
              keys={owners.map((owner) => owner.OwnerName)}
            />
          )}
        </ChartContainer>

        <div className="flex gap-6 pb-[44px]">
          <ChartContainer
            title="Title"
            containerClassName="w-full h-[610px] mt-[44px]"
            chartContainerClassName="h-[450px]"
          >
            <ResponsiveContainer
              width="100%"
              height="100%"
            >
              <LineChart data={lineData}>
                <CartesianGrid strokeDasharray="1 1" />

                <XAxis dataKey="name" />

                <YAxis />

                <Tooltip />

                <Legend />

                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />

                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#82ca9d"
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>

          <ChartContainer
            title="Tickets Statuses"
            containerClassName="min-w-[768px] max-w-[768px] h-[610px] mt-[44px]"
            chartContainerClassName="h-[450px]"
            isLoading={isFetching}
            actions={getExcelButton(excelPieData, 'Tickets Statuses')}
          >
            {isPieChartVisible && (
              <CustomPieChart
                data={pieData}
                innerPrimaryText={ticketStatusesAmount}
                innerSecondaryText="Total"
              />
            )}
          </ChartContainer>
        </div>
      </div>
    </div>
  );
};

export { Dashboard };
