import {
  CopyTicket,
  EquipmentEntity,
  EquipmentTab,
  EquipmentTabType,
  PersonEntity,
  SortOrder,
  TicketEntity,
} from 'src/shared/types';
import { dayjs, formatDate, getDatesBetween, getPaginationDays, not } from 'src/shared/utils';
import { api } from 'src/store/api/api';
import { store } from 'src/store';

import { configActions } from '../slices';

import { jobsApi } from './jobs';
import { peopleApi } from './people';

enum TicketConnections {
  supervisor = 'supervisor',
  crewLeader = 'crew-leader',
  person = 'person',
  equipmentItem = 'equipment-item',
}

type CopyTicketPayload = {
  ticketSourceId: number;
  startDate: string;
  endDate: string;
  keepAssignments: boolean;
};

const ticketsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTickets: builder.query<
      {
        tickets: TicketEntity[];
        total: number;
        pageSize: number;
        totalPages: number;
      },
      {
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        sorting?: {
          key?: keyof TicketEntity | null;
          order?: SortOrder;
        };
      }
    >({
      query: ({ pagination, sorting }) => ({
        url: '/tickets',
        params: {
          pagination,
          sorting,
        },
      }),
      providesTags: ['Tickets'],
      transformResponse: (response: {
        data: {
          tickets: TicketEntity[];
          total: number;
          pageSize: number;
          totalPages: number;
        };
      }) => response.data,
    }),
    getTicketById: builder.query<TicketEntity, string>({
      query: (id) => ({
        url: `/tickets/${id}`,
      }),
      providesTags: ['Tickets'],
    }),
    addTicket: builder.mutation<TicketEntity, TicketEntity>({
      query: (ticket) => ({
        url: '/tickets',
        method: 'POST',
        body: {
          ...ticket,
          id: undefined,
          owner: ticket?.owner?.OwnerID,
          ownerContract: ticket?.ownerContract?.RateSheetID,
          ownerContact: ticket?.ownerContact?.OwnerPersonnelEmail,
          ownerRepresentative: ticket?.ownerRepresentative?.OwnerPersonnelEmail,
          ownerLocation: ticket?.ownerLocation?.OwnerLocationID,
          jobCategory: ticket?.jobCategory?.Id,
          equipmentKitID: ticket?.JELL?.EquipmentKitID,
        },
      }),
      async onQueryStarted(ticket, { queryFulfilled, dispatch }) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, search } = store.getState().config;

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              const job = jobs.find((job) => job.id === ticket.job);

              if (!job) return;

              job.tickets.push({
                ...ticket,
                startTime: ticket.startTime,
              } as TicketEntity);
            },
          ),
        );

        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags(['Tickets']));
          dispatch(api.util.invalidateTags(['Jobs']));
        } catch {
          dispatch(api.util.invalidateTags(['Jobs']));
        }
      },
    }),
    copyTicket: builder.mutation<CopyTicket, CopyTicketPayload>({
      query: ({ ticketSourceId, startDate, endDate, keepAssignments }) => ({
        url: '/tickets/copy',
        method: 'POST',
        body: {
          ticketSourceId,
          startDate,
          endDate,
          keepAssignments,
        },
      }),
      async onQueryStarted(
        { ticketSourceId, startDate: _startDate, endDate: _endDate },
        { queryFulfilled, dispatch },
      ) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, search } = store.getState().config;
        const dates = getDatesBetween({
          startDate: _startDate,
          endDate: _endDate,
        });

        const getCorrectDate = (ticketDate: string, incomingDate: string) => {
          const daysDifference = dayjs(formatDate(incomingDate)).diff(
            formatDate(ticketDate),
            'day',
          );

          if (daysDifference > 0) {
            return dayjs(ticketDate).add(daysDifference, 'day').toString();
          }
          return dayjs(ticketDate).subtract(Math.abs(daysDifference), 'day').toString();
        };

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              const job = jobs.find((job) =>
                job.tickets.some((ticket) => ticket.id === ticketSourceId),
              );
              const ticket = job?.tickets.find((ticket) => ticket.id === ticketSourceId);

              if (!job && !ticket) return;

              dates.forEach((date) => {
                job?.tickets.push({
                  ...ticket,
                  ticketNumber: '',
                  startDate: getCorrectDate(ticket?.startDate || '', date),
                  endDate: getCorrectDate(ticket?.endDate || '', date),
                  startTime: getCorrectDate(ticket?.startDate || '', date),
                  endTime: getCorrectDate(ticket?.endDate || '', date),
                } as TicketEntity);
              });
            },
          ),
        );

        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags(['Jobs']));
        } catch {
          dispatch(api.util.invalidateTags(['Jobs']));
        }
      },
    }),
    updateTicket: builder.mutation<TicketEntity, Partial<TicketEntity>>({
      query: (ticket) => ({
        url: `/tickets/${ticket.id}`,
        method: 'PATCH',
        body: {
          ...ticket,
          ownerContact: ticket?.ownerContact?.OwnerPersonnelEmail,
          ownerRepresentative: ticket?.ownerRepresentative?.OwnerPersonnelEmail,
          jobCategory: ticket?.jobCategory?.Id,
          equipmentKitID: ticket?.JELL?.EquipmentKitID,
        },
      }),
      async onQueryStarted(ticket, { queryFulfilled, dispatch }) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, search } = store.getState().config;

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              const job = jobs.find((job) => job.id === ticket.job);

              if (!job) {
                return;
              }

              job.tickets.splice(
                job.tickets.findIndex((el) => el.id === ticket.id),
                1,
                ticket as TicketEntity,
              );
            },
          ),
        );

        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags(['Jobs']));
        } catch {
          dispatch(api.util.invalidateTags(['Jobs']));
        }
      },
    }),
    updateTicketStatus: builder.mutation<TicketEntity, Partial<TicketEntity>>({
      query: (ticket) => ({
        url: `/tickets/${ticket.id}`,
        method: 'PATCH',
        body: {
          status: ticket.status,
          thisShiftTimeOnYard: ticket.thisShiftTimeOnYard,
        },
      }),
      async onQueryStarted(ticket, { queryFulfilled, dispatch }) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, search } = store.getState().config;

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              const job = jobs.find((job) => job.id === ticket.job);

              if (!job) {
                return;
              }

              const ticketIndex = job.tickets.findIndex((el) => el.id === ticket.id);
              const fullTicket = job.tickets.find((el, i) => i === ticketIndex);

              job.tickets.splice(ticketIndex, 1, {
                ...fullTicket,
                status: ticket.status,
              } as TicketEntity);
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(api.util.invalidateTags(['Jobs']));
        }
      },
    }),
    deleteTicket: builder.mutation<TicketEntity, string>({
      query: (id) => ({
        url: `/tickets/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { queryFulfilled, dispatch }) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, selectedWeek, search } = store.getState().config;

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              const job = jobs.find((job) => job.tickets.find((ticket) => ticket.id === id));

              if (!job) {
                return;
              }

              dispatch(
                configActions.changeRowHeight({
                  selectedWeek,
                  row: job.row || 0,
                  height: 0,
                }),
              );

              job.tickets.splice(
                job.tickets.findIndex((ticket) => ticket.id === id),
                1,
              );
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(api.util.invalidateTags(['Jobs']));
        }
      },
    }),
    assignToTicket: builder.mutation<
      TicketEntity,
      {
        ticketId: string;
        entityId: string;
        entityType: TicketConnections;
        sourceEntityId?: string;
        type?: EquipmentTabType;
      }
    >({
      query: ({ ticketId, entityId, entityType, sourceEntityId, type = EquipmentTab.owned }) => ({
        url: `/tickets/assign/${ticketId}/${entityType}`,
        method: 'PATCH',
        body: {
          entityId,
          sourceEntityId,
          equipmentType: type,
        },
      }),
      async onQueryStarted(ticketOptions, { queryFulfilled, dispatch }) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, search } = store.getState().config;

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              const { sidebarFilters } = store.getState().config;
              const { data: people = [] } = peopleApi.endpoints.getPeople.select({
                filters: sidebarFilters,
                pagination: {
                  search: '',
                },
              })(store.getState());
              const { ticketId, entityId, entityType, sourceEntityId, type } = ticketOptions;

              const job = jobs.find((job) => job.tickets.some((ticket) => ticket.id === ticketId));
              if (!job) return;

              const ticket = job.tickets.find((ticket) => ticket.id === ticketId);
              if (!ticket || !ticket.id) return;

              if (!ticket.id) return;

              const ticketIndex = job.tickets.findIndex((ticket) => ticket.id === ticketId);
              const personEntity = people.find((person) => person.id === entityId);

              const isAlreadyAssignedToLabors = ticket.labor.find(
                (employee) => employee.ProviderPersonnel?.id === entityId,
              );
              const isAlreadyAssignedToSupervisors = ticket.supervisors.find(
                (supervisor) => supervisor.id === entityId,
              );
              // | Last step is check if entity is already assigned to ticket.
              if (
                entityType === TicketConnections.person &&
                personEntity &&
                not(isAlreadyAssignedToLabors)
              ) {
                const indexToRemove = ticket.labor.findIndex(
                  (el) => el.PeopleWorkRecordID === sourceEntityId,
                );

                job.tickets.splice(ticketIndex, 1, {
                  ...ticket,
                  labor: [
                    ...ticket.labor.filter((_, index) => index !== indexToRemove),
                    {
                      ProviderPersonnel: personEntity as PersonEntity,
                      PeopleWorkRecordID: sourceEntityId as string,
                      ContractRateSheet: ticket.labor[indexToRemove].ContractRateSheet,
                      AcceptWork: ticket.labor[indexToRemove].AcceptWork,
                      Provider: ticket.labor[indexToRemove].Provider,
                    },
                  ],
                  crewLeader:
                    ticket?.crewLeader &&
                    ticket?.crewLeader?.ProviderPersonnel?.id === personEntity?.id
                      ? null
                      : ticket?.crewLeader,
                });
              }

              if (
                entityType === TicketConnections.supervisor &&
                personEntity &&
                !isAlreadyAssignedToSupervisors
              ) {
                job.tickets.splice(ticketIndex, 1, {
                  ...ticket,
                  crewLeader:
                    ticket?.crewLeader &&
                    ticket?.crewLeader?.ProviderPersonnel?.id === personEntity?.id
                      ? null
                      : ticket?.crewLeader,
                  supervisors: [
                    ...(ticket?.supervisors ? ticket.supervisors : []),
                    personEntity as PersonEntity,
                  ],
                });
              }

              const crewLeader = ticket?.labor.find(
                (employee) => employee?.PeopleWorkRecordID === sourceEntityId,
              );

              if (entityType === TicketConnections.crewLeader && personEntity && crewLeader) {
                job.tickets.splice(ticketIndex, 1, {
                  ...ticket,
                  crewLeader: {
                    ...crewLeader,
                    ProviderPersonnel: personEntity,
                  },
                  labor: ticket?.labor?.filter(
                    (employee) => employee.ProviderPersonnel?.id !== personEntity?.id,
                  ),
                  supervisors: ticket?.supervisors?.filter(
                    (supervisor) => supervisor.id !== personEntity?.id,
                  ),
                });
              }

              if (entityType === TicketConnections.equipmentItem) {
                const indexToRemove = ticket.equipment.findIndex(
                  (el) => el.KITItemsRecordID === sourceEntityId,
                );

                const isRental = type === EquipmentTab.rental;

                job.tickets.splice(ticketIndex, 1, {
                  ...ticket,
                  equipment: [
                    ...ticket.equipment.filter((_, index) => index !== indexToRemove),
                    {
                      UnitNumber: isRental ? null : entityId,
                      RentalUnitNumber: isRental ? entityId : null,
                      UnitNo: entityId,
                      AssetID: entityId,
                      IsRental: isRental,
                    } as EquipmentEntity,
                  ],
                });
              }
            },
          ),
        );

        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags(['People', 'Equipment', 'Tickets', 'Jobs']));
        } catch {
          dispatch(api.util.invalidateTags(['Jobs']));
        }
      },
    }),
    unassignFromTicket: builder.mutation<
      TicketEntity,
      {
        ticketId: string;
        entityId: string;
        entityType: TicketConnections;
        sourceEntityId?: string;
        type?: EquipmentTabType;
      }
    >({
      query: ({ ticketId, entityId, entityType, type = EquipmentTab.owned }) => ({
        url: `/tickets/unassign/${ticketId}/${entityType}`,
        method: 'PATCH',
        body: {
          entityId,
          equipmentType: type,
        },
      }),
      async onQueryStarted(ticketOptions, { queryFulfilled, dispatch }) {
        const [startDate, endDate] = getPaginationDays(store.getState().calendar.dates);
        const { boardFilters: filters, selectedWeek, search } = store.getState().config;

        dispatch(
          jobsApi.util.updateQueryData(
            'getJobs',
            {
              filters: {
                ...filters,
                startDate,
                endDate,
              },
              pagination: {
                search,
              },
            },
            ({ jobs }) => {
              const { ticketId, entityId, entityType, sourceEntityId } = ticketOptions;

              const job = jobs.find((job) => job.tickets.some((ticket) => ticket.id === ticketId));
              if (!job) return;

              const ticket = job.tickets.find((ticket) => ticket.id === ticketId);
              if (!ticket || !ticket.id) return;

              if (!ticket.id) return;

              dispatch(
                configActions.changeRowHeight({
                  selectedWeek,
                  row: job.row || 0,
                  height: 0,
                }),
              );

              const ticketIndex = job.tickets.findIndex((ticket) => ticket.id === ticketId);

              if (entityType === TicketConnections.person) {
                const indexToRemove = ticket.labor.findIndex(
                  (el) => el.PeopleWorkRecordID === sourceEntityId,
                );

                job.tickets.splice(ticketIndex, 1, {
                  ...ticket,
                  labor: [
                    ...ticket.labor.filter((_, index) => index !== indexToRemove),
                    {
                      ProviderPersonnel: undefined,
                      PeopleWorkRecordID: sourceEntityId as string,
                      ContractRateSheet: ticket.labor[indexToRemove].ContractRateSheet,
                      AcceptWork: ticket.labor[indexToRemove].AcceptWork,
                      Provider: ticket.labor[indexToRemove].Provider,
                    },
                  ],
                });
              }

              if (entityType === TicketConnections.equipmentItem) {
                const indexToRemove = ticket.equipment.findIndex((equipment) =>
                  equipment.IsRental
                    ? equipment.RentalUnitNumber === entityId
                    : equipment.UnitNumber === entityId,
                );

                const equipmentItem = ticket.equipment.find((equipment) =>
                  equipment.IsRental
                    ? equipment.RentalUnitNumber === entityId
                    : equipment.UnitNumber === entityId,
                );

                job.tickets.splice(ticketIndex, 1, {
                  ...ticket,
                  equipment: [
                    ...ticket.equipment.filter((_, index) => index !== indexToRemove),
                    {
                      ...equipmentItem,
                      RentalUnitNumber: '',
                      UnitNumber: '',
                      IsRental: false,
                    } as EquipmentEntity,
                  ],
                });
              }

              if (entityType === TicketConnections.supervisor) {
                job.tickets.splice(ticketIndex, 1, {
                  ...ticket,
                  supervisors: ticket?.supervisors?.filter(
                    (supervisor) => supervisor.id !== entityId,
                  ),
                });
              }

              if (entityType === TicketConnections.crewLeader && ticket.crewLeader) {
                job.tickets.splice(ticketIndex, 1, {
                  ...ticket,
                  labor: [...ticket.labor, ticket.crewLeader],
                  crewLeader: null,
                });
              }
            },
          ),
        );

        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags(['Tickets']));
          dispatch(api.util.invalidateTags(['Jobs']));
        } catch {
          dispatch(api.util.invalidateTags(['Jobs']));
        }
      },
    }),
  }),
});

export const {
  useGetTicketsQuery,
  useLazyGetTicketsQuery,
  useGetTicketByIdQuery,
  useAddTicketMutation,
  useCopyTicketMutation,
  useUpdateTicketMutation,
  useUpdateTicketStatusMutation,
  useDeleteTicketMutation,
  useAssignToTicketMutation,
  useUnassignFromTicketMutation,
} = ticketsApi;

export { TicketConnections };
