import dayjs from 'dayjs';
import { FC, useCallback, useMemo, useState } from 'react';

import { ActionState } from 'src/shared/types/actions';
import { Modal } from 'src/shared/ui/modal';
import { Table } from 'src/shared/ui/table';
import { Typography } from 'src/shared/ui/typography';
import { DATE_FORMAT } from 'src/shared/utils';
import { useGetActionByIdUpdatesQuery } from 'src/store/api';
import { PageSize, SortOrder } from 'src/shared/types';

import { headersHistoryList } from '../../helpers/constants';

interface HistoryListActionModalProps {
  isOpen: boolean;
  closeModal: () => void;
  idForHistoryList: string;
}

const HistoryListActionModal: FC<HistoryListActionModalProps> = ({
  isOpen,
  closeModal,
  idForHistoryList,
}) => {
  const toggleModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const [sortSettings, setSortSettings] = useState<{
    key: keyof ActionState | null;
    order: SortOrder;
  }>({
    key: null,
    order: SortOrder.ASC,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>(15);

  const handleChangeSortSettings = (newSort: {
    key: keyof ActionState | null;
    order: SortOrder;
  }) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setSortSettings(newSort);
  };

  const handlePageSizeChange = (size: PageSize) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    setPageSize(size);
  };

  const {
    data: actionData,
    isFetching,
    isLoading,
    isError,
  } = useGetActionByIdUpdatesQuery(
    {
      id: idForHistoryList,
      sorting: sortSettings,
      pagination: {
        page: `${currentPage}`,
        pageSize: `${pageSize}`,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const totalActions = useMemo(() => {
    return actionData?.totalActions || 0;
  }, [actionData]);

  const tableData = useMemo(() => {
    return (actionData?.actionList || []).map((action) => ({
      id: action.id,
      createdAt: action.createdAt,
      actionId: action.actionId,
      status: action.status || '',
      assignedTo: action.assignedTo || '',
      updatedAt: dayjs(action.updatedAt).format(DATE_FORMAT.DEFAULT_ADMIN_PAGE),
      notes: action.notes || '',
      updatedBy: action.updatedBy || '',
    }));
  }, [actionData]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[calc(100%-30px)] md:w-[646px] lg:w-[990px] overflow-y-auto"
      removeScroll
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        History List
      </Typography>

      <Table
        headers={headersHistoryList}
        data={tableData}
        isFetching={isFetching}
        isLoading={isLoading}
        sortSettings={sortSettings}
        onSort={handleChangeSortSettings}
        withIndicator
        variant="secondary"
        containerClassName="h-full max-h-[300px] md:max-h-[400px] lg:max-h-[600px]"
        sticky
        scroll
        isError={isError}
        pagination={{
          currentPage,
          onPageChange: (page: number) => setCurrentPage(page),
          onPageSizeChange: handlePageSizeChange,
          pageSize,
          total: totalActions,
        }}
      />
    </Modal>
  );
};

export { HistoryListActionModal };
