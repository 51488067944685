import { FC, useEffect, useState } from 'react';

import { useToggle } from 'src/shared/hooks/useToggle';
import { Typography } from 'src/shared/ui/typography';
import { Icon } from 'src/shared/ui/icon';
import { Icons } from 'src/assets/icons';
import { Tag } from 'src/shared/ui/tag';
import { IconButton } from 'src/shared/ui/iconButton';
import { showToastErrorMessage } from 'src/shared/utils';

import { SignaturePopup, SignaturePopupProps } from '../signaturePopup';

import { EmployeeMeta, EmployeeWithMeta } from './types';

type SignatureListProps = Pick<SignaturePopupProps, 'onSubmit' | 'metadataKey'> & {
  employeesRef: React.MutableRefObject<EmployeeWithMeta[]>;
  unsetPerson: (personId: string) => void;
};

const SignatureList: FC<SignatureListProps> = ({
  employeesRef,
  metadataKey,
  onSubmit: passedOnSubmit,
  unsetPerson,
}) => {
  const [isOpen, toggleOpen, setOpen] = useToggle(false);
  const [employeesMeta, setEmployeesMeta] = useState<EmployeeMeta[]>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>();

  useEffect(() => {
    setEmployeesMeta(
      employeesRef.current.map(({ id, firstName, lastName, ...meta }) => {
        const isSigned = !!meta[metadataKey]?.signature;
        const isLocationCaptured = !!meta[metadataKey]?.locationTimestamp;
        const isSubmitted = isSigned && isLocationCaptured;

        return {
          id,
          firstName,
          lastName,
          isSubmitted,
        };
      }),
    );
  }, [metadataKey, employeesRef]);

  const updateEmployee = (id: string, data: Partial<Omit<EmployeeWithMeta, 'id'>>) => {
    setEmployeesMeta((prevState) => {
      const selectedEmployeeIndex = prevState.findIndex(({ id: employeeId }) => employeeId === id);

      const updatedEmployee = {
        ...data,
        id,
      };

      if (selectedEmployeeIndex === -1) {
        return [...prevState, updatedEmployee];
      }

      prevState.splice(selectedEmployeeIndex, 1, updatedEmployee);

      return prevState;
    });
  };

  const handleModalOpen = (id: string) => {
    setSelectedEmployeeId(id);
    setOpen(true);
  };

  const closeModal = () => {
    setSelectedEmployeeId(undefined);
    setOpen(false);
  };

  const handleSubmit = (...args: Parameters<typeof passedOnSubmit>) => {
    if (!selectedEmployeeId) {
      showToastErrorMessage('There is no selected employee');
      return;
    }

    updateEmployee(selectedEmployeeId, { isSubmitted: true });
    closeModal();

    passedOnSubmit(...args);
  };

  const getTagProps = (isSubmitted?: boolean) => {
    if (isSubmitted) {
      return {
        type: 'success',
        startIcon: <Icons.Filled.Edit.CheckmarkCircle />,
        text: 'Signed',
      } as const;
    }

    return {
      type: 'light',
      startIcon: <Icons.Filled.Misc.Clock />,
      text: 'Pending',
    } as const;
  };

  const employeesWithMeta = employeesRef.current.map((employee) => {
    const meta = employeesMeta.find(({ id }) => id === employee.id);

    return {
      ...employee,
      ...meta,
    };
  });

  const selectedEmployeeWithMeta = employeesWithMeta.find(({ id }) => id === selectedEmployeeId);

  return (
    <div className="min-w-max flex flex-col gap-y-2">
      {employeesWithMeta.map(({ id, firstName, lastName, isSubmitted }) => (
        <button
          key={id}
          type="button"
          onClick={() => handleModalOpen(id)}
          className="px-3 py-2 flex justify-between items-center gap-x-6 border rounded-lg border-outlineColor-input-border bg-white"
        >
          <div className="flex flex-col-reverse md:w-full md:justify-between md:flex-row gap-y-2">
            <Typography
              variant="p1"
              fontWeight="medium"
            >
              {`${firstName} ${lastName} (${id})`}
            </Typography>

            <Tag
              startIconProps={{ size: 'xs' }}
              {...getTagProps(isSubmitted)}
            />
          </div>

          <div className="flex gap-x-2">
            <IconButton onClick={() => unsetPerson(id)}>
              <Icon icon={<Icons.Outlined.Account.PersonRemove />} />
            </IconButton>

            <IconButton>
              <Icon icon={<Icons.Filled.Chevrons.ChevronRight />} />
            </IconButton>
          </div>
        </button>
      ))}

      {selectedEmployeeWithMeta && (
        <SignaturePopup
          open={isOpen}
          employee={selectedEmployeeWithMeta}
          metadataKey={metadataKey}
          toggleModal={toggleOpen}
          onSubmit={handleSubmit}
          onCancel={closeModal}
        />
      )}
    </div>
  );
};

export { SignatureList, type SignatureListProps };
