import { FC, useState } from 'react';

import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { useGetPeopleQuery } from 'src/store/api';
import { PersonEntity } from 'src/shared/types';

type SurveySelectProps = {
  onChange: ({
    id,
    firstName,
    lastName,
  }: {
    id: string;
    firstName: string;
    lastName: string;
  }) => void;
  onClear: () => void;
};

const SurveySelect: FC<SurveySelectProps> = ({ onChange, onClear }) => {
  const { data: people = [] } = useGetPeopleQuery({});
  const [selectedPerson, setSelectedPerson] = useState<string>('');

  const handleClear = () => {
    onClear();
    setSelectedPerson('');
  };

  return (
    <SelectInput
      name="selectedPerson"
      value={selectedPerson}
      label=""
      placeholder="Select person"
      onClear={handleClear}
      items={
        people.map((el) => {
          const { id, firstName, lastName } = people.find(
            (person) => person.id === el.id,
          ) as PersonEntity;

          const formattedPerson = `${firstName} ${lastName} (${id})`;

          return {
            label: (
              <SelectInputItem selected={selectedPerson === formattedPerson}>
                {formattedPerson}
              </SelectInputItem>
            ),
            value: formattedPerson,
            onClick: () => {
              setSelectedPerson(formattedPerson);
              onChange({
                id,
                firstName,
                lastName,
              });
            },
          };
        }) ?? []
      }
    />
  );
};

export { SurveySelect };
