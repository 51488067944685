import { FC, useEffect, useMemo, useState } from 'react';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import { ElementFactory } from 'survey-core';
import { useNavigate, useParams } from 'react-router-dom';

import { useCreateSurveyMutation, useEditSurveyMutation, useGetSurveyQuery } from 'src/store/api';
import { Spinner } from 'src/shared/ui/spinner';
import { showToastErrorMessage } from 'src/shared/utils';
import { BackButton } from 'src/shared/ui/backButton';

import { NavigationWarningModal } from './Features';

import {
  QuestionQRCodeModel,
  QuestionGeolocationModel,
  QuestionMultiSignatureModel,
  QuestionSurveySelectModel,
  QuestionSurveyMultiSelectModel,
  MULTI_SIGNATURE_TYPE,
} from './customFormQuestions';

import { surveyCreatorConfig } from './Features/surveyCreatorConfig';

import './style.css';

ElementFactory.Instance.registerElement('qrcode', (name) => {
  return new QuestionQRCodeModel(name);
});

ElementFactory.Instance.registerElement('geolocation', (name) => {
  return new QuestionGeolocationModel(name);
});

ElementFactory.Instance.registerElement(MULTI_SIGNATURE_TYPE, (name) => {
  return new QuestionMultiSignatureModel(name);
});

ElementFactory.Instance.registerElement('surveySelect', (name) => {
  return new QuestionSurveySelectModel(name);
});

ElementFactory.Instance.registerElement('surveyMultiSelect', (name) => {
  return new QuestionSurveyMultiSelectModel(name);
});

type SurveyFormCreatorType = 'create' | 'edit';

type SurveyFormCreatorProps = {
  type: SurveyFormCreatorType;
};

const SurveyFormCreator: FC<SurveyFormCreatorProps> = ({ type }) => {
  const navigate = useNavigate();
  const { formId } = useParams();

  const { data: form, isFetching: isLoadingForm } = useGetSurveyQuery(formId || '', {
    refetchOnMountOrArgChange: true,
  });

  const [createSurvey, { isLoading: isCreating }] = useCreateSurveyMutation();
  const [editSurvey, { isLoading: isEditing }] = useEditSurveyMutation();

  const [isNavigationWarningModalOpen, setIsNavigationWarningModalOpen] = useState(false);

  const isSubmitting = isCreating || isEditing;

  const creator = useMemo(() => {
    return new SurveyCreator(surveyCreatorConfig);
  }, []);

  creator.saveSurveyFunc = async (saveNo: any, callback: any) => {
    const newForm = {
      name: creator.JSON.title,
      content: creator.JSON,
    };

    if (type === 'create') {
      try {
        await createSurvey(newForm).unwrap();
      } catch (error) {
        showToastErrorMessage('There was an error trying to create form');
      }
    }

    if (type === 'edit' && form) {
      try {
        await editSurvey({
          id: form.id,
          ...newForm,
        }).unwrap();
      } catch (error) {
        showToastErrorMessage('There was an error trying to update form');
      }
    }

    callback(saveNo, true);
    navigate('/forms');
  };

  const navigateTo = (href: string) => {
    navigate(href);
  };

  const handleNavigationClick = (href: string) => {
    const formData = creator.JSON;

    const hasStartedCreatingForm =
      formData && Object.prototype.hasOwnProperty.call(formData, 'pages');

    if (hasStartedCreatingForm) {
      setIsNavigationWarningModalOpen(true);
    } else {
      navigateTo(href);
    }
  };

  useEffect(() => {
    creator.text = form ? JSON.stringify(form.content) : JSON.stringify({});
  }, [creator, form]);

  if (isLoadingForm) {
    return <Spinner withBackdrop />;
  }

  return (
    <>
      {isSubmitting && <Spinner withBackdrop />}

      <div className="flex flex-col gap-y-4 w-full pt-4">
        <BackButton
          href="/forms"
          className="ml-7"
          handleNavigationClick={handleNavigationClick}
        />

        <div className="flex w-full h-full">
          <SurveyCreatorComponent creator={creator} />
        </div>

        <NavigationWarningModal
          isOpen={isNavigationWarningModalOpen}
          setIsOpen={setIsNavigationWarningModalOpen}
          navigateTo={navigateTo}
          href="/forms"
        />
      </div>
    </>
  );
};

export { SurveyFormCreator };
