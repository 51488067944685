import { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FormControlLabel } from '@mui/material';

import { Checkbox } from 'src/shared/ui/checkbox';
import {
  TypeNotation,
  RelationOptionsMap,
  MetadataMap,
  RelationOption,
  TagType,
  CustomComponentName,
  SurveyEntity,
  TableManagerFieldValue,
} from 'src/shared/types';
import { DATE_FORMAT } from 'src/shared/utils';
import { MultiSelectAutocomplete } from 'src/shared/ui/multiSelectAutocomplete';
import { BasicSelectAutocomplete } from 'src/shared/ui/basicSelectAutocomplete';
import { DateTimeControl } from 'src/shared/ui/dateTimeControl';
import { InputField } from 'src/shared/ui/inputField';

import { DataGridContainerField } from '../DataGridContainerField';
import { AdminSurveyFormNavigator } from '../AdminSurveyFormNavigator';
import { FileUploadDialog } from '../FileUploadDialog';
import { DocumentTable } from '../DocumentTable';

interface IDetailsFieldProps {
  schemaElement: TypeNotation;
  relationOptions: RelationOptionsMap;
  selectedRow: Record<string, TableManagerFieldValue>;
  fieldsMetadata: MetadataMap;
  idField: string;
}

const DetailsField: FC<IDetailsFieldProps> = ({
  schemaElement,
  relationOptions,
  selectedRow,
  fieldsMetadata,
  idField,
}) => {
  const { field, type, values = [] } = schemaElement;
  const { modelName } = useParams<{ modelName: TagType }>();

  const isCustomComponent =
    modelName && fieldsMetadata?.customComponents?.[modelName]?.[field]?.field === field;

  const label = fieldsMetadata?.headerNames?.[field]?.name ?? field;

  const modelNameData =
    modelName && fieldsMetadata?.customComponents?.[modelName]?.[field]?.settings?.modelName;

  const customComponents: { [key in CustomComponentName]?: ReactElement | null } = {
    AdminSurveyFormNavigator: isCustomComponent ? (
      <AdminSurveyFormNavigator
        items={selectedRow[field] as Pick<SurveyEntity, 'id' | 'name'>[]}
        state={selectedRow}
        label={label}
      />
    ) : null,
    DataGridContainerField:
      isCustomComponent && modelNameData ? (
        <DataGridContainerField
          modelName={modelNameData}
          state={selectedRow}
          parentIdField={idField}
        />
      ) : null,
    FileUploadDialog:
      isCustomComponent && modelName ? (
        <FileUploadDialog
          relationOptions={relationOptions}
          modelName={modelName}
          schemaElement={schemaElement}
          fieldsMetadata={fieldsMetadata}
          state={selectedRow}
          idField={idField}
        />
      ) : null,
    DocumentTable:
      isCustomComponent && modelName ? (
        <DocumentTable
          state={selectedRow}
          modelName={modelName}
          idField={idField}
        />
      ) : null,
  };

  if (isCustomComponent) {
    const componentName = fieldsMetadata?.customComponents?.[modelName]?.[field]
      .componentName as CustomComponentName;

    const SelectedComponent = customComponents[componentName];

    if (SelectedComponent) {
      return <Box>{SelectedComponent}</Box>;
    }
  }

  if (
    field in relationOptions &&
    fieldsMetadata?.multiSelectFields?.includes(field) &&
    Array.isArray(selectedRow[field])
  ) {
    const currentValues = selectedRow[field] as string[];
    const currentOptions = Array.isArray(currentValues)
      ? relationOptions[field].filter((el) => currentValues.includes(el.value))
      : [];

    return (
      <MultiSelectAutocomplete
        items={relationOptions[field]}
        selectedItems={currentOptions}
        label={label}
        readOnly
      />
    );
  }

  if (field in relationOptions) {
    const selectedValue = relationOptions[field].find((el) => el.value === selectedRow[field]);

    return (
      <BasicSelectAutocomplete
        items={relationOptions[field]}
        selectedItem={selectedValue as RelationOption}
        label={label}
        readOnly
      />
    );
  }

  if (type === 'enum' && values) {
    return (
      <BasicSelectAutocomplete
        items={values}
        selectedItem={selectedRow[field] as string}
        label={label}
        readOnly
      />
    );
  }

  if (typeof relationOptions[field] === 'boolean') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedRow[field] as boolean}
            readOnly
          />
        }
        label={label}
      />
    );
  }

  if (type === 'date') {
    const dateFormat =
      fieldsMetadata?.fieldsConfirurations?.[field]?.dateFormat || DATE_FORMAT.DEFAULT_ADMIN_PAGE;

    const includeTime = !!fieldsMetadata?.fieldsConfirurations?.[field]?.dateFormat;

    return (
      <DateTimeControl
        value={selectedRow[field] as string}
        label={label}
        dateFormat={dateFormat}
        includeTime={includeTime}
        readOnly
      />
    );
  }

  if (type === 'string') {
    return (
      <InputField
        type={type}
        name={field}
        label={label}
        defaultValue={(selectedRow[field] as string) || ''}
        InputProps={{
          readOnly: true,
        }}
      />
    );
  }

  if (type === 'number') {
    return (
      <InputField
        type={type}
        name={field}
        label={label}
        defaultValue={(selectedRow[field] as string) || ''}
        InputProps={{
          readOnly: true,
        }}
      />
    );
  }

  // TODO: Change on MUI component
  // eslint-disable-next-line etc/no-commented-out-code
  // if (type === 'array' && isStringArray(selectedRow[field])) {
  //   const items: string[] = selectedRow[field] as string[];

  //   return (
  //     <div className={clsx('flex flex-col gap-y-2 w-full')}>
  //       <TextField
  //         label={label}
  //         value=""
  //         onChange={() => {}}
  //         startIcon={
  //           <DropDown
  //             options={{ placement: 'bottom-start' }}
  //             config={{
  //               closeOnSelect: false,
  //               focusLastElement: false,
  //               itemsElementClassName: (isOpen) =>
  //                 clsx(
  //                   'shadow-[0px_2px_66px_-10px_#0000000F]',
  //                   'rounded-lg',
  //                   'bg-[#F3F6FA]',
  //                   'w-full',
  //                   'p-4',
  //                   !isOpen && 'p-0 border-0',
  //                 ),
  //             }}
  //             renderElement={(isOpen) => (
  //               <div className="absolute left-1 w-[40px] flex justify-center items-center">
  //                 <Icons.Outlined.Chevrons.ArrowUpIcon
  //                   className={clsx(
  //                     'fill-[#2E3A59] transition-all cursor-pointer',
  //                     isOpen ? 'rotate-0' : 'rotate-180',
  //                   )}
  //                 />
  //               </div>
  //             )}
  //             items={items.map((item) => ({
  //               value: item,
  //               label: (
  //                 <DropDownItemMenu titleClassName="text-textColor-tertiary">
  //                   {item}
  //                 </DropDownItemMenu>
  //               ),
  //             }))}
  //           />
  //         }
  //         endIcon={
  //           <div className="absolute right-1">
  //             <Typography
  //               variant="h3"
  //               className="flex items-center justify-center size-[40px] text-semanticColor-success"
  //             >
  //               {items.length}
  //             </Typography>
  //           </div>
  //         }
  //       />
  //     </div>
  //   );
  // }

  return null;
};

export { DetailsField };
