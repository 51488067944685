import { FC, memo, useCallback } from 'react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import clsx from 'clsx';

import { dayjs } from 'src/shared/utils';
import { TimePickerInput } from 'src/shared/ui/timePickerInput';
import { MuiDateTimePicker, MuiDateTimePickerProps } from 'src/shared/ui/muiDateTimePicker';

import { formatISODurationToHHmm, getISODurationFromHHmm } from '../../helpers';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

type DueDateRangePickerValues = {
  delayStart: string;
  delayEnd: string;
  duration: string;
};

type DateRangeLabels = {
  startTime?: string;
  duration?: string;
  endTime?: string;
};

type DueDateRangePickerProps = {
  values: DueDateRangePickerValues;
  timeZone: string;
  setFieldValue: (field: keyof DueDateRangePickerValues, value: any) => void;
  labels: DateRangeLabels;
  className?: string;
  errors?: Partial<Record<keyof DueDateRangePickerValues, string>>;
} & Omit<MuiDateTimePickerProps, 'value' | 'onChange' | 'error'>;

const defaultLabels: Required<DateRangeLabels> = {
  duration: 'Duration',
  endTime: 'End Time',
  startTime: 'Start Time',
};

const DelayDueDateRangePicker: FC<DueDateRangePickerProps> = ({
  values,
  timeZone,
  setFieldValue,
  labels,
  className,
  errors,
  ...restPickerProps
}) => {
  const onStartTimeSelect = useCallback(
    (newStartTimeValue: string | null) => {
      if (values.duration && newStartTimeValue) {
        const duration = dayjs.duration(values.duration);
        const newDateEnd = dayjs.utc(newStartTimeValue).tz(timeZone).add(duration);
        setFieldValue('duration', newDateEnd.toISOString());
      } else if (values.delayEnd && newStartTimeValue) {
        const newDuration = dayjs
          .duration(dayjs(values.delayEnd).diff(dayjs(newStartTimeValue)))
          .toISOString();
        setFieldValue('duration', `${newDuration}`);
      }
      setFieldValue('delayStart', newStartTimeValue || '');
    },
    [values.duration, values.delayEnd, setFieldValue, timeZone],
  );

  const onDurationSelect = useCallback(
    (name: string, value: string) => {
      if (!value) return;
      const duration = dayjs.duration(getISODurationFromHHmm(value));
      if (values.delayStart) {
        const newDateEnd = dayjs.utc(values.delayStart).tz(timeZone).add(duration).toISOString();
        if (newDateEnd !== values.delayEnd) {
          setFieldValue('delayEnd', newDateEnd);
        }
      } else if (values.delayEnd) {
        const newStartDate = dayjs
          .utc(values.delayEnd)
          .tz(timeZone)
          .subtract(duration)
          .toISOString();
        if (newStartDate !== values.delayStart) {
          setFieldValue('delayStart', newStartDate);
        }
      }
      setFieldValue('duration', duration.toISOString());
    },
    [values.delayStart, values.delayEnd, setFieldValue, timeZone],
  );

  const onEndTimeSelect = useCallback(
    (newEndTimeValue: string | null) => {
      if (values.delayStart && newEndTimeValue) {
        const formattedEndTime = dayjs.utc(newEndTimeValue).tz(timeZone);
        const formattedStartTime = dayjs.utc(values.delayStart).tz(timeZone);
        const newDuration = dayjs.duration(formattedEndTime.diff(formattedStartTime)).toISOString();
        setFieldValue('duration', `${newDuration}`);
      } else if (values.duration && newEndTimeValue) {
        const duration = dayjs.duration(values.duration);
        const newStartDate = dayjs
          .utc(newEndTimeValue)
          .tz(timeZone)
          .subtract(duration)
          .toISOString();
        setFieldValue('delayStart', newStartDate);
      }
      setFieldValue('delayEnd', newEndTimeValue || '');
    },
    [values.delayStart, values.duration, setFieldValue, timeZone],
  );

  return (
    <>
      <div className={clsx('flex flex-col md:flex-row gap-x-8', className)}>
        <MuiDateTimePicker
          value={values.delayStart || null}
          onChange={onStartTimeSelect}
          isRequired
          label={labels?.startTime || defaultLabels.startTime}
          error={errors?.delayStart}
          className="shrink-1"
          {...restPickerProps}
        />

        <MuiDateTimePicker
          value={values.delayEnd || null}
          onChange={onEndTimeSelect}
          isRequired
          label={labels?.endTime || defaultLabels.endTime}
          error={errors?.delayEnd}
          className="shrink-1"
          {...restPickerProps}
        />
      </div>

      <TimePickerInput
        isRequired
        required
        name="duration"
        label={labels?.duration || defaultLabels.duration}
        handleSelect={onDurationSelect}
        onClear={() => setFieldValue('duration', '')}
        value={values.duration ? formatISODurationToHHmm(values.duration) : ''}
        placeholder="Duration..."
        className="shrink-0 w-max"
      />
    </>
  );
};

const memoizedDueDateRangePicker = memo(DelayDueDateRangePicker);

export { memoizedDueDateRangePicker as DelayDueDateRangePicker };
