import { ActivityHealthEnum, ActivityTrack, EnumActivityTrack } from 'src/shared/types';

export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const ONE_HOUR_WIDTH = 33;
export const ONE_HOUR_DAY_WIDTH = 61;
export const ONE_HOUR_WIDTH_BY_MINUTES = ONE_HOUR_WIDTH / MINUTES_IN_HOUR;
export const ONE_HOUR_DAY_WIDTH_BY_MINUTES = ONE_HOUR_DAY_WIDTH / MINUTES_IN_HOUR;
export const HOURS_IN_COLUMN = 3;
export const CARD_SPREAD_BUTTON_RADIUS = 16;
export const CARD_UPDATE_ICON_SIZE = 20;
export const MAX_CARD_Z_INDEX = 997;
export const HEADER_HEIGHT = 72;
export const DATES_VERTICAL_GAP = 12;
export const DATES_TOP_SPACE = 12;
export const DAY_TITLE_HEIGHT = 24;
export const DAY_PADDING_BOTTOM = 56;
export const POLLING_INTERVAL_ACTIVITIES = 60000;
export const DASHBOARD_BRUSH_VALUE = 4;
export const REPORT_TABLE_PAGINATION_HEIGHT = 50;
export const REPORT_TABLE_BOTTOM_SPACE = 16;

export const DEFAULT_SELECT_VALUE = {
  name: '',
  id: '',
};

export const DEFAULT_LOCATION_TIME_ZONE = 'America/Chicago';
export const DEFAULT_OWNER_LOCATION_ID = '1';

export const ACTIVITY_STATUSES: ActivityTrack[] = ['NotStarted', 'InProgress', 'Completed'];

export const GRAPH_COLORS = [
  '#F57024',
  '#4769BA',
  '#A663CC',
  '#D7B166',
  '#4A4766',
  '#6C809A',
  '#00C2B8',
  '#006D77',
  '#9E2A2B',
];

export const GRAPH_TRACK_COLORS = {
  [EnumActivityTrack.NotStarted]: '#93B700',
  [EnumActivityTrack.InProgress]: '#0390F4',
  [EnumActivityTrack.Completed]: '#14B78B',
};

export const XLSX_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const ACTIVITY_HEALTH_BG_COLORS = {
  [ActivityHealthEnum.Scheduled]: 'bg-gray-500',
  [ActivityHealthEnum.Delayed]: 'bg-semanticColor-warning',
  [ActivityHealthEnum.OffTrack]: 'bg-semanticColor-danger',
  [ActivityHealthEnum.OnTrack]: 'bg-[#14B78B]',
};
