import { FC } from 'react';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { Typography } from 'src/shared/ui/typography';
import { not } from 'src/shared/utils';

const CustomTooltip: FC<TooltipProps<ValueType, NameType>> = ({ active, payload, label }) => {
  const isTooltipVisible = active && payload && payload.length;

  if (not(isTooltipVisible)) return null;

  const totalNumberOfTickets = payload?.reduce((acc, el) => acc + Number(el?.value || 0), 0);

  return (
    <div className="relative">
      <div className="flex flex-col gap-5 p-4 bg-white border border-outlineColor-input-border rounded-lg z-[1000] relative">
        <div className="flex justify-between">
          <Typography
            variant="p2"
            fontWeight="bold"
            className="text-textColor-secondary"
          >
            {label}
          </Typography>

          <Typography
            variant="p2"
            fontWeight="bold"
            className="text-textColor-secondary"
          >
            {`Total: ${totalNumberOfTickets} ticket(s)`}
          </Typography>
        </div>

        <div className="flex flex-col gap-4">
          {payload?.map((item) => (
            <div
              key={item.dataKey}
              className="flex items-center justify-between gap-2"
            >
              <div className="flex items-center gap-2">
                <div
                  className="w-4 h-4 rounded-full"
                  style={{ background: item.color }}
                />

                <Typography
                  variant="p1"
                  fontWeight="bold"
                >
                  {item.dataKey}
                </Typography>
              </div>

              <Typography
                variant="p1"
                fontWeight="bold"
                className="pl-4"
              >
                {item.value}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      <div className="absolute left-[-5px] top-[50%] w-[26px] h-[26px] bg-white border border-outlineColor-input-border transform translate-y-[-50%] rotate-45 z-[1]" />
    </div>
  );
};

export { CustomTooltip };
