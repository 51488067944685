import { FC } from 'react';
import { useDrop } from 'react-dnd';

import { DropField } from 'src/shared/ui/dropField';
import { clsx } from 'src/shared/utils';
import { PersonEntity } from 'src/shared/types';
import { ReactComponent as PersonAddIcon } from 'src/assets/icons/outlined/account/person-add.svg';
import { ReactComponent as HardHatIcon } from 'src/assets/icons/outlined/account/hard-hat.svg';

type PersonDropfieldProps = {
  title: string;
  entityType: 'person' | 'crewLeader' | 'supervisor';
  isDropDisabled?: boolean;
  sourceEntityId?: string;
  onPersonDrop?: (item: PersonEntity, key: string) => void;
};

const PersonDropfield: FC<PersonDropfieldProps> = ({
  title,
  sourceEntityId = '',
  entityType,
  isDropDisabled = false,
  onPersonDrop,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['person', 'crewLeader', 'supervisor'],
    drop: (item) => {
      if (onPersonDrop) {
        onPersonDrop(item as PersonEntity, sourceEntityId);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const icons = {
    person: <PersonAddIcon />,
    crewLeader: <HardHatIcon />,
    supervisor: <HardHatIcon />,
  };

  const className = clsx(
    isOver &&
      'rounded-lg bg-bgColor-dropZoneHovered border-brandingColor-primary-gradient transition-[opacity] duration-300 ease-in-out',
  );

  const iconClasses = clsx(
    entityType === 'crewLeader' && isOver && 'fill-brandingColor-primary-gradient',
  );

  const titleClasses = clsx(
    entityType === 'crewLeader' && isOver && 'text-brandingColor-primary-gradient',
  );

  return (
    <div ref={isDropDisabled ? undefined : drop}>
      <DropField
        icon={icons[entityType]}
        title={title}
        className={className}
        iconClasses={iconClasses}
        titleClasses={titleClasses}
        isDropDisabled={isDropDisabled}
      />
    </div>
  );
};

export { PersonDropfield };
