import { useEffect, useState } from 'react';

const useMount = (opened: boolean, animationTime = 200) => {
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (opened && !mounted) {
      setMounted(true);
    } else if (!opened && mounted) {
      timeout = setTimeout(() => {
        setMounted(false);
      }, animationTime);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [opened]);

  return mounted;
};

export { useMount };
