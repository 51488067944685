import { api } from 'src/store/api/api';
import { Document, TagType } from 'src/shared/types';

const documentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createDocuments: builder.mutation<unknown, FormData>({
      query: (body) => ({
        url: '/admin/documents',
        method: 'POST',
        body,
      }),

      invalidatesTags: ['Documents'],
    }),
    getDocuments: builder.query<
      Document[],
      {
        modelName: TagType;
        filters: {
          entityId: string;
        };
      }
    >({
      query: ({ modelName, filters }) => ({
        url: '/admin/documents',
        method: 'GET',
        params: {
          modelName,
          filters: {
            entityId: filters.entityId,
          },
        },
      }),
      providesTags: ['Documents'],
      transformResponse: (response: { data: Document[] }) => response.data,
    }),
  }),
});

export const { useCreateDocumentsMutation, useGetDocumentsQuery } = documentApi;
