import { FC } from 'react';

import { clsx } from 'src/shared/utils';
import { Typography } from 'src/shared/ui/typography';

type SpinnerProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  withBackdrop?: boolean;
  fallbackText?: string;
};

const Spinner: FC<SpinnerProps> = ({ size = 'lg', withBackdrop = false, fallbackText }) => {
  const className = clsx(
    'inline-block animate-spin rounded-full border-8 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
    size === 'xs' && 'h-6 w-6 border-4',
    size === 'sm' && 'h-8 w-8',
    size === 'md' && 'h-12 w-12',
    size === 'lg' && 'h-16 w-16',
  );

  const content = (
    <div
      className={className}
      role="status"
    />
  );

  const fallbackTextContainer = <Typography variant="p1">{fallbackText}</Typography>;

  return withBackdrop ? (
    <div className="absolute z-[100] w-full h-full flex flex-col justify-center items-center gap-y-1 bg-slate-400 bg-opacity-30">
      {content}

      {fallbackTextContainer}
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center gap-y-1">
      {content}

      {fallbackTextContainer}
    </div>
  );
};

export { Spinner };
