import { FC, useId, useState } from 'react';
import { Checkbox, FormLabel } from '@mui/material';

import { Typography } from 'src/shared/ui/typography';
import { Checkboxes } from 'src/shared/ui/checkboxes';
import { Button } from 'src/shared/ui/button';
import { Icons } from 'src/assets/icons';
import { IconButton } from 'src/shared/ui/iconButton';
import { Tag } from 'src/shared/ui/tag';
import { FilterSelect } from 'src/shared/ui/filterselect';
import { EntityRecord, Filter } from 'src/shared/types';
import { Filters } from 'src/shared/ui/filters';
import { getReportChoicesEntities } from 'src/pages/activities/helpers';
import { CheckboxItem } from 'src/shared/ui/checkboxes/checkboxes';

interface TableReportHeaderProps {
  isDownloadAllClicked: boolean;
  setIsDownloadAllClicked: (value: boolean) => void;
  handleExportToExcel: () => void;
  appliedFiltersAmount: number;
  filters: Filter[];
  totalValues: {
    total: number;
    totalStates: number;
  };
  selectedEntity: string;
  handleSelectEntity: (option: EntityRecord) => void;
  isExcelDisabled?: boolean;
}

const TableReportHeader: FC<TableReportHeaderProps> = ({
  isDownloadAllClicked,
  setIsDownloadAllClicked,
  handleExportToExcel,
  appliedFiltersAmount,
  filters,
  totalValues,
  selectedEntity,
  handleSelectEntity,
  isExcelDisabled,
}) => {
  const downloadAllId = useId();
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const toggleFilterMenu = () => setIsFilterMenuOpen((prev) => !prev);

  const entities = getReportChoicesEntities(totalValues);

  const renderFilters = filters.map((filter) => (
    <FilterSelect
      key={`ActivityFilter-${filter.label}`}
      options={filter.options.map((option) => ({
        label: option,
        value: option,
      }))}
      label={filter.label}
      placeholder={`Filter by ${filter.label}`}
      id={filter.label}
      type="board"
    />
  ));

  return (
    <div className="flex items-center gap-4 justify-between flex-wrap my-3">
      <Typography variant="h2">Activity Reports</Typography>

      <div className="flex items-center gap-4 flex-wrap">
        <div className="flex gap-1 items-center">
          <FormLabel
            htmlFor={downloadAllId}
            className="!text-textColor-primary !font-semibold"
          >
            Download All
          </FormLabel>

          <Checkbox
            checked={isDownloadAllClicked}
            onChange={(_, checked) => setIsDownloadAllClicked(checked)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
            id={downloadAllId}
          />

          <Button
            size="lg"
            variant="filled"
            endIcon={<Icons.Filled.Files.DownloadIcon fill="#231F20" />}
            className="border-[#636874] flex !gap-3 !p-0"
            onClick={handleExportToExcel}
            disabled={isExcelDisabled}
          >
            Excel
          </Button>
        </div>

        <div className="flex gap-2">
          <div className="relative">
            <IconButton
              size="none"
              iconSize="md"
              color="basic"
              className="h-[48px] w-[48px]"
              onClick={toggleFilterMenu}
            >
              <Icons.Outlined.Controls.FilterIcon />
            </IconButton>

            {!!appliedFiltersAmount && (
              <Tag
                type="white"
                className="absolute right-[-2px] top-0 !px-1"
              >
                {appliedFiltersAmount}
              </Tag>
            )}
          </div>

          {isFilterMenuOpen && (
            <Filters
              title="Filters"
              isOpen={isFilterMenuOpen}
              closeMenu={setIsFilterMenuOpen}
              type="board"
              className="w-[calc(100%-30px)] right-[15px] md:w-auto md:right-[60px] top-[85px] md:min-w-[450px] md:max-w-[450px] z-[9999]"
            >
              {renderFilters}
            </Filters>
          )}
        </div>

        <Checkboxes
          options={entities.reduce<CheckboxItem[]>((acc, option) => {
            return [
              ...acc,
              {
                className: 'w-[151px]',
                label: option.label,
                amount: option.amount,
                onClick: () => handleSelectEntity(option),
              },
            ];
          }, [])}
          selectedValue={selectedEntity}
          className="w-fit"
        />
      </div>
    </div>
  );
};

export { TableReportHeader };
