import { FC } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { RawResult, SearchResult } from 'src/shared/types';

type PopupBodyProps = {
  selectedAddress: SearchResult<RawResult> | null;
};

const PopupBody: FC<PopupBodyProps> = ({ selectedAddress }) => {
  return (
    <div className="flex flex-col gap-2 divide-y">
      <Typography
        className="font-semibold"
        variant="p1"
      >
        {selectedAddress?.label}
      </Typography>

      <div className="flex flex-col gap-2 py-2">
        <Typography variant="p1">
          <span className="font-semibold">Latitude:</span>

          <span>{selectedAddress?.y || 'N/A'}</span>
        </Typography>

        <Typography variant="p1">
          <span className="font-semibold">Longitude:</span>

          <span>{selectedAddress?.x || 'N/A'}</span>
        </Typography>
      </div>
    </div>
  );
};
export { PopupBody };
