import { Dispatch, FC, SetStateAction } from 'react';

import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { not } from 'src/shared/utils';

type NavigationWarningModalProps = {
  isOpen: boolean;
  href: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  navigateTo: (href: string) => void;
};

const NavigationWarningModal: FC<NavigationWarningModalProps> = ({
  isOpen,
  href,
  setIsOpen,
  navigateTo,
}) => {
  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    navigateTo(href);
  };

  const buttons = (
    <div className="flex justify-end gap-2">
      <Button
        type="button"
        variant="outlined"
        color="basic"
        size="lg"
        onClick={closeModal}
      >
        Cancel
      </Button>

      <Button
        type="submit"
        color="success"
        size="lg"
        autoFocus
        onClick={handleConfirm}
      >
        Confirm
      </Button>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
    >
      <div className="flex flex-col justify-between gap-y-6  min-h-[368x] min-w-[451px] max-w-[451px]">
        <div className="flex flex-col gap-y-6">
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            Unsaved Changes
          </Typography>

          <div className="flex flex-col gap-y-1">
            <Typography
              variant="label"
              fontWeight="bold"
            >
              You have unsaved changes. Are you sure you want to continue?
            </Typography>
          </div>

          {buttons}
        </div>
      </div>
    </Modal>
  );
};

export { NavigationWarningModal };
