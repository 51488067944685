import { lighten } from 'polished';

const generateColor = (index: number, isHovered = false): string => {
  const baseHue = (index * 137.508) % 360;
  const baseColor = `hsl(${baseHue}, 70%, 50%)`;

  return isHovered ? lighten(0.08, baseColor) : baseColor;
};

const getColorForItem = (index: number, isHovered = false): string => {
  return generateColor(index, isHovered);
};

export { getColorForItem };
