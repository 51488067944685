import { FC, ReactNode } from 'react';
import { Button, ButtonProps } from '@mui/material';

type MuiButtonProps = ButtonProps & {
  children: ReactNode;
};

const MuiButton: FC<MuiButtonProps> = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        height: '36.5px',
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export { MuiButton };
