import { FC, useState, ChangeEvent, FormEvent } from 'react';

import { EquipmentJELL, TicketEntity } from 'src/shared/types';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { TextField } from 'src/shared/ui/textField';
import { Button } from 'src/shared/ui/button';
import { useGetJELLDetailsQuery, useEditJELLItemMutation } from 'src/store/api';
import { CheckboxItem, Checkboxes } from 'src/shared/ui/checkboxes/checkboxes';
import { showToastErrorMessage } from 'src/shared/utils';
import { Spinner } from 'src/shared/ui/spinner';

type EditItemModalProps = {
  ticket: TicketEntity;
  isOpen: boolean;
  type: 'Labor' | 'Equipment';
  setIsOpen: (openModalId: 'edit' | null) => void;
  selectedItemId: string;
};

const EditItemModal: FC<EditItemModalProps> = ({
  ticket,
  isOpen,
  type,
  setIsOpen,
  selectedItemId,
}) => {
  const {
    data = {
      requiredEquipment: [],
      requiredLabor: [],
    },
  } = useGetJELLDetailsQuery(ticket.id as string, {
    refetchOnMountOrArgChange: true,
  });

  const { requiredEquipment, requiredLabor } = data;

  const selectedItem =
    type === 'Labor'
      ? requiredLabor.find((el) => el.PeopleWorkRecordID === selectedItemId)
      : requiredEquipment.find((el) => el.KITItemsRecordID === selectedItemId);

  const [quantity, setQuantity] = useState(Number(selectedItem?.QTY));
  const [isRequired, setIsRequired] = useState(
    type === 'Equipment' ? (selectedItem as EquipmentJELL)?.RequiresUnitNumber || 'Yes' : 'Yes',
  );

  const [editItem, { isLoading: isUpdating }] = useEditJELLItemMutation();

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(isOpen ? null : 'edit');
  };

  const closeModal = () => {
    setIsOpen(null);
  };

  const changeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const formattedQTY = Number(value.replace(/[^0-9]/g, ''));

    if (formattedQTY > 20) {
      showToastErrorMessage('Exceeded quantity limit of 20');
    }

    const newQTY = formattedQTY > 20 ? 20 : formattedQTY;

    setQuantity(newQTY);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await editItem({
        id: selectedItemId,
        QTY: String(quantity),
        requiresUnitNumber: isRequired,
        type,
      }).unwrap();
    } catch (error) {
      showToastErrorMessage(`There was an error trying to update ${type.toLowerCase()}`);
    } finally {
      toggleModal(isOpen);
    }
  };

  const submitButtonContent = isUpdating ? <Spinner size="sm" /> : 'Save';
  const isSubmitButtonDisabled = isUpdating || quantity < 1;
  const buttons = (
    <div className="flex justify-end gap-2">
      <Button
        type="button"
        variant="outlined"
        color="basic"
        size="lg"
        onClick={closeModal}
      >
        Cancel
      </Button>

      <Button
        type="submit"
        color="primary"
        size="lg"
        disabled={isSubmitButtonDisabled}
      >
        {submitButtonContent}
      </Button>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
    >
      <form
        className="flex flex-col justify-between gap-y-6  min-h-[368x] min-w-[451px] max-w-[451px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            {`Change ${type}`}
          </Typography>

          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <Typography variant="label">Item Code</Typography>

                <Typography
                  variant="p1"
                  fontWeight="medium"
                >
                  {selectedItem?.ContractRateSheet?.Itemcode}
                </Typography>
              </div>

              <div className="flex flex-col gap-2">
                <Typography variant="label">Description</Typography>

                <Typography
                  variant="p1"
                  fontWeight="medium"
                >
                  {selectedItem?.ContractRateSheet?.CustomItemDescription}
                </Typography>
              </div>
            </div>

            {type === 'Equipment' && (
              <Checkboxes
                label="Requires Unit Number"
                options={['Yes', 'No'].reduce<CheckboxItem[]>((acc, option) => {
                  return [
                    ...acc,
                    {
                      className: 'min-w-[82px]',
                      label: option,
                      onClick: () => setIsRequired(option as 'Yes' | 'No'),
                    },
                  ];
                }, [])}
                selectedValue={isRequired}
                isRequired
              />
            )}

            <div className="flex flex-col gap-3">
              <TextField
                label="Quantity"
                placeholder="1000"
                value={quantity}
                onChange={changeQuantity}
                disabled={isUpdating}
              />

              <Typography
                variant="p2"
                className="text-textColor-tertiary"
              >
                Quantity must be higher than zero
              </Typography>
            </div>
          </div>

          {buttons}
        </div>
      </form>
    </Modal>
  );
};

export { EditItemModal };
