import { ComponentPropsWithoutRef, FC, ReactElement } from 'react';

import { Icon, IconProps } from '../icon';

const typeStyles = {
  info: 'bg-[rgba(3,144,244,0.08)] text-brandingColor-primary-gradient',
  danger: `bg-[rgba(220,88,88,0.15)] text-textColor-danger`,
  success: `bg-bgColor-green text-semanticColor-success`,
  warning: `bg-[#FADCC6] text-[#AF6027]`,
  disabled: `bg-[rgba(51,51,51,0.08)] text-brandingColor-primary`,
  light: `bg-[rgba(118,136,173,0.08)] text-textColor-tertiary`,
  white: `bg-bgColor-menu text-textColor-primary`,
};

const iconStyles = {
  info: '!fill-brandingColor-primary-gradient',
  danger: `!fill-textColor-danger`,
  success: `!fill-semanticColor-success`,
  warning: `!fill-semanticColor-F69E5E`,
  disabled: `!fill-brandingColor-primary`,
  light: `!fill-textColor-tertiary`,
  white: `!fill-textColor-tertiary`,
};

const defaultStyles = `flex items-center justify-center w-max text-xs rounded-[25px]`;
const defaultOutlinedStyles = `${defaultStyles} px-[6px] py-[3px] border border-solid`;
const defaultNotOutlinedStyles = `${defaultStyles} px-[12px] py-[2px] font-bold`;

const outlinedStyles = {
  info: `${defaultOutlinedStyles} ${typeStyles.info} border-brandingColor-primary-gradient`,
  danger: `${defaultOutlinedStyles} ${typeStyles.danger} border-textColor-danger`,
  success: `${defaultOutlinedStyles} ${typeStyles.success} border-semanticColor-success`,
  warning: `${defaultOutlinedStyles} ${typeStyles.warning} border-semanticColor-F69E5E`,
  disabled: `${defaultOutlinedStyles} ${typeStyles.disabled} border-brandingColor-primary`,
  light: `${defaultOutlinedStyles} ${typeStyles.light} border-textColor-tertiary`,
  white: `${defaultOutlinedStyles} ${typeStyles.white} border-textColor-tertiary`,
};

const notOutlinedStyles = {
  info: `${defaultNotOutlinedStyles} ${typeStyles.info}`,
  danger: `${defaultNotOutlinedStyles} ${typeStyles.danger}`,
  success: `${defaultNotOutlinedStyles} ${typeStyles.success}`,
  warning: `${defaultNotOutlinedStyles} ${typeStyles.warning}`,
  disabled: `${defaultNotOutlinedStyles} ${typeStyles.disabled}`,
  light: `${defaultNotOutlinedStyles} ${typeStyles.light}`,
  white: `${defaultNotOutlinedStyles} ${typeStyles.white}`,
};

type TagType = 'info' | 'danger' | 'success' | 'warning' | 'disabled' | 'light' | 'white';

type TagProps = ComponentPropsWithoutRef<'div'> & {
  type: TagType;
  isOutlined?: boolean;
  startIcon?: ReactElement;
  startIconProps?: Omit<IconProps, 'icon'>;
  endIcon?: ReactElement;
  endIconProps?: Omit<IconProps, 'icon'>;
};

const Tag: FC<TagProps> = ({
  type,
  isOutlined = false,
  className,
  startIcon,
  startIconProps,
  endIcon,
  endIconProps,
  children,
}) => {
  const styles = isOutlined ? outlinedStyles[type] : notOutlinedStyles[type];

  return (
    <div className={`${styles} ${className} gap-1`}>
      {startIcon && (
        <Icon
          icon={startIcon}
          size="sm"
          className={iconStyles[type]}
          {...startIconProps}
        />
      )}

      {children}

      {endIcon && (
        <Icon
          icon={endIcon}
          size="sm"
          className={iconStyles[type]}
          {...endIconProps}
        />
      )}
    </div>
  );
};

export { Tag };
export type { TagType };
