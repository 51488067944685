import { FC, FormEvent } from 'react';

import { TicketEntity } from 'src/shared/types';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { useDeleteJELLItemMutation, useGetJELLDetailsQuery } from 'src/store/api';
import { Spinner } from 'src/shared/ui/spinner';
import { showToastErrorMessage } from 'src/shared/utils';

type DeleteItemModalProps = {
  ticket: TicketEntity;
  isOpen: boolean;
  type: 'Labor' | 'Equipment';
  setIsOpen: (openModalId: 'delete' | null) => void;
  selectedItemId: string;
};

const DeleteItemModal: FC<DeleteItemModalProps> = ({
  ticket,
  isOpen,
  type,
  setIsOpen,
  selectedItemId,
}) => {
  const {
    data = {
      requiredEquipment: [],
      requiredLabor: [],
    },
  } = useGetJELLDetailsQuery(ticket.id as string, {
    refetchOnMountOrArgChange: true,
  });

  const { requiredEquipment, requiredLabor } = data;

  const selectedEquipmentItem =
    type === 'Labor'
      ? requiredLabor.find((el) => el.PeopleWorkRecordID === selectedItemId)
      : requiredEquipment.find((el) => el.KITItemsRecordID === selectedItemId);

  const [deleteItem, { isLoading: isDeleting }] = useDeleteJELLItemMutation();

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(isOpen ? null : 'delete');
  };

  const closeModal = () => {
    setIsOpen(null);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await deleteItem({
        selectedItemId,
        type,
      }).unwrap();
    } catch (error) {
      showToastErrorMessage(`There was an error trying to delete ${type.toLowerCase()}`);
    } finally {
      toggleModal(isOpen);
    }
  };

  const submitButtonContent = isDeleting ? <Spinner size="sm" /> : 'Delete';
  const buttons = (
    <div className="flex justify-end gap-2">
      <Button
        type="button"
        variant="outlined"
        color="basic"
        size="lg"
        onClick={closeModal}
      >
        Cancel
      </Button>

      <Button
        type="submit"
        color="danger"
        size="lg"
        disabled={isDeleting}
        autoFocus
      >
        {submitButtonContent}
      </Button>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
    >
      <form
        className="flex flex-col justify-between gap-y-6  min-h-[368x] min-w-[451px] max-w-[451px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            {`Delete ${type}`}
          </Typography>

          <Typography
            variant="label"
            fontWeight="bold"
            className="flex gap-1"
          >
            {`Are you sure you want to delete this ${type.toLowerCase()} item?`}
          </Typography>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <Typography variant="label">Item Code</Typography>

              <Typography
                variant="p1"
                fontWeight="medium"
              >
                {selectedEquipmentItem?.ContractRateSheet?.Itemcode}
              </Typography>
            </div>

            <div className="flex flex-col gap-1">
              <Typography variant="label">Description</Typography>

              <Typography
                variant="p1"
                fontWeight="medium"
              >
                {selectedEquipmentItem?.ContractRateSheet?.CustomItemDescription}
              </Typography>
            </div>
          </div>

          {buttons}
        </div>
      </form>
    </Modal>
  );
};

export { DeleteItemModal };
