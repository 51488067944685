(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("plotly.js-dist-min"), require("survey-core"));
	else if(typeof define === 'function' && define.amd)
		define("SurveyAnalytics", ["plotly.js-dist-min", "survey-core"], factory);
	else if(typeof exports === 'object')
		exports["SurveyAnalytics"] = factory(require("plotly.js-dist-min"), require("survey-core"));
	else
		root["SurveyAnalytics"] = factory(root["Plotly"], root["Survey"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_plotly_js_dist_min__, __WEBPACK_EXTERNAL_MODULE_survey_core__) => {
return 