import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type RootState } from 'src/store';

export const OpenModalId = {
  CreateLabor: 'CreateLabor',
  CreateEquipment: 'CreateEquipment',
} as const;

export type OpenModalIdType = keyof typeof OpenModalId | null;
export type OpenCreateUniversalModalType = 'Labor' | 'Equipment' | null;

type InitialState = {
  openAdditionalEquipmentModalTicketId: string;
  openProfileModalPersonEmail: string;
  openModalId: OpenModalIdType;
  openCreateUniversalModalType: OpenCreateUniversalModalType;
  lastDroppedUniversalItemId: string | null;
};

const initialState: InitialState = {
  openAdditionalEquipmentModalTicketId: '',
  openModalId: null,
  openCreateUniversalModalType: null,
  openProfileModalPersonEmail: '',
  lastDroppedUniversalItemId: null,
};

const modalConfigSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setOpenAdditionalEquipmentModalTicketId: (state, action: PayloadAction<string>) => {
      state.openAdditionalEquipmentModalTicketId = action.payload;
    },
    setOpenProfileModalPersonEmail: (state, action: PayloadAction<string>) => {
      state.openProfileModalPersonEmail = action.payload;
    },
    setOpenModalId: (state, action: PayloadAction<OpenModalIdType>) => {
      state.openModalId = action.payload;
    },
    setLastDroppedUniversalItemId: (state, action: PayloadAction<string>) => {
      state.lastDroppedUniversalItemId = action.payload;
    },
    setOpenCreateUniversalModalType: (
      state,
      action: PayloadAction<OpenCreateUniversalModalType>,
    ) => {
      state.openCreateUniversalModalType = action.payload;
    },
  },
});

export const modalConfigReducer = modalConfigSlice.reducer;
export const modalConfigActions = modalConfigSlice.actions;
export const selectModalConfig = (state: RootState) => state.modalConfig;
