import { useFormik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useSelector } from 'react-redux';

import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { TextAreaInput } from 'src/pages/activities/ui';
import { Button } from 'src/shared/ui/button';
import { Icons } from 'src/assets/icons';
import { selectCurrentUser } from 'src/store/slices';
import {
  useGetOwnerLocationsQuery,
  useGetPersonnelListByOwnerLocationIdQuery,
  usePostActionMutation,
} from 'src/store/api';
import { showToastErrorMessage } from 'src/shared/utils';
import { TextField } from 'src/shared/ui/textField';

import { addActionSchema } from '../../helpers/validation';
import { ActionStatus } from '../../helpers/types';

interface AddNewActionModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onActionAdd: () => void;
}

const AddNewActionModal: FC<AddNewActionModalProps> = ({ isOpen, closeModal, onActionAdd }) => {
  const toggleModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const user = useSelector(selectCurrentUser);

  const { data: ownerLocationsData, isLoading } = useGetOwnerLocationsQuery('');
  const [postNewAction, { isLoading: isPosting }] = usePostActionMutation();

  const initialValues = useMemo(
    () => ({
      actionStatus: ActionStatus.Open,
      assignedTo: {
        id: '',
        name: '',
      },
      ownerLocation: {
        id: '',
        name: '',
      },
      notes: '',
      shortDescription: '',
    }),
    [],
  );

  const onSubmit = useCallback(
    async (data: z.infer<typeof addActionSchema>) => {
      if (!user) {
        return;
      }
      try {
        await postNewAction({
          assignedTo: data.assignedTo.id,
          ownerLocationID: data.ownerLocation.id,
          shortDescription: data.shortDescription,
          status: data.actionStatus,
          notes: data.notes || undefined,
          updatedBy: user.email,
        }).unwrap();

        onActionAdd();
        closeModal();
      } catch (error) {
        showToastErrorMessage(`There was an error trying to create action state`);
      }
    },
    [closeModal, user, onActionAdd, postNewAction],
  );

  const { values, handleChange, handleSubmit, setFieldValue, errors } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(addActionSchema),
    initialValues,
    enableReinitialize: true,
  });

  const { data: personnelsData } = useGetPersonnelListByOwnerLocationIdQuery(
    {
      id: values.ownerLocation.id,
    },
    {
      skip: !values.ownerLocation.id,
    },
  );

  const ownerLocationsOptions = useMemo(() => {
    const variants = ownerLocationsData
      ? ownerLocationsData.map((ownerInfo) => ({
          id: ownerInfo.OwnerLocationID,
          name: ownerInfo.OwnerLocation,
        }))
      : [];

    return variants.map(({ id, name }) => ({
      label: (
        <SelectInputItem selected={values.ownerLocation.name === name}>{name}</SelectInputItem>
      ),
      value: id,
      onClick: () => {
        handleChange({
          target: {
            name: 'ownerLocation',
            value: {
              id,
              name,
            },
          },
        });
      },
    }));
  }, [ownerLocationsData, handleChange, values.ownerLocation.name]);

  const assignees = useMemo(() => {
    const people =
      personnelsData?.personnels.map(({ OwnerPersonnelEmail, FirstName, LastName, BadgeNo }) => ({
        id: OwnerPersonnelEmail,
        name: `${FirstName} ${LastName}${BadgeNo ? ` (${BadgeNo})` : ''}`,
      })) || [];

    return people.map(({ id, name }) => ({
      label: <SelectInputItem selected={values.assignedTo.name === name}>{name}</SelectInputItem>,
      value: id,
      onClick: () => {
        handleChange({
          target: {
            name: 'assignedTo',
            value: {
              id,
              name,
            },
          },
        });
      },
    }));
  }, [handleChange, values.assignedTo.name, personnelsData]);

  const actionStatusOptions = useMemo(() => {
    return Object.entries(ActionStatus).map(([id, name]) => ({
      label: <SelectInputItem selected={values.actionStatus === id}>{name}</SelectInputItem>,
      value: id,
      onClick: () => {
        handleChange({
          target: {
            name: 'actionStatus',
            value: id as ActionStatus,
          },
        });
      },
    }));
  }, [handleChange, values.actionStatus]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[calc(100%-30px)] md:w-[646px] overflow-y-auto min-h-[300px]"
      removeScroll
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        Add New Action
      </Typography>

      <form
        className="w-full"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-8 w-full max-h-[60vh] lg:max-h-[50vh] min-h-[368px] md:h-[616px] overflow-y-auto p-1">
          <TextField
            name="shortDescription"
            label="Short Description"
            placeholder="Short Description..."
            required
            isRequired
            value={values.shortDescription}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'shortDescription',
                  value: value.target.value,
                },
              })
            }
            error={errors.shortDescription}
          />

          <SelectInput
            isRequired
            required
            name="ownerLocation"
            label="Owner Location"
            value={values.ownerLocation.name}
            placeholder="Owner Location ..."
            onClear={() => setFieldValue('ownerLocation', '')}
            items={ownerLocationsOptions}
            disabled={isLoading}
          />

          <SelectInput
            isRequired
            required
            name="assignedTo"
            label="Assigned To"
            value={values.assignedTo.name}
            placeholder="Assigned To ..."
            onClear={() => setFieldValue('assignedTo', '')}
            items={assignees}
            disabled={!assignees.length}
          />

          <div className="flex gap-x-8 w-full">
            <SelectInput
              isRequired
              required
              name="actionStatus"
              label="Action Status"
              value={ActionStatus[values.actionStatus as keyof typeof ActionStatus]}
              placeholder="Action Status..."
              onClear={() => setFieldValue('actionStatus', '')}
              items={actionStatusOptions}
            />
          </div>

          <TextAreaInput
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'notes',
                  value,
                },
              })
            }
            value={values.notes || ''}
            label="Notes"
            placeholder="Add notes"
          />
        </div>

        <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
          <Button
            type="button"
            variant="outlined"
            color="basic"
            size="lg"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            size="lg"
            endIcon={<Icons.Outlined.Edit.CheckmarkIcon className="fill-white" />}
            autoFocus
            disabled={isPosting}
          >
            Create Action
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { AddNewActionModal };
