import { FC } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomTooltip } from './ui/customTooltip';

const BAR_COLOR = ['#6B9DFF', '#F0A8A8', '#B1B4FC', '#F0CC96', '#B5EBCD', '#B1E5FC'];

export type BarChartData = {
  name: string;
} & {
  [key in string]: string;
};

type CustomBarChartProps = {
  data: BarChartData[];
  keys: string[];
};

const CustomBarChart: FC<CustomBarChartProps> = ({ data, keys }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="1 1" />

        <XAxis dataKey="name" />

        <YAxis />

        <Tooltip content={<CustomTooltip />} />

        {keys.map((key, i) => (
          <Bar
            stackId="a"
            key={key}
            dataKey={key}
            fill={BAR_COLOR[i]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export { CustomBarChart };
