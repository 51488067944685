import { ComponentProps, FC, ReactNode } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { clsx } from 'src/shared/utils/clsx';

type CardMenuItemProps = ComponentProps<'button'> & {
  titleClassName?: string;
  startIcon?: ReactNode;
};

const CardMenuItem: FC<CardMenuItemProps> = ({ children, titleClassName, startIcon, ...props }) => {
  return (
    <button
      type="button"
      className={clsx(
        'flex w-full py-[12px] bg-bgColor-card cursor-pointer duration-200',
        'hover:bg-bgColor-card-hover active:bg-bgColor-card-active',
      )}
      {...props}
    >
      <div className="flex items-center gap-x-2 px-[12px]">
        {startIcon}

        <Typography
          fontWeight="bold"
          variant="p2"
          className={clsx('text-textColor-white', titleClassName)}
        >
          {children}
        </Typography>
      </div>
    </button>
  );
};

export { CardMenuItem };
export type { CardMenuItemProps };
