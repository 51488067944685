import { Dashboard } from 'src/features/admin/Dashboard';
import {
  useGetC2OAdminMetricsQuery,
  useGetSurveyAnswersStatsByTypeQuery,
  useGetInspectorStatsBySurveyAnswersQuery,
  useGetTopProjectsStatsBySurveyAnswersQuery,
  useGetTopAssignmentsStatsBySurveyAnswersQuery,
} from 'src/store/api';
import {
  ChartComponentName,
  DEFAULT_COLOR_BAR,
  SECONDARY_COLOR_BAR,
} from 'src/features/admin/Dashboard/helpers/constants';

const C2ODashboard = () => {
  const { data: metrics = [], isLoading: isLoadingMetrics } = useGetC2OAdminMetricsQuery();
  const { data: inspectorStatsBySurveyAnswers, isLoading: isLoadingInspectorStatsBySurveyAnswers } =
    useGetInspectorStatsBySurveyAnswersQuery('');
  const { data: projectsStatsBySurveyAnswers, isLoading: isLoadingProjectsStatsBySurveyAnswers } =
    useGetTopProjectsStatsBySurveyAnswersQuery('');
  const {
    data: assignmentsStatsBySurveyAnswers,
    isLoading: isLoadingAssignmentsStatsBySurveyAnswers,
  } = useGetTopAssignmentsStatsBySurveyAnswersQuery('');
  const { data: surveyAnswersStatsByType, isLoading: isLoadingSurveyAnswersStatsByType } =
    useGetSurveyAnswersStatsByTypeQuery('');

  const isLoadingData =
    isLoadingMetrics ||
    isLoadingInspectorStatsBySurveyAnswers ||
    isLoadingProjectsStatsBySurveyAnswers ||
    isLoadingAssignmentsStatsBySurveyAnswers ||
    isLoadingSurveyAnswersStatsByType;

  return (
    <div className="w-full h-full p-4 max-h-screen overflow-y-scroll">
      <Dashboard
        metrics={metrics}
        chartViewList={[
          {
            data: projectsStatsBySurveyAnswers,
            componentName: ChartComponentName.BarChartView,
            defaultColor: DEFAULT_COLOR_BAR,
            layout: 'vertical',
          },
          {
            data: assignmentsStatsBySurveyAnswers,
            componentName: ChartComponentName.BarChartView,
            defaultColor: SECONDARY_COLOR_BAR,
            layout: 'vertical',
          },
          {
            data: inspectorStatsBySurveyAnswers,
            componentName: ChartComponentName.PieChartView,
          },
          {
            data: surveyAnswersStatsByType,
            componentName: ChartComponentName.BarChartView,
            layout: 'horizontal',
          },
        ]}
        isLoadingData={isLoadingData}
      />
    </div>
  );
};

export { C2ODashboard };
