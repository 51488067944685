import { ComponentPropsWithRef, FC, useState } from 'react';

type ImageViewerProps = ComponentPropsWithRef<'img'>;

const ImageViewer: FC<ImageViewerProps> = ({ ...props }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <img
      alt=""
      style={{ visibility: imageLoaded ? 'visible' : 'hidden' }}
      onLoad={handleImageLoad}
      {...props}
    />
  );
};

export { ImageViewer };
