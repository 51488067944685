import { FC } from 'react';
import { Stack, Box, useMediaQuery, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

import {
  DashboardAdminMetric,
  ProjectsStatsBySurveyAnswers,
  AssignmentsStatsBySurveyAnswers,
  InspectorsStatsBySurveyAnswers,
  SurveyAnswersStatsByType,
} from 'src/shared/types';
import { Metrics } from './ui/Metrics';
import { PieChartView } from './ui/PieChartView';
import { BarChartView } from './ui/BarChartView';
import { CHART_HEIGHT, ChartComponentName } from './helpers/constants';

type ChartFullData = {
  data:
    | ProjectsStatsBySurveyAnswers
    | AssignmentsStatsBySurveyAnswers
    | InspectorsStatsBySurveyAnswers
    | SurveyAnswersStatsByType
    | undefined;
  componentName: string;
  defaultColor?: string;
  layout?: 'horizontal' | 'vertical';
};

type DashboardProps = {
  metrics: DashboardAdminMetric[];
  isLoadingData: boolean;
  chartViewList: ChartFullData[];
};
const Dashboard: FC<DashboardProps> = ({ metrics, isLoadingData, chartViewList }) => {
  const theme = useTheme();
  const isLGDown = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Stack sx={{ gap: '1rem' }}>
      <Metrics
        metrics={metrics}
        isLoadingData={isLoadingData}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isLGDown ? '1fr' : '1fr 1fr',
          gap: '1rem',
        }}
      >
        {chartViewList.map((chartData) => {
          const { data, componentName, ...rest } = chartData;

          let ChartComponent;
          switch (componentName) {
            case ChartComponentName.PieChartView:
              ChartComponent = PieChartView;
              break;
            case ChartComponentName.BarChartView:
              ChartComponent = BarChartView;
              break;

            default:
              return null;
          }

          return !isLoadingData && data ? (
            <ChartComponent
              key={uuidv4()}
              {...data}
              {...rest}
            />
          ) : (
            <Skeleton
              key={uuidv4()}
              variant="rounded"
              height={CHART_HEIGHT}
            />
          );
        })}
      </Box>
    </Stack>
  );
};

export { Dashboard };
