import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';

import { SurveyElement } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import { Checkbox } from 'src/shared/ui/checkbox';
import { Modal, ModalProps } from 'src/shared/ui/modal';

export interface DisplayedQuestionsModalProps extends Pick<ModalProps, 'toggleModal'> {
  open?: boolean;
  onClose: () => void;
  questions: SurveyElement[];
  displayedQuestions: string[];
  onChange: (questions: string[]) => void;
}

export const DisplayedQuestionsModal: FC<DisplayedQuestionsModalProps> = ({
  questions,
  displayedQuestions,
  open: isOpen = false,
  onChange,
  onClose,
  ...modalProps
}) => {
  const { handleSubmit, handleChange, values, setValues } = useFormik({
    initialValues: {} as Record<string, boolean>,
    onSubmit: (values) => {
      onChange(
        Object.entries(values)
          .filter(([, checked]) => checked)
          .map(([name]) => name),
      );
      onClose();
    },
  });

  useEffect(() => {
    const valueEntries = questions.map(({ name }) => {
      const isChecked = displayedQuestions.includes(name);

      return [name, isChecked];
    });

    setValues(Object.fromEntries(valueEntries));
  }, [displayedQuestions, questions, setValues]);

  return (
    <Modal
      isOpen={isOpen}
      removeScroll
      {...modalProps}
    >
      <form onSubmit={handleSubmit}>
        <ul className="max-h-[80vh] overflow-y-scroll">
          {questions.map(({ name, title }) => (
            <li key={name}>
              <Checkbox
                name={name}
                checked={values[name]}
                endLabel={title || name}
                onChange={handleChange}
              />
            </li>
          ))}
        </ul>

        <div className="mt-4 flex gap-x-2">
          <Button
            type="submit"
            variant="filled"
            color="primary"
            className="w-full"
          >
            Apply
          </Button>

          <Button
            variant="outlined"
            color="basic"
            className="w-full"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};
