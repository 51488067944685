import { SurveyNotification, SurveyNotificationTemplate } from 'src/shared/types';
import { api } from 'src/store/api/api';

export type SubscribeUsersForSurveyNotification = {
  userIds: string[];
  notificationId: string;
};

export type SubscribeSurveyForSurveyNotifications = {
  notificationIds: string[];
  surveyId: string;
};

type CreateOrUpdateSurveyNotification = {
  name: string;
  templateId: string;
  id?: string;
};

export const surveyNotificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    subscribeUsersForSurveyNotification: builder.mutation<
      undefined,
      SubscribeUsersForSurveyNotification
    >({
      query: (body) => ({
        url: '/notification/subscribe-users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SurveyNotification'],
    }),
    subscribeSurveyForSurveyNotifications: builder.mutation<
      undefined,
      SubscribeSurveyForSurveyNotifications
    >({
      query: (body) => ({
        url: '/notification/subscribe-survey',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SurveyNotification', 'Survey'],
    }),
    getSurveyNotificationTemplates: builder.query<SurveyNotificationTemplate[], ''>({
      query: () => ({
        url: '/notification/templates',
        method: 'GET',
      }),
      providesTags: ['SurveyNotification'],
      transformResponse: (response: { data: SurveyNotificationTemplate[] }) => response.data,
    }),
    getSurveyNotifications: builder.query<SurveyNotification[], ''>({
      query: () => ({
        url: '/notification',
        method: 'GET',
      }),
      providesTags: ['SurveyNotification'],
      transformResponse: (response: { data: SurveyNotification[] }) => response.data,
    }),
    getSurveyNotificationByName: builder.query<SurveyNotification, { name: string }>({
      query: ({ name }) => ({
        url: '/notification/by-name',
        method: 'GET',
        params: {
          name,
        },
      }),
      transformResponse: (response: { data: SurveyNotification }) => response.data,
    }),
    createSurveyNotification: builder.mutation<
      SurveyNotification,
      CreateOrUpdateSurveyNotification
    >({
      query: (body) => ({
        url: '/notification',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SurveyNotification'],
    }),
    updateSurveyNotification: builder.mutation<
      SurveyNotification,
      CreateOrUpdateSurveyNotification
    >({
      query: ({ id, ...body }) => ({
        url: `/notification/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['SurveyNotification'],
    }),
    deleteSurveyNotification: builder.mutation<SurveyNotification, string>({
      query: (id) => ({
        url: `/notification/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SurveyNotification'],
    }),
  }),
});

export const {
  useGetSurveyNotificationsQuery,
  useGetSurveyNotificationTemplatesQuery,
  useLazyGetSurveyNotificationByNameQuery,
  useSubscribeUsersForSurveyNotificationMutation,
  useSubscribeSurveyForSurveyNotificationsMutation,
  useCreateSurveyNotificationMutation,
  useUpdateSurveyNotificationMutation,
  useDeleteSurveyNotificationMutation,
} = surveyNotificationApi;
