import { FC } from 'react';

import { clsx } from 'src/shared/utils';

import { Typography } from '../typography';
import { Button } from '../button';
import { Tag } from '../tag';

type CheckboxItem = {
  label: string;
  className?: string;
  amount?: number;
  onClick: () => void;
};

type CheckboxesProps = {
  options: CheckboxItem[];
  selectedValue: string;
  className?: string;
  label?: string;
  isRequired?: boolean;
};

const Checkboxes: FC<CheckboxesProps> = ({
  label,
  options,
  selectedValue,
  className,
  isRequired = false,
}) => {
  return (
    <div className={clsx('flex flex-col gap-4 w-full', className)}>
      {label && (
        <Typography variant="label">
          {label}

          {isRequired && <span className="text-textColor-danger">*</span>}
        </Typography>
      )}

      <div className="flex gap-2 p-1 rounded-lg bg-[rgba(99,155,221,0.16)]">
        {options.map((item) => (
          <Button
            key={item.label}
            variant="filled"
            color={selectedValue === item.label ? 'primary' : 'basic'}
            size="lg"
            className={clsx(
              'w-full',
              selectedValue === item.label &&
                `bg-brandingColor-primary text-white hover:bg-brandingColor-primary-gradient-active
            active:bg-brandingColor-primary-gradient-hover`,
              item.className,
            )}
            onClick={item.onClick}
          >
            <div
              key={item.label}
              className="flex items-center gap-x-1"
            >
              {item.label}

              {(!!item.amount || item.amount === 0) && (
                <Tag
                  type={selectedValue === item.label ? 'white' : 'disabled'}
                  className="!px-[4px] !py-[2px] duration-200"
                >
                  {item.amount}
                </Tag>
              )}
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export { Checkboxes };
export type { CheckboxItem };
