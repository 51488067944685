import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';

const BUTTON_STYLES = {
  'display': 'flex',
  'alignItems': 'center',
  '& span': {
    lineHeight: '0.5',
  },
};

const TableView = () => {
  const navigate = useNavigate();

  const handleNavigationClick = () => {
    navigate('/c2o/Inspectors');
  };
  return (
    <Button
      color="primary"
      startIcon={<AppsIcon />}
      onClick={handleNavigationClick}
      sx={BUTTON_STYLES}
    >
      Table View
    </Button>
  );
};

export { TableView };
