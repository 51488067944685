import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { SurveyNotification, SurveyNotificationTemplate, User } from 'src/shared/types';
import { MultiSelectInput } from 'src/shared/ui/MultiSelectInput';
import { Button } from 'src/shared/ui/button';
import { IconButton } from 'src/shared/ui/iconButton';
import { Modal } from 'src/shared/ui/modal';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { Spinner } from 'src/shared/ui/spinner';
import { TextField } from 'src/shared/ui/textField';
import { Typography } from 'src/shared/ui/typography';
import { showToastErrorMessage } from 'src/shared/utils';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import {
  useCreateSurveyNotificationMutation,
  useGetSurveyNotificationTemplatesQuery,
  useSubscribeUsersForSurveyNotificationMutation,
  useUpdateSurveyNotificationMutation,
  useLazyGetSurveyNotificationByNameQuery,
} from 'src/store/api/survey-notification';

type CreateOrUpdateSurveyNotificationModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  notification?: SurveyNotification;
  authPeople?: User[];
};

type InitialValues = {
  name: string;
  selectedEmails: string[];
  selectedTemplate: SurveyNotificationTemplate | null;
};

const initialValues: InitialValues = {
  name: '',
  selectedEmails: [],
  selectedTemplate: null,
};

const NAME_DEBOUNCE_DELAY = 500;

const CreateOrUpdateSurveyNotificationModal: FC<CreateOrUpdateSurveyNotificationModalProps> = ({
  isOpen,
  setIsOpen,
  notification,
  authPeople,
}) => {
  const [createNotification, { isLoading: isCreating }] = useCreateSurveyNotificationMutation();
  const [updateNotification, { isLoading: isUpdating }] = useUpdateSurveyNotificationMutation();
  const [subscribeUsersForNotification, { isLoading: isSubscribing }] =
    useSubscribeUsersForSurveyNotificationMutation();
  const { data: templates = [] } = useGetSurveyNotificationTemplatesQuery('');

  const [nameError, setNameError] = useState<string | null>(null);

  const [trigger, { isFetching: isCheckingName }] = useLazyGetSurveyNotificationByNameQuery({});

  const isUpdateState = !!notification;

  const type = isUpdateState ? 'Update' : 'Create';

  const schema = z.object({
    name: z
      .string({ message: 'Name is required' })
      .min(1, 'Name must be at least 1 character long'),
    selectedEmails: z.array(z.string()),
    selectedTemplate: z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .nullable()
      .refine((value) => value !== null, {
        message: 'Template selection is required',
      }),
  });

  const {
    values,
    handleChange,
    handleSubmit,
    resetForm,
    errors,
    setValues,
    setFieldError,
    isValid,
    setFieldValue,
    isSubmitting: isSubmittingForm,
  } = useFormik({
    validationSchema: toFormikValidationSchema(schema),
    initialValues,
    onSubmit: async (values: InitialValues) => {
      try {
        if (isUpdateState && notification && values?.name && values?.selectedTemplate) {
          await Promise.all([
            updateNotification({
              id: notification?.id,
              name: values?.name,
              templateId: values?.selectedTemplate?.id,
            }).unwrap(),
            subscribeUsersForNotification({
              userIds:
                authPeople?.reduce<string[]>((acc, el) => {
                  return values.selectedEmails.includes(el.email) ? [...acc, el.id] : acc;
                }, []) || [],
              notificationId: notification.id,
            }).unwrap(),
          ]);
        } else if (values?.name && values?.selectedTemplate) {
          await createNotification({
            name: values?.name,
            templateId: values?.selectedTemplate?.id,
          }).unwrap();
        }
      } catch (error) {
        showToastErrorMessage(
          `There was an error trying to ${isUpdateState ? 'update' : 'create'} notification`,
        );
      } finally {
        setIsOpen(false);
        resetForm();
      }
    },
    validate: () => {
      const errors: { [key: string]: string } = {};
      if (nameError) {
        errors.name = nameError;
      }

      return errors;
    },
  });
  const debouncedName = useDebounce(values.name, NAME_DEBOUNCE_DELAY);

  const isSubmitting = isCreating || isUpdating || isSubscribing || isSubmittingForm;

  const closeModal = () => {
    setIsOpen(false);
    setValues(initialValues);
  };

  const removeEmail = (email: string) => {
    setFieldValue(
      'selectedEmails',
      values.selectedEmails.filter((selectedEmail) => selectedEmail !== email),
      true,
    );
  };

  const toggleSelectedEmail = (email: string) => {
    if (values.selectedEmails.includes(email)) {
      removeEmail(email);
    } else {
      setFieldValue('selectedEmails', [...values.selectedEmails, email], true);
    }
  };

  useEffect(() => {
    if (debouncedName && debouncedName !== notification?.name) {
      trigger({
        name: debouncedName,
      }).then(({ data: surveyData }) => {
        if (surveyData) {
          setNameError('Notification name already exists');
          setFieldError('name', 'Notification name already exists');
        } else {
          setNameError(null);
          setFieldError('name', undefined);
        }
      });
    }
  }, [debouncedName, trigger, setFieldError, notification?.name]);

  useEffect(() => {
    if (!notification || !templates) return;

    const currentTemplate =
      templates.find((template) => template.id === notification.emailTemplateId) || null;

    const emails = authPeople
      ? notification.subscriptions.reduce<string[]>((acc, el) => {
          const person = authPeople.find((person) => person.id === el.userId);
          if (person) {
            return [person.email, ...acc];
          }
          return acc;
        }, [])
      : [];

    setValues((prevState) => ({
      ...prevState,
      selectedEmails: authPeople ? emails : prevState.selectedEmails,
      name: notification.name || '',
      selectedTemplate: currentTemplate,
    }));
  }, [notification, isOpen, authPeople, templates, setValues]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={closeModal}
    >
      <form
        className="flex flex-col gap-y-6 w-[550px]"
        onSubmit={handleSubmit}
      >
        <Typography variant="h2">{`${type} Notification`}</Typography>

        <TextField
          label="Notification Name"
          placeholder="Enter Notification name"
          name="name"
          value={values.name}
          onChange={(e) => {
            handleChange(e);
            setFieldError('name', undefined);
            if (nameError) {
              setNameError(null);
            }
          }}
          isRequired
          disabled={isSubmitting}
          error={errors.name}
          className="relative"
          endIcon={
            isCheckingName ? (
              <div className="absolute translate-x-[-50%] translate-y-[-50%] top-[50%] right-0">
                <Spinner size="xs" />
              </div>
            ) : undefined
          }
        />

        <SelectInput
          isRequired
          name="template"
          value={values.selectedTemplate?.name}
          label="Template"
          placeholder="Select template"
          error={errors.selectedTemplate}
          onClear={() => {
            setFieldValue('selectedTemplate', null, true);
          }}
          disabled={isSubmitting}
          items={
            templates.map((template) => ({
              label: (
                <SelectInputItem selected={values.selectedTemplate?.name === template.name}>
                  {template.name}
                </SelectInputItem>
              ),
              value: template.name,
              onClick: () => {
                setFieldValue('selectedTemplate', template, true);
              },
            })) ?? []
          }
        />

        {isUpdateState && (
          <div className="flex flex-col gap-y-3">
            <Typography variant="h3">Set Emails</Typography>

            <MultiSelectInput
              placeholder="Select emails"
              label="Emails"
              items={
                authPeople?.map((person) => ({
                  label: (
                    <SelectInputItem selected={values.selectedEmails.includes(person.email)}>
                      {person.email}
                    </SelectInputItem>
                  ),
                  value: person.email,
                  onClick: () => toggleSelectedEmail(person.email),
                })) ?? []
              }
            />

            <div className="flex flex-col gap-y-3 h-[198px] overflow-y-scroll">
              {values.selectedEmails.map((email) => (
                <div
                  key={email}
                  className="flex items-center justify-between border border-[#E4E9F2]  py-2 px-3 rounded-lg"
                >
                  {email}

                  <IconButton
                    color="basic"
                    size="md"
                    iconSize="md"
                    iconClassName="fill-[#231F20]"
                    onClick={() => removeEmail(email)}
                    disabled={isSubmitting}
                  >
                    <TrashIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="flex w-full justify-end gap-x-2">
          <Button
            variant="outlined"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? <Spinner size="sm" /> : type}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { CreateOrUpdateSurveyNotificationModal };
