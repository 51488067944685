/* eslint-disable react/no-array-index-key */
import { ComponentProps, FC, useMemo } from 'react';

import { Skeleton } from 'src/shared/ui/skeleton';
import { clsx } from 'src/shared/utils';

type SidebarSkeletonProps = ComponentProps<'div'>;
const SidebarSkeleton: FC<SidebarSkeletonProps> = ({ className, ...props }) => {
  const cardsBlock = useMemo(
    () => (
      <div className="flex flex-col w-[340px] gap-y-[16px]">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <Skeleton className="w-[110px] h-[24px]" />

            <Skeleton className="w-[50px] h-[28px] rounded-[25px]" />
          </div>

          <div className="flex gap-2">
            <Skeleton className="w-[24px] h-[24px]" />

            <Skeleton className="w-[24px] h-[24px]" />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-[8px]">
          {Array.from({ length: 20 }).map((_, i) => (
            <Skeleton
              className="w-[150px] h-[62px]"
              key={i}
            />
          ))}
        </div>

        <div className="flex flex-col gap-2 justify-between">
          <Skeleton className="w-[335px] h-[40px] rounded-xl" />

          <Skeleton className="w-[335px] h-[40px] rounded-xl" />
        </div>
      </div>
    ),
    [],
  );

  return (
    <div
      className={clsx(
        'h-full flex grow flex-col gap-y-6 bg-bgColor-card py-7 px-4 border-[1px] border-outlineColor-input-border',
        'overflow-y-scroll min-w-[420px] max-w-[420px]',
      )}
    >
      <div
        className={clsx('flex flex-col gap-y-[36px] justify-center items-center', className)}
        {...props}
      >
        <div className="flex flex-col gap-y-6">
          <div className="flex justify-between gap-x-[50px] items-center">
            <Skeleton className="w-[148px] h-[48px]" />

            <div className="flex gap-x-4">
              <Skeleton className="w-[108px] h-[48px]" />

              <Skeleton className="w-[48px] h-[48px]" />
            </div>
          </div>

          <div className="flex flex-wrap gap-4">
            <Skeleton className="w-full h-[48px]" />

            <Skeleton className="w-full h-[48px]" />
          </div>
        </div>

        {cardsBlock}

        {cardsBlock}
      </div>
    </div>
  );
};

export type { SidebarSkeletonProps };
export { SidebarSkeleton };
