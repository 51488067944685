import { FC } from 'react';

import { Button } from 'src/shared/ui/button';
import { Icons } from 'src/assets/icons';

type AddAssignmentMapProps = {
  isSearchVisible: boolean;
  toggleSearchVisibility: () => void;
};

const AddAssignmentMap: FC<AddAssignmentMapProps> = ({
  isSearchVisible,
  toggleSearchVisibility,
}) => {
  const buttonLabel = isSearchVisible ? 'Close search' : 'Assigment';

  return (
    <Button
      variant="filled"
      color="primary"
      className="fixed bottom-10 right-10 p-4 z-[900]"
      startIcon={isSearchVisible ? <Icons.Filled.Edit.CloseIcon /> : <Icons.Filled.Edit.PlusIcon />}
      onClick={toggleSearchVisibility}
    >
      {buttonLabel}
    </Button>
  );
};

export { AddAssignmentMap };
