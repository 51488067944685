import { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type InputFieldProps = TextFieldProps;

const InputField: FC<InputFieldProps> = ({ sx, ...props }) => {
  return (
    <TextField
      sx={{
        width: '100%',
      }}
      {...props}
    />
  );
};

export { InputField };
