/* eslint-disable react/no-unstable-nested-components */
import { FC } from 'react';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { v4 as uuidv4 } from 'uuid';

import { Button } from 'src/shared/ui/button';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { ReactComponent as ClockIcon } from 'src/assets/icons/outlined/misc/clock.svg';
import { clsx } from 'src/shared/utils/clsx';
import { IconButton } from 'src/shared/ui/iconButton';
import { Datepicker } from 'src/shared/ui/datepicker';
import { dayjs, getDatesBetween, showToastErrorMessage } from 'src/shared/utils';
import { JobEntity } from 'src/shared/types';
import { useCopyJobMutation } from 'src/store/api';

import { DateRangeType } from '../datepicker/types';
import { TextField } from '../textField';

type InitialValues = {
  dateRange: DateRangeType;
  description: string;
};

const initialValues: InitialValues = {
  dateRange: {
    startDate: null,
    endDate: null,
  },
  description: '',
};

type CopyJobModalProps = {
  isOpen: boolean;
  job: JobEntity;
  setIsOpen: (isOpen: boolean) => void;
};

const CopyJobModal: FC<CopyJobModalProps> = ({ isOpen = false, job, setIsOpen }) => {
  const [copyJob] = useCopyJobMutation();

  const toggleCopyJobModal = (isOpen: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resetForm();
    setIsOpen(!isOpen);
  };

  const onSubmit = async (values: InitialValues) => {
    toggleCopyJobModal(isOpen);

    try {
      await copyJob({
        newJobId: uuidv4(),
        description: values.description,
        jobSourceId: job.id,
        startDate: dayjs(values.dateRange?.startDate).hour(12).utc().format(),
        endDate: dayjs(values.dateRange?.endDate).hour(12).utc().format(),
      }).unwrap();
    } catch {
      showToastErrorMessage('Sorry, an error occurred, when you tried to copy a job');
    }
  };

  const jobDatesLength = getDatesBetween({
    startDate: job.requestedStartDate,
    endDate: job.requestedCompleteDate,
  }).length;

  const as = z.object({
    dateRange: z
      .object({
        startDate: z.string(),
        endDate: z.string(),
      })
      .refine(
        (data) => {
          const { startDate, endDate } = data;

          const selectedDates = getDatesBetween({
            startDate,
            endDate,
          });

          return selectedDates.length === jobDatesLength;
        },
        {
          message: `Selected date range amount should be the same as amount of Job days (${jobDatesLength} days)`,
        },
      ),
  });

  const { values, handleChange, handleSubmit, resetForm, errors } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(as),
    initialValues,
    enableReinitialize: true,
  });

  const isSaveDisabled = Boolean(
    !values.dateRange?.startDate ||
      !values.dateRange?.endDate ||
      errors.dateRange?.startDate ||
      errors.dateRange?.endDate ||
      !values.description,
  );

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleCopyJobModal}
    >
      <form
        className="flex flex-col gap-y-6"
        onSubmit={handleSubmit}
      >
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Copy Job
        </Typography>

        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <Typography variant="label">Copy to Dates</Typography>

            <Datepicker
              error={errors.dateRange as string}
              useRange={false}
              placeholder="Select date(s)"
              value={values.dateRange}
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'dateRange',
                    value,
                  },
                })
              }
              displayFormat="dddd, DD MMMM"
              separator="-"
              toggleClassName={clsx(
                values.dateRange?.startDate && values.dateRange?.endDate
                  ? 'absolute right-[12px] top-[12px] bg-transparent'
                  : 'absolute left-[16px] top-[12px] bg-transparent',
              )}
              iconPosition="start"
              toggleIcon={(open) => {
                return (
                  <IconButton
                    iconClassName="fill-textColor-tertiary"
                    size="none"
                  >
                    {open ? <ClockIcon /> : <CloseCircleIcon />}
                  </IconButton>
                );
              }}
              inputClassName="w-[364px]"
            />

            <TextField
              label="Description"
              name="description"
              placeholder="Type a description"
              isRequired
              onChange={handleChange}
            />
          </div>

          <div className="flex w-full gap-x-2">
            <Button
              variant="outlined"
              color="basic"
              size="lg"
              className="w-full"
              onClick={() => toggleCopyJobModal(isOpen)}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              color="primary"
              size="lg"
              className="w-full"
              disabled={isSaveDisabled}
            >
              Copy
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { CopyJobModal };
export type { CopyJobModalProps };
