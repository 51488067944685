import { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'src/store';
import { dayjs, getPaginationDays } from 'src/shared/utils';
import { useGetJobsQuery } from 'src/store/api';
import { schedulerBoardSettingsActions } from 'src/store/slices/schedulerBoardSettings';
import { configActions, selectConfig } from 'src/store/slices';
import { SchedulerColumn } from 'src/shared/ui/schedulerBoard/ui/schedulerColumn';
import { SchedulerEmptyColumn } from 'src/shared/ui/schedulerBoard/ui/schedlerEmptyColumn';
import { SchedulerBoardRef } from 'src/shared/ui/schedulerBoard/scheduler.types';
import { EquipmentDetails } from 'src/shared/ui/details/equipmentDetails';
import { Details } from 'src/shared/ui/details';

type SchedulerBoardProps = {
  currentDate?: string;
};

const SchedulerBoard: FC<PropsWithChildren<SchedulerBoardProps>> = () => {
  const dates = useSelector((state: RootState) => state.calendar.dates);
  const { boardFilters, search, selectedEquipmentItem } = useSelector(selectConfig);
  const [startDate, endDate] = getPaginationDays(dates);

  const {
    data = {
      jobs: [],
    },
  } = useGetJobsQuery({
    filters: {
      ...boardFilters,
      startDate,
      endDate,
    },
    pagination: {
      search,
    },
  });

  const { jobs } = data;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!jobs) return;

    dispatch(
      schedulerBoardSettingsActions.setCardSettings(
        jobs.map(({ id, requestedStartDate, requestedCompleteDate }) => ({
          id,
          requestedStartDate,
          requestedCompleteDate,
        })),
      ),
    );
  }, [dispatch, jobs]);

  const setIsEquipmentDetailsOpen = (isOpen: boolean) => {
    dispatch(
      configActions.setSelectedEquipmentItem(
        isOpen && selectedEquipmentItem ? selectedEquipmentItem : null,
      ),
    );
  };

  return (
    <div className="flex overflow-scroll h-full">
      <SchedulerEmptyColumn />

      {dates.map((date, index) => {
        return (
          <SchedulerColumn
            columnIndex={index}
            key={date}
            date={date}
            allJobs={jobs}
            jobs={
              jobs
                ? jobs.filter((job) => {
                    const isBetween = dayjs(date)
                      .utc()
                      .isBetween(job?.requestedStartDate, job?.requestedCompleteDate, 'day', '[]');

                    return isBetween;
                  })
                : []
            }
          />
        );
      })}

      {!!selectedEquipmentItem && (
        <Details
          isOpen={!!selectedEquipmentItem}
          setIsOpen={setIsEquipmentDetailsOpen}
          title="Equipment"
          hasOutsideClick
        >
          <EquipmentDetails />
        </Details>
      )}

      <SchedulerEmptyColumn />
    </div>
  );
};

export { SchedulerBoard };
export type { SchedulerBoardProps, SchedulerBoardRef };
