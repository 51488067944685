import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import dayjs from 'dayjs';

import { RootState } from 'src/store';

import { TimePeriod } from '../types';

export type UseSetLocalStorageCallback<T> = (value: T | ((val: T) => T)) => void;

function useGetActivityDates() {
  const datesState = useSelector((state: RootState) => state.calendar.dates);
  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);

  const isDayPeriod = useMemo(() => filterPeriod === TimePeriod.Day, [filterPeriod]);

  const dates = useMemo(
    () => datesState.map((date) => dayjs(date).format().toString()),
    [datesState],
  );

  const { startDate, endDate } = useMemo(() => {
    const localStartDate = dayjs(dates[0])
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .toDate();
    const localEndDate = dayjs(dates[dates.length - 1])
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .toDate();

    const startDate = dayjs.utc(localStartDate).toDate().toISOString();
    const endDate = dayjs.utc(localEndDate).toDate().toISOString();

    return {
      startDate,
      endDate,
    };
  }, [dates]);

  const preparedDates = useMemo(() => {
    return isDayPeriod
      ? [dayjs(dates[0]).format('dddd, MMMM D')]
      : dates.map((date) => dayjs(date).format('dddd, MMMM D'));
  }, [dates, isDayPeriod]);

  return {
    startDate,
    endDate,
    preparedDates,
  };
}

export { useGetActivityDates };
