import { FC, SVGAttributes, ReactSVGElement, cloneElement } from 'react';
import { match } from 'ts-pattern';
import clsx from 'clsx';

import { Color, Size } from './icon.types';

type IconProps = {
  size?: Size;
  icon: React.ReactNode;
  color?: Color;
};

const Icon: FC<SVGAttributes<ReactSVGElement> & IconProps> = ({
  icon,
  size = 'md',
  // | Light theme used by default so icons are dark by default.
  color = 'dark',
  className,
  ...props
}) => {
  const sizeClasses = match(size)
    .with('xs', () => 'w-[16px] h-[16px]')
    .with('sm', () => 'w-[20px] h-[20px]')
    .with('md', () => 'w-[24px] h-[24px]')
    .with('lg', () => 'w-[28px] h-[28px]')
    .otherwise(() => '');

  const colorClasses = match(color)
    .with('dark', () => 'fill-[#2E3A59]')
    .with('light', () => 'fill-textColor-white')
    .otherwise(() => '');

  const Icon = cloneElement(icon as React.ReactElement, {
    className: clsx(sizeClasses, colorClasses, className),
    ...props,
  });

  return Icon;
};

export { Icon };
export type { IconProps };
