import { LatLngExpression } from 'leaflet';

export const MAP_DEFAULT_VIEW: LatLngExpression = [39, -100];
export const MAP_DEFAULT_ZOOM = 4;
export const MAP_MAX_ZOOM = 18;

export const ATTRIBUTION_TILE_STREET_LAYER =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
export const URL_TILE_STREET_LAYER = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const TILE_STREET_SUBDOMAINS = ['a', 'b', 'c'];

export const ATTRIBUTION_TILE_SATELLITE_LAYER =
  '&copy; <a href="https://maps.google.com">Google Maps</a>';
export const URL_TILE_SATELLITE_LAYER = 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';

export const TILE_SATELLITE_SUBDOMAINS = ['mt1', 'mt2', 'mt3'];
