import { PdfBrick, FlatQuestion, IRect } from 'survey-pdf';

import { MultiSignatureState } from './types';

interface TableSignature {
  displayName: string;
  signatureBefore?: string;
  signatureAfter?: string;
}

const FONT_SIZE = {
  L: 18,
  M: 14,
};

const RENDER_MODE = {
  OUTLINED: 'S',
  OUTLINED_FILLED: 'FD',
};

const WHITE = '#FFF';

/**
 * Custom PDF renderer built using [jsPDF API](https://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html)
 */
class MultiSignaturePdfBrick extends PdfBrick {
  static DEFAULT_TEXT_OPTIONS = {
    lineHeightFactor: 1,
    baseline: 'top',
  };

  static SIGNATURE_SIZE = 130;

  static VERTICAL_SPACE = 12;

  getFullAvailableWidth() {
    return this.controller.paperWidth - (this.controller.margins.right || 0) - this.xLeft;
  }

  renderText({
    text,
    x = this.xLeft,
    y = this.yTop,
    options = MultiSignaturePdfBrick.DEFAULT_TEXT_OPTIONS,
  }: {
    text: string;
    x?: number;
    y?: number;
    options?: Record<string, unknown>;
  }) {
    const { doc } = this.controller;

    return doc.text(text, x, y, options);
  }

  renderCaption(text: string) {
    const { doc } = this.controller;

    const prevFontSize = doc.getFontSize();
    const currentFontSize = FONT_SIZE.L;

    doc.setFontSize(currentFontSize);

    this.renderText({ text });

    this.yTop += currentFontSize;
    doc.setFontSize(prevFontSize);
  }

  addVerticalSpace() {
    this.yTop += MultiSignaturePdfBrick.VERTICAL_SPACE;
  }

  renderEmployeeSignatures(signatures: TableSignature[]) {
    const { doc } = this.controller;

    const textPadding = 8;

    const renderSignatureHeadings = () => {
      const prevFontSize = doc.getFontSize();
      const currentFontSize = FONT_SIZE.M;
      const prevXLeft = this.xLeft;

      const headingHeight = currentFontSize * 2 + textPadding * 2;

      this.renderText({
        text: 'Acknowledgement\nsignature',
        x: this.xLeft + textPadding,
        y: this.yTop + textPadding,
      });

      doc.rect(this.xLeft, this.yTop, MultiSignaturePdfBrick.SIGNATURE_SIZE, headingHeight);

      this.xLeft += MultiSignaturePdfBrick.SIGNATURE_SIZE;

      this.renderText({
        text: 'End of shift\nsignature',
        x: this.xLeft + textPadding,
        y: this.yTop + textPadding,
      });

      doc.rect(this.xLeft, this.yTop, MultiSignaturePdfBrick.SIGNATURE_SIZE, headingHeight);

      this.xLeft += MultiSignaturePdfBrick.SIGNATURE_SIZE;

      this.renderText({
        text: 'Employee',
        x: this.xLeft + textPadding,
        y: this.yTop + textPadding,
      });

      doc.rect(this.xLeft, this.yTop, this.getFullAvailableWidth(), headingHeight);

      this.xLeft = prevXLeft;

      this.yTop += headingHeight;

      doc.setFontSize(prevFontSize);
    };

    const renderSignatureRow = ({
      displayName,
      signatureBefore,
      signatureAfter,
    }: TableSignature) => {
      const prevFontSize = doc.getFontSize();
      const currentFontSize = FONT_SIZE.M;

      const prevXLeft = this.xLeft;

      doc.setFontSize(currentFontSize);

      const renderSignature = (signature?: string) => {
        if (signature) {
          doc.addImage(
            signature,
            'PNG',
            this.xLeft,
            this.yTop,
            MultiSignaturePdfBrick.SIGNATURE_SIZE,
            MultiSignaturePdfBrick.SIGNATURE_SIZE,
          );
        }

        const prevFillColor = doc.getFillColor();
        doc.setFillColor(WHITE);

        doc.rect(
          this.xLeft,
          this.yTop,
          MultiSignaturePdfBrick.SIGNATURE_SIZE,
          MultiSignaturePdfBrick.SIGNATURE_SIZE,
          signature ? RENDER_MODE.OUTLINED : RENDER_MODE.OUTLINED_FILLED,
        );

        doc.setFillColor(prevFillColor);

        this.xLeft += MultiSignaturePdfBrick.SIGNATURE_SIZE;
      };

      [signatureBefore, signatureAfter].forEach(renderSignature);

      this.renderText({
        text: displayName,
        x: this.xLeft + textPadding,
        y: this.yTop + MultiSignaturePdfBrick.SIGNATURE_SIZE / 2 - currentFontSize,
      });

      doc.rect(
        this.xLeft,
        this.yTop,
        this.getFullAvailableWidth(),
        MultiSignaturePdfBrick.SIGNATURE_SIZE,
      );

      doc.setFontSize(prevFontSize);
      this.xLeft = prevXLeft;
      this.yTop += MultiSignaturePdfBrick.SIGNATURE_SIZE;
    };

    renderSignatureHeadings();
    signatures.map(renderSignatureRow);
  }

  async renderInteractive() {
    const { doc } = this.controller;
    const { crewLeader, crewMembers } = (this.question.value || {}) as MultiSignatureState;

    const prevFontSize = doc.getFontSize();

    this.renderCaption('Crew leader:');

    this.addVerticalSpace();

    if (crewLeader) {
      this.renderEmployeeSignatures([
        {
          displayName: `${crewLeader.firstName} ${crewLeader.lastName} (${crewLeader.id})`,
          signatureBefore: crewLeader.preShiftMetadata?.signature,
          signatureAfter: crewLeader.postShiftMetadata?.signature,
        },
      ]);
    } else {
      this.renderText({ text: 'No crew leader data' });
    }

    this.addVerticalSpace();

    this.renderCaption('Crew members:');

    this.addVerticalSpace();

    if (crewMembers?.length) {
      const crewMemeberSignatures = crewMembers.map(
        ({ id, firstName, lastName, preShiftMetadata, postShiftMetadata }) => ({
          displayName: `${firstName} ${lastName} (${id})`,
          signatureBefore: preShiftMetadata?.signature,
          signatureAfter: postShiftMetadata?.signature,
        }),
      );

      this.renderEmployeeSignatures(crewMemeberSignatures);
    } else {
      this.renderText({ text: 'No crew members data' });
    }

    doc.setFontSize(prevFontSize);
  }
}

class FlatMultiSignature extends FlatQuestion {
  async generateFlatsContent(point: Pick<IRect, 'xLeft' | 'yTop'>) {
    const rect = {
      ...point,
      xRight: point.xLeft,
      yBot: point.yTop,
    };

    return [new MultiSignaturePdfBrick(this.question, this.controller, rect)];
  }
}

export { FlatMultiSignature };
