import { FC } from 'react';
import { Stack, Box, Typography, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { DATE_FORMAT } from 'src/shared/utils';
import { formatFileSize } from '../helpers/file';

type FileListProps = {
  files: File[];
  disabled: boolean;
  handleRemoveFile: (fileName: string) => void;
};

const WrapperFiles = styled(Stack)(({ theme }) => ({
  gap: '0.5rem',
}));
const WrapperFile = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '.5rem',
  border: '1px solid #C4C4C4',
  borderRadius: '4px',
}));

const FileList: FC<FileListProps> = ({ files, disabled = false, handleRemoveFile }) => {
  return (
    <WrapperFiles>
      {files.map(({ name, size, lastModified }) => (
        <WrapperFile key={name}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1">{name}</Typography>
            <IconButton
              onClick={() => handleRemoveFile(name)}
              aria-label="delete"
              size="small"
              disabled={disabled}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Typography
              variant="body2"
              component="span"
              color="gray"
            >
              Size: {formatFileSize(size)}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              color="gray"
            >
              Modified Time: {dayjs(lastModified).format(DATE_FORMAT.DEFAULT_ADMIN_PAGE)}
            </Typography>
          </Box>
        </WrapperFile>
      ))}
    </WrapperFiles>
  );
};

export { FileList };
