import { TicketStatus } from 'src/shared/types';

import { TagType } from '../tag';

type TicketStatusOption = {
  text: TicketStatus;
  type: TagType;
};

export const ticketStatusOptions: TicketStatusOption[] = [
  {
    text: TicketStatus.Scheduled,
    type: 'success',
  },
  {
    text: TicketStatus.ReadyToDispatch,
    type: 'light',
  },
];
