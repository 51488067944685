import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Tooltip, Typography, ListSubheader, List, ListItem } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link } from 'react-router-dom';

import { SurveyEntity, TableManagerFieldValue } from 'src/shared/types';
import { getFieldValue } from 'src/shared/utils';

import { NavigateButton } from '../NavigateButton';

type AdminSurveyFormNavigatorProps = {
  items: Pick<SurveyEntity, 'id' | 'name'>[];
  state: Record<string, TableManagerFieldValue>;
  label: string;
  withNavigation?: boolean;
};

const WrapperNavigator = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '.5rem',
  border: '1px solid #C4C4C4',
  borderRadius: '4px',
}));

const CustomList = styled(List)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
}));
const CustomListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  width: '100%',
}));
const CustomListItemContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '1rem',
  width: '100%',
}));
const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));
const CustomListSubheader = styled(ListSubheader)(({ theme }) => ({
  fontWeight: 700,
  padding: 0,
  lineHeight: '2rem',
}));

const assignmentId = 'assignmentId';
const inspectorId = 'inspectorId';

const AdminSurveyFormNavigator: FC<AdminSurveyFormNavigatorProps> = ({
  items,
  state,
  label,
  withNavigation = true,
}) => {
  const assignmentIdValue = getFieldValue(state, assignmentId);
  const inspectorIdValue = getFieldValue(state, inspectorId);

  return (
    <WrapperNavigator>
      <CustomListSubheader>{label.charAt(0).toUpperCase() + label.slice(1)}</CustomListSubheader>

      {items.length > 0 && (
        <CustomList>
          {items.map(({ name, id }) => (
            <CustomListItem key={id}>
              <RadioButtonUncheckedIcon
                sx={{
                  fontSize: '0.5rem',
                }}
              />
              <CustomListItemContent>
                <Typography display="inline">{name}</Typography>
                {withNavigation && (
                  <ActionsWrapper>
                    <Tooltip
                      title="Submit New Form"
                      placement="top"
                    >
                      <NavigateButton
                        variant="contained"
                        color="success"
                        component={Link}
                        to={`/forms/${id}?AssignmentID=${assignmentIdValue}&InspectorID=${inspectorIdValue}`}
                      >
                        <AddIcon />
                      </NavigateButton>
                    </Tooltip>

                    <Tooltip
                      title="View Survey Answers"
                      placement="top"
                    >
                      <NavigateButton
                        variant="contained"
                        color="info"
                        component={Link}
                        to={`/forms/${id}/answers`}
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </NavigateButton>
                    </Tooltip>
                  </ActionsWrapper>
                )}
              </CustomListItemContent>
            </CustomListItem>
          ))}
        </CustomList>
      )}

      {items.length === 0 && (
        <Typography
          variant="body1"
          fontWeight={600}
        >
          There are no related forms yet.
        </Typography>
      )}
    </WrapperNavigator>
  );
};

export { AdminSurveyFormNavigator };
