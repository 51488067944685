import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  TypeNotation,
  RelationOptionsMap,
  MetadataMap,
  TableManagerFieldValue,
  TagType,
} from 'src/shared/types';
import { useScreenBreakpoints } from 'src/shared/hooks/useScreenBreakpoints';

import { DetailsFieldDialog } from '../DetailsFieldDialog';
import { getGridClasses } from '../../helpers';

interface IDetailsFieldsDialogProps {
  selectedRow: Record<string, TableManagerFieldValue> | null;
  schema: TypeNotation[];
  relationOptions: RelationOptionsMap;
  fieldsMetadata: MetadataMap;
}

const DetailsFieldsDialog: FC<IDetailsFieldsDialogProps> = ({
  selectedRow,
  schema,
  relationOptions,
  fieldsMetadata,
}) => {
  const { modelName } = useParams<{ modelName: TagType }>();

  const deviceStatus = useScreenBreakpoints();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {selectedRow &&
        schema.map((schemaElement) => {
          if (fieldsMetadata?.details && fieldsMetadata?.details?.[schemaElement.field]?.hidden) {
            return null;
          }

          if (!modelName) return null;

          const isCustomComponent =
            modelName &&
            fieldsMetadata?.customComponents?.[modelName]?.[schemaElement.field]?.field ===
              schemaElement.field;

          const gridClasses = isCustomComponent
            ? getGridClasses(
                fieldsMetadata?.customComponents?.[modelName]?.[schemaElement.field]?.gridColumns,
                deviceStatus,
              )
            : {};

          return (
            <Box
              sx={{
                ...gridClasses,
              }}
              key={schemaElement.field}
            >
              <DetailsFieldDialog
                selectedRow={selectedRow}
                schemaElement={schemaElement}
                relationOptions={relationOptions}
                fieldsMetadata={fieldsMetadata}
              />
            </Box>
          );
        })}
    </div>
  );
};

export { DetailsFieldsDialog };
