import React, { FC } from 'react';
import dayjs from 'dayjs';

import { JobEntity } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';

import { getFormattedDate } from './helpers';
import { DetailsMeta } from './detailsMeta';

type JobDetailsProps = {
  job?: JobEntity;
};

const JobDetails: FC<JobDetailsProps> = ({ job }) => {
  if (!job) {
    return <Typography variant="h3">There is no information about this Job</Typography>;
  }

  const {
    projectName,
    workRequestNotes,
    owner,
    ownerContract,
    ownerLocation,
    ownerContact,
    ownerRepresentative,
    wo,
    po,
    department,
    serviceLine,
    serviceType,
    shiftType,
    requestedStartDate,
    requestedCompleteDate,
    isLunchAllowed,
    jobCategory,
  } = job;

  const getMeta = () => {
    return (
      // TODO: move duplicated styles to the separate components (e.g. detailsSection)
      <div className="flex flex-col gap-[20px] border border-solid border-textColor-light rounded-2xl p-4">
        <DetailsMeta meta={job} />
      </div>
    );
  };

  const getOwnerDetails = () => (
    <div className="flex flex-col gap-[25px] border border-solid border-textColor-light rounded-2xl p-4">
      <div className="flex flex-col gap-1">
        <Typography variant="h3">Owner Details</Typography>

        <Typography
          fontWeight="medium"
          variant="p1"
          className="break-all"
        >
          {owner?.OwnerName || '-'}
        </Typography>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <Typography variant="label">Rate Sheet</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {ownerContract?.RateSheetDescription || '-'}
          </Typography>
        </div>

        <div className="flex flex-col gap-1">
          <Typography variant="label">Location</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {ownerLocation?.OwnerLocation || '-'}
          </Typography>
        </div>

        <div className="flex justify-between gap-5">
          <div className="w-[50%] break-all">
            <Typography variant="label">Contact</Typography>

            <Typography
              fontWeight="medium"
              variant="p1"
              className="max-w-[221px] break-all"
            >
              {ownerContact?.OwnerPersonnelEmail || '-'}
            </Typography>
          </div>

          <div className="w-[50%] break-all">
            <Typography variant="label">Representative</Typography>

            <Typography
              fontWeight="medium"
              variant="p1"
              className="break-all"
            >
              {ownerRepresentative?.OwnerPersonnelEmail || '-'}
            </Typography>
          </div>
        </div>

        <div className="flex justify-between gap-5">
          <div className="w-[50%] break-all">
            <Typography variant="label">W.O</Typography>

            <Typography
              fontWeight="medium"
              variant="p1"
              className="max-w-[221px] break-all"
            >
              {wo || '-'}
            </Typography>
          </div>

          <div className="w-[50%] break-all">
            <Typography variant="label">P.O</Typography>

            <Typography
              fontWeight="medium"
              variant="p1"
              className="break-all"
            >
              {po || '-'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );

  const getJobDetails = () => (
    <div className="flex flex-col gap-[25px] border border-solid border-textColor-light rounded-2xl p-[18px]">
      <div className="flex justify-between gap-2">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Job Details
        </Typography>

        <Typography
          variant="label"
          className="font-normal text-textColor-tertiary break-all"
        >
          {jobCategory?.Category || '-'}
        </Typography>
      </div>

      <div className="flex flex-col gap-1">
        <Typography variant="label">Job Number</Typography>

        <Typography
          fontWeight="medium"
          variant="p1"
          className="break-all"
        >
          {job?.sro || 'Pending'}
        </Typography>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <Typography variant="label">Department</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {department?.ProviderDivisionName || '-'}
          </Typography>
        </div>

        <div className="flex flex-col gap-1">
          <Typography variant="label">Service Line</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {serviceLine?.ServiceLineDescription || '-'}
          </Typography>
        </div>

        <div className="flex flex-col gap-1">
          <Typography variant="label">Service Type</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {serviceType?.ServiceTypeDescription || '-'}
          </Typography>
        </div>

        <div className="flex flex-col gap-1">
          <Typography variant="label">Job Identifier</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {projectName}
          </Typography>
        </div>
      </div>
    </div>
  );

  const getDescriptionDetails = () => (
    <div className="flex flex-col gap-5 border border-solid border-textColor-light rounded-2xl p-[18px]">
      <Typography
        variant="p1"
        fontWeight="bold"
      >
        Description
      </Typography>

      <Typography
        fontWeight="medium"
        variant="p1"
        className="break-all"
      >
        {workRequestNotes}
      </Typography>
    </div>
  );

  const getScheduleDetails = () => (
    <div className="flex flex-col gap-[25px] border border-solid border-textColor-light rounded-2xl p-[18px]">
      <div className="flex justify-between gap-2">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Schedule
        </Typography>

        <Typography
          variant="label"
          className="font-normal text-textColor-tertiary break-all"
        >
          {shiftType || '-'}
        </Typography>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <Typography variant="label">Due Date</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {`${getFormattedDate(
              String(dayjs(requestedStartDate).add(8, 'hour')),
            )} - ${getFormattedDate(String(dayjs(requestedCompleteDate).add(8, 'hour')))}`}
          </Typography>
        </div>

        <div className="flex justify-between gap-5">
          <div className="w-[50%] break-all">
            <Typography variant="label">Lunch</Typography>

            <Typography
              fontWeight="medium"
              variant="p1"
              className="break-all"
            >
              {isLunchAllowed === 'Yes' ? 'Allowed' : 'Not allowed'}
            </Typography>
          </div>

          <div className="w-[50%] break-all">
            <Typography variant="label">Shift Time</Typography>

            <Typography
              fontWeight="medium"
              variant="p1"
              className="break-all"
            >
              -
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {getMeta()}

      {getOwnerDetails()}

      {getJobDetails()}

      {getDescriptionDetails()}

      {getScheduleDetails()}
    </>
  );
};

export { JobDetails };
