import { FC, useState, PropsWithChildren, HTMLAttributes } from 'react';
import { useMediaQuery, Dialog, SxProps, Theme, DialogProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type ResponsiveDialogProps = PropsWithChildren<DialogProps & HTMLAttributes<HTMLElement>> & {
  sx?: SxProps<Theme>;
};

const ResponsiveDialog: FC<ResponsiveDialogProps> = ({ children, sx, ...rest }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      aria-labelledby="responsive-dialog-title"
      {...rest}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: fullScreen ? '100%' : '80%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          ...sx,
        },
      }}
    >
      {children}
    </Dialog>
  );
};

export { ResponsiveDialog };
