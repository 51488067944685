import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';

import { api } from 'src/store/api/api';

import {
  schedulerBoardSettingsReducer,
  calendarReducer,
  filtersReducer,
  authReducer,
  configReducer,
  adminTableReducer,
} from './slices';
import { modalConfigReducer } from './slices/modalConfig';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'calendar'],
};

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

const reducers = combineReducers({
  calendar: calendarReducer,
  schedulerBoardSettings: schedulerBoardSettingsReducer,
  filters: filtersReducer,
  auth: authReducer,
  config: configReducer,
  modalConfig: modalConfigReducer,
  adminTable: adminTableReducer,
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const useAppDispatch: () => AppDispatch = useDispatch;
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

export const persistor = persistStore(store);
export { store, useAppDispatch };
export type { RootState, AppDispatch };
