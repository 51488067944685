import { FC, PropsWithChildren } from 'react';
import { match } from 'ts-pattern';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid, GridCellProps } from 'react-virtualized';
import { useSelector } from 'react-redux';

import { LaborEntity, PersonEntity, Role, TicketEntity } from 'src/shared/types';
import { PersonCard, PersonCardMode } from 'src/shared/ui/personCard';
import { not, sortAssignedPeople } from 'src/shared/utils';
import { Typography } from 'src/shared/ui/typography';
import { clsx } from 'src/shared/utils/clsx';
import { selectCurrentUser } from 'src/store/slices';
import { PersonDropfield } from 'src/shared/ui/peopleList/ui/personDropfield';

import { UniversalPersonDropfield } from './ui/universalPersonDropfield';

type PeopleListProps = {
  people?: PersonEntity[];
  employees?: string[];
  crewLeader?: LaborEntity | null;
  cardId?: string;
  ticket?: TicketEntity;
  isDropDisabled?: boolean;
  isTicketCard?: boolean;
  onEmployeeDrop?: (item: PersonEntity, sourceEntityId: string) => void;
  onCrewLeaderDrop?: (item: LaborEntity, sourceEntityId: string) => void;
};

const PeopleList: FC<PropsWithChildren<PeopleListProps>> = ({
  people = [],
  employees,
  crewLeader,
  cardId,
  ticket,
  isDropDisabled = false,
  isTicketCard = false,
  onEmployeeDrop,
  onCrewLeaderDrop,
}) => {
  const user = useSelector(selectCurrentUser);

  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const filteredRequiredLaborList = ticket?.labor.filter((el) => not(el.ProviderPersonnel)) || [];

  const isCrewLeaderAssigned = !!crewLeader;

  const cache = new CellMeasurerCache({
    defaultHeight: 68,
    fixedWidth: true,
  });

  const sortedPeople = sortAssignedPeople(people);

  const cellRenderer = ({ key, rowIndex, style, parent }: GridCellProps) => {
    const person = sortedPeople[rowIndex];

    const sourceEntityId = ticket?.labor.find(
      (el) => el.ProviderPersonnel?.providerPersonnelID === person.id,
    )?.PeopleWorkRecordID;
    const withMoreButton =
      not(isCrewLeaderAssigned) || person.id === crewLeader?.ProviderPersonnel?.id;

    if (not(person)) return null;

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          key={key}
          style={{
            ...style,
            paddingBottom: 4,
          }}
        >
          <PersonCard
            isTicketCard={false}
            disabled={employees?.includes(person.id)}
            clearAfterDrag
            cardId={cardId}
            ticket={ticket}
            key={`${person.firstName} ${person.lastName} ${person.id} ${ticket?.id} labor`}
            withMoreButton={withMoreButton}
            sourceEntityId={sourceEntityId}
            onCrewLeaderAssign={onCrewLeaderDrop}
            type="person"
            {...person}
          />
        </div>
      </CellMeasurer>
    );
  };

  const peopleContent = match(sortedPeople.length)
    .when(
      (peopleLength) => peopleLength === 0 && not(isDropDisabled),
      () => (
        <div className={clsx('grid grid-cols-2 gap-2 relative', isTicketCard && 'grid-cols-1')}>
          {filteredRequiredLaborList.map((el) => (
            <PersonDropfield
              key={el.PeopleWorkRecordID}
              title={`Drop ${el.ContractRateSheet.CustomItemDescription} here to assign`}
              entityType="person"
              sourceEntityId={el.PeopleWorkRecordID}
              onPersonDrop={onEmployeeDrop}
              isDropDisabled={isDropDisabled}
            />
          ))}

          {crewLeader && (
            <PersonCard
              clearAfterDrag
              cardId={cardId}
              ticket={ticket}
              mode={PersonCardMode.CrewLeader}
              {...(crewLeader.ProviderPersonnel as PersonEntity)}
              disabled={isTicketCard && isDropDisabled}
              isTicketCard={isTicketCard}
              withMoreButton
              type="crewLeader"
              onCrewLeaderAssign={onCrewLeaderDrop}
            />
          )}

          {not(isReadonly) && (
            <UniversalPersonDropfield
              ticket={ticket}
              isDropDisabled={isDropDisabled}
            />
          )}

          {not(ticket?.labor.length) && not(isCrewLeaderAssigned) && isReadonly && (
            <PersonDropfield
              title="There is no required labor"
              entityType="person"
              isDropDisabled={isDropDisabled}
            />
          )}
        </div>
      ),
    )
    .when(
      () =>
        not(ticket?.labor.length) && not(isCrewLeaderAssigned) && isTicketCard && isDropDisabled,
      () => (
        <PersonDropfield
          title="There was no required labor"
          entityType="person"
          isDropDisabled={isDropDisabled}
        />
      ),
    )
    .when(
      () => not(ticket?.labor.length) && isCrewLeaderAssigned && isTicketCard && isDropDisabled,
      () =>
        crewLeader && (
          <PersonCard
            clearAfterDrag
            cardId={cardId}
            ticket={ticket}
            mode={PersonCardMode.CrewLeader}
            {...(crewLeader.ProviderPersonnel as PersonEntity)}
            disabled={isDropDisabled}
            isTicketCard={isTicketCard}
            withMoreButton={not(isDropDisabled)}
            type="crewLeader"
            onCrewLeaderAssign={onCrewLeaderDrop}
          />
        ),
    )
    .when(
      (peopleLength) => peopleLength === 0 && isTicketCard && isDropDisabled,
      () => (
        <>
          {crewLeader && (
            <PersonCard
              clearAfterDrag
              cardId={cardId}
              ticket={ticket}
              mode={PersonCardMode.CrewLeader}
              {...(crewLeader.ProviderPersonnel as PersonEntity)}
              disabled={isDropDisabled}
              isTicketCard={isTicketCard}
              withMoreButton={not(isDropDisabled)}
              type="crewLeader"
              onCrewLeaderAssign={onCrewLeaderDrop}
            />
          )}

          {filteredRequiredLaborList.map((el) => (
            <PersonDropfield
              key={el.PeopleWorkRecordID}
              title={`${el.ContractRateSheet.CustomItemDescription} was not assigned`}
              entityType="person"
              sourceEntityId={el.PeopleWorkRecordID}
              onPersonDrop={onEmployeeDrop}
              isDropDisabled={isDropDisabled}
            />
          ))}
        </>
      ),
    )
    .when(
      (peopleLength) => peopleLength === 0 && not(isTicketCard),
      () => <Typography variant="p1">There is no people matching search criteria</Typography>,
    )
    .when(
      (peopleLength) => peopleLength > 0 && isTicketCard,
      () => (
        <div className={clsx('grid grid-cols-1 gap-2 relative')}>
          {crewLeader && (
            <PersonCard
              clearAfterDrag
              cardId={cardId}
              ticket={ticket}
              mode={PersonCardMode.CrewLeader}
              {...(crewLeader.ProviderPersonnel as PersonEntity)}
              disabled={isDropDisabled}
              isTicketCard={isTicketCard}
              withMoreButton={not(isDropDisabled)}
              type="crewLeader"
              onCrewLeaderAssign={onCrewLeaderDrop}
            />
          )}

          {isDropDisabled &&
            filteredRequiredLaborList.map((el) => (
              <PersonDropfield
                key={el.PeopleWorkRecordID}
                title={`${el.ContractRateSheet.CustomItemDescription} was not assigned`}
                entityType="person"
                sourceEntityId={el.PeopleWorkRecordID}
                onPersonDrop={onEmployeeDrop}
                isDropDisabled={isDropDisabled}
              />
            ))}

          {not(isDropDisabled) &&
            filteredRequiredLaborList.map((el) => (
              <PersonDropfield
                key={el.PeopleWorkRecordID}
                title={`Drop ${el.ContractRateSheet.CustomItemDescription} here to assign`}
                entityType="person"
                sourceEntityId={el.PeopleWorkRecordID}
                onPersonDrop={onEmployeeDrop}
                isDropDisabled={isDropDisabled}
              />
            ))}

          {sortedPeople.map((person) => (
            <PersonCard
              isTicketCard={isTicketCard}
              disabled={isDropDisabled}
              clearAfterDrag
              cardId={cardId}
              ticket={ticket}
              key={`${person.firstName} ${person.lastName} ${person.id} ${ticket?.id} labor`}
              {...person}
              type="person"
              withMoreButton={
                (not(isCrewLeaderAssigned) || person.id === crewLeader?.ProviderPersonnel?.id) &&
                not(isDropDisabled)
              }
              sourceEntityId={
                ticket?.labor.find((el) => el.ProviderPersonnel?.providerPersonnelID === person.id)
                  ?.PeopleWorkRecordID
              }
              onCrewLeaderAssign={onCrewLeaderDrop}
            />
          ))}

          {not(isReadonly) && not(isDropDisabled) && (
            <UniversalPersonDropfield
              ticket={ticket}
              isDropDisabled={isDropDisabled}
            />
          )}
        </div>
      ),
    )
    .when(
      (peopleLength) => peopleLength > 0 && not(isTicketCard),
      () => (
        <div className="h-[550px]">
          <AutoSizer>
            {({ height, width }) => (
              <Grid
                cellRenderer={cellRenderer}
                columnCount={1}
                columnWidth={width - 15}
                height={height}
                rowCount={sortedPeople.length}
                rowHeight={(index) => cache.rowHeight(index) + 4}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      ),
    )
    .otherwise(() => null);

  return <div className={clsx('flex flex-col gap-y-2')}>{peopleContent}</div>;
};

export { PeopleList };
export type { PeopleListProps };
