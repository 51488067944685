import React, { FC } from 'react';

import { SelectInput, SelectInputItem } from '../selectInput';
import { getTimePickerOptions } from '../../utils';
import { TextFieldProps } from '../textField';

type TimePickerInputProps = TextFieldProps & {
  label: string;
  name: string;
  value: string;
  handleSelect: (name: string, value: string) => void;
  isRequired?: boolean;
  onClear?: (name: string) => void;
};

const TimePickerInput: FC<TimePickerInputProps> = ({
  label,
  name,
  value,
  isRequired = false,
  handleSelect,
  onClear,
  className,
}) => {
  const timePickerOptions = getTimePickerOptions(name.toLowerCase().includes('duration'));

  const getTimepickerItems = (name: string, value: string) => {
    const timepickerItems =
      timePickerOptions.map((time) => {
        const formattedTime = time.replace(':', '');

        return {
          label: <SelectInputItem selected={value === formattedTime}>{time}</SelectInputItem>,
          value: formattedTime,
          onClick: () => handleSelect(name, formattedTime),
        };
      }) ?? [];

    return timepickerItems;
  };

  const handleClear = () => {
    if (onClear) {
      onClear(name);
    }
  };

  return (
    <SelectInput
      name={name}
      isRequired={isRequired}
      required={isRequired}
      value={value}
      label={label}
      placeholder="hh:mm"
      onClear={handleClear}
      items={getTimepickerItems(name, value)}
      className={className}
    />
  );
};

export { TimePickerInput };
