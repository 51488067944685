import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { JobEntity } from 'src/shared/types';
import { not } from 'src/shared/utils';

type CardId = string;
type CardHeight = number;
type CardDate = string;
type InitialState = {
  filterAssignedForDate: string;
  shouldScrollToToday: boolean;
  cards: {
    [key in CardId]: {
      heights: Record<CardDate, CardHeight>;
      row?: number;
      column?: number;
      isOpen: boolean;
      startDate: string;
      endDate: string;
    };
  };
};

const initialState: InitialState = {
  filterAssignedForDate: '',
  cards: {},
  shouldScrollToToday: false,
};

const schedulerBoardSettingsSlice = createSlice({
  name: 'schedulerBoardSettings',
  initialState,
  reducers: {
    setCardSettings: (
      state,
      action: PayloadAction<
        Pick<JobEntity, 'id' | 'requestedStartDate' | 'requestedCompleteDate'>[]
      >,
    ) => {
      action.payload.forEach(({ id, requestedStartDate, requestedCompleteDate }) => {
        if (not(state.cards[id]))
          state.cards[id] = {
            heights: {},
            isOpen: true,
            row: 0,
            column: 0,
            startDate: requestedStartDate as string,
            endDate: requestedCompleteDate as string,
          };
      });
    },
    addCardHeight: (
      state,
      action: PayloadAction<{ id: string; item: { date: string; height: number } }>,
    ) => {
      const { id, item } = action.payload;

      if (state.cards[id]) state.cards[id].heights[item.date] = item.height;
    },
    changeCardRow: (state, action: PayloadAction<{ id: string; row: number }>) => {
      const { id, row } = action.payload;

      if (state.cards[id]) state.cards[id].row = row;
    },
    changeCardRows: (state, action: PayloadAction<{ id: string; row: number }[]>) => {
      action.payload.forEach(({ id, row }) => {
        if (state.cards[id]) state.cards[id].row = row;
      });
    },
    changeCardColumn: (state, action: PayloadAction<{ id: string; column: number }>) => {
      const { id, column } = action.payload;

      if (state.cards[id]) state.cards[id].column = column;
    },
    changeIsOpen: (state, action: PayloadAction<{ id: string; isOpen: boolean }>) => {
      const { id, isOpen } = action.payload;

      if (state.cards[id]) state.cards[id].isOpen = isOpen;
    },
    changeShouldScrollToToday: (state, action: PayloadAction<boolean>) => {
      state.shouldScrollToToday = action.payload;
    },
    changeFilterAssignedForDate: (state, action: PayloadAction<string>) => {
      state.filterAssignedForDate = action.payload;
    },
    clearCardData: (state, action: PayloadAction<string>) => {
      delete state.cards[action.payload];
    },
    resetRows: (state) => {
      Object.keys(state.cards).forEach((cardId) => {
        state.cards[cardId].row = undefined;
      });
    },
  },
});

const schedulerBoardSettingsReducer = schedulerBoardSettingsSlice.reducer;
const schedulerBoardSettingsActions = schedulerBoardSettingsSlice.actions;

export { schedulerBoardSettingsReducer, schedulerBoardSettingsActions };
