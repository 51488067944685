import dayjs from 'dayjs';

export const getFormattedDate = (dateToFormat: string, withYear = false, timezone?: string) => {
  const date = new Date(dayjs(dateToFormat).format());

  if (timezone) {
    const timezoneDate = dayjs.utc(dateToFormat).tz(timezone);

    const month = timezoneDate.format('MMM');
    const dayOfMonth = timezoneDate.format('D');
    const year = timezoneDate.format('YYYY');
    const dayOfWeek = timezoneDate.format('dddd');

    return `${month}. ${dayOfMonth} (${dayOfWeek}) ${withYear ? year : ''}`;
  }

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dayOfWeekNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  const dayOfWeek = dayOfWeekNames[date.getDay()];

  const formattedDate = `${month}. ${day} (${dayOfWeek}) ${withYear ? year : ''}`;

  return formattedDate;
};
