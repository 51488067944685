import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { DndProvider, TouchTransition, MouseTransition } from 'react-dnd-multi-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { usePreview } from 'react-dnd-preview';
import { match } from 'ts-pattern';
import { slk } from 'survey-core';

import { Router } from './router';
import { persistor, store } from './store';
import { PersonCard, PersonCardMode } from './shared/ui/personCard';
import { EquipmentEntity, PersonEntity } from './shared/types';
import { EquipmentCard } from './shared/ui/equipmentCard';

// * all the packages versions should be the same to make the `buy banner` go away
slk(process.env.REACT_APP_SURVEY_KEY || '');

export const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
      preview: false,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      transition: TouchTransition,
      preview: true,
    },
  ],
};

export const DragSourceType = {
  CrewLeader: 'crewLeader',
  Person: 'person',
  Supervsior: 'supervisor',
  Equipment: 'equipment',
} as const;

export type UserType = (typeof DragSourceType)[keyof typeof DragSourceType] | (string & object);

const MyPreview = () => {
  const preview = usePreview();

  if (!preview.display) {
    return null;
  }

  const { itemType, item, style } = preview;

  const content = match(itemType as UserType)
    .with(DragSourceType.Person, () => (
      <PersonCard
        {...(item as PersonEntity)}
        type={DragSourceType.Person}
        mode={PersonCardMode.Employee}
        isTicketCard
      />
    ))
    .with(DragSourceType.CrewLeader, () => (
      <PersonCard
        {...(item as PersonEntity)}
        type={DragSourceType.CrewLeader}
        mode={PersonCardMode.CrewLeader}
        isTicketCard
      />
    ))
    .with(DragSourceType.Supervsior, () => (
      <PersonCard
        {...(item as PersonEntity)}
        type={DragSourceType.Supervsior}
        mode={PersonCardMode.Supervisor}
        isTicketCard
      />
    ))
    .with(DragSourceType.Equipment, () => <EquipmentCard {...(item as EquipmentEntity)} />)
    .otherwise(() => undefined);

  return <div style={style}>{content}</div>;
};

const App = () => {
  useEffect(() => {
    // | Remove redundant error message from ResizeObserver.
    window.addEventListener('error', (e) => {
      if (
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message === 'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <DndProvider options={HTML5toTouch}>
          <Router />

          <MyPreview />

          <ToastContainer toastClassName="bg-[#F3F5F9] text-textColor-primary" />
        </DndProvider>
      </PersistGate>
    </Provider>
  );
};

export { App };
