/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { match } from 'ts-pattern';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { FC } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { clsx, truncateText } from 'src/shared/utils';
import { NavigationLinkProps } from 'src/shared/ui/layout/navigationLink';
import { SvgRenderer } from 'src/shared/ui/svgRenderer';

type PrimaryCardProps = NavigationLinkProps & {
  index: number;
};

const PrimaryCard: FC<PrimaryCardProps> = ({
  label,
  description,
  icon,
  link,
  isAppLink,
  index,
  disabled: isDisabled = false,
}) => {
  const navigate = useNavigate();

  const containerClassName = match(index)
    .with(0, () => 'bg-[#E9F4FE] hover:bg-[#2393FD]')
    .with(1, () => 'bg-[#F1EDF4] hover:bg-[#794F92]')
    .with(2, () => 'bg-[#F2F7E5] hover:bg-[#7DB800]')
    .with(3, () => 'bg-[#FFF4E5] hover:bg-[#FF9900]')
    .with(4, () => 'bg-[#FEF9E8] hover:bg-[#FEC620]')
    .otherwise(() => undefined);

  const iconContainerClassName = match(index)
    .with(0, () => 'bg-[#C1E0FE] group-hover:bg-[#1E7CD6]')
    .with(1, () => 'bg-[#D9CDE0] group-hover:bg-[#5F3E73]')
    .with(2, () => 'bg-[#DAEAB7] group-hover:bg-[#669600]')
    .with(3, () => 'bg-[#FFE2B7] group-hover:bg-[#CC7E0A]')
    .with(4, () => 'bg-[#FEEFC0] group-hover:bg-[#E5B010]')
    .otherwise(() => undefined);

  const content = (
    <>
      <div
        className={clsx(
          'flex justify-center items-center w-[80px] h-[80px] duration-200 ease-in-out rounded-[13px]',
          iconContainerClassName,
        )}
      >
        <SvgRenderer
          svgUrl={icon}
          className={`[&>svg]:fill-black group-hover:[&>svg]:fill-white `}
        />
      </div>

      <Typography
        variant="p1"
        fontWeight="bold"
        className="text-textColor-black text-center duration-200 ease-in-out group-hover:text-textColor-white"
      >
        {truncateText(label, 18)}
      </Typography>

      <Tooltip
        id={`description-${label}`}
        place="bottom"
        content={description}
        className="max-w-[250px] z-[1] bg-[rgba(0,0,0,0.7)]"
      />
    </>
  );

  const className = clsx(
    'group flex flex-col justify-center items-center gap-1 w-[186px] p-3 border border-solid border-textColor-light rounded-[10px] duration-200 ease-in-out',
    containerClassName,
    isDisabled && 'cursor-not-allowed',
  );

  const handleClick = () => {
    if (isAppLink) {
      navigate(link);
      return;
    }

    window.open(link, '_blank', 'noreferrer');
  };

  return (
    <button
      type="button"
      className={className}
      onClick={handleClick}
      data-tooltip-id={`description-${label}`}
      disabled={isDisabled}
    >
      {content}
    </button>
  );
};

export { PrimaryCard };
