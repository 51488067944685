import { FC } from 'react';
import { useSelector } from 'react-redux';

import { showToastErrorMessage, not } from 'src/shared/utils';
import {
  type EquipmentEntity,
  type JobEntity,
  type TicketEntity,
  EquipmentTab,
  Role,
} from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { EquipmentList } from 'src/shared/ui/equipmentList';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';
import { IconButton } from 'src/shared/ui/iconButton';
import {
  useAssignToTicketMutation,
  useGetEquipmentQuery,
  TicketConnections,
  useGetRentalEquipmentQuery,
} from 'src/store/api';
import { useAppDispatch } from 'src/store';
import {
  modalConfigActions,
  OpenModalId,
  OpenModalIdType,
  selectCurrentUser,
} from 'src/store/slices';

type TicketCardEquipmentListProps = {
  ticket: TicketEntity;
  job: JobEntity;
  isTicketDropDisabled: boolean;
};

const TicketCardEquipmentList: FC<TicketCardEquipmentListProps> = ({
  ticket,
  job,
  isTicketDropDisabled,
}) => {
  const dispatch = useAppDispatch();

  const [assignToTicket] = useAssignToTicketMutation();

  const { data: equipmentData = [] } = useGetEquipmentQuery({});
  const { data: rentalEquipmentData = [] } = useGetRentalEquipmentQuery({});

  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const ownedEquipment = equipmentData?.reduce<EquipmentEntity[]>((acc, equipment) => {
    const assignedOwnedEquipment = ticket?.equipment?.find(
      (assignedEquipment) =>
        assignedEquipment?.UnitNumber === equipment.AssetID && not(assignedEquipment.IsRental),
    );

    if (assignedOwnedEquipment) {
      acc.push({
        ...equipment,
        KITItemsRecordID: assignedOwnedEquipment.KITItemsRecordID,
      });
    }

    return acc;
  }, []);

  const rentalEquipment = rentalEquipmentData?.reduce<EquipmentEntity[]>((acc, equipment) => {
    const assignedRentalEquipment = ticket?.equipment?.filter(
      (assignedEquipment) =>
        assignedEquipment?.RentalUnitNumber === equipment.AssetID && assignedEquipment.IsRental,
    );

    assignedRentalEquipment.forEach((assigned) => {
      acc.push({
        ...equipment,
        KITItemsRecordID: assigned.KITItemsRecordID,
      });
    });

    return acc;
  }, []);

  const ticketEquipment = [...ownedEquipment, ...rentalEquipment].sort(
    (a, b) => (b?.UnitDescription?.length || 0) - (a?.UnitDescription?.length || 0),
  );

  const openCreateAdditionalItemsModal = (modalId: OpenModalIdType) => {
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(ticket.id));
    dispatch(modalConfigActions.setOpenModalId(modalId));
  };

  const handleEquipmentDrop = async (equipment: EquipmentEntity, sourceEntityId: string) => {
    try {
      await assignToTicket({
        ticketId: ticket.id,
        entityId: equipment.AssetID,
        entityType: TicketConnections.equipmentItem,
        sourceEntityId,
        type: equipment.IsRental ? EquipmentTab.rental : EquipmentTab.owned,
      }).unwrap();
    } catch (error) {
      showToastErrorMessage(
        `Sorry, an error occurred, when you tried to assign ${equipment?.UnitDescription} for a ticket, please check your internet connection`,
      );
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography
          variant="p2"
          fontWeight="medium"
        >
          Equipment
        </Typography>

        {not(isTicketDropDisabled) && not(isReadonly) && (
          <IconButton
            color="basic"
            size="sm"
            iconSize="sm"
            className="bg-bgColor-ticketCard"
            iconClassName="fill-textColor-tertiary"
            onClick={() => openCreateAdditionalItemsModal(OpenModalId.CreateEquipment)}
          >
            <PlusIcon />
          </IconButton>
        )}
      </div>

      <EquipmentList
        equipment={ticketEquipment}
        onEquipmentDrop={handleEquipmentDrop}
        cardId={job.id}
        ticket={ticket}
        isDropDisabled={isTicketDropDisabled}
        isTicketCard
      />
    </>
  );
};

TicketCardEquipmentList.displayName = 'TicketCardEquipmentList';
export { TicketCardEquipmentList };
