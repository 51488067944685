import { OwnerLocation } from 'src/shared/types';
import { api } from 'src/store/api/api';

const ownerLocationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOwnerLocations: builder.query<OwnerLocation[], ''>({
      query: () => ({
        url: '/owner-locations',
      }),
      providesTags: ['OwnerLocations'],
      transformResponse: (response: { data: OwnerLocation[] }) => response.data,
    }),
  }),
});

export const { useGetOwnerLocationsQuery } = ownerLocationApi;
