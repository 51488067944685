import { FC, useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
  LabelList,
} from 'recharts';
import { styled } from '@mui/material/styles';
import { Box, Divider, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { WrapperChart } from '../WrapperChart';
import { ItemChartData } from 'src/shared/types';
import { getColorForItem } from '../../helpers/functions';

type BarChartViewProps = {
  title: string;
  items: ItemChartData[];
  layout?: 'horizontal' | 'vertical';
  defaultColor?: string;
  legendName?: string;
};

const WrapperTitle = styled(Box)(({ theme }) => ({
  padding: '1rem',
}));

const BarChartView: FC<BarChartViewProps> = ({
  title = 'Chart',
  items = [],
  defaultColor,
  layout = 'vertical',
  legendName,
}) => {
  const keys = useMemo(() => {
    return Array.from(
      new Set(items.flatMap((item) => Object.keys(item).filter((key) => key !== 'name'))),
    );
  }, [items]);

  const hasData = items.length > 0;

  return (
    <WrapperChart>
      <WrapperTitle>
        <Typography
          textAlign="center"
          variant="h6"
          color="initial"
        >
          {title}
        </Typography>
      </WrapperTitle>
      <Divider />

      <Box
        sx={{
          width: '100%',
          height: '400px',
          padding: '1rem',
        }}
      >
        {hasData ? (
          <ResponsiveContainer
            width="100%"
            height="100%"
          >
            <BarChart
              data={items}
              layout={layout}
            >
              <CartesianGrid strokeDasharray="3 3" />
              {layout === 'vertical' ? (
                <>
                  <XAxis type="number" />
                  <YAxis
                    dataKey="name"
                    type="category"
                    width={120}
                  />
                </>
              ) : (
                <>
                  <XAxis
                    dataKey="name"
                    type="category"
                  />
                  <YAxis type="number" />
                </>
              )}
              <Tooltip />
              <Legend
                iconSize={10}
                iconType="circle"
                {...(legendName && { formatter: () => legendName })}
              />

              {keys.map((key, index) => (
                <Bar
                  key={uuidv4()}
                  dataKey={key}
                  stackId="a"
                  fill={defaultColor ?? getColorForItem(index)}
                >
                  <LabelList
                    dataKey={key}
                    position="middle"
                  />
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Typography
            variant="body1"
            color="textSecondary"
            textAlign="center"
          >
            No data available
          </Typography>
        )}
      </Box>
    </WrapperChart>
  );
};

export { BarChartView };
