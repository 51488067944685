import { useEffect, useCallback } from 'react';

export const useEscapeClick = (setIsOpen: (isOpen: boolean) => void) => {
  const handleEscapeClick = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  useEffect(() => {
    document.addEventListener('keyup', handleEscapeClick, false);

    return () => {
      document.removeEventListener('keyup', handleEscapeClick, false);
    };
  }, [handleEscapeClick]);
};
