import React, { FC } from 'react';

import { JobEntity, TicketEntity } from 'src/shared/types';

import { Typography } from '../typography';

import { getFormattedDate } from './helpers';

export type DetailsMetaProps = {
  meta: Pick<JobEntity | TicketEntity, 'createdBy' | 'modifiedBy' | 'createDate' | 'modifiedDate'>;
};

export const DetailsMeta: FC<DetailsMetaProps> = ({ meta }) => {
  const { createdBy, modifiedBy, createDate, modifiedDate } = meta;

  return (
    <>
      <div className="flex justify-between gap-2">
        <div className="flex flex-col gap-2">
          <Typography variant="label">Created By</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {createdBy}
          </Typography>
        </div>

        <Typography
          variant="label"
          className="font-normal text-textColor-tertiary break-all"
        >
          {getFormattedDate(createDate)}
        </Typography>
      </div>

      <div className="flex justify-between gap-2">
        <div className="flex flex-col gap-2">
          <Typography variant="label">Last updated by</Typography>

          <Typography
            fontWeight="medium"
            variant="p1"
            className="break-all"
          >
            {modifiedBy}
          </Typography>
        </div>

        <Typography
          variant="label"
          className="font-normal text-textColor-tertiary break-all"
        >
          {getFormattedDate(modifiedDate)}
        </Typography>
      </div>
    </>
  );
};
