import { api } from 'src/store/api/api';
import { ContractList } from 'src/shared/types';

const contractListApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContractLists: builder.query({
      query: () => '/contract-list',
      providesTags: ['ContractList'],
      transformResponse: (response: { data: ContractList[] }) => response.data,
    }),
  }),
});

export const { useGetContractListsQuery } = contractListApi;
