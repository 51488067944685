import { ChangeEvent, useState, FC, InputHTMLAttributes } from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { MuiButton } from '../muiButton';

import { FileList } from './ui/fileList';
import { fileListToArray } from './helpers/file';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: '.5rem',
}));

const FILE_UPLOAD_INPUT = 'file-upload-input';

type InputFileUploadProps = InputHTMLAttributes<HTMLInputElement> & {
  files: File[];
  handleFileChange: (files: File[]) => void;
};

const InputFileUpload: FC<InputFileUploadProps> = ({
  files,
  handleFileChange,
  multiple = false,
  disabled = false,
  ...props
}) => {
  const [inputKey, setInputKey] = useState(0);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files?.length) {
      return;
    }

    const fileList = e.target.files;
    const filesData = fileList ? fileListToArray(fileList) : [];

    if (multiple) {
      if (files.length > 0) {
        handleFileChange([...files, ...filesData]);
      } else {
        handleFileChange(filesData);
      }
    } else {
      handleFileChange([filesData[0]]);
    }

    setInputKey((prevKey) => prevKey + 1);
  };

  const handleRemoveFile = (fileName: string) => {
    const filteredFiles = files.filter((file) => file.name !== fileName);

    handleFileChange(filteredFiles);

    setInputKey((prevKey) => prevKey + 1);
  };

  return (
    <Wrapper>
      <MuiButton
        component="label"
        role={undefined}
        variant="outlined"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        disabled={disabled}
        sx={{
          height: '56px',
        }}
      >
        Select files
        <VisuallyHiddenInput
          key={inputKey}
          id={FILE_UPLOAD_INPUT}
          type="file"
          onChange={handleChange}
          multiple={multiple}
          disabled={disabled}
          {...props}
        />
      </MuiButton>

      {files.length > 0 && (
        <FileList
          files={files}
          handleRemoveFile={handleRemoveFile}
          disabled={disabled}
        />
      )}
    </Wrapper>
  );
};

export { InputFileUpload };
