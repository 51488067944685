import { ReactNode, FC } from 'react';
import { Card } from '@mui/material';

type WrapperChartProps = {
  children: ReactNode;
};

const WrapperChart: FC<WrapperChartProps> = ({ children }) => {
  return <Card variant="outlined">{children}</Card>;
};

export { WrapperChart };
