import { FC, useMemo } from 'react';
import { OneDaySector } from '../oneDaySector';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { TimePeriod } from 'src/shared/types';
import dayjs from 'dayjs';

const ActivityGridTable: FC = () => {
  const datesState = useSelector((state: RootState) => state.calendar.dates);
  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);

  const isDayPeriod = useMemo(() => filterPeriod === TimePeriod.Day, [filterPeriod]);

  const dates = useMemo(
    () => datesState.map((date) => dayjs(date).format().toString()),
    [datesState],
  );

  const preparedDates = useMemo(() => {
    return isDayPeriod
      ? [dayjs(dates[0]).format('dddd, MMMM D')]
      : dates.map((date) => dayjs(date).format('dddd, MMMM D'));
  }, [dates, isDayPeriod]);

  return (
    <>
      {preparedDates.map((date, index) => (
        <OneDaySector
          key={date}
          index={index}
          date={date}
        />
      ))}
    </>
  );
};

export { ActivityGridTable };
