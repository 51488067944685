import { FC, ComponentProps, ReactElement } from 'react';

import './style.css';

import { Icon } from '../icon';

interface SwitchProps extends ComponentProps<'input'> {
  startLabel?: string;
  endLabel?: string;
  icon?: ReactElement;
  iconClassName?: string;
  startIcon?: React.ReactNode;
  startIconClassName?: string;
  endIcon?: React.ReactNode;
  endIconClassName?: string;
}

const Switch: FC<SwitchProps> = ({
  name,
  value,
  checked,
  onChange,
  className,
  defaultChecked,
  startLabel,
  endLabel,
  icon,
  iconClassName,
  startIcon,
  startIconClassName,
  endIcon,
  endIconClassName,
  ...props
}) => {
  return (
    <label className="switch-container flex items-center gap-2  w-fit cursor-pointer">
      <input
        type="checkbox"
        className="switch-input sr-only"
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
        defaultChecked={defaultChecked}
        {...props}
      />

      {!!startLabel && startLabel}

      {!!startIcon && (
        <Icon
          icon={startIcon}
          color="dark"
          className={startIconClassName}
        />
      )}

      <div className="switch-body relative flex p-[1px] items-center flex-shrink-0 bg-[#C0C4CC] border border-solid border-textColor-light cursor-pointer transition-all duration-300 w-[52px] h-[32px] rounded-full">
        <div className="switch-thumb-large flex justify-center items-center absolute w-[28px] h-[28px] bg-textColor-white rounded-[50%] transition-all duration-300">
          {!!icon && (
            <Icon
              className="fill-textColor-tertiary w-[12px] h-[12px]"
              icon={icon}
            />
          )}
        </div>

        <div className="switch-thumb-large absolute bg-textColor-white rounded-[50%] transition-all duration-300" />
      </div>

      {!!endIcon && (
        <Icon
          icon={endIcon}
          color="dark"
          className={startIconClassName}
        />
      )}

      {!!endLabel && endLabel}
    </label>
  );
};

export { Switch };
