import { FC, useState } from 'react';

import { SelectInputItem } from 'src/shared/ui/selectInput';
import { useGetPeopleQuery } from 'src/store/api';
import { PersonEntity } from 'src/shared/types';
import { MultiSelectInput } from 'src/shared/ui/MultiSelectInput';

type SurveySelectProps = {
  togglePerson: ({
    id,
    firstName,
    lastName,
  }: {
    id: string;
    firstName: string;
    lastName: string;
  }) => void;
};

const SurveyMultiSelect: FC<SurveySelectProps> = ({ togglePerson }) => {
  const { data: people = [] } = useGetPeopleQuery({});
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);

  return (
    <MultiSelectInput
      placeholder="Select people"
      items={
        people.map((el) => {
          const { id, firstName, lastName } = people.find(
            (person) => person.id === el.id,
          ) as PersonEntity;

          const formattedPerson = `${firstName} ${lastName} (${id})`;

          return {
            label: (
              <SelectInputItem selected={selectedPeople.includes(formattedPerson)}>
                {formattedPerson}
              </SelectInputItem>
            ),
            value: formattedPerson,
            onClick: () => {
              if (selectedPeople.includes(formattedPerson)) {
                setSelectedPeople(selectedPeople.filter((person) => person !== formattedPerson));
              } else {
                setSelectedPeople([...selectedPeople, formattedPerson]);
              }

              togglePerson({
                id,
                firstName,
                lastName,
              });
            },
          };
        }) ?? []
      }
    />
  );
};

export { SurveyMultiSelect };
