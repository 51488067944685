import { FC } from 'react';
import { Box, Card, CardContent, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardAdminMetric } from 'src/shared/types';
import { SvgRenderer } from 'src/shared/ui/svgRenderer';
import { getAzureUrl } from 'src/shared/utils';

const CustomCardContent = styled(CardContent)(({ theme }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'padding': '0.5rem',
  '&: last-child': {
    padding: '0.5rem',
  },
}));
const WrapperInfo = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: 0,
}));
const CardTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'start',
}));
const CardText = styled(Typography)(({ theme }) => ({
  textAlign: 'start',
}));
const WrapperSvg = styled(Box)(({ theme }) => ({
  padding: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type MetricCardProps = {
  metric: DashboardAdminMetric;
  fileRootPath?: string;
};

const MetricCard: FC<MetricCardProps> = ({ metric, fileRootPath }) => {
  const icon =
    fileRootPath && metric?.icon ? (
      <SvgRenderer
        width="30"
        height="30"
        svgUrl={getAzureUrl(fileRootPath, metric.icon)}
      />
    ) : (
      <Skeleton
        variant="rounded"
        width={30}
        height={30}
        animation={false}
      />
    );

  return (
    <Card variant="outlined">
      <CustomCardContent>
        <WrapperSvg>{icon}</WrapperSvg>
        <WrapperInfo>
          <CardTitle variant="body1">{metric.name}</CardTitle>
          <CardText
            variant="body1"
            color="textSecondary"
          >
            {metric.count}
          </CardText>
        </WrapperInfo>
      </CustomCardContent>
    </Card>
  );
};

export { MetricCard };
