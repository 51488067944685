import { ComponentProps, FC } from 'react';

import { clsx } from 'src/shared/utils';

import { CardSkeleton } from './cardSkeleton';
import { ColumnSkeleton } from './columnSkeleton';

type BoardSkeletonProps = ComponentProps<'div'>;

const BoardSkeleton: FC<BoardSkeletonProps> = ({ className, ...props }) => {
  return (
    <div
      className={clsx('flex h-full overflow-scroll', className)}
      {...props}
    >
      <div className="flex h-full">
        <div className="min-w-[12px] h-[56px] bg-bgColor-card border-b border-outlineColor-input-border" />

        <ColumnSkeleton>
          <CardSkeleton className="rounded-r-none" />

          <CardSkeleton />
        </ColumnSkeleton>

        <ColumnSkeleton>
          <CardSkeleton
            isMainCard={false}
            className="rounded-none"
          />
        </ColumnSkeleton>

        <ColumnSkeleton>
          <CardSkeleton
            isMainCard={false}
            className="rounded-l-none"
          />

          <CardSkeleton className="rounded-r-none" />
        </ColumnSkeleton>

        <ColumnSkeleton>
          <div className="min-h-[482px]" />

          <CardSkeleton
            isMainCard={false}
            className="rounded-l-none"
          />
        </ColumnSkeleton>

        <ColumnSkeleton>
          <CardSkeleton className="rounded-r-none" />
        </ColumnSkeleton>

        <ColumnSkeleton>
          <CardSkeleton
            isMainCard={false}
            className="rounded-l-none"
          />

          <CardSkeleton />
        </ColumnSkeleton>

        <ColumnSkeleton />

        <ColumnSkeleton />

        <div className="min-w-[12px] h-[56px] bg-bgColor-card border-b border-outlineColor-input-border" />
      </div>
    </div>
  );
};

export type { BoardSkeletonProps };
export { BoardSkeleton };
