import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import clsx from 'clsx';

import { Typography } from 'src/shared/ui/typography';
import { Icons } from 'src/assets/icons';

interface TaskTimeInfoProps {
  startWork: Date;
  endWork: Date;
  timeZone: string;
  isLineThrough?: boolean;
  isTimeColored?: boolean;
  isStart?: boolean;
  isEnd?: boolean;
  hasOriginal?: boolean;
}

const TaskTimeInfo: FC<TaskTimeInfoProps> = ({
  startWork,
  endWork,
  timeZone,
  isLineThrough,
  isTimeColored,
  isStart,
  isEnd,
  hasOriginal = true,
}) => {
  const startWorkTime = dayjs.utc(startWork).tz(timeZone).format('MM/DD/YYYY, HH:mm');
  const endWorkTime = dayjs.utc(endWork).tz(timeZone).format('MM/DD/YYYY, HH:mm');
  const workDurationHours = dayjs(endWork).diff(dayjs(startWork), 'hour');
  const workDurationMinutes = dayjs(endWork)
    .subtract(workDurationHours, 'hour')
    .diff(dayjs(startWork), 'minutes');

  const phrases = useMemo(() => {
    if (isStart) {
      return {
        start: 'Scheduled start: ',
        end: 'Actual start: ',
      };
    }

    if (isEnd) {
      return {
        start: 'Scheduled end: ',
        end: 'Actual end: ',
      };
    }

    return {
      start: 'Start: ',
      end: 'End: ',
    };
  }, [isStart, isEnd]);

  return (
    <div className="flex items-center gap-2 shrink-0">
      <div className="flex gap-1 items-center shrink-0">
        <Icons.Outlined.Misc.ClockIcon
          className={clsx(
            'shrink-0 w-5 h-5',
            isLineThrough || isTimeColored ? 'fill-semanticColor-warning' : 'fill-[#636874]',
          )}
        />

        <Typography
          variant="c2"
          className={clsx(
            'text-xs leading-[18px] shrink-0',
            isLineThrough || isTimeColored ? 'text-semanticColor-warning' : 'text-[#636874]',
            isLineThrough && 'line-through',
          )}
        >
          <strong>{phrases.start}</strong>

          {`${startWorkTime} - `}

          <strong>{phrases.end}</strong>

          {`${endWorkTime}`}
        </Typography>
      </div>

      {!isStart && !isEnd && (
        <Typography
          variant="c2"
          className={clsx(
            'text-xs leading-[18px] shrink-0',
            isLineThrough || isTimeColored ? 'text-semanticColor-warning' : 'text-[#636874]',
            isLineThrough && 'line-through',
          )}
        >
          <strong>
            {hasOriginal && 'Original '}
            Duration:{' '}
          </strong>

          {`${workDurationHours}h${workDurationMinutes > 0 ? ` ${workDurationMinutes}m` : ''}`}
        </Typography>
      )}
    </div>
  );
};

export { TaskTimeInfo };
