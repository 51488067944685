import { FC } from 'react';
import { TextField } from 'src/shared/ui/textField';
import { Spinner } from 'src/shared/ui/spinner';

interface ISearchFieldProps {
  value: string;
  handleChange: (value: string) => void;
  isFetching: boolean;
}

const SearchField: FC<ISearchFieldProps> = ({ value, handleChange, isFetching }) => {
  return (
    <TextField
      placeholder="Enter Survey Answers"
      name="name"
      value={value}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
      className="relative"
      endIcon={
        !!value && isFetching ? (
          <div className="absolute translate-x-[-50%] translate-y-[-50%] top-[50%] right-0">
            <Spinner size="xs" />
          </div>
        ) : undefined
      }
    />
  );
};

export { SearchField };
