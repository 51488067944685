import { api } from 'src/store/api/api';
import { Category } from 'src/shared/types';

const jobCategoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getJobCategories: builder.query<Category[], ''>({
      query: () => ({
        url: '/job-category',
      }),
      providesTags: ['Jobs'],
      transformResponse: (response: { data: Category[] }) => response.data,
    }),
  }),
});

export const { useGetJobCategoriesQuery } = jobCategoryApi;
