import clsx from 'clsx';
import { FC } from 'react';

import { Skeleton } from 'src/shared/ui/skeleton';

type SkeletonTableProps = {
  containerClassName?: string;
  withPagination?: boolean;
};

const SkeletonTable: FC<SkeletonTableProps> = ({ containerClassName, withPagination }) => {
  return (
    <div className="flex flex-col">
      <div className={clsx('overflow-y-scroll', containerClassName)}>
        <div className="flex flex-col gap-1">
          {Array.from({ length: 30 }, (_, index) => index).map((el) => (
            <Skeleton
              key={el}
              className="w-full h-[33px]"
            />
          ))}
        </div>
      </div>

      {withPagination && (
        <div className="flex items-center justify-between h-[56px] py-[7px] px-4 border-[1px] bg-white">
          <div className="flex gap-x-2">
            <Skeleton className="w-8 h-8" />

            <div className="flex items-center gap-x-1">
              {Array.from({ length: 7 }, (_, index) => index).map((el) => (
                <Skeleton
                  key={el}
                  className="w-8 h-8"
                />
              ))}
            </div>

            <Skeleton className="w-8 h-8" />
          </div>

          <div className="flex items-center gap-x-2">
            <Skeleton className="w-[98px] h-5" />

            <Skeleton className="w-[72px] h-10" />
          </div>
        </div>
      )}
    </div>
  );
};

export { SkeletonTable };
