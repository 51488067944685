import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';

import { Filter, PageSize, SortOrder } from 'src/shared/types';
import { RootState } from 'src/store';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { DATE_FORMAT } from 'src/shared/utils';
import { exportToExcel } from 'src/shared/utils/excel';
import { useGetAllActionsQuery } from 'src/store/api';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { DateRangeType } from 'src/shared/ui/datepicker/types';
import { Typography } from 'src/shared/ui/typography';

import { REPORT_TABLE_BOTTOM_SPACE, REPORT_TABLE_PAGINATION_HEIGHT } from '../activities/helpers';

import { ActionHeaderBlock, HistoryListActionModal, UpdateActionModal } from './ui';
import { Action, ActionStatus } from './helpers/types';
import { ACTION_ID, SEARCH_FIELD_DEBOUNCE_DELAY } from './helpers/constants';

const UnclosedFindings: FC = () => {
  const [search, setSearch] = useState('');
  const debouncedSearchField = useDebounce(search, SEARCH_FIELD_DEBOUNCE_DELAY);

  const [searchParams] = useSearchParams();

  const actionId = useMemo(() => {
    return searchParams.get(ACTION_ID);
  }, [searchParams]);

  const [dateRange, setDateRange] = useState<DateRangeType | null>(null);

  const handleChangeDateRange = (val: DateRangeType | null) => setDateRange(val);

  const dateRangeFilters = useMemo(() => {
    return !dateRange || !dateRange.startDate
      ? undefined
      : [
          dayjs(dateRange.startDate)
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .toDate()
            .toISOString(),
          dayjs(dateRange.endDate)
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .toDate()
            .toISOString(),
        ];
  }, [dateRange]);

  const [idToUpdate, setIdToUpdate] = useState<string | null>(null);
  const [idForHistoryList, setIdForHistoryList] = useState<string | null>(null);

  const [sortSettings, setSortSettings] = useState<{
    key: keyof Action | 'status' | 'assignedTo' | null;
    order: SortOrder;
  }>({
    key: null,
    order: SortOrder.ASC,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>(15);

  const boardFilters = useSelector((state: RootState) => state.config.boardFilters);

  const {
    data: actionsData,
    refetch,
    isError,
    isFetching,
    isLoading,
  } = useGetAllActionsQuery(
    {
      pagination: {
        page: `${currentPage}`,
        pageSize: `${pageSize}`,
        search: debouncedSearchField,
      },
      sorting: sortSettings,
      filters: {
        status: boardFilters.status || undefined,
        assignedTo: boardFilters.assignedTo || undefined,
        refinery: boardFilters.refinery || undefined,
        dates: dateRangeFilters,
        actionIds: actionId ? [actionId] : undefined,
        types: ['UnclosedFindings'],
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [minViewSpace, setMinViewSpace] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const offsetTop = containerRef.current.getBoundingClientRect().top;
      setMinViewSpace(offsetTop + REPORT_TABLE_BOTTOM_SPACE + REPORT_TABLE_PAGINATION_HEIGHT);
    }
  }, [containerRef]);

  const appliedFiltersAmount = useMemo(() => {
    return (
      Object.values(boardFilters).filter((filter) => filter.length > 0).length +
      (dateRangeFilters ? 1 : 0)
    );
  }, [boardFilters, dateRangeFilters]);

  const filters: Filter[] = useMemo(() => {
    return actionsData?.filterOptions || [];
  }, [actionsData]);

  const tableData = useMemo(() => {
    return (actionsData?.actions || []).map((action) => ({
      id: action.id,
      status: action.actualState?.status || '',
      shortDescription: action.shortDescription,
      assignedTo: action.actualState?.assignedTo || '',
      refinery: action.refinery.OwnerLocation,
      updatedAt: action.actualState?.updatedAt
        ? dayjs(action.actualState.updatedAt).format(DATE_FORMAT.DEFAULT_ADMIN_PAGE)
        : '',
      createdAt: dayjs(action.createdAt).format(DATE_FORMAT.DEFAULT_ADMIN_PAGE),
      responseId: action.responseId || '',
      unitId: action.unitId || '',
      type: action.type,
    }));
  }, [actionsData]);

  const totalActions = useMemo(() => {
    return actionsData?.totalActions || 0;
  }, [actionsData]);

  const headers: TableHeaderType<Action>[] = useMemo(
    () => [
      {
        title: 'Response Id',
        field: 'responseId',
        className: 'w-max',
        canSort: true,
        render: (action) => action.responseId || '',
      },
      {
        title: 'Type',
        field: 'type',
        className: 'w-max',
        canSort: true,
        render: (action) => action.type,
      },
      {
        title: 'Unit',
        field: 'unitId',
        className: 'w-max',
        canSort: true,
        render: (action) => action.unitId || '',
      },
      {
        title: 'Status',
        field: 'status',
        className: 'w-max',
        canSort: true,
        render: (action) => {
          if (action.status in ActionStatus) {
            return ActionStatus[action.status as keyof typeof ActionStatus];
          }
          return action.status;
        },
      },
      {
        title: 'Short Description',
        field: 'shortDescription',
        className: 'w-max',
        canSort: true,
        render: (action) => action.shortDescription || '',
      },
      {
        title: 'Assigned to',
        field: 'assignedTo',
        className: 'w-max',
        canSort: true,
        render: (action) => action.assignedTo || '',
      },
      {
        title: 'Refinery',
        field: 'refinery',
        className: 'w-max',
        canSort: true,
        render: (action) => action.refinery || '',
      },
      {
        title: 'Last update',
        field: 'updatedAt',
        className: 'w-max',
        canSort: true,
        render: (action) =>
          action.updatedAt ? dayjs(action.updatedAt).format(DATE_FORMAT.DEFAULT_ADMIN_PAGE) : '',
      },
      {
        title: 'Next update due',
        field: 'updatedAt',
        className: 'w-max',
        canSort: true,
        render: (action) => {
          const inFortnight = dayjs(action.updatedAt).add(14, 'day');
          const isOverdue =
            dayjs().endOf('day').isAfter(inFortnight) && action.status !== ActionStatus.Closed;

          return (
            <div className={clsx(isOverdue && 'flex gap-1 items-center justify-center flex-wrap')}>
              {inFortnight.format(DATE_FORMAT.DEFAULT_ADMIN_PAGE)}

              {isOverdue && (
                <Typography
                  variant="p1"
                  fontWeight="semibold"
                  className="uppercase text-textColor-danger p-1 border border-textColor-danger rounded"
                >
                  overdue
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        title: 'Created Date',
        field: 'createdAt',
        className: 'w-max',
        canSort: true,
        render: (action) => dayjs(action.createdAt).format(DATE_FORMAT.DEFAULT_ADMIN_PAGE),
      },
      {
        title: 'Actions',
        field: 'actions',
        className: 'w-max',
        render: (action) => {
          return (
            <div className="flex flex-col gap-y-2 items-start">
              <button
                className="hover:text-textColor-danger text-brandingColor-primary-gradient font-bold"
                onClick={() => setIdToUpdate(action.id)}
              >
                Add Update
              </button>

              <button
                className="hover:text-textColor-danger text-brandingColor-primary-gradient font-bold"
                onClick={() => setIdForHistoryList(action.id)}
              >
                View History
              </button>
            </div>
          );
        },
      },
    ],
    [],
  );

  const handleChangeSortSettings = (newSort: { key: keyof Action | null; order: SortOrder }) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setSortSettings(newSort);
  };

  const handlePageSizeChange = (size: PageSize) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    setPageSize(size);
  };

  const handleExportToExcel = () => {
    const excelData = tableData.map((action) => {
      const {
        id,
        status,
        shortDescription = '',
        assignedTo = '',
        refinery = '',
        updatedAt = '',
        createdAt,
        responseId = '',
        type,
        unitId = '',
      } = action;

      return {
        ActionID: id,
        Status: status,
        ShortDescription: shortDescription,
        AssignedTo: assignedTo,
        Refinery: refinery,
        LastUpdated: updatedAt,
        CreatedDate: dayjs.utc(createdAt).format(DATE_FORMAT.MM_DD_YYYY_HH_MM),
        ResponseID: responseId,
        Type: type,
        UnitID: unitId,
      };
    });

    exportToExcel(excelData, 'Actions');
  };

  return (
    <>
      <div className="w-full px-6">
        <ActionHeaderBlock
          onExportToExcel={handleExportToExcel}
          appliedFiltersAmount={appliedFiltersAmount}
          filters={filters}
          search={search}
          setSearch={setSearch}
          onActionAdd={refetch}
          setDateRangeFilter={handleChangeDateRange}
        />

        <div ref={containerRef}>
          <Table
            headers={headers}
            data={tableData}
            isFetching={isFetching}
            isLoading={isLoading}
            sortSettings={sortSettings}
            onSort={handleChangeSortSettings}
            withIndicator
            variant="secondary"
            containerClassName="h-full"
            heightTable={minViewSpace}
            sticky
            scroll
            isError={isError}
            pagination={{
              currentPage,
              onPageChange: (page: number) => setCurrentPage(page),
              onPageSizeChange: handlePageSizeChange,
              pageSize,
              total: totalActions,
            }}
          />
        </div>
      </div>

      {idToUpdate && (
        <UpdateActionModal
          isOpen={!!idToUpdate}
          closeModal={() => setIdToUpdate(null)}
          idToUpdate={idToUpdate}
          onUpdated={refetch}
        />
      )}

      {idForHistoryList && (
        <HistoryListActionModal
          isOpen={!!idForHistoryList}
          closeModal={() => setIdForHistoryList(null)}
          idForHistoryList={idForHistoryList}
        />
      )}
    </>
  );
};

export { UnclosedFindings };
