import React, { useState, FC, ChangeEvent, FormEvent, useEffect, MouseEvent } from 'react';

import { ContractRateSheet, TicketEntity } from 'src/shared/types';
import { clsx, not, showToastErrorMessage } from 'src/shared/utils';
import {
  AdditionalEquipment,
  useAddAdditionalItemsMutation,
  useGetAdditionalJELLItemsQuery,
} from 'src/store/api';
import { ReactComponent as SearchIcon } from 'src/assets/icons/filled/internet&code/search.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/outlined/edit/close-circle.svg';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { Modal } from 'src/shared/ui/modal/modal';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { TextField } from 'src/shared/ui/textField';
import { Icon } from 'src/shared/ui/icon';
import { IconButton } from 'src/shared/ui/iconButton';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { Checkbox } from 'src/shared/ui/checkbox';
import { Spinner } from 'src/shared/ui/spinner';
import { useAppDispatch } from 'src/store';
import { modalConfigActions } from 'src/store/slices/modalConfig';

type CreateAdditionalItemsModalProps = {
  ticket: TicketEntity;
  rateSheeetId: string;
  type: 'Labor' | 'Equipment';
  isOpen: boolean;
  setIsOpen: (openModalId: 'create' | null) => void;
};

const CreateAdditionalItemsModal: FC<CreateAdditionalItemsModalProps> = ({
  ticket,
  rateSheeetId,
  type,
  isOpen,
  setIsOpen,
}) => {
  const dispatch = useAppDispatch();

  const {
    data = {
      additionalEquipment: [],
      additionalLabor: [],
    },
    isFetching,
  } = useGetAdditionalJELLItemsQuery(rateSheeetId);

  const { additionalEquipment, additionalLabor } = data;

  const additionalItems = type === 'Labor' ? additionalLabor : additionalEquipment;

  const [addAdditionalItems, { isLoading: isAdding }] = useAddAdditionalItemsMutation();

  const [selectedItems, setSelectedItems] = useState<AdditionalEquipment[]>([]);
  const [filteredItems, setFilteredItems] = useState(additionalItems);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    const formattedSearch = debouncedSearch.toLowerCase();

    const filteredItems = additionalItems.filter((el) => {
      const { Itemcode, ItemCodeCategoryName, CustomItemDescription } = el;

      return (
        Itemcode?.toLowerCase().includes(formattedSearch) ||
        ItemCodeCategoryName?.toLowerCase().includes(formattedSearch) ||
        CustomItemDescription?.toLowerCase().includes(formattedSearch)
      );
    });

    setFilteredItems(filteredItems);
  }, [additionalItems, debouncedSearch]);

  const toggleEquipmentSelection = (value: string) => {
    const includesItem = selectedItems.some((el) => el.rateSheetItemID === value);

    if (includesItem) {
      setSelectedItems((prev) => prev.filter((el) => el.rateSheetItemID !== value));

      return;
    }

    setSelectedItems((prev) => [
      {
        rateSheetItemID: value,
        QTY: 1,
      },
      ...prev,
    ]);
  };

  const changeQuantity = (value: string, QTY: string) => {
    const formattedQTY = Number(QTY.replace(/[^0-9]/g, ''));

    if (formattedQTY > 20) {
      showToastErrorMessage('Exceeded quantity limit of 20');
    }

    const newQTY = formattedQTY > 20 ? 20 : formattedQTY;

    setSelectedItems((prev) =>
      prev.map((el) => {
        if (el.rateSheetItemID === value) {
          return {
            ...el,
            QTY: newQTY,
          };
        }

        return el;
      }),
    );
  };

  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>, value: string) => {
    e.preventDefault();
    toggleEquipmentSelection(value);
  };

  const toggleCheckbox = (value: string) => {
    toggleEquipmentSelection(value);
  };

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(isOpen ? null : 'create');
    setSearch('');
    setSelectedItems([]);
    dispatch(modalConfigActions.setOpenModalId(null));
  };

  const changeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearch(value === ' ' ? '' : value);
  };

  const clearSearch = () => {
    setSearch('');
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await addAdditionalItems({
        items: selectedItems,
        ticketId: ticket.id,
        type,
      }).unwrap();
    } catch {
      showToastErrorMessage(`There was an error trying to add additional ${type}`);
    } finally {
      toggleModal(isOpen);
    }
  };

  const getIsItemSelected = (id: string) => {
    const item = selectedItems.find((item) => item.rateSheetItemID === id);

    return !!item;
  };

  const submitButtonContent = isAdding ? <Spinner size="sm" /> : 'Save and Add';

  const isSubmitButtonDisabled =
    not(selectedItems.length) || isAdding || selectedItems.some((item) => item.QTY <= 0);

  const buttons = (
    <div className="flex justify-between gap-2">
      <Button
        type="button"
        variant="outlined"
        color="basic"
        size="lg"
        className="w-[108px]"
        onClick={() => toggleModal(isOpen)}
      >
        Cancel
      </Button>

      <Button
        type="submit"
        color="primary"
        size="lg"
        disabled={isSubmitButtonDisabled}
        className="w-[131px]"
      >
        {submitButtonContent}
      </Button>
    </div>
  );

  const additionalItemsHeaders: TableHeaderType<ContractRateSheet>[] = [
    {
      title: 'Select',
      field: 'Select',
      render: (el) => {
        const includesInSelected = getIsItemSelected(el.RateSheetItemID);

        return (
          <div className="flex justify-center">
            <Checkbox
              checked={includesInSelected}
              onChange={() => toggleCheckbox(el.RateSheetItemID)}
              disabled={isAdding}
            />
          </div>
        );
      },
      className: 'w-[40px] max-w-[40px]',
    },
    {
      title: 'Item Code',
      field: 'Itemcode',
      className: 'w-[120px]',
    },
    {
      title: 'Description',
      field: 'CustomItemDescription',
      className: 'w-[300px]',
    },
    {
      title: 'Category',
      field: 'ItemCodeCategoryName',
      className: 'w-[150px]',
    },
    {
      title: 'UOM',
      field: 'UOM',
      className: 'w-[75px]',
    },
    {
      title: 'QTY',
      field: 'QTY',
      className: 'w-[75px]',
      render: (el) => {
        const item = selectedItems.find(
          (equipment) => equipment.rateSheetItemID === el.RateSheetItemID,
        );

        const includesInSelected = getIsItemSelected(el.RateSheetItemID);

        return (
          <TextField
            value={item?.QTY || 0}
            onClick={(e) => e.stopPropagation()}
            inputClassName="bg-bgColor-menu"
            className="h-[36px]"
            disabled={not(includesInSelected) || isAdding}
            onChange={(e) => changeQuantity(item?.rateSheetItemID as string, e.target.value)}
          />
        );
      },
    },
    ...(type === 'Equipment'
      ? ([
          {
            title: 'Req. Unit #',
            field: 'RequiresUnitNumber',
            className: 'w-[75px]',
          },
          {
            title: 'Rental',
            field: 'Rental',
            className: 'w-[75px]',
            render: (el) => el.RentalItemCode === '1' && 'Yes',
          },
        ] as TableHeaderType<ContractRateSheet>[])
      : []),
  ];

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="h-full max-h-[735px] overflow-y-auto"
    >
      <form
        className="flex flex-col justify-between gap-y-6  h-[700px] min-w-[1200px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <div className="flex justify-between items-center">
            <Typography
              variant="h2"
              fontWeight="bold"
            >
              {`Add ${type}`}
            </Typography>

            <TextField
              startIcon={
                <Icon
                  icon={<SearchIcon />}
                  className="absolute left-[15px] top-[12px]"
                />
              }
              endIcon={
                search ? (
                  <IconButton
                    size="md"
                    className="absolute right-[5px] top-[4px]"
                    onClick={clearSearch}
                  >
                    <CloseCircleIcon className="fill-[#2E3A59] cursor-pointer" />
                  </IconButton>
                ) : undefined
              }
              placeholder={`Search for ${type.toLowerCase()}`}
              className="w-[470px]"
              value={search}
              onChange={changeSearch}
            />
          </div>

          <Table
            containerClassName="h-[550px]"
            scroll
            sticky
            headers={additionalItemsHeaders}
            data={filteredItems}
            isLoading={isFetching}
            onRowClick={(e, el: ContractRateSheet) => handleRowClick(e, el.RateSheetItemID)}
            idProvider={(el: ContractRateSheet) => el.RateSheetItemID}
            getRowClassName={(el: ContractRateSheet) => {
              const includesInSelected = getIsItemSelected(el.RateSheetItemID);

              return clsx(includesInSelected && 'bg-[#D0D8E8]', isAdding && 'pointer-events-none');
            }}
          />
        </div>

        <div className="flex w-full justify-between items-center">
          <Typography
            variant="label"
            fontWeight="bold"
          >
            {`${selectedItems.length} selected ${type.toLowerCase()}`}
          </Typography>

          {buttons}
        </div>
      </form>
    </Modal>
  );
};

export { CreateAdditionalItemsModal };
