/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';

import { clsx } from 'src/shared/utils/clsx';

import { DatepickerContext } from '../../contexts/DatepickerContext';
import { generateArrayNumber } from '../../helpers';

interface Props {
  year: number;
  currentYear: number;
  minYear: number | null;
  maxYear: number | null;
  clickYear: (data: number) => void;
}

const Years: React.FC<Props> = ({ year, currentYear, minYear, maxYear, clickYear }) => {
  const { dateLooking } = useContext(DatepickerContext);

  let startDate = 0;
  let endDate = 0;

  switch (dateLooking) {
    case 'backward':
      startDate = year - 11;
      endDate = year;
      break;
    case 'middle':
      startDate = year - 4;
      endDate = year + 7;
      break;
    case 'forward':
    default:
      startDate = year - 5;
      endDate = year + 6;
      break;
  }

  return (
    <div className="w-full h-full grid grid-cols-4 grid-rows-3 px-[11.1px] pb-[10px]">
      {generateArrayNumber(startDate, endDate).map((item, index) => (
        <button
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          type="button"
          onClick={() => clickYear(item)}
          disabled={(maxYear !== null && item > maxYear) || (minYear !== null && item < minYear)}
          className={clsx(
            'text-textColor-primary text-[15px] heading-[24px] h-[44px] w-[76px] hover:bg-[#BFD1EA] hover:rounded',
            currentYear === item &&
              'bg-brandingColor-primary-gradient text-textColor-white rounded hover:bg-brandingColor-primary-gradient-hover',
          )}
        >
          <>{item}</>
        </button>
      ))}
    </div>
  );
};

export { Years };
