import { useEffect, useMemo, useState } from 'react';

import { PageSize, SortOrder, TicketEntity } from 'src/shared/types';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { useGetJobsQuery, useGetTicketsQuery } from 'src/store/api';
import { IconButton } from 'src/shared/ui/iconButton';
import { Button } from 'src/shared/ui/button';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/filled/files/download.svg';
import { exportToExcel } from 'src/shared/utils/excel';
import { TextField } from 'src/shared/ui/textField';
import { Icon } from 'src/shared/ui/icon';
import { ReactComponent as SearchIcon } from 'src/assets/icons/filled/internet&code/search.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { getFormattedTimePickerDateTime, not } from 'src/shared/utils';
import { getFormattedDate } from 'src/shared/ui/details/helpers';

const TicketsTable = () => {
  const [sortSettings, setSortSettings] = useState<{
    key: keyof TicketEntity | null;
    order: SortOrder;
  }>({
    key: null,
    order: SortOrder.ASC,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>(15);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);

  const {
    data = {
      tickets: [],
      pageSize: 0,
      total: 0,
      totalPages: 0,
      search: '',
    },
    isError,
    isFetching,
    isLoading,
  } = useGetTicketsQuery({
    pagination: {
      page: String(currentPage),
      pageSize: String(pageSize),
      search: debouncedQuery,
    },
    sorting: sortSettings,
  });
  const { data: allTicketsData = { tickets: [] }, isFetching: isAllTicketsFetching } =
    useGetTicketsQuery({});
  const { data: allJobsData = { jobs: [] } } = useGetJobsQuery({});

  const { tickets = [], total } = data;

  const { jobs } = allJobsData;

  // *  Data for the Excel Export
  const { tickets: allTickets } = allTicketsData;

  const headers: TableHeaderType<TicketEntity>[] = useMemo(
    () => [
      {
        title: 'Scheduled Work Id',
        field: 'ScheduledWorkID',
        className: 'min-w-[170px]',
        canSort: true,
        render: (ticket) => ticket.id,
      },
      {
        title: 'Owner',
        field: 'OwnerID',
        className: 'min-w-[170px]',
        canSort: true,
        render: (ticket) => ticket.owner.OwnerName,
      },
      {
        title: 'Owner Location',
        field: 'OwnerLocationID',
        className: 'min-w-[150px]',
        canSort: true,
        render: (ticket) => ticket.ownerLocation?.OwnerLocation,
      },
      {
        title: 'Category',
        field: 'Category',
        className: 'min-w-[90px]',
        canSort: true,
        render: (ticket) => ticket.jobCategory.Category,
      },
      {
        title: 'Description',
        field: 'ProjectName',
        className: 'min-w-[150px]',
        canSort: true,
        render: (ticket) => ticket.projectName,
      },
      {
        title: 'PO',
        field: 'PO',
        className: 'min-w-[99px]',
        canSort: true,
        render: (ticket) => ticket.po,
      },
      {
        title: 'WO',
        field: 'WO',
        className: 'min-w-[100px]',
        canSort: true,
        render: (ticket) => ticket.wo,
      },
      {
        title: 'Contract',
        field: 'Contract',
        className: 'min-w-[100px]',
        canSort: true,
        render: (ticket) => ticket?.ownerContract?.RateSheetDescription,
      },
      {
        title: 'Service Line',
        field: 'ServiceLineID',
        className: 'min-w-[150px]',
        canSort: true,
        render: (ticket) => {
          const job = jobs.find((job) => job.tickets.some((el) => el.id === ticket.id));

          return job?.serviceLine.ServiceLineDescription || '';
        },
      },
      {
        title: 'Service Type',
        field: 'ServiceTypeID',
        className: 'min-w-[150px]',
        canSort: true,
        render: (ticket) => {
          const job = jobs.find((job) => job.tickets.some((el) => el.id === ticket.id));

          return job?.serviceType?.ServiceTypeDescription || '';
        },
      },
      {
        title: 'Owner Representative',
        field: 'OwnerPlanner',
        className: 'min-w-[126px]',
        canSort: true,
        render: (ticket) => {
          const job = jobs.find((job) => job.tickets.some((el) => el.id === ticket.id));

          return job?.ownerRepresentative
            ? `${job.ownerRepresentative.FirstName} ${job.ownerRepresentative.LastName}`
            : '';
        },
      },
      {
        title: 'Owner Contact',
        field: 'OwnerContact',
        className: 'min-w-[126px]',
        canSort: true,
        render: (ticket) => {
          const job = jobs.find((job) => job.tickets.some((el) => el.id === ticket.id));

          return job?.ownerContact
            ? `${job.ownerContact.FirstName} ${job.ownerContact.LastName}`
            : '';
        },
      },
      {
        title: 'Shift Type',
        field: 'Shift',
        className: 'min-w-[99px]',
        canSort: true,
        render: (ticket) => ticket.shift,
      },
      {
        title: 'Lunch Allowed',
        field: 'LunchAllowed',
        className: 'min-w-[99px]',
        canSort: true,
        render: (ticket) => ticket.lunchAllowed,
      },
      {
        title: 'Lunch Time',
        field: 'ScheduledLunch',
        className: 'min-w-[99px]',
        canSort: true,
        render: (ticket) => ticket.scheduledLunch,
      },
      {
        title: 'Status',
        field: 'Status',
        className: 'min-w-[100px]',
        canSort: true,
        render: (ticket) => ticket.status,
      },
      {
        title: 'Crew Leader',
        field: 'crewLeaderId',
        className: 'min-w-[200px]',
        canSort: true,
        render: (ticket) =>
          ticket.crewLeader
            ? `${ticket.crewLeader.ProviderPersonnel?.firstName} ${ticket.crewLeader.ProviderPersonnel?.lastName} (${ticket.crewLeader.ProviderPersonnel?.id})`
            : '',
      },
      {
        title: 'Ticket Number',
        field: 'ticketNumber',
        className: 'min-w-[100px]',
        canSort: true,
        render: (ticket) => ticket.ticketNumber,
      },
      {
        title: 'Start Time',
        field: 'ThisShiftStartTimeLocal',
        className: 'min-w-[180px]',
        canSort: true,
        render: (ticket) =>
          `${getFormattedDate(ticket.startTime)} ${getFormattedTimePickerDateTime(
            ticket.startTime,
          )}`,
      },
      {
        title: 'End Time',
        field: 'ThisShiftScheduledEndTimeLocal',
        className: 'min-w-[180px]',
        canSort: true,
        render: (ticket) =>
          `${getFormattedDate(ticket.endTime)} ${getFormattedTimePickerDateTime(ticket.endTime)}`,
      },
    ],
    [jobs],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedQuery, pageSize]);

  const handleExportToExcel = async () => {
    const excelData = allTickets.map((ticket) => {
      const job = jobs.find((job) => job.tickets.some((el) => el.id === ticket.id));

      return {
        'Scheduled Work Id': ticket.ticketNumber,
        'Owner': ticket.owner.OwnerName,
        'Owner Location': ticket?.ownerLocation?.OwnerLocation,
        'Category': ticket.jobCategory.Category,
        'Description': ticket.projectName,
        'PO': ticket.po,
        'WO': ticket.wo,
        'Contract': ticket?.ownerContract?.RateSheetDescription,
        'Service Line': job?.serviceLine.ServiceLineDescription || '',
        'Service Type': job?.serviceType?.ServiceTypeDescription || '',
        'Owner Representative': job?.ownerRepresentative
          ? `${job.ownerRepresentative.FirstName} ${job.ownerRepresentative.LastName}`
          : '',
        'Owner Contact': job?.ownerContact
          ? `${job.ownerContact.FirstName} ${job.ownerContact.LastName}`
          : '',
        'Shift Type': ticket.shift,
        'Lunch Allowed': ticket.lunchAllowed,
        'Lunch Time': ticket.scheduledLunch,
        'Status': ticket.status,
        'Crew Leader': ticket.crewLeader
          ? `${ticket.crewLeader.ProviderPersonnel?.firstName} ${ticket.crewLeader.ProviderPersonnel?.lastName} (${ticket.crewLeader.ProviderPersonnel?.id})`
          : '',
        'Ticket Number': ticket.status,
        'Start Time': `${getFormattedDate(ticket.startTime)} ${getFormattedTimePickerDateTime(
          ticket.startTime,
        )}`,
        'End Time': `${getFormattedDate(ticket.endTime)} ${getFormattedTimePickerDateTime(
          ticket.endTime,
        )}`,
      };
    });

    exportToExcel(excelData, 'Tickets');
  };

  return (
    <div className="flex flex-col gap-y-6 pb-6">
      {not(isError) && (
        <div className="flex justify-end items-center gap-x-6 w-full">
          <Button
            size="lg"
            variant="filled"
            endIcon={<DownloadIcon fill="#231F20" />}
            className="border-[#636874] flex !gap-3 !p-0"
            onClick={handleExportToExcel}
            disabled={isAllTicketsFetching}
          >
            Excel
          </Button>

          <TextField
            startIcon={
              <Icon
                icon={<SearchIcon />}
                className="absolute left-[16px] top-[12px] fill-textColor-tertiary"
              />
            }
            endIcon={
              query ? (
                <IconButton
                  iconClassName="fill-textColor-tertiary"
                  size="none"
                  className="absolute right-[12px] top-[12px] bg-transparent"
                  onClick={() => setQuery('')}
                >
                  <CloseCircleIcon />
                </IconButton>
              ) : undefined
            }
            placeholder="Search by Ticket properties"
            className="flex-1 max-w-[450px]"
            inputClassName="bg-bgColor-menu"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      )}

      <Table
        headers={headers}
        data={tickets}
        sortSettings={sortSettings}
        isFetching={isFetching}
        isLoading={isLoading}
        withIndicator
        variant="secondary"
        containerClassName="h-[600px]"
        highlight={debouncedQuery}
        scroll
        sticky
        isError={isError}
        onSort={setSortSettings}
        pagination={{
          currentPage,
          onPageChange: (page: number) => setCurrentPage(page),
          onPageSizeChange: (pageSize: PageSize) => setPageSize(pageSize),
          pageSize,
          total,
        }}
      />
    </div>
  );
};

export { TicketsTable };
