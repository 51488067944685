import dayjs from 'dayjs';

import {
  MetadataMap,
  TypeNotation,
  TableManagerFieldValue,
  RelationOptionsMap,
  DefaultTableValue,
  GridColumns,
} from 'src/shared/types';
import { User, CreateModalValues } from 'src/store/slices';
import { isArrayMultiselectOptions } from 'src/shared/utils';

type InitialState = {
  [key: string]: TableManagerFieldValue;
};

type GetInitialState = {
  schema: TypeNotation[];
  fieldsMetadata: MetadataMap;
  user: User | null;
  isEditMode: boolean;
  relationOptions: RelationOptionsMap;
  defaultCreateModalState: CreateModalValues | null;
  selectedRow: Record<string, TableManagerFieldValue> | null;
};

const checkIsRelationOptionValue = (value: TableManagerFieldValue) => {
  return value !== null && value && typeof value === 'object' && 'value' in value;
};

const prepareDateValue = (
  value: any,
  key: string,
  fieldsMetadata: MetadataMap,
  schema: TypeNotation[],
) => {
  const currentField = schema.find((el) => el.field === key);

  if (currentField?.type === 'date' || currentField?.type === 'dateTime') {
    if (!value || fieldsMetadata?.update?.[key]?.hidden || fieldsMetadata?.create?.[key]?.hidden) {
      return [key, undefined];
    }

    const date = dayjs(value).toISOString();
    return [key, date];
  }

  return null;
};

const prepareMultiselectValue = (value: any, key: string) => {
  if (isArrayMultiselectOptions(value)) {
    const preparedMultiselectValues =
      value.length > 0
        ? value.map((el) => (typeof el === 'object' && 'value' in el ? el.value : el))
        : [];

    return [key, preparedMultiselectValues];
  }
  return null;
};

const prepareOptionValue = (
  value: any,
  key: string,
  previousFormState: Record<string, any> | null,
) => {
  if (value && typeof value === 'object' && 'value' in value) {
    const optionValue = value.value;

    const previousValue = previousFormState ? previousFormState[key] : null;

    if (optionValue === previousValue) {
      return [key, undefined];
    }

    return [key, optionValue];
  }
  return null;
};

const areArraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

const checkForChanges = (
  newFormState: Record<string, any>,
  previousFormState: Record<string, any> | null,
) => {
  if (previousFormState === null) {
    return newFormState;
  }

  const updatedFormState = Object.fromEntries(
    Object.entries(newFormState).map(([key, newValue]) => {
      const previousValue = previousFormState[key];

      if (Array.isArray(newValue) && Array.isArray(previousValue)) {
        return [key, areArraysEqual(newValue, previousValue) ? undefined : newValue];
      }

      return [key, newValue === previousValue ? undefined : newValue];
    }),
  );

  return updatedFormState;
};

const getInitialState = ({
  schema,
  fieldsMetadata,
  user,
  isEditMode,
  relationOptions,
  defaultCreateModalState,
  selectedRow,
}: GetInitialState): InitialState => {
  const initialState: InitialState = {};

  const defaultValues: Record<string, TableManagerFieldValue> = {
    boolean: false,
    string: '',
    number: '',
    date: null,
    relationOption: null,
    relationOptions: [],
    array: [],
    enum: '',
  };

  if (isEditMode && selectedRow) {
    schema.forEach((item) => {
      if (
        fieldsMetadata?.update?.[item.field]?.hidden &&
        fieldsMetadata?.update?.[item.field]?.defaultValue === DefaultTableValue.email &&
        !fieldsMetadata?.update?.[item.field]?.readonly &&
        user?.email
      ) {
        initialState[item.field] = user.email;
      } else if (
        item.field in relationOptions &&
        fieldsMetadata?.multiSelectFields?.includes(item.field)
      ) {
        const currentValues = selectedRow[item.field] as string[];
        const currentOptions = Array.isArray(currentValues)
          ? relationOptions[item.field].filter((el) => currentValues.includes(el.value))
          : [];
        initialState[item.field] = currentOptions.length > 0 ? currentOptions : [];
      } else if (item.field in relationOptions) {
        const currentValue = selectedRow[item.field];
        const currentOption = relationOptions[item.field].find((el) => el.value === currentValue);
        initialState[item.field] = currentOption ?? defaultValues.relationOption;
      } else {
        initialState[item.field] = selectedRow[item.field];
      }
    });
    return initialState;
  }
  schema.forEach((item) => {
    if (
      fieldsMetadata?.create?.[item.field]?.hidden &&
      fieldsMetadata?.create?.[item.field]?.defaultValue === DefaultTableValue.email &&
      user?.email
    ) {
      initialState[item.field] = user.email;
    } else if (
      fieldsMetadata?.create?.[item.field]?.defaultValue === DefaultTableValue.ownerLocationId &&
      user?.ProviderRoleMatrix.OwnerLocationID
    ) {
      initialState[item.field] =
        relationOptions[item.field].find(({ value }) => {
          return value === user.ProviderRoleMatrix.OwnerLocationID;
        }) || '';
    } else if (
      item.field in relationOptions &&
      fieldsMetadata?.multiSelectFields?.includes(item.field)
    ) {
      initialState[item.field] = defaultValues.relationOptions;
    } else if (
      item.field in relationOptions &&
      defaultCreateModalState &&
      item.field in defaultCreateModalState &&
      defaultCreateModalState[item.field].field === item.field
    ) {
      const currentOption = relationOptions[item.field].find(
        (el) => el.value === defaultCreateModalState[item.field].value,
      );

      initialState[item.field] = currentOption ?? defaultValues.relationOption;
    } else if (item.field in relationOptions) {
      initialState[item.field] = defaultValues.relationOption;
    } else if ('defaultValue' in item && item.defaultValue !== undefined) {
      initialState[item.field] = item?.defaultValue;
    } else {
      initialState[item.field] = defaultValues[item.type];
    }
  });

  return initialState;
};

const getGridClasses = (
  gridColumns: GridColumns | undefined,
  deviceStatus: {
    isSmallScreen: boolean;
    isMediumScreen: boolean;
    isLargeScreen: boolean;
  },
) => {
  const sm = gridColumns?.sm || 1;
  const md = gridColumns?.md !== undefined ? gridColumns.md : sm;
  const lg = gridColumns?.lg !== undefined ? gridColumns.lg : md;

  if (deviceStatus.isSmallScreen) {
    return { gridColumn: `span ${sm} / span ${sm}` };
  }
  if (deviceStatus.isMediumScreen) {
    return { gridColumn: `span ${md} / span ${md}` };
  }

  return { gridColumn: `span ${lg} / span ${lg}` };
};

export {
  prepareDateValue,
  prepareMultiselectValue,
  prepareOptionValue,
  checkForChanges,
  getInitialState,
  checkIsRelationOptionValue,
  getGridClasses,
};
