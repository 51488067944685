import { ChangeEvent, FC } from 'react';

import { Icon } from 'src/shared/ui/icon';
import { Icons } from 'src/assets/icons';
import { TextField } from 'src/shared/ui/textField';
import { IconButton } from 'src/shared/ui/iconButton';

interface SearchSideBarInputProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  type: 'people' | 'equipment';
}

const SearchSideBarInput: FC<SearchSideBarInputProps> = ({ value, onChange, onReset, type }) => {
  return (
    <div className="my-2">
      <TextField
        startIcon={
          <Icon
            icon={<Icons.Filled.InternetCode.Search />}
            className="absolute left-[16px] top-[12px] fill-textColor-tertiary"
          />
        }
        endIcon={
          value ? (
            <IconButton
              iconClassName="fill-textColor-tertiary"
              size="none"
              className="absolute right-[12px] top-[12px] bg-transparent"
              onClick={onReset}
            >
              <Icons.Filled.Edit.CloseCircleIcon />
            </IconButton>
          ) : undefined
        }
        placeholder={`Search ${type}...`}
        className="flex-1"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export { SearchSideBarInput };
