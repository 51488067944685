import { FC } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DATE_FORMAT } from 'src/shared/utils';
import { DateOrTimeView } from '@mui/x-date-pickers';

type DateTimeControlProps = {
  value: string | null;
  handleChange?: (value: string) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  dateFormat?: string;
  includeTime?: boolean;
};

const DateTimeControl: FC<DateTimeControlProps> = ({
  value,
  handleChange,
  label,
  required,
  disabled,
  readOnly,
  dateFormat = DATE_FORMAT.DEFAULT_ADMIN_PAGE,
  includeTime = false,
}) => {
  const timeView: DateOrTimeView[] = includeTime ? ['hours', 'minutes'] : [];

  const views: DateOrTimeView[] = ['day', 'month', ...timeView];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        sx={{
          padding: 0,
          overflow: 'visible',
        }}
        components={['DateTimePicker']}
      >
        <DateTimePicker
          label={label || 'Select date'}
          value={dayjs(value)}
          onChange={(newValue) => {
            if (handleChange) {
              handleChange(dayjs(newValue).format(dateFormat));
            }
          }}
          format={dateFormat}
          disabled={disabled}
          readOnly={readOnly}
          views={views}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export { DateTimeControl };
