import { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { useScreenBreakpoints } from 'src/shared/hooks/useScreenBreakpoints';
import {
  TypeNotation,
  TableManagerFieldValue,
  RelationOptionsMap,
  MetadataMap,
  TagType,
} from 'src/shared/types';

import { FormField } from '../FormField';
import { getGridClasses } from '../../helpers';

interface FormFieldsProps {
  children?: ReactNode;
  schema: TypeNotation[];
  formState: Record<string, TableManagerFieldValue>;
  idField: string;
  isLoadingForm: boolean;
  relationOptions: RelationOptionsMap;
  fieldsMetadata: MetadataMap;
  onChange: (field: string, value: TableManagerFieldValue) => void;
  selectedRow: Record<string, TableManagerFieldValue> | null;
  isLoadingOrFetchingRelationOptions: boolean;
}

const FormFields: FC<FormFieldsProps> = ({
  schema,
  formState,
  idField,
  isLoadingForm,
  relationOptions,
  fieldsMetadata,
  selectedRow,
  onChange,
  isLoadingOrFetchingRelationOptions,
}) => {
  const { modelName } = useParams<{ modelName: TagType }>();
  const isUpdateMode = !!selectedRow;

  const deviceStatus = useScreenBreakpoints();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {schema.map((schemaElement) => {
        const typeMetadata = isUpdateMode ? 'update' : 'create';

        if (
          fieldsMetadata?.[typeMetadata]?.[schemaElement.field] &&
          fieldsMetadata?.[typeMetadata]?.[schemaElement.field]?.hidden
        ) {
          return null;
        }

        if (!modelName) return null;

        const isCustomComponent =
          modelName &&
          fieldsMetadata?.customComponents?.[modelName]?.[schemaElement.field]?.field ===
            schemaElement.field;

        const gridClasses = isCustomComponent
          ? getGridClasses(
              fieldsMetadata?.customComponents?.[modelName]?.[schemaElement.field]?.gridColumns,
              deviceStatus,
            )
          : {};

        return (
          <Box
            sx={{
              ...gridClasses,
            }}
            key={schemaElement.field}
          >
            <FormField
              schemaElement={schemaElement}
              value={formState[schemaElement.field]}
              onChange={onChange}
              isLoadingForm={isLoadingForm}
              requiredState={
                isUpdateMode
                  ? fieldsMetadata.update?.[schemaElement.field]?.requiredState
                  : fieldsMetadata.create?.[schemaElement.field]?.requiredState
              }
              relationOptions={relationOptions}
              fieldsMetadata={fieldsMetadata}
              formState={formState}
              idField={idField}
              isLoadingOrFetchingRelationOptions={isLoadingOrFetchingRelationOptions}
            />
          </Box>
        );
      })}
    </div>
  );
};

export { FormFields };
