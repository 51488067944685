import { Dispatch, FC, ReactNode, SetStateAction, useEffect } from 'react';
import clsx from 'clsx';

import { GLOBAL_LAYOUT } from 'src/shared/constants';
import { Icons } from 'src/assets/icons';
import { breakpointValues } from 'src/shared/hooks/useBreakpoints';

import { Typography } from '../typography';
import { IconButton } from '../iconButton';

interface SidebarProps {
  children?: ReactNode;
  isExpanded: boolean;
  title: string;
  toggleExpanded: () => void;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

const Sidebar: FC<SidebarProps> = ({
  children,
  isExpanded,
  title,
  toggleExpanded,
  setExpanded,
}) => {
  useEffect(() => {
    const resize = () => {
      if (window.innerWidth <= breakpointValues.sm && isExpanded) {
        setExpanded(false);
      }
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [setExpanded, isExpanded]);

  return (
    <nav
      style={{
        width: '100%',
        minWidth: isExpanded
          ? GLOBAL_LAYOUT.formPageSidebar.expandedWidth
          : GLOBAL_LAYOUT.formPageSidebar.collapsedWidth,
        maxWidth: isExpanded
          ? GLOBAL_LAYOUT.formPageSidebar.expandedWidth
          : GLOBAL_LAYOUT.formPageSidebar.collapsedWidth,
      }}
      className={clsx(
        `box-border flex flex-col px-4 py-4 gap-y-8 sticky top-0 self-start h-full bg-white`,
      )}
    >
      <div className="flex flex-col gap-y-8 w-full">
        <div className="flex justify-between items-center">
          {isExpanded && (
            <Typography
              className="whitespace-nowrap overflow-hidden"
              variant="h2"
            >
              {title}
            </Typography>
          )}

          <IconButton
            color="secondary"
            size="lg"
            onClick={toggleExpanded}
          >
            {isExpanded ? (
              <Icons.Filled.Chevrons.ArrowIOSLeftIcon />
            ) : (
              <Icons.Filled.Chevrons.ArrowIOSRightIcon />
            )}
          </IconButton>
        </div>
      </div>

      {children}
    </nav>
  );
};

export { Sidebar };
