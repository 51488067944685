import { FC } from 'react';

import { TableManager, TableManagerProps } from 'src/features/admin/TableManager';

type AdminTableManagerProps = TableManagerProps;

const AdminTableManager: FC<AdminTableManagerProps> = (props) => {
  return (
    <div className="w-full h-full bg-[#EFF0F4] flex flex-col gap-4">
      <TableManager {...props} />
    </div>
  );
};

export { AdminTableManager };
