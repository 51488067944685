import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { selectCurrentUser } from 'src/store/slices';
import {
  getAzureUrl,
  getIsPortalSectionItemVisible,
  getIsPortalSectionVisible,
  getIsSectionItemClickable,
  not,
} from 'src/shared/utils';
import { useGetPortalProviderSettingsQuery } from 'src/store/api';
import { Role } from 'src/shared/types';

import { useGetProviderQuery } from '../../store/api/provider';

import { PrimaryCard, SecondaryCard, TertiaryCard } from './ui';

const AMOUNT_OF_PRIMARY_CARDS = 5;

const Home = () => {
  const { data: configuration } = useGetPortalProviderSettingsQuery({});
  const { data: provider } = useGetProviderQuery('');

  const user = useSelector(selectCurrentUser);

  const userRole = user?.ProviderRoleMatrix.userRole as Role | undefined;
  const isUser = user?.ProviderRoleMatrix?.userRole === Role.User;

  const sections = useMemo(() => {
    return userRole
      ? configuration?.ProviderPortalSections.map((section) => {
          return {
            ...section,
            isVisible: getIsPortalSectionVisible(section, userRole),
          };
        })
      : [];
  }, [configuration?.ProviderPortalSections, userRole]);

  const appSection = sections?.find((section) => section.Id === '1');
  const supportSection = sections?.find((section) => section.Id === '2');
  const adminSection = sections?.find((section) => section.Id === '3');

  const mainPrimaryCards = userRole
    ? appSection?.ProviderPortalSectionItems.filter((el) => el.Link !== '/')
        .filter((item) =>
          getIsPortalSectionItemVisible({
            section: appSection,
            item,
            userRole,
          }),
        )
        .slice(0, AMOUNT_OF_PRIMARY_CARDS)
    : [];
  const additionalPrimaryCards =
    appSection?.ProviderPortalSectionItems.slice(AMOUNT_OF_PRIMARY_CARDS);
  const secondaryCards = userRole
    ? supportSection?.ProviderPortalSectionItems.filter((item) =>
        getIsPortalSectionItemVisible({
          section: supportSection,
          item,
          userRole,
        }),
      )
    : [];
  const tertiaryCards = userRole
    ? adminSection?.ProviderPortalSectionItems.filter((item) =>
        getIsPortalSectionItemVisible({
          section: adminSection,
          item,
          userRole,
        }),
      )
    : [];

  const appsContent = !!appSection &&
    !!userRole &&
    getIsPortalSectionVisible(appSection, userRole) && (
      <div className="flex flex-col gap-6">
        <Typography
          variant="h2"
          className="text-textColor-black"
        >
          {appSection?.Title}
        </Typography>

        <div className="grid gap-4 place-items-center grid-cols-2 grid-rows-auto md:grid-cols-3  lg:grid-cols-5">
          {mainPrimaryCards?.map((option, i) =>
            not(not(option.Link.includes('http')) && isUser) ? (
              <PrimaryCard
                disabled={!getIsSectionItemClickable(option, userRole)}
                index={i}
                key={option.Title}
                label={option.Title}
                description={option.HoverText}
                icon={getAzureUrl(provider?.FileRootPath, option.Icon)}
                link={option.Link}
                isAppLink={not(option.Link.includes('http')) || false}
              />
            ) : null,
          )}
        </div>

        <div>
          {additionalPrimaryCards?.map((option, i) => (
            <TertiaryCard
              key={option.Title}
              label={option.Title}
              description={option.HoverText}
              icon={getAzureUrl(provider?.FileRootPath, option.Icon)}
              link={option.Link}
              isFirstCard={i === 0}
              isLastCard={i === (appSection?.ProviderPortalSectionItems.slice(5).length || 0) - 1}
              isAppLink={not(option.Link.includes('http')) || false}
            />
          ))}
        </div>
      </div>
    );

  const supportContent = !!supportSection &&
    !!userRole &&
    getIsPortalSectionVisible(supportSection, userRole) && (
      <div className="flex flex-col gap-6">
        <Typography
          variant="h3"
          className="text-textColor-black"
        >
          {supportSection?.Title}
        </Typography>

        <div className="flex gap-[48px]">
          {secondaryCards?.map((option) => (
            <SecondaryCard
              key={option.Title}
              label={option.Title}
              description={option.HoverText}
              icon={getAzureUrl(provider?.FileRootPath, option.Icon)}
              link={option.Link}
            />
          ))}
        </div>
      </div>
    );

  const adminToolsContent = !!adminSection &&
    !!userRole &&
    getIsPortalSectionVisible(adminSection, userRole) && (
      <div className="flex flex-col gap-6">
        <Typography
          variant="h3"
          className="text-textColor-black"
        >
          {adminSection?.Title}
        </Typography>

        <div className="flex flex-col">
          {tertiaryCards?.map((option, i) => (
            <TertiaryCard
              key={option.Title}
              label={option.Title}
              description={option.HoverText}
              icon={getAzureUrl(provider?.FileRootPath, option.Icon)}
              link={option.Link}
              isFirstCard={i === 0}
              isLastCard={i === (adminSection?.ProviderPortalSectionItems.length || 0) - 1}
            />
          ))}
        </div>
      </div>
    );

  return (
    <div className="flex relative flex-col items-center w-full">
      <div className="relative flex justify-center w-full h-full p-6">
        <div className="flex flex-col gap-[40px] w-full md:max-w-[1042px] h-fit bg-textColor-white rounded-[20px] p-6">
          {appsContent}

          {supportContent}

          {adminToolsContent}
        </div>
      </div>
    </div>
  );
};

export { Home };
