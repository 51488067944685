import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useMemo, useState } from 'react';

import { SurveyEntity, SurveyNotification } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import { Modal } from 'src/shared/ui/modal';
import { SelectInputItem } from 'src/shared/ui/selectInput';
import { MultiSelectInput } from 'src/shared/ui/MultiSelectInput';
import { Spinner } from 'src/shared/ui/spinner';
import { Typography } from 'src/shared/ui/typography';
import { showToastErrorMessage } from 'src/shared/utils';
import { IconButton } from 'src/shared/ui/iconButton';
import { useEditSurveyMutation } from 'src/store/api/survey';
import {
  useGetSurveyNotificationsQuery,
  useSubscribeSurveyForSurveyNotificationsMutation,
} from 'src/store/api/survey-notification';
import { Icons } from 'src/assets/icons';

type SurveyFormDetailsModalProps = {
  form: SurveyEntity;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const SurveyFormDetailsModal: FC<SurveyFormDetailsModalProps> = ({ form, isOpen, setIsOpen }) => {
  const { data: notifications = [] } = useGetSurveyNotificationsQuery('');

  const [updateSurvey, { isLoading: isUpdating }] = useEditSurveyMutation();
  const [subscribeSurveyForNotification, { isLoading: isSubscribing }] =
    useSubscribeSurveyForSurveyNotificationsMutation();

  const isSubmitting = isUpdating || isSubscribing;

  const [selectedNotifications, setSelectedNotifications] = useState<SurveyNotification[]>([]);

  const alreadySelectedNotificationsIds = useMemo(
    () => form.notifications.map((notification) => notification.surveyNotification.id),
    [form],
  );

  const toggleSelectedNotifications = (notification: SurveyNotification) => {
    setSelectedNotifications((prev) => {
      const isAlreadySelected = prev?.some(({ id }) => id === notification.id);

      if (isAlreadySelected) {
        return prev?.filter(({ id }) => id !== notification.id);
      }
      return [...prev, notification];
    });
  };

  const removeNotification = (notificationId: string) => {
    setSelectedNotifications((prev) => prev?.filter(({ id }) => id !== notificationId));
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedNotifications([]);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const preparedForm = {
      ...form,
      notifications: selectedNotifications.map((notification) => ({
        surveyNotification: notification,
      })),
    };

    try {
      await updateSurvey({
        ...preparedForm,
      }).unwrap();

      await subscribeSurveyForNotification({
        notificationIds: selectedNotifications.map(({ id }) => id),
        surveyId: form.id,
      });
    } catch (error) {
      showToastErrorMessage(`There was an error trying to update ${form.name}`);
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    if (notifications.length > 0 && alreadySelectedNotificationsIds.length > 0) {
      setSelectedNotifications((prev) => {
        const alreadySelectedNotifications = notifications.filter((notification) =>
          alreadySelectedNotificationsIds.includes(notification.id),
        );

        const remainingNotifications = prev.filter(
          (notif) => !alreadySelectedNotificationsIds.includes(notif.id),
        );

        return [...remainingNotifications, ...alreadySelectedNotifications];
      });
    }
  }, [notifications, alreadySelectedNotificationsIds, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={closeModal}
    >
      <form
        className="flex flex-col justify-between gap-y-6 w-[550px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-8">
          <Typography variant="h2">{form.name}</Typography>

          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-3">
              <Typography variant="h3">Select Notifications</Typography>

              <MultiSelectInput
                name="notifications"
                label="Select Notifications"
                placeholder="Notifications"
                disabled={isSubmitting}
                items={
                  notifications.map((notification) => ({
                    label: (
                      <SelectInputItem
                        selected={selectedNotifications.some(
                          (notif) => notif.id === notification.id,
                        )}
                      >
                        {notification.name}
                      </SelectInputItem>
                    ),
                    value: notification.name,
                    onClick: () => toggleSelectedNotifications(notification),
                  })) ?? []
                }
              />

              {!!selectedNotifications.length && (
                <div className="flex flex-col gap-y-3 h-[198px] overflow-y-scroll">
                  {selectedNotifications.map((notification) => (
                    <div
                      key={notification.id}
                      className="flex items-center justify-between border border-[#E4E9F2]  py-2 px-3 rounded-lg"
                    >
                      {notification.name}

                      <IconButton
                        color="basic"
                        size="md"
                        iconSize="md"
                        iconClassName="fill-[#231F20]"
                        onClick={() => removeNotification(notification.id)}
                        disabled={isSubmitting}
                      >
                        <Icons.Outlined.Edit.TrashIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex w-full justify-end gap-x-2">
          <Button
            variant="outlined"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Spinner size="sm" /> : 'Update'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { SurveyFormDetailsModal };
