import { useEffect, useState } from 'react';

type Relation = 'max' | 'min';
type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const breakpoints: Record<Breakpoint, string> = {
  'sm': '640px',
  'md': '768px',
  'lg': '1024px',
  'xl': '1280px',
  '2xl': '1536px',
};

const breakpointValues: Record<Breakpoint, number> = {
  'sm': 640,
  'md': 768,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
} as const;

const useBreakpoints = (breakpoint: Breakpoint, relation: Relation = 'min') => {
  const [targetReached, setTargetReached] = useState(false);

  const breakpointValue = breakpoints[breakpoint] ?? '';

  useEffect(() => {
    const listener = (event: MediaQueryListEvent) => setTargetReached(event.matches);

    const media = window.matchMedia(`(${relation}-width: ${breakpointValue})`);

    if (media.matches) {
      setTargetReached(media.matches);
    }

    media.addEventListener('change', listener);

    return () => {
      media.removeEventListener('change', listener);
    };
  }, [breakpoint, relation, breakpointValue]);

  return targetReached;
};

export { useBreakpoints, breakpointValues, breakpoints };
