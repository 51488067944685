/* eslint-disable react/no-array-index-key */
import { ComponentProps, FC, useCallback } from 'react';
import { match } from 'ts-pattern';

import { clsx, not } from 'src/shared/utils';
import { Skeleton } from 'src/shared/ui/skeleton';

type CardSkeletonProps = ComponentProps<'div'> & { isMainCard?: boolean; size?: number };

const CardSkeleton: FC<CardSkeletonProps> = ({
  className,
  isMainCard = true,
  size = 2,
  ...props
}) => {
  const ticketContent = useCallback(
    (size: number) => (
      <div className="flex flex-col gap-y-3">
        <Skeleton className="w-[48px] h-[22px]" />

        <div className="flex flex-col gap-y-2">
          <Skeleton className="w-full h-[56px]" />

          <div className="grid grid-cols-1 gap-2">
            {Array.from({ length: size }).map((_, i) => (
              <div
                key={i}
                className="flex flex-col gap-y-2"
              >
                <Skeleton className="w-[46px] h-[16px]" />

                <Skeleton className="w-full h-[56px]" />
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    [],
  );

  return (
    // | Card.
    <div
      className={clsx(
        'flex flex-col p-[16px] gap-y-5 rounded-2xl bg-bgColor-card',
        not(isMainCard) && 'pt-[60px]',
        className,
      )}
      {...props}
    >
      {/* Header */}
      {match(isMainCard)
        .with(true, () => (
          <div className="flex gap-x-[8px] items-center">
            <Skeleton className="w-[24px] h-[24px]" />

            <Skeleton className="w-[152px] h-[24px]" />

            <Skeleton className="w-[24px] h-[24px]" />
          </div>
        ))
        .otherwise(() => null)}

      {/* Ticket */}
      <div className="flex flex-col p-4 gap-y-3">
        {/* Ticket Details */}
        <div className="flex flex-col gap-y-3 pb-2">
          <div className="flex justify-between">
            <Skeleton className="w-[100px] h-[24px]" />

            <div className="flex flex-row-reverse gap-x-4">
              <Skeleton className="w-[24px] h-[24px]" />

              <Skeleton className="w-[24px] h-[24px]" />

              <Skeleton className="w-[24px] h-[24px]" />
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <Skeleton className="w-[84px] h-[24px]" />

              <Skeleton className="w-[120px] h-[24px]" />
            </div>

            <div className="flex items-end gap-x-[5px]">
              <Skeleton className="w-[76px] h-[24px]" />

              <Skeleton className="w-[24px] h-[24px]" />
            </div>
          </div>
        </div>

        {ticketContent(size)}
      </div>
    </div>
  );
};

export type { CardSkeletonProps };
export { CardSkeleton };
