import { FC, memo, useCallback } from 'react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import { dayjs } from 'src/shared/utils';
import { formatISODurationToHHmm, getISODurationFromHHmm } from '../../helpers';
import { TimePickerInput } from 'src/shared/ui/timePickerInput';
import clsx from 'clsx';
import { MuiDateTimePicker, MuiDateTimePickerProps } from 'src/shared/ui/muiDateTimePicker';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

type DueDateRangePickerValues = {
  startTime: string;
  endTime: string;
  duration: string;
};

type DateRangeLabels = {
  startTime?: string;
  duration?: string;
  endTime?: string;
};

type DueDateRangePickerProps = {
  values: DueDateRangePickerValues;
  timeZone: string;
  setFieldValue: (field: keyof DueDateRangePickerValues, value: any) => void;
  labels?: DateRangeLabels;
  className?: string;
  errors?: Partial<Record<keyof DueDateRangePickerValues, string>>;
} & Omit<MuiDateTimePickerProps, 'value' | 'onChange' | 'error'>;

const defaultLabels: Required<DateRangeLabels> = {
  duration: 'Duration',
  endTime: 'End Time',
  startTime: 'Start Time',
};

const DueDateRangePicker: FC<DueDateRangePickerProps> = ({
  values,
  timeZone,
  setFieldValue,
  labels,
  className,
  errors,
  ...restPickerProps
}) => {
  const onStartTimeSelect = useCallback(
    (newStartTimeValue: string | null) => {
      if (values.duration && newStartTimeValue) {
        const duration = dayjs.duration(values.duration);
        const newDateEnd = dayjs.utc(newStartTimeValue).tz(timeZone).add(duration);
        setFieldValue('endTime', newDateEnd.toISOString());
      } else if (values.endTime && newStartTimeValue) {
        const newDuration = dayjs
          .duration(dayjs(values.endTime).diff(dayjs(newStartTimeValue)))
          .toISOString();
        setFieldValue('duration', `${newDuration}`);
      }
      setFieldValue('startTime', newStartTimeValue || '');
    },
    [values.duration, values.endTime, setFieldValue, timeZone],
  );

  const onDurationSelect = useCallback(
    (name: string, value: string) => {
      if (!value) return;
      const duration = dayjs.duration(getISODurationFromHHmm(value));
      if (values.startTime) {
        const newDateEnd = dayjs.utc(values.startTime).tz(timeZone).add(duration).toISOString();
        if (newDateEnd !== values.endTime) {
          setFieldValue('endTime', newDateEnd);
        }
      } else if (values.endTime) {
        const newStartDate = dayjs
          .utc(values.endTime)
          .tz(timeZone)
          .subtract(duration)
          .toISOString();
        if (newStartDate !== values.startTime) {
          setFieldValue('startTime', newStartDate);
        }
      }
      setFieldValue('duration', duration.toISOString());
    },
    [values.startTime, values.endTime, setFieldValue, timeZone],
  );

  const onEndTimeSelect = useCallback(
    (newEndTimeValue: string | null) => {
      if (values.startTime && newEndTimeValue) {
        const formattedEndTime = dayjs.utc(newEndTimeValue).tz(timeZone);
        const formattedStartTime = dayjs.utc(values.startTime).tz(timeZone);
        const newDuration = dayjs.duration(formattedEndTime.diff(formattedStartTime)).toISOString();
        setFieldValue('duration', `${newDuration}`);
      } else if (values.duration && newEndTimeValue) {
        const duration = dayjs.duration(values.duration);
        const newStartDate = dayjs
          .utc(newEndTimeValue)
          .tz(timeZone)
          .subtract(duration)
          .toISOString();
        setFieldValue('startTime', newStartDate);
      }
      setFieldValue('endTime', newEndTimeValue || '');
    },
    [values.startTime, values.duration, setFieldValue, timeZone],
  );

  return (
    <>
      <div className={clsx('flex flex-col md:flex-row gap-x-8', className)}>
        <MuiDateTimePicker
          value={values.startTime || null}
          onChange={onStartTimeSelect}
          isRequired
          label={labels?.startTime || defaultLabels.startTime}
          error={errors?.startTime}
          className="shrink-1"
          {...restPickerProps}
        />
        <MuiDateTimePicker
          value={values.endTime || null}
          onChange={onEndTimeSelect}
          isRequired
          label={labels?.endTime || defaultLabels.endTime}
          error={errors?.endTime}
          className="shrink-1"
          {...restPickerProps}
        />
      </div>
      <TimePickerInput
        isRequired
        required
        name="duration"
        label={labels?.duration || defaultLabels.duration}
        handleSelect={onDurationSelect}
        onClear={() => setFieldValue('duration', '')}
        value={values.duration ? formatISODurationToHHmm(values.duration) : ''}
        placeholder="Duration..."
        className="shrink-0 w-max"
      />
    </>
  );
};

const memoizedDueDateRangePicker = memo(DueDateRangePicker);

export { memoizedDueDateRangePicker as DueDateRangePicker };
