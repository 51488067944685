import { FC, MouseEvent } from 'react';
import clsx from 'clsx';

import { Typography } from 'src/shared/ui/typography';
import { Tag } from 'src/shared/ui/tag';
import { IconButton } from 'src/shared/ui/iconButton';
import { Icons } from 'src/assets/icons';

interface CardTitleProps {
  title: string;
  count: number;
  onOpenFilterMenu: (e: MouseEvent<HTMLButtonElement>) => void;
  isFilterMenuOpen: boolean;
  appliedFiltersAmount?: number;
}

const CardTitle: FC<CardTitleProps> = ({
  title,
  count,
  onOpenFilterMenu,
  isFilterMenuOpen,
  appliedFiltersAmount,
}) => {
  return (
    <div className="flex w-full justify-between items-center">
      <div className="flex justify-between items-center gap-2">
        <Typography variant="h3">{title}</Typography>

        <Tag type="light">{count}</Tag>
      </div>

      <div className="relative">
        <IconButton
          size="sm"
          onClick={onOpenFilterMenu}
          className={clsx(
            'w-[24px] h-[24px]',
            isFilterMenuOpen && '!bg-[rgba(18,60,142,0.08)] rounded-full',
          )}
          iconClassName={clsx(isFilterMenuOpen && 'fill-brandingColor-primary-gradient')}
        >
          <Icons.Outlined.Controls.FilterIcon />
        </IconButton>

        {!!appliedFiltersAmount && (
          <Tag
            type="white"
            className="absolute right-[-2px] top-[-9px] !px-1 text-white bg-brandingColor-primary-gradient"
          >
            {appliedFiltersAmount}
          </Tag>
        )}
      </div>
    </div>
  );
};

export { CardTitle };
