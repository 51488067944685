import clsx from 'clsx';

import FallbackAvatarImage from 'src/assets/images/no-avatar.png';

type AvatarProps = { image?: string; className?: string };

const Avatar: React.FC<AvatarProps> = ({ image = FallbackAvatarImage, className }) => {
  return (
    <div className={clsx('w-[32px] h-[32px]', className)}>
      <img
        alt="avatar"
        src={image}
        className="rounded-full w-full"
      />
    </div>
  );
};

export { Avatar };
export type { AvatarProps };
