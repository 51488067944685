import { FC, Dispatch, SetStateAction, useEffect, useState, useRef, useMemo } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';

import { TextField } from 'src/shared/ui/textField';
import { Icons } from 'src/assets/icons';
import { Spinner } from 'src/shared/ui/spinner';
import { RawResult, SearchResult } from 'src/shared/types';
import { IconButton } from 'src/shared/ui/iconButton';

type SearchFieldMapProps = {
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  selectedAddress: SearchResult<RawResult> | null;
  setSelectedAddress: Dispatch<SetStateAction<SearchResult<RawResult> | null>>;
};

const SEARCH_FIELD_DELAY = 500;

const SearchFieldMap: FC<SearchFieldMapProps> = ({
  address,
  setAddress,
  selectedAddress,
  setSelectedAddress,
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchResult<RawResult>[]>([]);

  const provider = useMemo(() => new OpenStreetMapProvider(), []);

  const timerId = useRef<NodeJS.Timeout | null>(null);

  const handleSetResult = (result: SearchResult<RawResult>) => {
    setAddress(result.label);
    setSelectedAddress(result);
    setSearchResults([]);
  };

  const handleClearSearch = () => {
    setSearchResults([]);
    setAddress('');
    setSelectedAddress(null);
  };

  useEffect(() => {
    if (timerId?.current) {
      clearTimeout(timerId.current);
    }

    if (selectedAddress && address === selectedAddress?.label) {
      return undefined;
    }

    timerId.current = setTimeout(() => {
      (async () => {
        setIsFetching(true);
        const results = await provider.search({ query: address });

        setSearchResults(results);
        setIsFetching(false);
      })();
    }, SEARCH_FIELD_DELAY);

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, [address, selectedAddress]);

  return (
    <div
      className={clsx(
        'absolute -translate-x-1/2 top-0 left-1/2 bg-white w-full md:w-[70%] max-w-[700px] z-[900] p-2 rounded-b-2xl flex flex-col gap-2 divide-y',
      )}
    >
      <div className="w-full">
        <TextField
          placeholder="Enter your address..."
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          endIcon={
            !!address && isFetching ? (
              <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 right-2">
                <Spinner size="xs" />
              </div>
            ) : (
              <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 right-0">
                <IconButton
                  size="sm"
                  onClick={handleClearSearch}
                  disabled={!address.length}
                >
                  <Icons.Filled.Edit.CloseIcon />
                </IconButton>
              </div>
            )
          }
        />
      </div>

      {!!searchResults.length && (
        <div className="py-2">
          <div className="flex flex-col gap-y-2 max-h-[198px] overflow-y-scroll">
            {searchResults.map((result) => (
              <div
                onClick={() => handleSetResult(result)}
                key={uuidv4()}
                className="flex items-center justify-between border border-[#E4E9F2] py-2 px-3 rounded-lg cursor-pointer"
              >
                {result.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { SearchFieldMap };
