import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { ActivityJob } from 'src/shared/types';
import { ACTIVITY_HEALTH_BG_COLORS } from '../../helpers';

interface CardColorIndicatorProps {
  card: ActivityJob;
}

const CardColorIndicator: React.FC<CardColorIndicatorProps> = ({ card }) => {
  const { workTrack, workStatus } = card;
  const isCompleted = workStatus === 'Completed';

  return (
    <div
      className={clsx(
        'w-2.5 rounded-l-lg shrink-0',
        !isCompleted && ACTIVITY_HEALTH_BG_COLORS[workTrack],
        isCompleted && 'bg-black',
      )}
    />
  );
};

export { CardColorIndicator };
