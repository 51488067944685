import { forwardRef } from 'react';
import { match } from 'ts-pattern';

import { Icon } from 'src/shared/ui/icon';
import { clsx } from 'src/shared/utils/clsx';

import { type ButtonProps } from '../button';

type FilledButtonProps = ButtonProps & {
  buttonClassName?: string;
};

const FilledButton = forwardRef<HTMLButtonElement, FilledButtonProps>(
  (
    {
      children,
      className,
      buttonClassName,
      color = 'basic',
      startIcon,
      endIcon,
      size,
      darkBg,
      iconClassName,
      disabled,
      ...props
    },
    ref,
  ) => {
    // | INFO: For now correct hover and active states defined only for primary button.
    // | Should be extended in future.
    const colorClasses = match(color)
      // | Filled button styles.
      .with('basic', () => 'bg-bgColor-card')
      .with(
        'primary',
        () =>
          `bg-brandingColor-primary-gradient 
           text-textColor-white
           hover:bg-brandingColor-primary-gradient-hover
           active:bg-brandingColor-primary-gradient-active
           transition-colors`,
      )
      .with('success', () => `bg-semanticColor-success text-textColor-white`)
      .with('info', () => `bg-semanticColor-info text-textColor-white`)
      .with('warning', () => `bg-semanticColor-warning text-textColor-primary`)
      .with('danger', () => `bg-semanticColor-danger text-textColor-white`)
      .exhaustive();

    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled}
        className={clsx(
          buttonClassName,
          colorClasses,
          disabled &&
            'bg-semanticColor-disabled text-textColor-disabled hover:text-textColor-disabled hover:bg-semanticColor-disabled active:text-textColor-disabled active:bg-semanticColor-disabled',
          className,
        )}
        {...props}
      >
        {startIcon && (
          <Icon
            icon={startIcon}
            size={size}
            color={darkBg || color === 'primary' || color === 'info' ? 'light' : 'dark'}
            className={iconClassName}
          />
        )}

        <span>{children}</span>

        {endIcon && (
          <Icon
            icon={endIcon}
            size={size}
            color={darkBg || color === 'primary' || color === 'info' ? 'light' : 'dark'}
            className={iconClassName}
          />
        )}
      </button>
    );
  },
);

FilledButton.displayName = 'FilledButton';

export { FilledButton };
export type { FilledButtonProps };
