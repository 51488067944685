import { ComponentProps, FC } from 'react';

import { clsx } from 'src/shared/utils';
import { Skeleton } from 'src/shared/ui/skeleton';
import { CARD_WIDTH } from 'src/shared/constants';

type ColumnSkeletonProps = ComponentProps<'div'>;

const ColumnSkeleton: FC<ColumnSkeletonProps> = ({ className, children, ...props }) => {
  return (
    <div
      className={clsx(
        'flex flex-col h-full border-l border-r border-b border-outlineColor-input-border',
        'gap-y-4',
        className,
      )}
      style={{
        minWidth: `${CARD_WIDTH}px`,
      }}
      {...props}
    >
      <div className="min-h-[56px] w-full bg-bgColor-card border-b border-outlineColor-input-border flex justify-center items-center">
        <div className="flex gap-x-[12px]">
          <Skeleton className="w-[115px] h-[24px]" />

          <Skeleton className="w-[22px] h-[22px]" />
        </div>
      </div>

      {children}
    </div>
  );
};

export type { ColumnSkeletonProps };
export { ColumnSkeleton };
