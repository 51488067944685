import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useGetModelsOptionsQuery } from 'src/store/api';
import { Typography } from 'src/shared/ui/typography';

export const C2OAdmin: FC = () => {
  const { data: modelsOptions = {}, isFetching: isFetchingModelsOptions } =
    useGetModelsOptionsQuery();
  const { modelName } = useParams();

  const isPageSelected = !!modelName;

  return (
    <div className="w-full h-full p-4">
      {Object.keys(modelsOptions).length > 0 && (
        <Outlet context={{ modelsOptions, isFetchingModelsOptions }} />
      )}
      {!isPageSelected && (
        <div>
          <Typography
            variant="h3"
            className="text-center"
          >
            Select a Table to view details
          </Typography>
        </div>
      )}
    </div>
  );
};
