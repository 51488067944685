import { FC, useEffect, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Brush,
} from 'recharts';
import dayjs from 'dayjs';

import {
  useGetActivityDashboardQuery,
  useGetActivityProvidersQuery,
  useGetDelayCategoriesQuery,
} from 'src/store/api/activities';
import { ChoosingPeriodButtons } from 'src/shared/ui/choosingPeriodButtons';
import { DateRangeSelector } from 'src/shared/ui/dateRangeSelector';
import { useGetActivityDates } from 'src/shared/hooks/useGetActivityDates';
import { Typography } from 'src/shared/ui/typography';
import { MultiSelectInput } from 'src/shared/ui/MultiSelectInput';
import { SelectInputItem } from 'src/shared/ui/selectInput';

import { CustomBarTooltip } from './ui';
import { DASHBOARD_BRUSH_VALUE, GRAPH_COLORS, GRAPH_TRACK_COLORS } from './helpers';
import { TodayButton } from './ui/todayButton';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { EnumActivityTrack } from 'src/shared/types';

const ActivitiesDashboard: FC = () => {
  const { startDate, endDate } = useGetActivityDates();
  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);
  const datesState = useSelector((state: RootState) => state.calendar.dates);

  const brushStartIndex = useMemo(() => {
    if (filterPeriod === 'month' || filterPeriod === 'week') {
      return datesState.length - DASHBOARD_BRUSH_VALUE;
    }

    return null;
  }, [filterPeriod, datesState]);

  const {
    data: providerValuesData,
    isLoading,
    isFetching,
  } = useGetActivityProvidersQuery(
    {
      endDate,
      startDate,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: delayCategories } = useGetDelayCategoriesQuery();

  const providerValues = useMemo(() => {
    return providerValuesData || [];
  }, [providerValuesData]);

  const delayCategoriesValues = useMemo(() => {
    return delayCategories || [];
  }, [delayCategories]);

  const allTracks = Object.values(EnumActivityTrack);

  const [selectedTracks, setSelectedTracks] = useState<EnumActivityTrack[]>([...allTracks]);

  const [selectedProviderIds, setSelectedProviderIds] = useState<string[]>([]);

  useEffect(() => {
    const selectedProviders = providerValues.map((provider) => provider.ProviderID) || [];

    if (selectedProviders.length) {
      setSelectedProviderIds(selectedProviders);
    }
  }, [providerValues]);

  const { data: dashboardData } = useGetActivityDashboardQuery(
    {
      filters: {
        providerId: [...selectedProviderIds],
        track: [...selectedTracks],
        startDate,
        endDate,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <div className="flex flex-col gap-8 px-6">
      <div className="p-6 flex gap-x-4 gap-y-2 items-center justify-center flex-wrap">
        <ChoosingPeriodButtons />

        <DateRangeSelector isActivityPage />
        <TodayButton />
      </div>

      <div className="flex items-center justify-center gap-3 mx-6 p-6 flex-wrap bg-white rounded-lg">
        <div className="flex-1">
          <MultiSelectInput
            placeholder="Select Providers"
            label="Providers"
            value={selectedProviderIds.map(
              (selectedProviderId) =>
                providerValues.find((value) => value.ProviderID === selectedProviderId)
                  ?.ProviderName || '',
            )}
            items={providerValues.map((option) => {
              return {
                label: (
                  <SelectInputItem
                    selected={selectedProviderIds.some((item) => {
                      return item === option.ProviderID;
                    })}
                  >
                    {option.ProviderName}
                  </SelectInputItem>
                ),
                identifier: option.ProviderID,
                value: option.ProviderID,
                onClick: () => {
                  if (Array.isArray(selectedProviderIds)) {
                    const isAlreadySelected = selectedProviderIds.some(
                      (item) => item === option.ProviderID,
                    );

                    if (isAlreadySelected) {
                      const filteredValue = selectedProviderIds.filter(
                        (item) => item !== option.ProviderID,
                      );

                      setSelectedProviderIds(filteredValue);
                    } else {
                      setSelectedProviderIds((prev) => [...prev, option.ProviderID]);
                    }
                  }
                },
              };
            })}
          />
        </div>

        <div className="flex-1">
          <MultiSelectInput
            placeholder="Select Track"
            label="Track Status"
            value={selectedTracks}
            items={allTracks.map((option) => {
              return {
                label: (
                  <SelectInputItem
                    selected={selectedTracks.some((item) => {
                      return item === option;
                    })}
                  >
                    {option}
                  </SelectInputItem>
                ),
                identifier: option,
                value: option,
                onClick: () => {
                  if (Array.isArray(selectedTracks)) {
                    const isAlreadySelected = selectedTracks.some((item) => item === option);

                    if (isAlreadySelected) {
                      const filteredValue = selectedTracks.filter((item) => item !== option);

                      setSelectedTracks(filteredValue);
                    } else {
                      setSelectedTracks((prev) => [...prev, option]);
                    }
                  }
                },
              };
            })}
          />
        </div>
      </div>

      <div>
        <Typography
          variant="h3"
          className="p-6 flex gap-x-4 gap-y-2 items-center justify-center flex-wrap mb-5"
        >
          Actual
        </Typography>

        <div className="w-screen px-6 mt-3 flex justify-center">
          <div className="bg-white p-4 rounded-lg flex-1">
            {dashboardData ? (
              <ResponsiveContainer
                width="100%"
                height={400}
              >
                <BarChart data={dashboardData.activitiesByDates}>
                  <XAxis
                    dataKey="date"
                    tickFormatter={(value) => dayjs(value).format('MMM. D')}
                  />

                  <YAxis
                    tickSize={1}
                    type="number"
                    allowDecimals={false}
                  />

                  <Tooltip
                    cursor={false}
                    content={
                      <CustomBarTooltip
                        tracks={selectedTracks}
                        graphBy="tracks"
                      />
                    }
                  />

                  <Legend
                    formatter={(value) => {
                      const trackName = selectedTracks.find((track) => `${value}`.includes(track));

                      return trackName || '';
                    }}
                    iconType="circle"
                  />

                  {!isLoading && !isFetching && brushStartIndex && (
                    <Brush
                      dataKey="date"
                      height={30}
                      stroke="#8884d8"
                      startIndex={brushStartIndex}
                      tickFormatter={() => ''}
                    />
                  )}

                  {selectedTracks.map((track) => (
                    <Bar
                      key={track}
                      dataKey={`tracks[${track}]`}
                      fill={GRAPH_TRACK_COLORS[track]}
                      radius={[8, 8, 4, 4]}
                      barSize={40}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data to show</div>
            )}
          </div>
        </div>
      </div>

      <div>
        <Typography
          variant="h3"
          className="p-6 flex gap-x-4 gap-y-2 items-center justify-center flex-wrap mb-5"
        >
          Baseline
        </Typography>

        <div className="w-screen px-6 mt-3 flex justify-center gap-2 flex-wrap">
          <div className="bg-white p-4 rounded-lg flex-1">
            {dashboardData ? (
              <ResponsiveContainer
                width="100%"
                height={400}
              >
                <BarChart data={dashboardData.activitiesByDates}>
                  <XAxis
                    dataKey="date"
                    tickFormatter={(value) => dayjs(value).format('MMM. D')}
                    allowDataOverflow
                  />

                  <YAxis
                    tickSize={1}
                    type="number"
                    allowDecimals={false}
                  />

                  <Tooltip
                    cursor={false}
                    content={<CustomBarTooltip providers={providerValues} />}
                  />

                  <Legend
                    formatter={(value) => {
                      const provider = providerValues.find(
                        (provider) => provider.ProviderID === value,
                      );

                      return provider?.ProviderName || '';
                    }}
                    iconType="circle"
                  />

                  {selectedProviderIds.map((providerId, index) => (
                    <Bar
                      key={providerId}
                      dataKey={providerId}
                      stackId="providers"
                      fill={GRAPH_COLORS[index]}
                      radius={[8, 8, 4, 4]}
                      barSize={40}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data to show</div>
            )}
          </div>
          {/* TODO: uncomment after customer approves statistic fields */}
          {/* eslint-disable-next-line etc/no-commented-out-code */}
          {/* <div className="flex lg:flex-col justify-center lg:justify-between gap-1 flex-wrap">
            <StatisticCard
              title="Currently Delayed Activities"
              value={dashboardData?.activitiesStatistics.delayedActivities || 0}
            />

            <StatisticCard
              title="Delayed Activities Duration"
              value={
                dashboardData
                  ? `${formatDuration(dashboardData.activitiesStatistics.totalDelayedDuration)}`
                  : 0
              }
            />

            <StatisticCard
              title="Total Activity State Updates"
              value={dashboardData?.activitiesStatistics.totalStateUpdates || 0}
              isInfo
            />

            <StatisticCard
              title="Total completed Activities"
              value={dashboardData?.activitiesStatistics.completedActivities || 0}
              isInfo
            />
          </div> */}
        </div>
      </div>

      <div className="pb-10">
        <div className="w-screen px-6 mt-3 flex gap-2 justify-center flex-wrap">
          <div className="bg-white p-4 rounded-lg flex-1">
            <Typography
              variant="h3"
              className="mb-5 p-6 flex gap-x-4 gap-y-2 items-center justify-center flex-wrap"
            >
              Total Amount Activities By Providers
            </Typography>

            {dashboardData ? (
              <ResponsiveContainer
                width="100%"
                height={400}
              >
                <PieChart>
                  <Pie
                    data={dashboardData?.activitiesByProviders}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={70}
                    cornerRadius={4}
                    fill="#82ca9d"
                    paddingAngle={1}
                    label
                  >
                    {dashboardData?.activitiesByProviders.map((entry, index) => (
                      <Cell
                        key={`cell-${entry.name}`}
                        fill={GRAPH_COLORS[index]}
                      />
                    ))}
                  </Pie>

                  <Tooltip
                    content={
                      <CustomBarTooltip
                        providers={providerValues}
                        withoutDate
                      />
                    }
                  />

                  <Legend
                    formatter={(value) => {
                      const provider = providerValues.find(
                        (provider) => provider.ProviderID === value,
                      );

                      return provider?.ProviderName || '';
                    }}
                    iconType="circle"
                  />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data to show</div>
            )}
          </div>

          <div className="bg-white p-4 rounded-lg flex-1">
            <Typography
              variant="h3"
              className="mb-5 p-6 flex gap-x-4 gap-y-2 items-center justify-center flex-wrap"
            >
              Types Of Activity Delays
            </Typography>

            {dashboardData ? (
              <ResponsiveContainer
                width="100%"
                height={400}
              >
                <PieChart>
                  <Pie
                    data={dashboardData?.activitiesByDelayCategories}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={70}
                    cornerRadius={4}
                    fill="#82ca9d"
                    paddingAngle={1}
                    label
                  >
                    {dashboardData.activitiesByDelayCategories.map((entry, index) => (
                      <Cell
                        key={`cell-${entry.name}`}
                        fill={GRAPH_COLORS[GRAPH_COLORS.length - 1 - index]}
                      />
                    ))}
                  </Pie>

                  <Tooltip
                    content={
                      <CustomBarTooltip
                        delayCategory={delayCategoriesValues}
                        graphBy="delayCategories"
                        withoutDate
                      />
                    }
                  />

                  <Legend
                    formatter={(value) => {
                      const delayName = delayCategoriesValues.find(
                        (delayCategory) => delayCategory.DelayCategoryID === value,
                      );

                      return delayName?.DelayCategory || '';
                    }}
                    iconType="circle"
                  />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data to show</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ActivitiesDashboard };
