import { FC } from 'react';

import { ReactComponent as MoreHorizontalIcon } from 'src/assets/icons/filled/menus/more-horizontal.svg';
import { clsx, range } from 'src/shared/utils';
import { DropDown } from 'src/shared/ui/dropDown';
import { IconButton } from 'src/shared/ui/iconButton';

type PageDropDownProps = {
  totalPages: number;
  onSelect: (pageNumber: number) => void;
  currentPage: number;
};

const PageDropDown: FC<PageDropDownProps> = ({ totalPages, onSelect, currentPage }) => {
  const pages = range(1, totalPages);

  const selectPage = (pageNumber: number) => {
    onSelect(pageNumber);
  };

  return (
    <DropDown
      options={{ placement: 'bottom-start' }}
      config={{
        itemsElementClassName: clsx(
          'shadow-[0px_2px_66px_-10px_#0000000F]',
          'w-[82px]',
          'rounded-lg',
          'bg-bgColor-card',
        ),
      }}
      renderElement={() => (
        <IconButton
          color="basic"
          iconSize="md"
          iconClassName="fill-textColor-tertiary"
          className="bg-bgColor-ticketCard w-8 h-8"
        >
          <MoreHorizontalIcon />
        </IconButton>
      )}
      items={pages.map((pageNumber) => {
        const isCurrentPage = pageNumber === currentPage;

        return {
          value: pageNumber,
          label: (
            <div
              role="button"
              key={pageNumber}
              onClick={() => selectPage(pageNumber)}
              className={clsx(
                'bg-white py-2 px-3 text-sm cursor-pointer font-semibold text-center text-textColor-secondary hover:bg-[rgba(18,60,142,0.05)] hover:text-textColor-primary duration-200 transition-all active:text-brandingColor-primary-gradient active:bg-[rgba(18,60,142,0.1)]',
                isCurrentPage && 'text-brandingColor-primary-gradient bg-[rgba(18,60,142,0.1)]',
              )}
            >
              <span>{pageNumber}</span>
            </div>
          ),
        };
      })}
    />
  );
};

export { PageDropDown };
