import { api, BoardFilters } from 'src/store/api/api';
import {
  SortOrder,
  SurveyAnswerEntity,
  SurveyAnswerResponse,
  SurveyAnswerReportEntity,
  SurveyAnswersForExportResponse,
  SurveyAnswersStatsByType,
} from 'src/shared/types';

export type CreateSurveyAnswer = {
  answer: any;
  surveyId: string;
};

const surveyAnswerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSurveyAnswers: builder.query<SurveyAnswerEntity[], ''>({
      query: () => ({
        url: '/survey-answers',
        method: 'GET',
      }),
      providesTags: ['SurveyAnswer'],
      transformResponse: (response: { data: SurveyAnswerEntity[] }) => response.data,
    }),
    getSurveyAnswersByFormId: builder.query<
      SurveyAnswerResponse,
      {
        formId: string;
        pagination: {
          pageSize?: string;
          page?: string;
        };
        filters: {
          searchField: string;
          path: string[];
        };
        sort?: {
          fieldKey: string;
          order: SortOrder;
        };
      }
    >({
      query: ({ formId, pagination, filters, sort }) => ({
        url: `/survey-answers/form/${formId}`,
        method: 'GET',
        params: {
          pagination,
          filters,
          sort,
        },
      }),
      providesTags: ['SurveyAnswer'],
      transformResponse: (response: SurveyAnswerResponse) => response,
    }),
    getSurveyAnswersForExportByFormId: builder.query<
      SurveyAnswersForExportResponse,
      { formId: string }
    >({
      query: ({ formId }) => ({
        url: `/survey-answers/form/${formId}/export`,
        method: 'GET',
      }),
      providesTags: ['SurveyAnswer'],
    }),
    createSurveyAnswer: builder.mutation<SurveyAnswerEntity, CreateSurveyAnswer>({
      query: (body) => ({
        url: '/survey-answers',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['SurveyAnswer'],
    }),

    createPublicSurveyAnswer: builder.mutation<SurveyAnswerEntity, CreateSurveyAnswer>({
      query: (body) => ({
        url: '/survey-answers/public',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['SurveyAnswer'],
    }),

    editSurveyAnswer: builder.mutation<
      SurveyAnswerEntity,
      CreateSurveyAnswer & {
        answerId: string;
      }
    >({
      query: ({ answerId, ...body }) => ({
        url: `/survey-answers/${answerId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['Survey', 'SurveyAnswer'],
    }),
    deleteSurveyAnswer: builder.mutation<SurveyAnswerEntity, string>({
      query: (id) => ({
        url: `/survey-answers/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags(['Survey']));
        } catch {
          dispatch(api.util.invalidateTags(['Survey']));
        }
      },
      invalidatesTags: ['Survey', 'SurveyAnswer'],
    }),
    getSurveyAnswersReport: builder.query<
      {
        surveyAnswers: SurveyAnswerReportEntity[];
        total: number;
        pageSize: number;
        totalPages: number;
      },
      {
        filters?: BoardFilters;
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        sorting?: {
          key?: keyof SurveyAnswerReportEntity | null;
          order?: SortOrder;
        };
      }
    >({
      query: ({ pagination, filters, sorting }) => ({
        url: `/survey-answers/report`,
        params: {
          filters: {
            completedBy: filters?.completedBy,
            formType: filters?.formType,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
          },
          pagination,
          sorting,
        },
      }),
      providesTags: ['SurveyAnswer'],
      transformResponse: (response: {
        data: {
          surveyAnswers: SurveyAnswerReportEntity[];
          total: number;
          pageSize: number;
          totalPages: number;
        };
      }) => response.data,
    }),
    getSurveyAnswersReportAmount: builder.query<
      {
        total: number;
      },
      ''
    >({
      query: () => ({
        url: `/survey-answers/report/amount`,
      }),
      providesTags: ['SurveyAnswer'],
      transformResponse: (response: {
        data: {
          total: number;
        };
      }) => response.data,
    }),
    getSurveyAnswersStatsByType: builder.query<SurveyAnswersStatsByType, ''>({
      query: () => ({
        url: `/survey-answers/stats/by-type`,
      }),
      providesTags: ['SurveyAnswer'],
      transformResponse: (response: { data: SurveyAnswersStatsByType }) => response.data,
    }),
  }),
});

export const {
  useGetSurveyAnswersQuery,
  useGetSurveyAnswersByFormIdQuery,
  useGetSurveyAnswersReportQuery,
  useGetSurveyAnswersReportAmountQuery,
  useLazyGetSurveyAnswersByFormIdQuery,
  useLazyGetSurveyAnswersForExportByFormIdQuery,
  useCreateSurveyAnswerMutation,
  useCreatePublicSurveyAnswerMutation,
  useEditSurveyAnswerMutation,
  useDeleteSurveyAnswerMutation,
  useGetSurveyAnswersStatsByTypeQuery,
} = surveyAnswerApi;
