const BYTE = 1;
const KILOBYTE = 1024 * BYTE;
const MEGABYTE = 1024 * KILOBYTE;
const GIGABYTE = 1024 * MEGABYTE;

export function getTotalFilesSize(files: File[]): number {
  return files.reduce((previousValue, currentFile) => {
    return previousValue + currentFile.size;
  }, 0);
}

export function matchIsFile(value: unknown): value is File {
  return typeof window !== 'undefined' && value instanceof File;
}

export function fileListToArray(filelist: FileList): File[] {
  return Array.from(filelist);
}

export function getFileDetails(value: File | File[]) {
  const name = matchIsFile(value) ? value.name : value[0]?.name || '';
  const parts = name.split('.');
  const extension = parts.pop() as string;
  const filenameWithoutExtension = parts.join('.');

  return {
    filename: filenameWithoutExtension,
    extension,
  };
}

export const formatFileSize = (size: number) => {
  if (size < KILOBYTE) return `${size} B`;
  if (size < MEGABYTE) return `${(size / KILOBYTE).toFixed(2)} KB`;
  if (size < GIGABYTE) return `${(size / MEGABYTE).toFixed(2)} MB`;
  return `${(size / GIGABYTE).toFixed(2)} GB`;
};
