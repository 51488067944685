import { ReactComponent as CheckmarkIcon } from './checkmark.svg';
import { ReactComponent as TrashIcon } from './trash.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as TargetIcon } from './target.svg';
import { ReactComponent as MinusIcon } from './minus-circle.svg';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as CloseCircleIcon } from './close-circle.svg';

export const Edit = {
  TrashIcon,
  EditIcon,
  TargetIcon,
  CheckmarkIcon,
  MinusIcon,
  PlusIcon,
  CloseCircleIcon,
};
