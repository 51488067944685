import { api } from 'src/store/api/api';
import { JELL, JELLDetails } from 'src/shared/types';

const JELLApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTicketJELL: builder.query<JELL[], ''>({
      query: () => ({
        url: '/jell/ticket',
      }),
      providesTags: () => ['Equipment'],
      transformResponse: (response: { data: JELL[] }) => response.data,
    }),
    getJobJELL: builder.query<JELL[], ''>({
      query: () => ({
        url: '/jell/job',
      }),
      providesTags: () => ['Equipment'],
      transformResponse: (response: { data: JELL[] }) => response.data,
    }),
    getJELLDetails: builder.query<JELLDetails, string>({
      query: (ticketId) => ({
        url: `/jell/${ticketId}`,
      }),
      providesTags: () => ['Equipment'],
      transformResponse: (response: { data: JELLDetails }) => response.data,
    }),
  }),
});

export const {
  useGetTicketJELLQuery,
  useGetJobJELLQuery,
  useGetJELLDetailsQuery,
  useLazyGetJELLDetailsQuery,
} = JELLApi;
