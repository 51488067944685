import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store';

import { PageSize, SortOrder, SurveyAnswerReportEntity } from 'src/shared/types';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { useGetSurveyAnswersReportQuery } from 'src/store/api';
import { IconButton } from 'src/shared/ui/iconButton';
import { Button } from 'src/shared/ui/button';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/filled/files/download.svg';
import { exportToExcelWithImg } from 'src/shared/utils/exceljs';
import { TextField } from 'src/shared/ui/textField';
import { Icon } from 'src/shared/ui/icon';
import { ReactComponent as SearchIcon } from 'src/assets/icons/filled/internet&code/search.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { not, dayjs, getPaginationRangeDays } from 'src/shared/utils';
import { getFormattedDate } from 'src/shared/ui/details/helpers';
import { selectConfig, filtersActions, configActions } from 'src/store/slices';
import { DatesStateRange, DateValueType } from 'src/shared/ui/datepicker/types';
import { BoardFilters } from 'src/store/api/api';

import { DateRange } from './dateRange';

const TYPE_FILTER = 'board';

const emptyBoardFilters: Omit<BoardFilters, 'startDate' | 'endDate'> = {
  owner: [],
  ownerSite: [],
  jobCategory: [],
  serviceLine: [],
  providerArea: [],
  providerRegion: [],
  providerBranch: [],
  division: [],
  formType: [],
  completedBy: [],
};

const initialStateDateFilter = {
  start: {
    startDate: null,
    endDate: null,
  },
  end: {
    startDate: null,
    endDate: null,
  },
};

const SurveyAnswersTable = () => {
  const dispatch = useAppDispatch();

  const [sortSettings, setSortSettings] = useState<{
    key: keyof SurveyAnswerReportEntity | null;
    order: SortOrder;
  }>({
    key: null,
    order: SortOrder.ASC,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>(15);
  const [datesState, setDatesState] = useState<DatesStateRange>(initialStateDateFilter);
  const [query, setQuery] = useState('');

  const config = useSelector(selectConfig);

  const debouncedQuery = useDebounce(query, 300);

  const [startDate, endDate] = getPaginationRangeDays(datesState);

  const {
    data = {
      surveyAnswers: [],
      pageSize: 0,
      total: 0,
      totalPages: 0,
      search: '',
    },
    isError,
    isFetching,
    isLoading,
  } = useGetSurveyAnswersReportQuery({
    filters: {
      ...config.boardFilters,
      startDate,
      endDate,
    },
    pagination: {
      page: String(currentPage),
      pageSize: String(pageSize),
      search: debouncedQuery,
    },
    sorting: sortSettings,
  });

  const {
    data: allData = {
      surveyAnswers: [],
    },
    isFetching: isAllSurveyAnswersFetching,
  } = useGetSurveyAnswersReportQuery({});

  const { surveyAnswers = [], total } = data;

  // *  Data for the Excel Export
  const { surveyAnswers: allSurveyAnswers } = allData;

  const headers: TableHeaderType<SurveyAnswerReportEntity>[] = useMemo(
    () => [
      {
        title: 'Category',
        field: 'category',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) => surveyAnswer?.category,
      },
      {
        title: 'Completed By',
        field: 'CompletedBy',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) => surveyAnswer.CompletedBy,
      },
      {
        title: 'Created At',
        field: 'createdAt',
        className: 'min-w-[170px]',
        canSort: true,
        render: (surveyAnswer) =>
          getFormattedDate(String(dayjs(surveyAnswer.createdAt).add(8, 'hour'))),
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        className: 'min-w-[170px]',
        canSort: true,
        render: (surveyAnswer) =>
          getFormattedDate(String(dayjs(surveyAnswer.updatedAt).add(8, 'hour'))),
      },
      {
        title: 'Job Number',
        field: 'JobNum',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) => surveyAnswer.JobNum,
      },
      {
        title: 'Shift',
        field: 'Shift',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) => surveyAnswer.Shift,
      },
      {
        title: 'Job Description',
        field: 'Job Description',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) => surveyAnswer['Job Description'],
      },
      {
        title: 'Client/Plant',
        field: 'ClientPlant',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) => surveyAnswer.ClientPlant,
      },
      {
        title: 'Unit',
        field: 'Unit',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) => surveyAnswer.Unit,
      },
      {
        title: 'Loc/equip',
        field: 'LocEquip',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) => surveyAnswer.LocEquip,
      },
      {
        title: 'Supervisor',
        field: 'Supervisor',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) => surveyAnswer.Supervisor,
      },
      {
        title: 'Supervisor signature',
        field: 'SupervSign',
        className: 'min-w-[170px]',
        canSort: false,
        render: (surveyAnswer) =>
          surveyAnswer.SupervSign ? (
            <img
              className="h-32 aspect-[5/3]"
              src={surveyAnswer.SupervSign}
              alt="SupervSign"
              loading="lazy"
            />
          ) : null,
      },
    ],
    [],
  );

  const handleExportToExcel = useCallback(async () => {
    const excelData = allSurveyAnswers.map((surveyAnswer) => ({
      'Category': surveyAnswer.category,
      'Created At': getFormattedDate(String(dayjs(surveyAnswer.createdAt).add(8, 'hour'))),
      'Updated At': getFormattedDate(String(dayjs(surveyAnswer.updatedAt).add(8, 'hour'))),
      'Completed By': Array.isArray(surveyAnswer.CompletedBy)
        ? surveyAnswer.CompletedBy.join(', ')
        : surveyAnswer.CompletedBy,
      'Job Number': surveyAnswer.JobNum,
      'Shift': surveyAnswer.Shift,
      'Job Description': surveyAnswer['Job Description'],
      'Client/Plant': surveyAnswer.ClientPlant,
      'Unit': surveyAnswer.Unit,
      'Loc/equip': surveyAnswer.LocEquip,
      'Supervisor': surveyAnswer.Supervisor,
      'Supervisor signature': surveyAnswer.SupervSign,
    }));

    exportToExcelWithImg(excelData, 'Survey Answers', ['Supervisor signature']);
  }, [allSurveyAnswers]);

  const handleChangeDatesState = useCallback((key: keyof DatesStateRange, value: DateValueType) => {
    setDatesState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const handleClearDatesState = useCallback(() => {
    setDatesState(initialStateDateFilter);
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedQuery, pageSize]);

  const clearFilterData = () => {
    dispatch(filtersActions.removeFiltersByType(TYPE_FILTER));

    dispatch(
      configActions.setSelectedFilters({
        type: TYPE_FILTER,
        filters: emptyBoardFilters,
      }),
    );
  };

  useEffect(() => {
    clearFilterData();
    return () => {
      clearFilterData();
    };
  }, []);

  return (
    <div className="flex flex-col gap-y-6 pb-6">
      {not(isError) && (
        <div className="flex justify-end items-center gap-x-6 w-full flex-wrap">
          <DateRange
            datesState={datesState}
            handleChange={handleChangeDatesState}
            handleClear={handleClearDatesState}
          />

          <Button
            size="lg"
            variant="filled"
            endIcon={<DownloadIcon fill="#231F20" />}
            className="border-[#636874] flex !gap-3 !p-0"
            onClick={handleExportToExcel}
            disabled={isAllSurveyAnswersFetching}
          >
            Excel
          </Button>

          <TextField
            startIcon={
              <Icon
                icon={<SearchIcon />}
                className="absolute left-[16px] top-[12px] fill-textColor-tertiary"
              />
            }
            endIcon={
              query ? (
                <IconButton
                  iconClassName="fill-textColor-tertiary"
                  size="none"
                  className="absolute right-[12px] top-[12px] bg-transparent"
                  onClick={() => setQuery('')}
                >
                  <CloseCircleIcon />
                </IconButton>
              ) : undefined
            }
            placeholder="Search by Ticket properties"
            className="flex-1 max-w-[450px]"
            inputClassName="bg-bgColor-menu"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      )}

      <Table
        headers={headers}
        data={surveyAnswers}
        sortSettings={sortSettings}
        isFetching={isFetching}
        isLoading={isLoading}
        withIndicator
        variant="secondary"
        containerClassName="h-[600px]"
        highlight={debouncedQuery}
        scroll
        sticky
        isError={isError}
        onSort={setSortSettings}
        pagination={{
          currentPage,
          onPageChange: (page: number) => setCurrentPage(page),
          onPageSizeChange: (pageSize: PageSize) => setPageSize(pageSize),
          pageSize,
          total,
        }}
      />
    </div>
  );
};

export { SurveyAnswersTable };
