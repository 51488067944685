import { api } from 'src/store/api/api';
import { AdminDataResponse, ModelsOptions } from 'src/shared/types';

const adminManagerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getModelsOptions: builder.query<ModelsOptions, void>({
      query: () => ({
        url: '/admin/helpers/models-options',
        method: 'GET',
      }),

      transformResponse: (response: { data: AdminDataResponse }) => response.data.modelsOptions,
    }),
  }),
});

export const { useGetModelsOptionsQuery } = adminManagerApi;
