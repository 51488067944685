import { FC } from 'react';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { Typography } from 'src/shared/ui/typography';
import { not } from 'src/shared/utils';

const CustomTooltip: FC<TooltipProps<ValueType, NameType>> = ({ active, payload }) => {
  const isTooltipVisible = active && payload && payload.length;

  if (not(isTooltipVisible)) return null;

  const {
    name,
    value,
    payload: { color },
  } = payload![0];

  return (
    <div className="flex flex-col gap-5 p-4 bg-white border border-outlineColor-input-border rounded-lg">
      <div className="flex justify-between">
        <Typography
          variant="c3"
          className="text-textColor-secondary"
        >
          {name}
        </Typography>
      </div>

      <div className="flex items-center gap-2">
        <div
          className="w-4 h-4 rounded-full"
          style={{ backgroundColor: color }}
        />

        <Typography
          variant="p1"
          fontWeight="bold"
        >
          {`${value} ticket(s)`}
        </Typography>
      </div>
    </div>
  );
};

export { CustomTooltip };
