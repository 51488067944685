import { FC, ReactNode } from 'react';

import { Tab } from './ui/Tab';

type TabItem = {
  title: string;
  component: ReactNode;
  amount?: ReactNode;
};

type TabsProps = {
  items: TabItem[];
  currentTabIndex: number;
  setCurrentTabIndex: (index: number) => void;
};

const Tabs: FC<TabsProps> = ({ items, currentTabIndex, setCurrentTabIndex }) => {
  return (
    <div className="flex gap-x-2">
      {items.map((item, index) => (
        <Tab
          key={item.title}
          index={index}
          title={item.title}
          amount={item.amount}
          currentTabIndex={currentTabIndex}
          onClick={() => setCurrentTabIndex(index)}
        />
      ))}
    </div>
  );
};

export { Tabs };
