import { FC } from 'react';

import { clsx } from 'src/shared/utils/clsx';

type SchedulerEmptyColumnProps = {
  className?: string;
};

const SchedulerEmptyColumn: FC<SchedulerEmptyColumnProps> = ({ className }) => {
  return (
    <div
      className={clsx(
        'sticky top-0 z-[10] w-full min-w-[12px] bg-transparent',
        'border-outlineColor-input-border',
        className,
      )}
    >
      <div className="w-full flex justify-center items-center bg-bgColor-card border-b border-outlineColor-input-border h-[56px] gap-x-3" />
    </div>
  );
};

export { SchedulerEmptyColumn };
