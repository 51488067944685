import { Typography } from 'src/shared/ui/typography';

const NotFound = () => {
  return (
    <div className="w-full h-full flex items-center justify-center flex-col gap-2">
      <Typography variant="h1">Oops!</Typography>

      <Typography variant="h2">404 - Page Not Found</Typography>

      <Typography variant="p2">Sorry, an unexpected error has occurred.</Typography>
    </div>
  );
};

export { NotFound };
