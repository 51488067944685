import { ReactComponent as ChevronRight } from './chevron-right.svg';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';
import { ReactComponent as ArrowIOSRightIcon } from './arrow-ios-right.svg';
import { ReactComponent as ArrowIOSLeftIcon } from './arrow-ios-left.svg';

export const Chevrons = {
  ChevronRight,
  ChevronLeft,
  ArrowIOSLeftIcon,
  ArrowIOSRightIcon,
};
