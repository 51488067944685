import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'src/store';
import { calendarActions } from 'src/store/slices';
import { schedulerBoardSettingsActions } from 'src/store/slices/schedulerBoardSettings';

const useScrollToToday = <T extends HTMLDivElement>(isCalendar?: boolean) => {
  const ref = useRef<T>(null);
  const dispatch = useAppDispatch();
  const shouldScrollToToday = useSelector((state: RootState) =>
    isCalendar
      ? state.calendar.shouldScrollToToday
      : state.schedulerBoardSettings.shouldScrollToToday,
  );

  useEffect(() => {
    if (shouldScrollToToday && ref.current)
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });

    return () => {
      if (isCalendar) {
        dispatch(calendarActions.changeShouldScrollToToday(false));
      } else {
        dispatch(schedulerBoardSettingsActions.changeShouldScrollToToday(false));
      }
    };
  }, [ref, shouldScrollToToday, dispatch, isCalendar]);

  return { ref };
};

export { useScrollToToday };
