import { FC, PropsWithChildren } from 'react';

import { showToastErrorMessage } from 'src/shared/utils';
import { type PersonEntity, type JobEntity, type TicketEntity } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { useAssignToTicketMutation, TicketConnections } from 'src/store/api';
import { SupervisorsList } from 'src/shared/ui/supervisorsList';

type TicketCardSupervisorsListProps = {
  ticket: TicketEntity;
  job: JobEntity;
  isTicketDropDisabled: boolean;
} & PropsWithChildren;

const TicketCardSupervisorsList: FC<TicketCardSupervisorsListProps> = ({
  ticket,
  job,
  isTicketDropDisabled,
}) => {
  const [assignToTicket] = useAssignToTicketMutation();

  const handleSupervisorDrop = async (person: PersonEntity, sourceEntityId: string) => {
    try {
      await assignToTicket({
        ticketId: ticket.id,
        entityType: TicketConnections.supervisor,
        entityId: person.id,
        sourceEntityId,
      }).unwrap();
    } catch {
      showToastErrorMessage(
        `Sorry, an error occurred, when you tried to assign ${person.firstName} ${person.lastName} for a ticket, please check your internet connection`,
      );
    }
  };

  return (
    <>
      <Typography
        variant="p2"
        fontWeight="medium"
      >
        Ticket Owners
      </Typography>

      <SupervisorsList
        supervisors={ticket?.supervisors}
        onSupervisorDrop={handleSupervisorDrop}
        cardId={job?.id}
        ticket={ticket}
        isDropDisabled={isTicketDropDisabled}
        isTicketCard
      />
    </>
  );
};

TicketCardSupervisorsList.displayName = 'TicketCardSupervisorsList';
export { TicketCardSupervisorsList };
