import { useFormik } from 'formik';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useSelector } from 'react-redux';

import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { TextAreaInput } from 'src/pages/activities/ui';
import { Button } from 'src/shared/ui/button';
import { Icons } from 'src/assets/icons';
import { InputFileUpload } from 'src/shared/ui/inputFileUpload';
import { selectCurrentUser } from 'src/store/slices';
import { useGetActionByIdQuery, usePostActionStateMutation } from 'src/store/api';
import { showToastErrorMessage } from 'src/shared/utils';
import { Spinner } from 'src/shared/ui/spinner';

import { updateActionSchema } from '../../helpers/validation';
import { ActionStatus } from '../../helpers/types';

interface UpdateActionModalProps {
  isOpen: boolean;
  closeModal: () => void;
  idToUpdate: string;
  onUpdated: () => void;
}

const UpdateActionModal: FC<UpdateActionModalProps> = ({
  isOpen,
  closeModal,
  idToUpdate,
  onUpdated,
}) => {
  const toggleModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const {
    data: actionData,
    isLoading,
    isFetching,
  } = useGetActionByIdQuery(
    {
      id: idToUpdate,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const user = useSelector(selectCurrentUser);

  const [postActionState, { isLoading: isPosting }] = usePostActionStateMutation();

  const initialValues = useMemo(
    () => ({
      actionStatus: ActionStatus.Open,
      assignedTo: {
        id: '',
        name: '',
      },
      notes: '',
      files: [],
    }),
    [],
  );

  const onSubmit = useCallback(
    async (data: z.infer<typeof updateActionSchema>) => {
      if (!user || !idToUpdate) {
        return;
      }
      try {
        await postActionState({
          actionId: idToUpdate,
          assignedTo: data.assignedTo.id,
          status: data.actionStatus,
          notes: data.notes,
          updatedBy: user.email,
        }).unwrap();

        onUpdated();
        closeModal();
      } catch (error) {
        showToastErrorMessage(`There was an error trying to create action state`);
      }
    },
    [closeModal, user, idToUpdate, postActionState, onUpdated],
  );

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(updateActionSchema),
    initialValues,
    enableReinitialize: true,
  });

  const handleFileChange = (files: File[]) => {
    handleChange({
      target: {
        name: 'files',
        value: files,
      },
    });
  };

  const assignees = useMemo(() => {
    const people =
      actionData?.personnels.map(({ OwnerPersonnelEmail, FirstName, LastName, BadgeNo }) => ({
        id: OwnerPersonnelEmail,
        name: `${FirstName} ${LastName}${BadgeNo ? ` (${BadgeNo})` : ''}`,
      })) || [];

    return people.map(({ id, name }) => ({
      label: <SelectInputItem selected={values.assignedTo.name === name}>{name}</SelectInputItem>,
      value: id,
      onClick: () => {
        handleChange({
          target: {
            name: 'assignedTo',
            value: {
              id,
              name,
            },
          },
        });
      },
    }));
  }, [handleChange, values.assignedTo.name, actionData]);

  useEffect(() => {
    if (actionData?.action.actualState?.assignedTo) {
      const assignedTo = actionData.personnels.find(
        (person) => person.OwnerPersonnelEmail === actionData.action.actualState?.assignedTo,
      );

      if (assignedTo) {
        setFieldValue('assignedTo', {
          id: actionData.action.actualState.assignedTo,
          name: `${assignedTo.FirstName} ${assignedTo.LastName}${
            assignedTo.BadgeNo ? ` (${assignedTo.BadgeNo})` : ''
          }`,
        });
      }
    }
    if (actionData?.action.actualState?.status) {
      setFieldValue('actionStatus', actionData.action.actualState.status);
    }
    if (actionData?.action.actualState?.notes) {
      setFieldValue('notes', actionData.action.actualState.notes);
    }
  }, [actionData, setFieldValue]);

  const actionStatusOptions = useMemo(() => {
    return Object.entries(ActionStatus).map(([id, name]) => ({
      label: <SelectInputItem selected={values.actionStatus === id}>{name}</SelectInputItem>,
      value: id,
      onClick: () => {
        handleChange({
          target: {
            name: 'actionStatus',
            value: id as ActionStatus,
          },
        });
      },
    }));
  }, [handleChange, values.actionStatus]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[calc(100%-30px)] md:w-[646px] overflow-y-auto min-h-[300px]"
      removeScroll
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        Add Update
      </Typography>

      {isLoading ? (
        <Spinner
          withBackdrop
          fallbackText="Loading..."
        />
      ) : (
        <form
          className="w-full"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-y-8 w-full max-h-[60vh] lg:max-h-[50vh] min-h-[368px] md:h-[616px] overflow-y-auto p-1">
            <div className="flex gap-x-8 w-full">
              <SelectInput
                isRequired
                required
                name="actionStatus"
                label="Action Status"
                value={ActionStatus[values.actionStatus as keyof typeof ActionStatus]}
                placeholder="Action Status..."
                onClear={() => setFieldValue('actionStatus', '')}
                items={actionStatusOptions}
              />
            </div>

            <SelectInput
              isRequired
              required
              name="assignedTo"
              label="Assigned To"
              value={values.assignedTo.name}
              placeholder="Assigned To ..."
              onClear={() => setFieldValue('assignedTo', '')}
              items={assignees}
              disabled={isLoading || isFetching}
            />

            <TextAreaInput
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'notes',
                    value,
                  },
                })
              }
              value={values.notes}
              label="Notes"
              placeholder="Add notes"
            />

            <div className="flex flex-col gap-y-2">
              <label
                htmlFor="file-upload-input"
                className="text-base font-bold text-textColor-primary"
              >
                Upload Files
              </label>

              <InputFileUpload
                handleFileChange={handleFileChange}
                files={values.files}
                disabled={false}
                multiple={false}
              />
            </div>
          </div>

          <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
            <Button
              type="button"
              variant="outlined"
              color="basic"
              size="lg"
              onClick={closeModal}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              color="primary"
              size="lg"
              endIcon={<Icons.Outlined.Edit.CheckmarkIcon className="fill-white" />}
              autoFocus
              disabled={isPosting}
            >
              Add Update
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export { UpdateActionModal };
