import React, { FC } from 'react';
import { Tooltip } from 'react-tooltip';

import { clsx } from '../../utils';

type EndsTomorrowIndicatorProps = {
  isTooltipAvailable: boolean;
  className?: string;
  tooltipId?: string;
};

const EndsTomorrowIndicator: FC<EndsTomorrowIndicatorProps> = ({
  className,
  isTooltipAvailable,
  tooltipId,
}) => {
  return (
    <>
      <span
        className={clsx(
          'underline text-base leading-4 font-semibold text-textColor-tertiary cursor-default',
          className,
        )}
        data-tooltip-id={tooltipId || 'ends-tomorrow'}
      >
        +1
      </span>

      {isTooltipAvailable && (
        <Tooltip
          id="ends-tomorrow"
          place="right"
          content="Ends Next Day"
          className="max-w-[200px] z-[1] bg-[rgba(0,0,0,0.7)]"
        />
      )}
    </>
  );
};

export { EndsTomorrowIndicator };
