import { FC, PropsWithChildren, HTMLAttributes } from 'react';
import { Box, DialogTitle, Divider, IconButton, SxProps, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type HeaderDialogProps = PropsWithChildren<HTMLAttributes<HTMLElement>> & {
  title: string;
  sx?: SxProps<Theme>;
  onClose?: () => void;
};

const HeaderDialog: FC<HeaderDialogProps> = ({ children, title, sx, onClose, ...rest }) => {
  return (
    <Box
      sx={{ ...sx }}
      {...rest}
    >
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DialogTitle
            sx={{ p: 0 }}
            id="responsive-dialog-title"
          >
            {title}
          </DialogTitle>
          {!!onClose && (
            <IconButton
              size="small"
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        {children}
      </Box>

      <Divider />
    </Box>
  );
};

export { HeaderDialog };
